import { TDR } from 'tdr-common';
import config from '../common/config';

export async function approveLargeGroupProposal(reservation: TDR.Reservation) {
	const response = await fetch(`${config.apiHost}/userAcceptProposal`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(reservation)

	});
	return await response.json();
}


export async function declineLargeGroupProposal(reservation: TDR.Reservation) {
	const response = await fetch(`${config.apiHost}/userDenyProposal`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},

		body: JSON.stringify(reservation)

	});
	return await response.json();
}

