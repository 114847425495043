import { TDR } from 'tdr-common';
import config from '../common/config';
import Checkout = TDR.CheckoutSession;
import { useMutation } from '@tanstack/react-query';
import { ErrorResponse } from '../common/ErrorResponse';

export const MUTATION_KEY = 'confirmFreeCheckoutSession';

export function useConfirmFreeCheckoutSession() {
	const mutation = useMutation({
		mutationKey: [MUTATION_KEY],
		mutationFn: (input: Checkout.ConfirmFreeCheckoutSessionInput) => confirmFreeCheckoutSession(input),
		scope: {
			id: 'confirmFreeCheckoutSession'
		}
	});

	return mutation;
}

async function confirmFreeCheckoutSession(
	input: Checkout.ConfirmFreeCheckoutSessionInput
): Promise<Checkout.CreateCheckoutSessionOutput> {
	const response = await fetch(`${config.apiHost}/confirmFreeCheckoutSession`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as Checkout.CreateCheckoutSessionOutput;
	if (!response.ok) {
		throw new ErrorResponse(response, responsePayload);
	}

	return responsePayload;
}
