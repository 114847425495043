import React from 'react';
import styles from './Header.module.scss';

type HeaderProps = {
	title: string;
	subtitle?: string;
	iconLeft?: React.ReactNode;
	iconLeftOnClick?: () => void;
	iconRight?: React.ReactNode;
	iconRightOnClick?: () => void;
};

export const Header = ({
	title,
	subtitle,
	iconLeft,
	iconLeftOnClick,
	iconRight,
	iconRightOnClick
}: HeaderProps) => {
	return (
		<header className={styles.Container}>
			{iconLeft && (
				<div className={styles.Icon} onClick={iconLeftOnClick}>
					{iconLeft}
				</div>
			)}

			<div className={`${styles.Text} ${iconLeft ? styles['Text--center'] : ''}`}>
				{subtitle && <h3>{subtitle}</h3>}
				<h1>{title}</h1>
			</div>

			<div className={styles.Icon} onClick={iconRightOnClick}>
				{iconRight}
			</div>
		</header>
	);
};
