import React from 'react';
import { TDR } from 'tdr-common';
import { getDisplayTime } from '../../helpers/getDisplayTime';
import { useOutletContext } from 'react-router-dom';
import { AvailabilityGuardContext } from '../../routes/AvailabilityGuard';

export interface TimeslotButtonProps {
	restaurant: TDR.Restaurant;
	table: TDR.Table;
	time: string;
	timeslot: TDR.TimeSlot;
	idx: number;
	onClick?: (evt: any, time: string, timeslot: TDR.TimeSlot, price?: number) => void;
	selected?: boolean;
	hideFreePricing?: boolean;
}

export function TimeslotButton({
	restaurant,
	table,
	time,
	selected,
	timeslot,
	idx,
	onClick,
	hideFreePricing = false
}: TimeslotButtonProps) {
	const context = useOutletContext<AvailabilityGuardContext>();
	const searchFilters = context?.searchFilters;
	const showTimeslotPrice = !!table && (timeslot.regularPrice > 0 || !hideFreePricing);

	const disabled = !timeslot?.available ? 'timeButtonDisabled' : '';
	const selectedClassName = selected ? 'timeButtonSelected' : '';
	const hidden = !time ? 'invisible' : '';
	const timeSlotSizeAdjust = showTimeslotPrice ? '' : 'timeSlotHalfHeight';
	const currencyConfig = TDR.CurrencyConfig[restaurant?.currency || TDR.Currency.USD];

	const currencySymbol = (
		<span style={currencyConfig.symbol === 'AED' ? { fontSize: '9px' } : { fontSize: '14px' }}>
			{currencyConfig.symbol}
		</span>
	);
	const price = timeslot.regularPrice;
	const priceTotaled = timeslot.isPerGuest ? price * searchFilters?.groupSize : price;

	if (showTimeslotPrice && price === undefined) {
		return <></>;
	}

	return (
		<button
			key={`${idx || time}`}
			id={`timeslot-button-${idx}`}
			disabled={hidden === 'invisible'}
			aria-hidden={hidden === 'invisible'}
			aria-pressed={selected}
			className={`timeSlotButton ${selectedClassName} ${disabled} ${hidden} ${timeSlotSizeAdjust}`}
			onClick={(evt) => {
				evt.stopPropagation();
				onClick(evt, time, timeslot, price);
			}}
			data-cy='timeslotButton'
		>
			<div className='timeslotInfo'>
				{showTimeslotPrice ? (
					<div className='timeslotTotal'>
						{currencyConfig.placement === TDR.CurrencyPlacement.BEFORE ? (
							<>
								{currencySymbol}
								<span className='timePricePriceAmount'>{priceTotaled}</span>
							</>
						) : (
							<>
								<span className='timePricePriceAmount'>{priceTotaled}</span>
								{currencySymbol}
							</>
						)}
					</div>
				) : (
					<></>
				)}
				<span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>{time ? getDisplayTime(time) : null}</span>
			</div>
		</button>
	);
}
