import { generateInvoice, legacyTimeslotsToPricingPolicies, TDR } from 'tdr-common';
import getReservationInvoice from '../api/getReservationInvoice';
import luxonToFirebaseTimestamp from './luxonToFirebaseTimestamp';
import { DateTime } from 'luxon';

export const getNewInvoiceForModifiedEpochOrGuestCount = async ({
	restaurant,
	table,
	newEpochInSeconds,
	newGuestCount,
	existingReservation,
	basePolicies
}: {
	restaurant: TDR.Restaurant;
	table: TDR.Table;
	newEpochInSeconds: number;
	newGuestCount: number;
	existingReservation: TDR.Reservation;
	basePolicies: TDR.PricingPolicy[];
}): Promise<Partial<TDR.Invoice>> => {
	const tableOverridePolicies = legacyTimeslotsToPricingPolicies(restaurant, [table]);
	const policies = [...tableOverridePolicies, ...basePolicies];
	const existingInvoice = await getReservationInvoice(existingReservation.id);

	const newReservation: TDR.Reservation = {
		...existingReservation,
		guests: newGuestCount,
		time: luxonToFirebaseTimestamp(DateTime.fromSeconds(newEpochInSeconds))
	};

	const newInvoice = generateInvoice(
		restaurant.currency,
		policies,
		newReservation,
		existingInvoice,
		restaurant,
		existingReservation,
		table
	);

	return newInvoice;
};
