import React from 'react';
import { Duration } from 'luxon';
import styles from './CheckoutTimer.module.scss';

type CheckoutTimerProps = {
	timeRemaining: number;
};

export const CheckoutTimer = ({ timeRemaining }: CheckoutTimerProps) => {
	const displayTime = timeRemaining ? Duration.fromObject({ seconds: timeRemaining }).toFormat('m:ss') : '...';

	return (
		<div className={styles.Container}>
			<p className={styles.Text}>
        We&apos;re holding this spot for you for{' '}
				<span className={styles.Time} aria-label='Checkout time remaining' role='timer' aria-atomic='true'>
					{displayTime}
				</span>
			</p>
		</div>
	);
};
