import React, { useEffect } from 'react';
import { Dialog, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../Dialog';
import { useDisclosure } from '../../../hooks/useDisclosure';
import DangerIcon from '../../../icons/yellow-triangle.svg';
import { CTAButton } from '../../buttons';

type ConfirmationDialogProps = {
	triggerButton: React.ReactElement;
	confirmButton: React.ReactElement;
	title: string;
	body: React.ReactNode;
	cancelButtonText?: string;
	icon?: 'danger' | 'info';
	isDone?: boolean;
};

export const ConfirmationDialog = ({ triggerButton, confirmButton, title, body, cancelButtonText = 'Cancel', icon, isDone }: ConfirmationDialogProps) => {
	const { close, open, isOpen } = useDisclosure();

	useEffect(() => {
		if (isDone) {
			close();
		}
	}, [isDone, close]);

	return (
		<Dialog
			open={isOpen}
			onOpenChange={(isOpen) => {
				if (!isOpen) {
					close();
				}
				else {
					open();
				}
			}}
		>
			<DialogTrigger asChild>{triggerButton}</DialogTrigger>

			<DialogContent hasCloseButton fillContainerWidth>
				<DialogHeader>
					{icon === 'danger' && (
						<img src={DangerIcon} alt='Warning' />
					)}
					<DialogTitle>{title}</DialogTitle>
				</DialogHeader>

				<DialogBody>{body}</DialogBody>

				<DialogFooter>
					{confirmButton}
					<CTAButton variant='dark' onClick={close} buttonText={cancelButtonText} />
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
