import React from 'react';
import { TDR } from 'tdr-common';
import { InfoDialog } from './InfoDialog';
import { DepositIcon } from '../../icons';
import { CancellationPolicy } from './CancellationPolicy';

type DepositInfoProps = {
	restaurant: TDR.Restaurant;
	invoice: TDR.Invoice;
};

export const DepositInfo = ({ restaurant, invoice }: DepositInfoProps) => {
	const cancelCutoff = (restaurant.reservationCutoffs.find((cutoff) => !!cutoff.cancel)?.cancel?.time || 24) + ' hours';

	return (
		<InfoDialog
			variant='feedback'
			title='Deposit'
			triggerButton={<DepositIcon />}
			body={
				<>
					<p>
            Deposits are sometimes required to confirm your booking. This fee is charged to you upfront and will be
            applied toward your final bill at the restaurant.
					</p>
					<p>
            The deposit is non-refundable past the cancellation window, as per the restaurant&apos;s{' '}
						<CancellationPolicy restaurant={restaurant} cancelCutoff={cancelCutoff} invoice={invoice} />
					</p>
				</>
			}
		/>
	);
};
