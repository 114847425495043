import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { MILLISECONDS_IN_A_MINUTE, TDR, toLuxon } from 'tdr-common';
import { getReservationOnSnapshot } from '../../../api/getReservation';
import { CheckoutURLParams } from '../../../common/types';
import { analytics } from '../../../helpers/analytics';
import { ConfirmationTimeout } from '../../../pages/errors/ConfirmationTimeout';
import { RestaurantGuardOutletContext } from '../../../routes/RestaurantGuard';
import { BookingConfirmed, Loading, RequestDenied, RequestSubmitted } from '../../confirmation';
import { ConfirmationProps } from '../../confirmation/types';

export const Confirmation = () => {
	const params = useParams<CheckoutURLParams>();

	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	const [booking, setBooking] = useState<TDR.Reservation>();
	const [loading, setLoading] = useState(booking === undefined);
	const [fetchBookingError, setFetchBookingError] = useState(false);

	const luxonBookingTime = toLuxon(booking?.time, booking?.timezone);
	const displayDate = luxonBookingTime?.toFormat('MMMM d');
	const displayTime = luxonBookingTime?.toFormat('h:mm a');

	useEffect(() => {
		let unsubscribe: () => void;
		let timeoutId: NodeJS.Timeout;

		const fetchBooking = async () => {
			setLoading(true);
			try {
				unsubscribe = getReservationOnSnapshot(params.checkoutId, (doc) => {
					if (doc.exists()) {
						setBooking(doc.data() as TDR.Reservation);
						clearTimeout(timeoutId);
						setLoading(false);
					}
				});

				timeoutId = setTimeout(() => {
					setLoading(false);
					setFetchBookingError(true);
					throw new Error(
						`Confirm booking timeout - failed to find reservation with id: ${params.checkoutId} within the alloted time.`
					);
				}, 2 * MILLISECONDS_IN_A_MINUTE);
			}
			catch (error) {
				const eventID = Sentry.captureException(error);
				analytics.track('Booking Confirmation Timed Out', {
					sentryErrorID: eventID,
					checkoutId: params.checkoutId
				});
			}
		};

		fetchBooking();

		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
			clearTimeout(timeoutId);
		};
	}, [params.checkoutId]);

	if (fetchBookingError) {
		return <ConfirmationTimeout sessionId={params.checkoutId} restaurantSlug={restaurant.slug} />;
	}

	if (loading || !booking) {
		return <Loading />;
	}

	const { AdminActionRequired, GuestActionRequired, Sent, Denied } = TDR.Reservation.LargeGroupStatus;
	const status = booking.largeGroupOptions?.largeGroupStatus;

	const isPendingRequest = booking.isLargeGroup && (status === AdminActionRequired || status === GuestActionRequired || status === Sent);
	const isDeniedRequest = booking.isLargeGroup && status === Denied;

	const props: ConfirmationProps = {
		linkTo: `/${restaurant.slug}/explore`,
		guests: booking.guests,
		time: displayTime,
		date: displayDate,
		restaurantName: restaurant.name,
		bookingId: booking.id,
		email: booking.email
	};

	if (isDeniedRequest) {
		return <RequestDenied {...props} />;
	}

	return isPendingRequest ? <RequestSubmitted {...props} /> : <BookingConfirmed {...props} />;
};
