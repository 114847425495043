import React from 'react';
import styles from './Divider.module.scss';


export interface DividerProps {
	width?: string | number;
}

export const Divider = ({ width }: DividerProps) => {
	const customStyles = {
		width: width || '100%'
	};

	return (
		<div className={styles.Divider} style={customStyles} />
	);
};

export const DividerWithText = ({ text }: { text: string}) => {
	return (
		<p className={styles.DividerWithText}>{text}</p>
	);
};