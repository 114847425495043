import React from 'react';
import styles from './CTAButton.module.scss';
import Spinner from '../Spinner';
import { Link, LinkProps, RelativeRoutingType, To } from 'react-router-dom';

export type CTALinkVariant = 'primary' | 'secondary' | 'text' | 'dark';

type CTALinkProps = LinkProps & {
	to: To;
	relative?: RelativeRoutingType;
	target?: string
	variant?: CTALinkVariant;
	text: string;
	textColor?: string;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	ariaLabel?: string;
	iconBefore?: React.ReactNode;
	iconAfter?: React.ReactNode;
	disabled?: boolean;
	loading?: boolean;
}

const CTALink = ({
	to,
	relative = 'path',
	target,
	variant = 'primary',
	text,
	textColor,
	onClick,
	ariaLabel,
	iconBefore,
	iconAfter,
	disabled = false,
	loading = false,
	...props
}: CTALinkProps) => {

	const classes = `${styles.Button} ${styles[`Button--${variant}`]} ${disabled ? styles['Button--disabled'] : ''}`;

	return (
		<Link
			to={to}
			relative={relative}
			target={target}
			rel='noreferrer'
			type='button'
			className={classes}
			onClick={onClick}
			title={text}
			aria-label={ariaLabel || text}
			aria-busy={loading}
			aria-disabled={disabled}
			style={{
				color: textColor
			}}
			{...props}
		>
			{loading ? <Spinner size='xs' /> : (
				<span>
					{iconBefore && iconBefore}
					{text}
					{iconAfter && iconAfter}
				</span>
			)}
		</Link>
	);
};

export default CTALink;