import React from 'react';
import { TDR } from 'tdr-common';
import { TableTag } from './TableTag';
import styles from './TableTag.module.scss';

type TableTagsProps = {
	attributes: TDR.Attributes.Attributes;
	guestCount?: string;
	resHours?: string;
};

export const TableTags: React.FC<TableTagsProps> = ({ attributes, guestCount, resHours }) => {
	return (
		<div className={styles.Container}>
			{guestCount && <TableTag label={guestCount} iconName='Profile2User' />}
			{resHours && <TableTag label={resHours} iconName='Clock' />}
			{Object.values(attributes)
				.flat(2)
				.map((attributeValue: TDR.Attributes.OptionValue, index: number) => (
					<TableTag
						label={attributeValue}
						key={`${attributeValue}-${index}`}
						iconName={TDR.Attributes.IconMapping[attributeValue]}
					/>
				))}
		</div>
	);
};
