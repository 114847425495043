import React from 'react';
import UpdatedIcon3D from '../../icons/3D.svg';
import styles from './VirtualViewButton.module.scss';

type VirtualViewButtonProps = {
	onClick: () => void;
	type: string;
};

export const VirtualViewButton = ({ onClick, type }: VirtualViewButtonProps) => {
	return (
		<button className={styles.ContainerLarge} onClick={onClick}>
			<img style={{ height: '120px', width: '120px' }} src={UpdatedIcon3D} alt='Show virtual view' />
			<p>Explore virtual view{!!type ? ` of the ${type.toLowerCase()}` : ''}.</p>
		</button>
	);
};
