import React, { useEffect } from 'react';
import { MODAL_OPENED_EVENT, analytics } from '../../../helpers/analytics';
import { ControlledDialog } from '../controlled-dialog/ControlledDialog';

type PastHorizonProps = {
	entityWithPolicies: any;
	isOpen: boolean;
	onClose: () => void;
};

export const PastHorizonInfo = ({ entityWithPolicies, isOpen, onClose }: PastHorizonProps) => {
	useEffect(() => {
		if (isOpen) {
			analytics.track(MODAL_OPENED_EVENT, { title: 'Horizon Past Modal' });
		}
	}, [isOpen]);

	const horizonPolicy = entityWithPolicies?.['availabilityRestrictingPolicies']?.find(
		(policy) => policy.type === 'BookingHorizon'
	);

	return (
		<ControlledDialog
			isOpen={isOpen}
			onClose={onClose}
			title={horizonPolicy?.displays?.[0]?.title ?? 'Too Far in Advance'}
			body={
				<span>
					{horizonPolicy?.displays?.[0]?.description ??
            'Bookings are not available this far in advance. Please try again later.'}
				</span>
			}
		/>
	);
};
