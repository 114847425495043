import { TDR } from 'tdr-common';
import { useQuery } from '@tanstack/react-query';
import config from '../common/config';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

export type getTableDto = {
	id: string;
	searchParams?: {
	 groupSize?: number;
	 date?: string;
	 time?: string;
	};
	location?: 'checkout' | 'table' | 'email';
}
export function useTableWithPolicies(input: getTableDto) {

	const query = useQuery({
		enabled: !!input?.id,
		queryKey: ['getTable', input],
		queryFn: () => getTableWithPolicies(input)
	});

	return query;
}

export function useTable(id: string) {
	const query = useQuery({
		enabled: !!id,
		queryKey: ['getTable', { id }],
		queryFn: () => getTable(id)
	});

	return query;
}
async function getTable (tableId: string): Promise<TDR.Table | null> {
	const db = getFirestore();
	const docRef = doc(db, 'Tables', tableId);
	const snapshot = await getDoc(docRef);
	return (snapshot.exists || null) && snapshot.data() as TDR.Table;
}

export async function getTableWithPolicies (input: getTableDto): Promise<TDR.TableWithPolicies | null> {

	const response = await fetch(`${config.adminApiHost}/tables`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as TDR.TableWithPolicies | null;

	return responsePayload;
}