import React from 'react';
import ErrorIcon from '../../icons/x-hex.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { CustomerSupportLink } from '../buttons';
import { ReturnToBookingPortal } from './buttons';
import { InactiveTableProps, TableVariant } from './types';

export const InactiveTable = ({ linkTo, variant = 'default', tableLabel = 'table' }: InactiveTableProps) => {
	const tableDeterminer: Record<TableVariant, 'this' | 'your'> = {
		default: 'this',
		withBooking: 'your'
	};

	const guestAction: Record<TableVariant, React.ReactNode> = {
		default: (
			<p>
        If you have any questions, please don&apos;t hesitate to contact us at <CustomerSupportLink />.
			</p>
		),
		withBooking: (
			<p>
        A member of our staff should be in contact with you shortly. If you have not been contacted, please email us at{' '}
				<CustomerSupportLink /> and we will make this right for you.
			</p>
		)
	};

	return (
		<ResultPageLayout headerIcon={ErrorIcon} iconAltText='Table Unavailable'>
			<ResultPageLayout.Title>Table Unavailable</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>
          We apologize for the inconvenience, but it appears {tableDeterminer[variant]} {tableLabel} has been removed
          from our system and cannot be accessed.
				</p>
				{guestAction[variant]}
			</ResultPageLayout.Body>

			<ResultPageLayout.Footer>
				<ReturnToBookingPortal to={linkTo} />
			</ResultPageLayout.Footer>
		</ResultPageLayout>
	);
};
