import React from 'react';
import { InputValidationError } from '../../form-inputs/InputValidationError';
import CTAButton from '../../buttons/CTAButton';

type FooterProps = {
	isCtaDisabled: boolean,
	handleCtaClick: () => void,
	confirming: boolean,
	errorMessage: string
}

export const Footer: React.FC<FooterProps> = ({ isCtaDisabled, handleCtaClick, confirming, errorMessage }) => {

	return	(
		<>
			{errorMessage && <InputValidationError message={errorMessage} />}
			<CTAButton
				buttonText={'Submit Request'}
				loading={confirming}
				disabled={confirming || isCtaDisabled}
				onClick={handleCtaClick}
			/>
		</>
	);
};
