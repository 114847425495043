import { useNavigate, useLocation } from 'react-router-dom';

const useNavigateWithQuery = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const navigateWithQuery = (path) => {
		navigate({ pathname: path, search: location.search });
	};

	return navigateWithQuery;
};

export default useNavigateWithQuery;