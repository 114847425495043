import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollRestoration = (key: string) => {
	const { pathname } = useLocation();

	useEffect(() => {
		const savedPosition = sessionStorage.getItem(key);
		if (savedPosition) {
			// window.scrollTo(0, parseInt(savedPosition, 10));
			window.scrollTo({ top: parseInt(savedPosition, 10), behavior: 'smooth' });
		}

		const handleScroll = () => {
			sessionStorage.setItem(key, window.scrollY.toString());
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [pathname, key]);
};

export default useScrollRestoration;
