import React from 'react';
import ArrowLeft2 from 'iconsax-react/dist/esm/ArrowLeft2';
import ArrowRight2 from 'iconsax-react/dist/esm/ArrowRight2';

import styles from './HorizontalCarouselNav.module.scss';

type HorizontalCarouselNavProps = {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	direction: 'next' | 'prev';
	hidden: boolean;
};

const HorizontalCarouselNav = ({ onClick, direction, hidden }: HorizontalCarouselNavProps) => {
	const icon = {
		prev: <ArrowLeft2 />,
		next: <ArrowRight2 />
	};

	return (
		<button
			className={styles.Button}
			onClick={onClick}
			disabled={hidden}
			title={direction === 'next' ? 'Next' : 'Previous'}
			aria-label={`Carousel ${direction} navigation button`}
		>
			{icon[direction]}
		</button>
	);
};

export default HorizontalCarouselNav;
