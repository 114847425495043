import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { TDR } from 'tdr-common';
import { analytics, LINK_CLICKED_EVENT, MODAL_OPENED_EVENT } from '../../../helpers/analytics';
import ArrowOutward from '../../../icons/arrow-outward.svg';
import ExternalLink from '../../buttons/ExternalLink';
import RestaurantEmailLink from '../../buttons/RestaurantEmailLink';
import RestaurantPhoneLink from '../../buttons/RestaurantPhoneLink';
import { ControlledDialog } from './ControlledDialog';

type NoAvailabilityProps = {
	isOpen: boolean;
	onClose: () => void;
	date: DateTime;
	groupSize: number;
	restaurant: TDR.Restaurant;
};

export const NoAvailability = ({ isOpen, onClose, date, groupSize, restaurant }: NoAvailabilityProps) => {
	const altPlatform = restaurant.settings?.alternativePlatform?.vendor;
	const altPlatformHref = restaurant.settings?.alternativePlatform?.href;

	const contactByPhone = altPlatform === 'phone';
	const contactByEmail = altPlatform === 'email';

	const handleThirdPartyRMSClick = () => {
		analytics.track(LINK_CLICKED_EVENT, {
			href: altPlatformHref,
			purpose: 'Redirect to RMS from No Availability Modal',
			platform: altPlatform
		});
	};

	const getActionText = () => {
		if (contactByPhone && !!restaurant.phoneNumber) {
			return (
				<span>
          Please try another date, or call us at <RestaurantPhoneLink phoneNumber={restaurant.phoneNumber} />.
				</span>
			);
		}

		if (contactByEmail && restaurant.contactEmail) {
			return (
				<span>
          Please try another date, or email us at <RestaurantEmailLink email={restaurant?.contactEmail} />.
				</span>
			);
		}

		if (!contactByEmail && !contactByPhone) {
			return (
				<span>
          Please try another date, or search on <strong>{TDR.RMSDisplayNameMap[altPlatform]}</strong>.
				</span>
			);
		}
	};

	const body = (
		<>
			<span>
        Unfortunately, there are no spots available for <strong>{groupSize}</strong> guest{groupSize > 1 ? 's' : ''} on{' '}
				<strong>{date?.toFormat('MMMM d')}</strong> through Tablz.
			</span>

			{getActionText()}
		</>
	);

	useEffect(() => {
		if (isOpen) {
			analytics.track(MODAL_OPENED_EVENT, { title: 'No Availability Modal' });
		}
	}, [isOpen]);

	return (
		<ControlledDialog
			isOpen={isOpen}
			onClose={onClose}
			title='No Availability'
			body={body}
			footer={
				<ExternalLink
					href={altPlatformHref}
					target='_blank'
					text={`Search on ${altPlatform}`}
					variant='secondary'
					onClick={handleThirdPartyRMSClick}
					iconAfter={<img src={ArrowOutward} />}
				/>
			}
		/>
	);
};
