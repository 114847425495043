import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TDR, toLuxon } from 'tdr-common';
import { useCancelReservation } from '../../api/cancelReservation';
import { useDeletePendingMods } from '../../api/deletePendingModifications';
import { useReservation } from '../../api/getReservation';
import { useRestaurant } from '../../api/getRestaurant';
import { useRestaurantBasePolicies } from '../../api/getRestaurantBasePolicies';
import { useFeatureFlags } from '../../api/getRestaurantFeatureFlags';
import { useRestaurantTaxPolicies } from '../../api/getRestaurantTaxPolicies';
import { useTable } from '../../api/getTable';
import { useTimeslot } from '../../api/getTimeslot';
import { URLParams } from '../../common/types';
import Spinner from '../../components/Spinner';
import { CTAButton, CustomerSupportLink } from '../../components/buttons';
import { Header } from '../../components/checkout/Header';
import { ConfirmationDialog } from '../../components/dialog/confirmation-dialog/ConfirmationDialog';
import { AddOns } from '../../components/modifications/AddOns';
import { BookingDetails } from '../../components/modifications/BookingDetails';
import { DietaryPreferences } from '../../components/modifications/DietaryPreferences';
import { GuestDetails } from '../../components/modifications/GuestDetails';
import { Occasion } from '../../components/modifications/Occasion';
import { SpecialRequests } from '../../components/modifications/SpecialRequests';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import { getNewInvoiceForCancellation } from '../../helpers/getNewInvoiceForCancellation';
import { trackReservationCancelled } from '../../helpers/reservationEventTrackers';
import TablzLogo from '../../images/poweredBy/tablz.svg';
import layoutStyles from '../../layouts/ModificationLayout.module.scss';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { ModifyBookingFormData } from '../../routes/BookingGuard';
import styles from './ManageBookingPage.module.scss';

const MAX_RETRIES = 10;

export function ManageBookingPage() {
	const navigate = useNavigate();
	const { reservationId } = useParams<URLParams>();

	const [refetchCount, setRefetchCount] = useState(0);
	const [refetchError, setRefetchError] = useState(false);
	const [displayTime, setDisplayTime] = useState<string>();

	const {
		startFlow,
		setNewInvoice,
		isPastModificationPeriod,
		isPastRefundablePeriod,
		setNonRefundableWarning,
		setCannotModifyWarning
	} = useModificationFlowState();

	const methods = useFormContext<ModifyBookingFormData>();

	const cancelReservationMutation = useCancelReservation(reservationId);
	const deletePendingModsMutation = useDeletePendingMods(reservationId);

	const { data: reservation, refetch: refetchReservation } = useReservation(reservationId);
	const { data: restaurant } = useRestaurant(reservation.restaurantId);
	const { data: table, isLoading: isLoadingTable } = useTable(reservation.tableId);
	const { data: restaurantPolicies } = useRestaurantBasePolicies(restaurant.id);
	const { data: taxPolicies } = useRestaurantTaxPolicies(restaurant);
	const { data: featureFlags } = useFeatureFlags(reservation.restaurantId);

	const addOns = reservation.items.filter((item) => item.type === 'addon');

	const luxonStartDateTime = toLuxon(reservation.time, reservation.timezone);

	// TODO: This should be updated to use the reservation.reservationLength after a couple weeks
	const { data: timeslot, isLoading: isLoadingTimeslot } = useTimeslot(
		table?.id,
		luxonStartDateTime.toFormat('yyyy-LL-dd'),
		restaurant.id,
		luxonStartDateTime.toFormat('HH:mm')
	);

	const displayDate = luxonStartDateTime.toFormat('DDDD');

	const clearNewInvoice = () => {
		setNewInvoice(undefined);
	};

	useEffect(() => {
		startFlow();
	}, [startFlow]);

	useEffect(() => {
		if (isLoadingTable || isLoadingTimeslot || !timeslot) {
			return;
		}
		const luxonEndDateTime = luxonStartDateTime.plus({ minutes: timeslot.timeSlot.resDuration });
		setDisplayTime(luxonStartDateTime.toFormat('h:mma') + ' - ' + luxonEndDateTime.toFormat('h:mma'));
	}, [isLoadingTable, isLoadingTimeslot, luxonStartDateTime, timeslot]);

	useEffect(() => {
		let interval: NodeJS.Timeout;

		if (reservation.status === TDR.Reservation.Status.Pending) {
			// Delete any pending changes and refetch reservation (in case user dropped off with pending changes while previously modifying their booking)
			if (reservation['expirationTask'] && reservation.pendingChanges) {
				deletePendingModsMutation.mutateAsync(reservationId).then(() => {
					refetchReservation();
					clearNewInvoice();
				});
			}

			// Or, we may be waiting for a stripe webhook response, refetch the reservation every 2s (up to MAX_RETRIES) to get the updated booking details
			else {
				interval = setInterval(() => {
					if (refetchCount < MAX_RETRIES) {
						refetchReservation();
						setRefetchCount((count) => count + 1);
					}
					else {
						setRefetchError(true);
						clearInterval(interval);
					}
				}, 2000);
			}
		}

		return () => clearInterval(interval);
	}, [reservation, refetchCount, refetchReservation]);

	const confirmCancellationBody = (
		<>
			{isPastRefundablePeriod ||
        (isPastModificationPeriod && (
        	<span>
            Note that you are not eligible for a refund, as per <strong>{restaurant.name}&apos;s</strong> Cancellation
            Policy.
        	</span>
        ))}
			<span>This action is immediate and irreversible.</span>

			<p>
        If you have any questions, please contact us at <CustomerSupportLink /> for further assistance.
			</p>
		</>
	);

	const handleCancellation = async () => {
		const cancellationInvoice = await getNewInvoiceForCancellation({
			restaurant,
			table,
			reservation,
			basePolicies: [...(restaurantPolicies ?? []), ...(taxPolicies ?? [])]
		});
		const response = await cancelReservationMutation.mutateAsync({ cancellationInvoice });

		if (response.success) {
			trackReservationCancelled(reservation);
			navigate(`/booking/${reservationId}/cancel-success`);
		}
		else {
			const errorMessage = `cancelReservation Error: ${response.message}`;
			const sentryErrorID = Sentry.captureException(new Error(errorMessage));
			trackReservationCancelled(reservation, errorMessage, sentryErrorID);
			toast.error('Oops, something went wrong');
		}
	};

	const checkCutoffs = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		const pathname = event.currentTarget.pathname;

		if (isPastModificationPeriod) {
			setCannotModifyWarning({ isOpen: true });
		}
		else if (isPastRefundablePeriod) {
			setNonRefundableWarning({ isOpen: true, proceedTo: pathname });
		}
		else {
			navigate(pathname);
		}
	};

	if (refetchError) {
		toast.error('We\'re having trouble loading your booking right now. Please try again', {
			toastId: 'refetch-error',
			autoClose: false
		});
	}

	if (cancelReservationMutation.isPending) {
		return <LoadingStep message='Cancelling your booking...' />;
	}

	if (reservation.status === TDR.Reservation.Status.Pending || deletePendingModsMutation.status === 'pending') {
		return <LoadingStep message='Loading your booking details...' />;
	}

	return (
		<>
			<div className={layoutStyles.Header}>
				<Header title='Manage Booking' subtitle={restaurant.name} iconLeft={<></>} />
			</div>

			<div className={layoutStyles.Body}>
				<BookingDetails
					table={table}
					restaurant={restaurant}
					reservation={reservation}
					displayDate={displayDate}
					displayTime={displayTime}
					checkCutoffs={checkCutoffs}
				/>

				{featureFlags?.enableAddOns && <AddOns addOns={addOns} restaurant={restaurant} />}

				<GuestDetails reservation={reservation} />

				<DietaryPreferences
					isEditEnabled={!isPastModificationPeriod}
					restaurant={restaurant}
					reservation={reservation}
					form={methods}
				/>

				{reservation.isLargeGroup && (
					<Occasion isEditEnabled={!isPastModificationPeriod} reservation={reservation} form={methods} />
				)}

				<SpecialRequests isEditEnabled={!isPastModificationPeriod} reservation={reservation} form={methods} />
			</div>

			<div className={layoutStyles.Footer}>
				<ConfirmationDialog
					icon='danger'
					title='Cancel Booking?'
					body={confirmCancellationBody}
					triggerButton={<p className={styles.Cancel}>Cancel Booking</p>}
					confirmButton={
						<CTAButton
							onClick={handleCancellation}
							variant='transparent'
							buttonText='Cancel Booking'
							textColor='#D92D20'
						/>
					}
					cancelButtonText='Return to Booking Details'
					isDone={cancelReservationMutation.isSuccess}
				/>
			</div>
		</>
	);
}

const LoadingStep = ({ message }: { message: string }) => {
	return (
		<ResultPageLayout>
			<img src={TablzLogo} />

			<ResultPageLayout.Title>{message}</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<Spinner size='xs' />
			</ResultPageLayout.Body>
		</ResultPageLayout>
	);
};
