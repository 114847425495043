import isEqual from 'lodash/isEqual';
import MpSdk, { CommonMpSdk } from 'matterport';
import React, { useState } from 'react';
import { TDR } from 'tdr-common';
import { useDebouncedCallback } from 'use-debounce';
import { distanceBetweenPoints, isPointInView, sortTablesByPos } from '../../helpers/geometry';
import { ClosestTableInfo } from './ClosestTableInfo';
import { LostYourWay } from './LostYourWay';

export const LOADING_COORDINATES = { x:0, y: 0, z: 0 };

type MatterportEnvironmentNoticeProps = {
	sdk: MpSdk;
	tables: TDR.Table[]
	gotoTable: (sdk: MpSdk, table: TDR.Table) => Promise<void | string>
}

export const MatterportEnvironmentNotice: React.FC<MatterportEnvironmentNoticeProps> = ({ gotoTable, sdk, tables }) => {
	const [closestVisibleTable, setClosestVisibleTable] = useState(null);
	const [closestAvailableTable, setClosestAvailableTable] = useState(null);
	const [pos, setPos] = useState(LOADING_COORDINATES);
	const [rotation, setRotation] = useState({ x:0, y: 0 });
	const [moved, setMoved] = useState<boolean | undefined>(undefined);

	const debouncedNoticePose = useDebouncedCallback((pose) => calculateNoticePosition(pose), 1000);
	const debouncedMovedMarker = useDebouncedCallback((pose) => recordFirstMovement(pose), 500);


	const calculateNoticePosition = (pose: CommonMpSdk.Camera.Pose) => {
		const filtered = tables.filter(table => {
			return distanceBetweenPoints(table.pos, pose.position) < 5.5 && isPointInView(pose.position, pose.rotation, table.pos);
		});
		if(!filtered.length) {
			const sorted = sortTablesByPos(tables, pose.position);
			setClosestAvailableTable(sorted[0]);
			// setClosestVisibleTable(null);
		}
	};

	const calculateTablePosition = (pose: CommonMpSdk.Camera.Pose) => {
		const filtered = tables.filter(table => {
			return distanceBetweenPoints(table.pos, pose.position) < 5.5 && isPointInView(pose.position, pose.rotation, table.pos);
		});
		if(filtered.length) {
			const sorted = sortTablesByPos(filtered, pose.position);
			setClosestVisibleTable(sorted[0]);
			setClosestAvailableTable(null);
		}
		else {
			setClosestVisibleTable(null);
		}

		setPos(pose.position);
		setRotation(pose.rotation);
	};

	const recordFirstMovement = (pose: CommonMpSdk.Camera.Pose) => {

		if (!isEqual(pose, LOADING_COORDINATES) && moved !== true) {
			if (moved === undefined) {
				setMoved(false);
			}
			else if (moved === false) {
				setMoved(true);
			}
		}
	};

	// Greet the World every second
	sdk && sdk.Camera && sdk.Camera.pose.subscribe(function (pose) {
		if(pose?.position && pose?.projection && tables) {
			if(!isEqual(pose.position, pos) || !isEqual(pose.rotation, rotation)) {
				setClosestAvailableTable(null);
				calculateTablePosition(pose);
				debouncedNoticePose(pose);
				debouncedMovedMarker(pose);
			}
		}
	});


	const onClosestTableHeaderClick = (table: TDR.Table) => {
		gotoTable(sdk, table);
	};

	if (closestVisibleTable) {
		return <ClosestTableInfo table={closestVisibleTable} onClick={onClosestTableHeaderClick} />;
	}

	if (closestAvailableTable && moved) {
		return <LostYourWay navigateToPin={(table) => gotoTable(sdk, table)} table={closestAvailableTable} />;
	}

	return null;
};