import React from 'react';
import DateChecked from '../../icons/date-checked.svg';
import GuestChecked from '../../icons/guest-checked.svg';
import TimeChecked from '../../icons/time-checked.svg';
import styles from './FilterIcons.module.scss';

type FilterIconsProps = {
	groupSize: number;
	date: string;
	time: string;
}

export const FilterIcons = ({ groupSize, date, time }: FilterIconsProps) => {
	return (
		<div className={styles.Container}>
			<FilterIcon
				icon={<img src={GuestChecked} alt={'Group Size'}/>}
				text={`${groupSize}`}
				ariaLabel='Group size'
			/>
			<FilterIcon
				icon={<img src={DateChecked} alt={'Date'}/>}
				text={date}
				ariaLabel='Date'
				grow
			/>
			<FilterIcon
				icon={<img src={TimeChecked} alt={'Time'}/>}
				text={time}
				ariaLabel='Time'
				grow
			/>

		</div>
	);
};

type FilterIconProps = {
	icon?: React.ReactNode;
	text?: string;
	ariaLabel?: string
	grow?: boolean;
};
const FilterIcon = ({ icon, text, ariaLabel, grow }: FilterIconProps) => {
	return (
		<div
			className={`${styles.Item} ${grow ? styles['Item--grow'] : ''}`}
			aria-label={ariaLabel}
		>
			{icon}
			{text}
		</div>
	);
};