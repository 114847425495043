import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TDR, toLuxon } from 'tdr-common';
import { useReservation } from '../api/getReservation';
import { useTable } from '../api/getTable';
import { URLParams } from '../common/types';
import { ExpiredBooking, InactiveTable, RequestDenied, RequestSubmitted } from '../components/confirmation';
import { CancelledAlready } from '../components/confirmation/CancelledAlready';
import { useModificationFlowState } from '../context/ModificationFlowProvider';
import { epochToDisplayDateLong } from '../helpers/epochToDisplayDateLong';
import { epochToDisplayTime } from '../helpers/epochToDisplayTime';
import { NotFoundErrorPage } from '../pages/errors/NotFoundErrorPage';

export const ModifiableBookingGuard = ({ children }: { children: React.ReactElement }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { reservationId } = useParams<URLParams>();

	const { hasStartedFlow } = useModificationFlowState();

	const { data: reservation, isLoading: loadingReservation } = useReservation(reservationId);
	const { data: table, isLoading: loadingTable } = useTable(reservation?.tableId);

	const guestCount = reservation?.pendingChanges?.guests || reservation?.guests;
	const bookingTime = reservation?.pendingChanges?.time?.seconds || reservation?.time.seconds;

	const displayDate = reservation ? epochToDisplayDateLong(bookingTime, reservation?.timezone) : null;
	const displayTime = reservation ? epochToDisplayTime(bookingTime, reservation?.timezone) : null;

	const baseRoute = `/booking/${reservationId}`;
	const returnToBookingPortalRoute = `/${reservation?.restaurantSlug}/explore`;

	useEffect(() => {
		if (reservation && reservation?.status === TDR.Reservation.Status.Cancelled) {
			navigate(baseRoute);
		}
		// Redirect to first step in mod flow on page refreshes or direct url input
		else if (!hasStartedFlow && location.pathname !== baseRoute) {
			navigate(baseRoute);
		}
	}, [reservation, reservationId, navigate, location.pathname, hasStartedFlow]);

	if (loadingReservation || loadingTable) {
		return null;
	}
	else if (!loadingReservation && !reservation) {
		return <NotFoundErrorPage />;
	}

	if (reservation?.status === TDR.Reservation.Status.Cancelled) {
		return (
			<CancelledAlready
				restaurantName={reservation?.restaurantName}
				date={displayDate}
				time={displayTime}
				email={reservation?.email}
				linkTo={returnToBookingPortalRoute}
			/>
		);
	}

	if (toLuxon(reservation?.time, reservation?.timezone) < DateTime.local()) {
		return <ExpiredBooking linkTo={returnToBookingPortalRoute} restaurantName={reservation?.restaurantName} />;
	}

	if (table?.disabled) {
		return <InactiveTable linkTo={returnToBookingPortalRoute} />;
	}

	if (reservation?.isLargeGroup && reservation?.status !== TDR.Reservation.Status.Pending) {
		const { AdminActionRequired, GuestActionRequired, Denied } = TDR.Reservation.LargeGroupStatus;
		const status = reservation?.largeGroupOptions?.largeGroupStatus;

		if (status === AdminActionRequired || status === GuestActionRequired) {
			return (
				<RequestSubmitted
					linkTo={returnToBookingPortalRoute}
					restaurantName={reservation?.restaurantName}
					guests={guestCount}
					date={displayDate}
					time={displayTime}
				/>
			);
		}

		if (status === Denied) {
			return (
				<RequestDenied
					linkTo={returnToBookingPortalRoute}
					bookingId={reservationId}
					restaurantName={reservation?.restaurantName}
					guests={guestCount}
					date={displayDate}
					time={displayTime}
				/>
			);
		}
	}

	// TODO: double booking and timeslot unavailable checks

	return children;
};
