import { useMutation } from '@tanstack/react-query';
import { TDR } from 'tdr-common';
import config from '../common/config';
import Checkout = TDR.CheckoutSession;

export const MUTATION_KEY = 'releaseCheckoutSession';

export function useReleaseCheckoutSession() {
	const mutation = useMutation({
		retry: 3,
		mutationKey: [MUTATION_KEY],
		mutationFn: (input: Checkout.ReleaseCheckoutSessionInput) => releaseCheckoutSession(input),
		scope: {
			id: 'releaseCheckoutSession'
		}
	});

	return mutation;
}

async function releaseCheckoutSession(
	input: Checkout.ReleaseCheckoutSessionInput
): Promise<Checkout.ReleaseCheckoutSessionOutput> {
	const response = await fetch(`${config.apiHost}/releaseCheckoutSession`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	return (await response.json()) as Checkout.ReleaseCheckoutSessionOutput;
}
