import React from 'react';
import { formatMonetaryValue, TDR } from 'tdr-common';
import { Divider } from '../divider/Divider';

type InvoiceItemProps = {
	title: string;
	subtitle?: React.ReactNode;
	iconBefore?: React.ReactNode;
	iconAfter?: React.ReactNode;
	amount: number;
	currency: TDR.Currency;
	variant?: 'default' | 'bold' | 'light' | 'line-through';
	isNegative?: boolean;
	isDivided?: boolean;
}

export const InvoiceItem = ({
	title,
	subtitle,
	iconBefore,
	iconAfter,
	amount,
	currency,
	variant = 'default',
	isNegative = false,
	isDivided = true
}: InvoiceItemProps) => {
	const isAmountZero = amount === 0;

	return (
		<>
			<li className={`InvoiceItem InvoiceItem--${variant}`}>
				<div className='InvoiceItem__Description'>
					<div className='InvoiceItem__Title'>
						{iconBefore}
						<p>{title}</p>
						{iconAfter}
					</div>

					{subtitle && <p className='InvoiceItem__Subtitle'>{subtitle}</p>}
				</div>

				<div className='InvoiceItem__Amount'>
					{isNegative ? '-' : ''}
					{formatMonetaryValue(amount, currency, isAmountZero)}
				</div>

			</li>
			{isDivided && <Divider />}
		</>
	);
};