import React, { useState } from 'react';
import { CTAButton } from '../../buttons';
import { ControlledDialog } from './ControlledDialog';

type AreYouStillThereProps = {
	open: boolean;
	onContinue: () => void;
	onEnd: () => void;
};

export const AreYouStillThere = ({ open, onContinue, onEnd }: AreYouStillThereProps) => {
	const [showConfirmation, setShowConfirmation] = useState(false);

	const showEndSessionConfirmation = () => {
		setShowConfirmation(true);
	};

	return (
		<ControlledDialog
			screenReaderDescription='Your session has expired'
			hasCloseButton={false}
			isOpen={open}
			onClose={() => {}}
			title={showConfirmation ? null : 'Are you still there?'}
			body={
				<span>
					{showConfirmation
						? 'Are you sure you want to end your session?'
						: 'Your session has expired. If you still wish to proceed with your booking, please continue your session to proceed.'}
				</span>
			}
			footer={
				showConfirmation ? (
					<>
						<CTAButton buttonText='Yes, browse more tables' onClick={onEnd} variant='dark' />
						<CTAButton buttonText='No, continue session' onClick={onContinue} />
					</>
				) : (
					<>
						<CTAButton buttonText='Continue session' onClick={onContinue} />
						<CTAButton buttonText='End session' onClick={showEndSessionConfirmation} variant='dark' />
					</>
				)
			}
		/>
	);
};
