import { DateTime } from 'luxon';

/**
 * getDisplayTime takes a string in the format "00:30"
 * and returns a formatted 12 hour time like "12:30AM"
 */
export 	const getDisplayTime = (time: string) => {
	if (!time) {
		return null;
	}

	const parsedTime = DateTime.fromFormat(time, 'HH:mm');
	const formatedTime = parsedTime.toFormat('h:mm a');
	return formatedTime;
};