import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from './context/AppContextProvider';
import { Router } from './routes';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { ErrorResponse } from './common/ErrorResponse';
import { InternalErrorToast } from './pages/errors/InternalErrorToast';
import hasOwn from 'object.hasown';

function setupPolyfills() {
	(window as any)._AutofillCallbackHandler = (window as any)._AutofillCallbackHandler || function() {};
	if (!Object.hasOwn) {
		hasOwn.shim();
	}
}

const globalErrorHandler = (error: Error | Response, id: string, isMutation = false) => {
	if (error) {
		if (error instanceof Error && error.message === 'Failed to fetch') {
			Sentry.captureMessage(`Failed to fetch ${isMutation ? 'mutation': 'query'}: ${id}`);
			if (isMutation) {
				toast('Your network connection is unstable. Please try again later.', {
					toastId: 'bad-network',
					type:'error'
				});
			}
			else {
				toast('Your network connection is unstable. Some functionality may not work as expected.', {
					toastId: 'bad-network',
					type:'error'
				});
			}
		}
		else if (error && error instanceof Response) {
			if (error.status >= 500) {
				toast(<InternalErrorToast />, { toastId: 'internal-error', type: 'error' });
				Sentry.captureMessage(`API call returned 500 Error for ${isMutation ? 'mutation': 'query'}: ${id}`);
			}
		}
		else if (error && error instanceof ErrorResponse) {
			if (error.response.status >= 500) {
				toast(<InternalErrorToast />, { toastId: 'internal-error', type: 'error' });
				Sentry.captureMessage(`API call returned 500 Error for ${isMutation ? 'mutation': 'query'}: ${id}`);
			}

			if (isMutation && error.response.status === 400) {
				toast(error.body.message, {
					toastId: 'user-error',
					type:'error',
					autoClose: false
				});
			}
		}
		else if (error && error instanceof Error) {
			Sentry.captureException(error);
		}
	}
	else {
		Sentry.captureException(new Error('Unknown error occurred when making an API call.'));
	}
};

const queryClient = new QueryClient({
	defaultOptions: {
		mutations: {
			retry: (_failureCount: number, error: Error) => {
				if (error instanceof ErrorResponse && error.response.status === 400) {
					return false;
				}
				else {
					return _failureCount < 3;
				}
			}
		}
	},
	mutationCache: new MutationCache({
		onError(error, _variables, _context, mutation) {
			return globalErrorHandler(error, mutation.options?.scope?.id || 'unknown mutation scope', true);
		}
	}),
	queryCache: new QueryCache({
		onError(error, query) {
			return globalErrorHandler(error, query.queryKey[0] as string || 'unknown mutation query key');
		}
	})
});

const TDRApp = () => {
	useEffect(() => {
		setupPolyfills();
	}, []);

	return (
		<BrowserRouter>
			<AppContextProvider>
				<QueryClientProvider client={queryClient}>
					<Router />
					{/* Uncomment to enable react-query dev tools (Will not be included in production build) */}
					{/* <ReactQueryDevtools /> */}
				</QueryClientProvider>
			</AppContextProvider>
		</BrowserRouter>
	);
};

export default TDRApp;
