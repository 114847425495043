import { AnimatePresence, motion } from 'framer-motion';
import isEqual from 'lodash/isEqual';
import MpSdk, { CommonMpSdk } from 'matterport';
import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useLocalStorage } from 'usehooks-ts';
import { analytics, EXPERIMENT_VIEWED, MP_OVERLAY_DISMISSED, MP_OVERLAY_EXPERIMENT } from '../../helpers/analytics';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import Icon360 from '../../icons/3D.svg';
import { RANDOM_TRUE_OR_FALSE } from '../notice/experiments/constants';
import { LOADING_COORDINATES } from '../table-proximity/MatterportEnvironmentNotice';
import styles from './MatterportEducationalOverlay.module.scss';

type MatterportEducationalOverlayProps = {
	sdk: MpSdk;
	restaurantName: string;
};

export const MatterportEducationalOverlay = ({ sdk, restaurantName }: MatterportEducationalOverlayProps) => {
	const [overlayExperiment] = useSessionStorage(MP_OVERLAY_EXPERIMENT.storage_key, { enabled: RANDOM_TRUE_OR_FALSE });

	const isOverlayEnabled = overlayExperiment?.enabled;

	const [isOverlayVisible, setIsOverlayVisible] = useState(true);
	const [lastDismissed, setLastDismissed] = useLocalStorage<string | null>('overlayLastDismissed', null);

	const [pos, setPos] = useState(LOADING_COORDINATES);
	const [rotation, setRotation] = useState({ x: 0, y: 0 });
	const [moved, setMoved] = useState<boolean | undefined>(undefined);

	const debouncedMovedMarker = useDebouncedCallback((pose) => recordFirstMovement(pose), 200);

	const recordFirstMovement = (pose: CommonMpSdk.Camera.Pose) => {
		if (!isEqual(pose, LOADING_COORDINATES) && moved !== true) {
			if (moved === undefined) {
				setMoved(false);
			}
			else if (moved === false) {
				setMoved(true);
			}
		}
	};

	useEffect(() => {
		if (moved) {
			dismissOverlay();
		}
	}, [moved]);

	sdk &&
    sdk.Camera &&
    sdk.Camera.pose.subscribe(function (pose) {
    	if (pose?.position && pose?.projection) {
    		if (!isEqual(pose.position, pos) || !isEqual(pose.rotation, rotation)) {
    			setPos(pose.position);
    			setRotation(pose.rotation);
    			debouncedMovedMarker(pose);
    		}
    	}
    });

	useEffect(() => {
		const today = new Date().toDateString();

		// Show the overlay if it hasn't been dismissed today
		if (lastDismissed === today) {
			setIsOverlayVisible(false);
		}
		else {
			setIsOverlayVisible(true);
		}
	}, [lastDismissed]);

	const dismissOverlay = () => {
		if (isOverlayVisible) {
			analytics.track(MP_OVERLAY_DISMISSED);
			setIsOverlayVisible(false);
			setLastDismissed(new Date().toDateString());
		}
	};

	useEffect(() => {
		analytics.track(EXPERIMENT_VIEWED, {
			experiment: MP_OVERLAY_EXPERIMENT.experiment_name,
			variant: overlayExperiment?.enabled
				? MP_OVERLAY_EXPERIMENT.variants.enabled
				: MP_OVERLAY_EXPERIMENT.variants.disabled
		});
	}, []);

	if (!isOverlayEnabled) {
		return null;
	}

	return (
		<AnimatePresence>
			{isOverlayVisible && (
				<>
					<motion.div
						className={styles.Container}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.5 }}
					>
						<img src={Icon360} alt='Explore virtual space' id='icon360' />
						<span>Explore virtual view of {restaurantName}.</span>
					</motion.div>

					<button
						type='button'
						className={styles.Dismiss}
						onClick={dismissOverlay}
						tabIndex={0}
						aria-label='Dismiss overlay'
					>
            Dismiss
					</button>
				</>
			)}
		</AnimatePresence>
	);
};
