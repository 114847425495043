import { isValidPhoneNumber } from 'react-phone-number-input';
import { TDR } from 'tdr-common';
import { z } from 'zod';

const NAME_REGEX = new RegExp(/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,20}$/);

export const GuestInfoSchema = z.object({
	firstName: z
		.string({ required_error: 'First name is required' })
		.min(2, 'First name must be 2 characters or more')
		.max(20, 'First name must be 20 characters or less')
		.refine(
			(value) => {
				// Using the following syntax to avoid issues with names not using english alphabet, (e.g. Chinese, Japanese, Eastern EU etc.)
				return NAME_REGEX.test(value);
			},
			{ message: 'Name can\'t contain special characters' }
		),

	lastName: z
		.string({ required_error: 'Last name is required' })
		.min(2, 'Last name must be 2 characters or more')
		.max(20, 'Last name must be 20 characters or less')
		.refine(
			(value) => {
				// Using the following syntax to avoid issues with names not using english alphabet, (e.g. Chinese, Japanese, Eastern EU etc.)
				return NAME_REGEX.test(value);
			},
			{ message: 'Name can\'t contain special characters' }
		),
	email: z.string({ required_error: 'Email is required' }).email('Invalid email'),
	phone: z.string({ required_error: 'Phone number is required' }).refine((val) => isValidPhoneNumber(val), {
		message: 'Invalid phone number'
	}),
	dietaryRestrictions: z.array(z.union([z.nativeEnum(TDR.DietaryRestriction), z.boolean()])),
	dietaryNote: z.string().max(300, 'Must be 300 characters or less'),
	occasion: z.nativeEnum(TDR.Occasion).optional(),
	specialRequests: z.string().max(300, 'Must be 300 characters or less'),
	fastCheckoutEnabled: z.boolean().optional()
});

export type GuestInfo = z.infer<typeof GuestInfoSchema>;

export const PaymentInfoSchema = z.object({
	promoCode: z.string().optional()
});

export type PaymentInfo = z.infer<typeof PaymentInfoSchema>;

export type GuestDetailsDTO =
  | TDR.CheckoutSession.ConfirmFreeCheckoutSessionInput
  | TDR.CheckoutSession.UpdateCheckoutSessionInput;

export enum SubmissionError {
	Confirmation = 'Confirmation',
	Proceed = 'Proceed',
}
