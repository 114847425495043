import React from 'react';
import DangerIcon from '../../../icons/yellow-triangle.svg';
import ErrorIcon from '../../../icons/x-hex.svg';
import {
	Dialog,
	DialogBody,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle
} from '../Dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';

type ControlledDialogProps = {
	isOpen: boolean;
	onClose: () => void;
	icon?: 'danger' | 'info' | 'error';
	title: string;
	body: React.ReactNode;
	footer?: React.ReactNode;
	hasCloseButton?: boolean;
	screenReaderDescription?: string;
};

export const ControlledDialog = ({
	isOpen,
	onClose,
	icon,
	title,
	body,
	footer,
	hasCloseButton = true,
	screenReaderDescription = title
}: ControlledDialogProps) => {
	return (
		<Dialog
			open={isOpen}
			onOpenChange={(isOpen) => {
				if (!isOpen) {
					onClose();
				}
			}}
		>
			<DialogContent hasCloseButton={hasCloseButton} fillContainerWidth>
				<VisuallyHidden.Root>
					<DialogDescription>{screenReaderDescription}</DialogDescription>
				</VisuallyHidden.Root>
				<DialogHeader>
					{icon === 'danger' && <img src={DangerIcon} alt='Warning' />}
					{icon === 'error' && <img src={ErrorIcon} alt='Unavailable' />}
					<DialogTitle>{title}</DialogTitle>
				</DialogHeader>

				<DialogBody>{body}</DialogBody>

				{footer && <DialogFooter>{footer}</DialogFooter>}
			</DialogContent>
		</Dialog>
	);
};
