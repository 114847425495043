import { TDR } from 'tdr-common';
import config from '../common/config';
import Availability = TDR.Availability;
import { useQuery } from '@tanstack/react-query';


export function useTimeslot(tableId: string, dateStr: string, restaurantId: string, timeStr: string) {
	const query = useQuery({
		enabled: !!restaurantId && !!tableId && !!dateStr && !!timeStr,
		queryKey: ['timeslots', tableId, dateStr, restaurantId, timeStr],
		queryFn: () => getTimeslot({
			tableId,
			dateStr,
			restaurantId,
			timeStr
		})
	});

	return query;
}

async function getTimeslot(input: Availability.getTimeSlotInput): Promise<Availability.getTimeSlotOutput> {
	const response = await fetch(`${config.apiHost}/getTimeSlot`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as Availability.getTimeSlotOutput;
	if (!response.ok) {
		throw response;
	}
	else if (!responsePayload?.success) {
		const { restaurantId, tableId, dateStr, timeStr } = input;
		throw Error(`Could not find timeslot for restaurantId: ${restaurantId}, tableId: ${tableId} dateStr: ${dateStr}, timeStr: ${timeStr}`);
	}

	return responsePayload;
}