import createReservation from './createReservation';
import getReservation from './getReservation';
import getRestaurant from './getRestaurant';
import modifyReservation from './modifyReservation';
import getPromoCode from './getPromoCode';

export {
	createReservation,
	getReservation,
	getRestaurant,
	getPromoCode,
	modifyReservation
};