/* eslint-disable react/prop-types */
import React from 'react';
import { TDR } from 'tdr-common';
import { PolicyItem } from '../policies/PolicyItem';
import styles from './PolicySection.module.scss';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';



export default function PolicySection({ table }: {table: TDR.TableWithPolicies}) {
	return (
		<ul className={styles.PolicyList}>
			{table?.policies?.map((policy) => {
				return <PolicyItem key={policy.id} icon={policy.displays?.[0]?.icon} label={policy.displays[0]?.label} title={policy.displays[0]?.title}>
					<ReactMarkdown
						remarkPlugins={[remarkGfm]}
						components={{
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							a({ node, target, children, ...props }) {
								if (props.href?.includes(location.hostname)) {
									<a {...props} target={target}>{children}</a>;
								}
								else {
									return <a {...props} target='_blank'>{children}</a>;
								}
							}
						}}
					>
						{policy.displays[0]?.description}
					</ReactMarkdown>
				</PolicyItem>;
			})}
		</ul>
	);
}
