import { useIsMutating } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import { useLocation, useOutletContext, useSearchParams } from 'react-router-dom';
import { DATESTR_FMT, MONTHSTR_FMT, classifyAvailability, getDateFromNow, isRestaurantClosed } from 'tdr-common';
import { useCalendarAvailability } from '../../api/availability/availability';
import getFirstBookableMonthByTable, { getEarliestMonth } from '../../api/getFirstBookableMonth';
import { MUTATION_KEY } from '../../api/releaseCheckoutSession';
import { DAY_IN_MILISECONDS } from '../../common/constants';
import { HASH_ELEMENT_IDS } from '../../common/hashElementIds';
import { applyGroupSizeFilter } from '../../helpers/applyGroupSizeFilter';
import DownArrow from '../../icons/down-chevron.svg';
import { AvailabilityGuardContext, SearchFilters } from '../../routes/AvailabilityGuard';
import { TableGuardOutletContext } from '../../routes/TableGuard';
import Spinner from '../Spinner';
import { LowAvailabilityNotice } from '../notice/LowAvailabilityNotice';
import { AvailabilityIndicator } from './AvailabilityIndicator';
import { AvailabilityLegend } from './AvailabilityLegend';
import styles from './Calendar.module.scss';
import { fromLocalJsDate } from './Calendar.utils';

export type AvailabilityCalendarProps = {
	onChange?: (date: DateTime, availabilityPercent: number) => void;
	disabled?: boolean;
	filters: SearchFilters;
	entityWithPolicies?: any;
}

const AvailabilityCalendar = ({ onChange, disabled, filters, entityWithPolicies }: AvailabilityCalendarProps) => {
	const { tables: allTables, restaurant, updateSearchParams } = useOutletContext<AvailabilityGuardContext>();
	const tableContext = useOutletContext<TableGuardOutletContext>();
	const isReleasing = !!useIsMutating({
		mutationKey: [MUTATION_KEY],
		exact: true
	});

	const location = useLocation();
	const [searchParams] = useSearchParams();

	const TODAY = DateTime.now().setZone(restaurant?.timezone);
	const selectedTable = tableContext?.table;

	const THIS_MONTH_STR = TODAY.toFormat(MONTHSTR_FMT);
	const STARTING_MONTH_STR = filters.date ? DateTime.fromFormat(filters.date, DATESTR_FMT).toFormat(MONTHSTR_FMT) : THIS_MONTH_STR;
	const [selectedMonth, setSelectedMonth ] = useState<string>(STARTING_MONTH_STR);

	const activeStartDate = selectedMonth && DateTime.fromFormat(selectedMonth, MONTHSTR_FMT).toJSDate();
	const filteredTables = selectedTable ? [selectedTable] : applyGroupSizeFilter(allTables, filters.groupSize);
	const { data: selectedMonthAvailability, isLoading } = useCalendarAvailability({ tables: filteredTables, month: selectedMonth, restaurantId: restaurant.id }, isReleasing);
	const restaurantFurthestBookableDate = restaurant.bookableDaysInAdvance ? new Date(TODAY.toJSDate().getTime() + (restaurant.bookableDaysInAdvance * DAY_IN_MILISECONDS)) : null;
	const entityHorizonCutoff = entityWithPolicies?.['availabilityRestrictingPolicies']?.find((policy) => policy.type === 'BookingHorizon')?.cutoff;
	const furthestBookableDate = entityHorizonCutoff ? getDateFromNow(entityHorizonCutoff['value'], entityHorizonCutoff['unit']) : restaurantFurthestBookableDate;
	const getFirstBookableMonth = useMemo(() => () => {
		getFirstBookableMonthByTable(restaurant.id).then((firstMonthsForTables) => {
			if (firstMonthsForTables) {
				let firstMonth = THIS_MONTH_STR;
				if (selectedTable && selectedTable.id in firstMonthsForTables.byTable) {
					firstMonth = firstMonthsForTables.byTable[selectedTable.id] ?? firstMonth;
				}
				else {
					const earliestMonth = getEarliestMonth(firstMonthsForTables);
					firstMonth = earliestMonth && earliestMonth > firstMonth ? earliestMonth : firstMonth;
				}
				if(selectedMonth && firstMonth > selectedMonth) {
					setSelectedMonth(firstMonth);
				}
			}
		});
	}, [restaurant.id]);

	useEffect(() => {
		getFirstBookableMonth?.();
	}, [getFirstBookableMonth]);

	useEffect(() => {
		if (filters.date && selectedMonthAvailability) {
			const initialDate = DateTime.fromFormat(filters.date, DATESTR_FMT).toJSDate();
			handleDateChange(initialDate);
		}
	}, [selectedMonthAvailability]);

	const handleDateChange = (date: Date) => {
		const luxonDate = fromLocalJsDate(date, restaurant?.timezone);
		const dateStr = luxonDate.toFormat(DATESTR_FMT);
		const dayAvailability = selectedMonthAvailability?.calendarAvailability[dateStr]?.available;
		if (
			dayAvailability === 0
		) {
			return onChange(luxonDate, 0);
		}
		const isClosed = isRestaurantClosed(luxonDate, restaurant);

		if (isClosed) {
			updateSearchParams({
				date: luxonDate.toFormat(DATESTR_FMT)
			});
			return onChange(luxonDate, 0);
		}

		updateSearchParams({
			date: luxonDate.toFormat(DATESTR_FMT)
		},
		// if no date change, keep current url hash, otherwise on date change set url hash to #timeslots
		{ hash: dateStr === searchParams.get('date') ? location.hash : HASH_ELEMENT_IDS.timeslots }
		);
		return onChange(luxonDate, 100);
	};

	const handleActiveStartDateChange = ({ activeStartDate }) => {
		setSelectedMonth(DateTime.fromJSDate(activeStartDate).toFormat(MONTHSTR_FMT));
	};

	const getAvailabilityForDate = (date: Date) => {
		return selectedMonthAvailability?.calendarAvailability
		  ? classifyAvailability(
			  selectedMonthAvailability.calendarAvailability[
					DateTime.fromJSDate(date).toFormat(DATESTR_FMT)
			  ]
			)
		  : 'closed';
	  };

	const isBeforeDate = (date1: DateTime, date2: DateTime) => {
		// We need to do that because now that the timezone is set (to handle days rolling over)
		// using simple diffs and <> operators don't work anymore

		if (date1.year < date2.year) {
			return true;
		}
		else if (date1.year > date2.year) {
			return false;
		}

		if (date1.month < date2.month) {
			return true;
		}
		else if (date1.month > date2.month) {
			return false;
		}

		if (date1.day < date2.day) {
			return true;
		}
		return false;
	};

	return (
		<div className={styles.Container} translate='no' id={HASH_ELEMENT_IDS.calendar}>
			{filters?.groupSize && (isLoading || !!isReleasing) && (
				<div className={styles.Spinner} data-cy='calendarLoading'>
					<Spinner size='xs' />
				</div>
			)}

			<ReactCalendar
				locale={(new Intl.NumberFormat())?.resolvedOptions()?.locale || 'en-US'}
				view={'month'}
				value={filters.date ? DateTime.fromFormat(filters.date, DATESTR_FMT).toJSDate() : null}
				onChange={handleDateChange}
				activeStartDate={activeStartDate}
				onActiveStartDateChange={handleActiveStartDateChange}
				nextLabel={<NextMonthIcon />}
				prevLabel={<PrevMonthIcon />}
				calendarType='gregory' // displays Sunday as first day of week
				tileContent={({ date }) => (
					<AvailabilityIndicator
						{...{ date }}
						availability={getAvailabilityForDate(date)}
						disabled={
							isBeforeDate(DateTime.fromJSDate(date), TODAY) ||
							disabled ||
							isLoading ||
							isRestaurantClosed(fromLocalJsDate(date), restaurant) ||
							!!isReleasing
						}
					/>
				)}
				tileDisabled={({ date }) =>
					isBeforeDate(DateTime.fromJSDate(date), TODAY) ||
					disabled ||
					isRestaurantClosed(fromLocalJsDate(date), restaurant) ||
					isLoading ||
					!!isReleasing
				}
				minDate={TODAY.toJSDate()}
				maxDate={disabled ? TODAY.toJSDate() : furthestBookableDate} // for disabling month navigation when calendar is disabled
				showNeighboringMonth={false}
				aria-busy={isLoading || isReleasing}
				next2Label={null}
				prev2Label={null}
				className={disabled ? 'react-calendar--disabled' : ''}
			/>
			<AvailabilityLegend disabled={disabled} />
			{selectedMonthAvailability?.aggregatedStatus === 'limited' ? <LowAvailabilityNotice selectedMonth={selectedMonth} />: null }
		</div>
	);
};

export default AvailabilityCalendar;


/*********************
* Icons
*********************/
const NextMonthIcon = () => <img src={DownArrow} alt={'Next month button'} />;
const PrevMonthIcon = () => <img src={DownArrow} alt={'Previous month button'} />;