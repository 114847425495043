import * as Sentry from '@sentry/react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntentResult, SetupIntentResult, StripeError } from '@stripe/stripe-js';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TDR } from 'tdr-common';
import { useDeletePendingMods } from '../../api/deletePendingModifications';
import { getReservationOnSnapshot, useReservation } from '../../api/getReservation';
import { useRestaurant } from '../../api/getRestaurant';
import { useTable } from '../../api/getTable';
import { URLParams } from '../../common/types';
import { CTAButton } from '../../components/buttons';
import { SquareIconButton } from '../../components/buttons/SquareIconButton';
import { Header } from '../../components/checkout/Header';
import { PaymentInput } from '../../components/checkout/PaymentInput';
import { DiscardChanges } from '../../components/dialog/controlled-dialog/DiscardChanges';
import SecureEncryptInfo from '../../components/dialog/info-dialog/SecureEncryptInfo';
import { FilterIcons } from '../../components/filter-icons/FilterIcons';
import { InputValidationError } from '../../components/form-inputs/InputValidationError';
import { SubHeader } from '../../components/headers/SubHeader';
import {
	ImageCard,
	ImageCardAsset,
	ImageCardHeader,
	ImageCardHeaderPrimary,
	ImageCardHeaderTitle
} from '../../components/image-card/ImageCard';
import { OrderSummary } from '../../components/modifications/OrderSummary';
import { Skeleton } from '../../components/skeleton/Skeleton';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import { CheckoutCTALabel } from '../../context/new/CheckoutContext';
import { analytics } from '../../helpers/analytics';
import { epochToDisplayDateShort } from '../../helpers/epochToDisplayDateShort';
import { epochToDisplayTime } from '../../helpers/epochToDisplayTime';
import { getCdnImageUri } from '../../helpers/getCDNURI';
import luxonToFirebaseTimestamp from '../../helpers/luxonToFirebaseTimestamp';
import { trackReservationModifications } from '../../helpers/reservationEventTrackers';
import styles from '../../layouts/ModificationLayout.module.scss';
import { ModifyBookingFormData } from '../../routes/BookingGuard';

export function ModificationPaymentPage() {
	const { reservationId } = useParams<URLParams>();

	const stripe = useStripe();
	const elements = useElements();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [stripeError, setStripeError] = useState('');
	const [isPaymentInfoComplete, setIsPaymentInfoComplete] = useState(false);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const isValid = !stripeError && isPaymentInfoComplete;

	const { data: reservation } = useReservation(reservationId);
	const { data: restaurant } = useRestaurant(reservation.restaurantId);

	const deletePendingModsMutation = useDeletePendingMods(reservationId);
	const { newInvoice, setNewInvoice } = useModificationFlowState();

	const navigate = useNavigate();

	const methods = useFormContext<ModifyBookingFormData>();

	const watchTableId = methods.watch('tableId');
	const watchGroupSize = methods.watch('guests');
	const watchBookingDateTime = methods.watch('epochInSeconds');
	const watchPaymentMethod = methods.watch('stripePaymentMethod');

	const displayDate = epochToDisplayDateShort(watchBookingDateTime, restaurant.timezone);
	const displayTime = epochToDisplayTime(watchBookingDateTime, restaurant.timezone);

	const { data: table, isLoading } = useTable(watchTableId);

	const imageSrc = getCdnImageUri(table.carouselImagePath, {
		width: 359,
		fit: 'crop',
		dpr: 2
	});

	const handleBackClick = () => {
		deletePendingModsMutation.mutate(reservationId);
		navigate(-1);
	};

	const handleExitClick = () => {
		setIsConfirmOpen(true);
	};

	const handleConfirmExit = () => {
		resetFormAndExit();
		setNewInvoice(undefined);
		setIsConfirmOpen(false);
	};

	const closeConfirmation = () => {
		setIsConfirmOpen(false);
	};

	const resetFormAndExit = () => {
		methods.reset();
		deletePendingModsMutation.mutate(reservationId);
		navigate(`/booking/${reservationId}`);
	};

	// Listen to reservation status changes to redirect to booking page at appropriate time
	useEffect(() => {
		const unsub = getReservationOnSnapshot(reservationId, (doc) => {
			if (doc.exists() && (doc.data() as TDR.Reservation).status !== TDR.Reservation.Status.Pending) {
				setIsSubmitting(false);
				navigate(`/booking/${reservationId}`);
				trackReservationModifications({
					reservation,
					itemsChanged: {
						tableName: table.name,
						guests: watchGroupSize,
						time: luxonToFirebaseTimestamp(DateTime.fromSeconds(watchBookingDateTime)),
						price: newInvoice.total
					}
				});
				toast.success('Changes saved successfully!');
			}
		});

		return () => {
			unsub();
		};
	}, [reservationId]);

	// Redirect to the booking details page after 10 seconds if still submitting
	useEffect(() => {
		if (isSubmitting) {
			const timer = setTimeout(() => {
				toast.error('Timeout: Booking update took too long. Please try again.');
				navigate(`/booking/${reservationId}`);
			}, 10000);

			// Clear the timer if isSubmitting goes back to false
			return () => clearTimeout(timer);
		}
	}, [isSubmitting]);

	const handleSubmit = async (prefilledPayment: boolean) => {
		setIsSubmitting(true);
		try {
			let stripeResponse: PaymentIntentResult | SetupIntentResult;
			const stripeConfirmation = stripe.confirmPayment;

			if (prefilledPayment) {
				stripeResponse = await stripeConfirmation({
					clientSecret: methods.getValues('stripeClientSecret'),
					redirect: 'if_required'
				});
			}
			else {
				stripeResponse = await stripeConfirmation({
					elements,
					redirect: 'if_required',
					confirmParams: {
						payment_method_data: {
							billing_details: {
								email: reservation.email,
								name: reservation.firstName + ' ' + reservation.lastName,
								phone: reservation.phone
							}
						}
					}
				});
			}

			const error = (stripeResponse as PaymentIntentResult)?.error;

			if (error) {
				handleError(error);
			}
		}
		catch (e) {
			const sentryErrorID = Sentry.captureException(e);
			trackReservationModifications({ reservation, errorMessage: 'Stripe payment error', sentryErrorID });
			setStripeError('An unexpected error occurred, update your payment details and try again, later.');
		}
	};

	const handleError = (error: StripeError) => {
		try {
			if (!error) {
				setStripeError('');
				return;
			}
			if (error.type === 'card_error' || error.type === 'validation_error' || error.type === 'invalid_request_error') {
				setStripeError(error.message);
				analytics.track('Payment Input User Error', { reservationId });
			}
			else {
				throw error;
			}
		}
		catch (e) {
			const sentryErrorID = Sentry.captureException(e);
			trackReservationModifications({ reservation, errorMessage: 'Stripe payment error', sentryErrorID });
			setStripeError('An unexpected error occurred, update your payment details and try again, later.');
		}
	};

	useEffect(() => {
		if (watchPaymentMethod) {
			setIsPaymentInfoComplete(true);
		}
	}, [watchPaymentMethod]);

	return (
		<>
			<div className={styles.Header}>
				<Header
					title='Order Summary'
					subtitle={restaurant.name}
					iconLeft={<SquareIconButton variant='back' />}
					iconLeftOnClick={handleBackClick}
					iconRight={<SquareIconButton variant='exit' />}
					iconRightOnClick={handleExitClick}
				/>
			</div>

			<div className={styles.Body}>
				<section className={styles.Section}>
					{isLoading ? (
						<Skeleton width='359px' height='240px' />
					) : (
						<ImageCard height={240} brightness={restaurant.brightness ?? 1} contrast={restaurant.contrast ?? 1}>
							<ImageCardAsset src={imageSrc} alt={table.name} />
							<ImageCardHeader>
								<ImageCardHeaderPrimary>
									<ImageCardHeaderTitle>
										<span>{table.name}</span>
									</ImageCardHeaderTitle>
								</ImageCardHeaderPrimary>
							</ImageCardHeader>
						</ImageCard>
					)}
					<FilterIcons groupSize={watchGroupSize} date={displayDate} time={displayTime} />
				</section>

				<section className={styles.Section}>
					<SubHeader title='Order Summary' />
					<OrderSummary
						invoice={newInvoice as TDR.Invoice}
						restaurant={restaurant}
						guests={watchGroupSize}
						tableId={table.id}
						isLargeGroup={table.supportLargeGroup}
					/>
				</section>

				<section className={styles.Section}>
					<SubHeader
						title='Payment Method'
						subtitle={
							<>
                All transactions are secure and encrypted.
								<SecureEncryptInfo />
							</>
						}
						isRequired
					/>
					<PaymentInput
						stripePaymentMethod={watchPaymentMethod}
						setIsPaymentInfoComplete={setIsPaymentInfoComplete}
						onError={handleError}
					/>
				</section>
			</div>

			<div className={styles.Footer}>
				{stripeError && <InputValidationError message={stripeError} />}
				<CTAButton
					buttonText={reservation.isLargeGroup ? CheckoutCTALabel.SUBMIT : CheckoutCTALabel.MODIFY}
					disabled={!isValid || isSubmitting}
					loading={isSubmitting}
					onClick={() => handleSubmit(!!watchPaymentMethod)}
				/>
			</div>

			<DiscardChanges
				open={isConfirmOpen}
				onDiscard={handleConfirmExit}
				onClose={closeConfirmation}
				onCancel={closeConfirmation}
			/>
		</>
	);
}
