import React, { HTMLAttributeAnchorTarget } from 'react';
import { Link } from 'react-router-dom';
import styles from './BookingOptionTile.module.scss';

type BookingOptionTileProps = {
	title: string;
	description?: string;
	linkTo: string;
	target?: HTMLAttributeAnchorTarget;
	onClick?: () => void;
	ariaLabel: string;
	dataCy?: string;
	flex?: number;
	backgroundImage?: React.ReactNode;
	icon?: React.ReactNode;
	justifyStart?: boolean;
	platformLogo?: React.ReactNode;
}

export const BookingOptionTile = ({
	title,
	description,
	linkTo,
	target,
	onClick,
	ariaLabel,
	dataCy,
	flex,
	backgroundImage,
	icon,
	justifyStart,
	platformLogo
}: BookingOptionTileProps) => {
	return (
		<Link
			to={linkTo}
			target={target}
			className={styles.Tile}
			onClick={onClick}
			aria-label={ariaLabel}
			data-cy={dataCy}
			style={{ flex }}
		>
			<div className={styles.BackgroundImage}>
				{backgroundImage}
			</div>

			<div className={`${styles.Option} ${justifyStart ? styles.OptionJustifyStart : ''}`}>
				<div className={`${styles.OptionText}`}>
					<h2 className={styles.OptionTitle}>{title}</h2>
					<p className={styles.OptionDescription}>{description}</p>
				</div>

				{icon}
			</div>

			{platformLogo}
		</Link>
	);
};
