import React, { useEffect } from 'react';
import { TDR } from 'tdr-common';
import { analytics, MODAL_OPENED_EVENT } from '../../../helpers/analytics';
import { CTAButton } from '../../buttons';
import { ControlledDialog } from './ControlledDialog';

type ModificationUnavailableProps = {
	isOpen: boolean;
	onClose: () => void;
	onDismiss: () => void;
	restaurant: TDR.Restaurant;
};

export const ModificationUnavailable = ({ isOpen, onClose, onDismiss, restaurant }: ModificationUnavailableProps) => {
	let body = (
		<>
			<span>
        We apologize for any inconvenience, but you are no longer able to modify this booking, as your booking is just around the corner.
			</span>
			<span>If you are unable to attend your scheduled booking, please cancel it.</span>
		</>
	);

	if (restaurant?.name) {
		body = (
			<>
				<span>
			We apologize for any inconvenience, but you are no longer able to modify this booking, as your arrival at{' '}
					<strong>{restaurant.name}</strong> is just around the corner.
				</span>
				<span>If you are unable to attend your scheduled booking, please cancel it.</span>
			</>
		);
	}

	useEffect(() => {
		if (isOpen) {
			analytics.track(MODAL_OPENED_EVENT, { title: 'Modification Unavailable' });
		}
	}, [isOpen]);

	return (
		<ControlledDialog
			isOpen={isOpen}
			onClose={onClose}
			title='Booking Modification Unavailable'
			icon='error'
			body={body}
			footer={<CTAButton buttonText='Dismiss' onClick={onDismiss} variant='dark' />}
		/>
	);
};
