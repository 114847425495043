import { TDR } from 'tdr-common';
import config from '../common/config';
import { useMutation } from '@tanstack/react-query';

export function useCancelReservation(reservationId: string) {
	const mutation = useMutation({
		mutationKey: ['cancelReservation', reservationId],
		mutationFn: ({ cancellationInvoice }: { cancellationInvoice: Partial<TDR.Invoice> }) =>
			cancelReservation(cancellationInvoice)
	});

	return mutation;
}

export default async function cancelReservation(cancellationInvoice: Partial<TDR.Invoice>) {
	const cancelledReservation: Partial<TDR.Reservation> = {
		id: cancellationInvoice.reservationId,
		status: TDR.Reservation.Status.Cancelled,
		items: [],
		price: cancellationInvoice.total,
		subtotalPrice: cancellationInvoice.subtotal
	};
	const response = await fetch(`${config.apiHost}/modifyReservation`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},

		body: JSON.stringify(cancelledReservation)
	});

	return response.json();
}