import * as Sentry from '@sentry/react';
import { motion, Variants } from 'framer-motion';
import React, { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { TDR } from 'tdr-common';
import { MatterportForMods } from '../components/matterport-environment/MatterportForMods';
import ScrollToHashElement from '../components/scroll-to-hash-element/ScrollToHashElement';
import { useMatterport } from '../context/new/MatterportContext';
import { useViewToggle } from '../context/ViewToggleProvider';
import { useScreenSize } from '../hooks/useScreenSize';
import { AnimatedImageGallery } from '../components/animated-image-gallery/AnimatedImageGallery';

import 'react-toastify/dist/ReactToastify.css';
import styles from './AppLayout.module.scss';

export interface ModificationLayoutProps {
	restaurant: TDR.Restaurant;
	tables: TDR.Table[];
	children: ReactNode;
}

export function ModificationLayout({ restaurant, tables, children }: ModificationLayoutProps) {
	const { isError: matterportError } = useMatterport();

	const params = useParams();
	const { isSmallScreen } = useScreenSize();
	const { isVirtualView, turnOffVirtualView } = useViewToggle();

	useEffect(() => {
		if (!isSmallScreen || matterportError) {
			turnOffVirtualView();
		}
	}, [isSmallScreen, matterportError]);

	const sidebarVariants: Variants = {
		hidden: {
			opacity: 0,
			visibility: 'hidden',
			transition: {
				duration: 0.6,
				// only add delay on table details route
				delay: params?.tableSlug ? 0.5 : 0
			}
		},
		visible: {
			opacity: 1,
			visibility: 'visible',
			transition: {
				when: 'beforeChildren',
				duration: 0.7
			}
		}
	};
	return (
		<main className={styles.wrapper}>
			<ScrollToHashElement />

			<motion.section
				id='sidebar-container'
				className={styles.sidebar}
				initial={'visible'}
				variants={sidebarVariants}
				animate={isVirtualView ? 'hidden' : 'visible'}
			>
				{children}
				<ToastContainer position='bottom-left' theme='dark' />
			</motion.section>

			<section
				className={`${styles.matterport} ${styles['matterport--disabled']}`}
			>
				<Sentry.ErrorBoundary
					fallback={() => <AnimatedImageGallery staticImage={true} restaurant={restaurant} tables={tables} />}
					beforeCapture={(scope) => {
						scope.setTag('location', 'Modification Layout');
					}}
				>
					<MatterportForMods
						restaurant={restaurant}
						tables={tables}
					/>
				</Sentry.ErrorBoundary>
			</section>
		</main>
	);
}
