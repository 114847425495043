import { motion, useScroll, Variants } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { hasConflict, useSearch } from '../../api/availability/search';
import { CAROUSEL_SCROLL_POSITION_KEY_DESKTOP, CAROUSEL_SCROLL_POSITION_KEY_MOBILE } from '../../common/constants';
import { GlobalParameterContext } from '../../context/new/GlobalParameterContext';
import { analytics } from '../../helpers/analytics';
import { mostCompatibleGroupSize } from '../../helpers/groupSIze';
import useScrollRestoration from '../../hooks/useScrollRestoration';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { AvailabilityGuardContext, serializeSearchFiltersForURL } from '../../routes/AvailabilityGuard';
import AlternativeBookingOptions from '../alternativeBookingOption/AlternativeBookingOption';
import ExternalLink from '../buttons/ExternalLink';
import { SearchButton } from '../buttons/SearchButton';
import { SidebarFiltersHeader } from '../sidebar-filters-header/SidebarFiltersHeader';
import { SidebarHeader } from '../sidebar-header/SidebarHeader';
import CarouselCard from './CarouselCard';
import { CarouselSkeleton } from './CarouselSkeleton';
import styles from './InventoryCarousel.module.scss';

type InventoryCarouselProps = {
	onClick: (item: TDR.Table) => void;
};

export const InventoryCarousel = ({ onClick }: InventoryCarouselProps) => {
	const navigate = useNavigate();

	/**
	 * Why do we need separate storage for mobile and desktop scroll positions?
	 *
	 * - Desktop: The desktop layout prevents scrolling on the `window` itself, so we handle scroll
	 *   positions differently to manage fixed content.
	 *
	 * - Mobile: The mobile layout allows `window` scrolling to enable the mobile address bar to hide
	 *   on scroll. This requires a separate scroll position to be managed for the mobile layout.
	 */
	useScrollRestoration(CAROUSEL_SCROLL_POSITION_KEY_MOBILE);
	const [savedLocation, setSrollLocation] = useSessionStorage(CAROUSEL_SCROLL_POSITION_KEY_DESKTOP, '');

	const { restaurant, searchFilters, tables, featureFlags } = useOutletContext<AvailabilityGuardContext>();
	const { setSearchString } = useContext(GlobalParameterContext);

	const scrollContainerRef = useRef<HTMLElement>(null);
	const { scrollY } = useScroll({ container: scrollContainerRef });

	const { results, isLoading } = useSearch({
		params: searchFilters,
		restaurantId: restaurant.id,
		space: tables
	});

	const areAnyFiltersSet = !!searchFilters.groupSize || !!searchFilters.date || !!searchFilters.time;

	const altPlatform = restaurant.settings?.alternativePlatform?.vendor;
	const altPlatformHref = restaurant.settings?.alternativePlatform?.href;

	const contactByPhone = altPlatform === 'phone';
	const contactByEmail = altPlatform === 'email';

	const searchResultTables = tables
		.filter((t) => !results?.metadata || results.metadata.groupSize.includes(t.id))
		.sort((table1, table2) => {
			const table1HasConflict = hasConflict(results, table1);
			const table2HasConflict = hasConflict(results, table2);
			const MAX_SORT_ORDER = 9999;

			if (table1HasConflict !== table2HasConflict) {
				return table1HasConflict ? -1 : 1;
			}

			return (table1.displayOrder ?? MAX_SORT_ORDER) - (table2.displayOrder ?? MAX_SORT_ORDER);
		});

	useEffect(() => {
		if (results && searchFilters) {
			if (results?.metadata?.groupSize?.length === 0) {
				navigate({ search: '' });
			}
		}
	}, [results]);

	useEffect(() => {
		setSearchString(serializeSearchFiltersForURL(searchFilters).toString());
	}, [searchFilters]);

	// On scroll change, saves scroll position to useSessionStorage after 1 second of inactivity
	useEffect(() => {
		let timeoutId: NodeJS.Timeout;

		const handleScrollChange = (value: number) => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				setSrollLocation(`${value}`);
			}, 1000);
		};

		const unsubscribe = scrollY.on('change', handleScrollChange);

		return () => {
			clearTimeout(timeoutId);
			unsubscribe();
		};
	}, [scrollY]);

	// Sets scroll position from session storage if it exists
	useEffect(() => {
		if (isLoading) {
			return;
		}
		if (savedLocation && scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({ top: parseInt(savedLocation, 10), behavior: 'auto' });
		}
	}, [scrollContainerRef, isLoading]);

	// Saves the scroll position to useSessionStorage on component unmount
	useEffect(() => {
		return () => {
			setSrollLocation(`${scrollY.get()}`);
		};
	}, []);

	const resetScroll = () => {
		scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
	};

	const handleAnalytics = () => {
		if (contactByPhone) {
			analytics.track('Redirected to Phone Number');
		}
		else if (contactByEmail) {
			analytics.track('Redirected to Email');
		}
		else {
			analytics.track('Redirected to Standard Booking Portal', {
				platform: altPlatform,
				exitLink: altPlatformHref
			});
		}
	};

	const headerVariants: Variants = {
		visible: {
			opacity: 1,
			y: 0
		},
		hidden: {
			opacity: 0,
			y: '-100%'
		}
	};

	const listAndFooterVariants: Variants = {
		visible: {
			opacity: 1,
			y: 0
		},
		hidden: {
			opacity: 0,
			y: '100%'
		}
	};

	return (
		<div
			className={`${styles.Container}`}
			data-cy='table-carousel'
		>
			<motion.div
				className={styles.Header}
				initial={'hidden'}
				animate={'visible'}
				exit={'hidden'}
				variants={headerVariants}
				transition={{ duration: 0.4 }}
			>
				{areAnyFiltersSet ? (
					<SidebarFiltersHeader resetScroll={resetScroll} />
				) : (
					<SidebarHeader
						title='Browse Seating'
						subtitle={restaurant.name}
						buttonRight={
							<SearchButton
								to={{
									pathname: 'filters',
									search: serializeSearchFiltersForURL(
										areAnyFiltersSet ? searchFilters : featureFlags?.autoSelectGroupSize ? { groupSize: mostCompatibleGroupSize(tables) } : {}
									).toString()
								}}
							/>
						}
					/>
				)}
			</motion.div>

			<motion.nav
				className={styles.List}
				ref={scrollContainerRef}
				variants={listAndFooterVariants}
				initial={'hidden'}
				animate={'visible'}
				exit={'hidden'}
				transition={{ duration: 0.4 }}
			>
				{restaurant?.settings?.alternativeBookingOption && (
					<AlternativeBookingOptions
						label={restaurant.settings.alternativeBookingOption.label}
						selfLabel={restaurant.settings.alternativeBookingOption.selfLabel}
						href={
							restaurant.settings.alternativeBookingOption.href +
              (areAnyFiltersSet ? '?' + serializeSearchFiltersForURL(searchFilters).toString() : '')
						}
					/>
				)}
				<ul>
					{isLoading ? (
						<CarouselSkeleton />
					) : (
						searchResultTables.map((table) => (
							<CarouselCard
								key={table.id}
								restaurant={restaurant}
								searchFilters={searchFilters}
								table={table}
								available={!results?.resultMap?.[table.id]?.['conflict']}
								conflictingFilter={results?.resultMap?.[table.id]?.['conflict']?.includes('date') ? 'date' : 'time'}
								onClick={() => onClick(table)}
								tableSearchResult={results?.resultMap?.[table.id]}
							/>
						))
					)}
				</ul>
			</motion.nav>

			<motion.div
				className={styles.Footer}
				variants={listAndFooterVariants}
				initial={'hidden'}
				animate={'visible'}
				exit={'hidden'}
				transition={{ duration: 0.4 }}
			>
				<ExternalLink
					variant='dark'
					text='Skip Seating Selection'
					href={altPlatformHref}
					styleOverride={{ margin: 0 }}
					target='_blank'
					onClick={handleAnalytics}
				/>
			</motion.div>
		</div>
	);
};
