import React, { useEffect } from 'react';
import { Contact } from './Contact';
import { useScrollToElement } from '../../../hooks/useScrollToElement';
import styles from '../../../layouts/InboundRequestLayout.module.scss';

type ContactWrapperProps = {
	setIsContactFormValid: React.Dispatch<React.SetStateAction<boolean>>;
	setContactDetails: React.Dispatch<React.SetStateAction<any>>;
	setHasScrolledPastContactForm?: React.Dispatch<React.SetStateAction<boolean>>;
	hasScrolledPastContactForm: boolean;
};
export const ContactWrapper: React.FC<ContactWrapperProps> = ({
	setIsContactFormValid,
	setContactDetails,
	setHasScrolledPastContactForm,
	hasScrolledPastContactForm
}) => {
	const [isVisible, containerRef] = useScrollToElement({ threshold: 1 });

	useEffect(() => {
		if (!hasScrolledPastContactForm && isVisible) {
			setHasScrolledPastContactForm(true);
		}
	}, [isVisible]);

	return (
		<div className={styles.Section} id='booking-contact-details' ref={containerRef}>
			<Contact setIsContactFormValid={setIsContactFormValid} setContactInformation={setContactDetails} />
		</div>
	);
};
