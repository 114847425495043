export const delay = (time) => new Promise((res) => setTimeout(res, time));

const ONE_SECOND_IN_MS = 1000;

export async function retry<T>(
	fn: () => Promise<T>,
	retries: number,
	delayMs: number = ONE_SECOND_IN_MS,
	onFailedAttempt?: (
		error: Error,
		attempt: number,
		retries: number,
		currentDelay: number
	) => Promise<T>
) {
	let attempt = 0;
	let currentDelay = delayMs;

	while (true) {
		try {
			return await fn();
		}
		catch (error) {
			if (onFailedAttempt) {
				onFailedAttempt(error, attempt, retries, currentDelay);
			}

			if (attempt++ < retries) {
				await delay(currentDelay);
				currentDelay *= 2; // Exponential backoff: double the delay each time
			}
			else {
				throw error;
			}
		}
	}
}

export const withTimeout = (millis: number, promise: Promise<any>) => {
	let timeoutPid;
	const timeout = new Promise((resolve, reject) =>
		timeoutPid = setTimeout(
			() => reject(`Timed out after ${millis} ms.`),
			millis));
	return Promise.race([
		promise,
		timeout
	]).finally(() => {
		if (timeoutPid) {
			clearTimeout(timeoutPid);
		}
	});
};
