import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { useReservation } from '../../api/getReservation';
import { useRestaurant } from '../../api/getRestaurant';
import { useModifyReservation } from '../../api/modifyReservation';
import { URLParams } from '../../common/types';
import { SquareIconButton } from '../../components/buttons/SquareIconButton';
import SubmitButton from '../../components/buttons/SubmitButton';
import { Header } from '../../components/checkout/Header';
import { DiscardChanges } from '../../components/dialog/controlled-dialog/DiscardChanges';
import { RHFPhoneInput } from '../../components/form-inputs/RHFPhoneInput';
import { RHFTextInput } from '../../components/form-inputs/RHFTextInput';
import { countryCodeConverter } from '../../helpers/countryCodeConverter';
import { trackReservationModifications } from '../../helpers/reservationEventTrackers';
import layoutStyles from '../../layouts/ModificationLayout.module.scss';

export function GuestDetailsPage() {
	const navigate = useNavigate();
	const { reservationId } = useParams<URLParams>();

	const { data: reservation } = useReservation(reservationId);
	const { data: restaurant } = useRestaurant(reservation.restaurantId);
	const modifyReservationMutation = useModifyReservation(reservationId);

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const NAME_REGEX = new RegExp(/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,20}$/);

	// Schema & Types - Contact Info
	const ContactFormSchema = z.object({
		firstName: z
			.string({ required_error: 'First name is required' })
			.min(2, 'First name must be 2 characters or more')
			.max(20, 'First name must be 20 characters or less')
			.refine(
				(value) => {
				// Using the following syntax to avoid issues with names not using english alphabet, (e.g. Chinese, Japanese, Eastern EU etc.)
					return NAME_REGEX.test(value);
				},
				{ message: 'Name can\'t contain special characters' }
			),
		fullName: z.string(),
		lastName: z
			.string({ required_error: 'Last name is required' })
			.min(2, 'Last name must be 2 characters or more')
			.max(20, 'Last name must be 20 characters or less')
			.refine(
				(value) => {
				// Using the following syntax to avoid issues with names not using english alphabet, (e.g. Chinese, Japanese, Eastern EU etc.)
					return NAME_REGEX.test(value);
				},
				{ message: 'Name can\'t contain special characters' }
			),
		email: z.string({ required_error: 'Email is required' }).email('Invalid email').transform((value) => value?.trim()).refine(
			(value) => {
				//Consider most common email typo as a validation error
				return !!value && !value.endsWith('@gmail.con');
			},
			{ message: 'Email domain is invalid, did you mean @gmail.com?' }
		),
		phone: z.string({ required_error: 'Phone number is required' }).refine((val) => isValidPhoneNumber(val), {
			message: 'Invalid phone number'
		})
	});

type ContactFormType = z.infer<typeof ContactFormSchema>;

const defaultValues = {
	firstName: '',
	lastName: '',
	fullName: '',
	email: '',
	phone: ''
};
// Form Logic & State
const {
	reset,
	register,
	control,
	formState: { errors, isValid, isDirty },
	handleSubmit,
	setValue,
	getValues
} = useForm<ContactFormType>({
	mode: 'onTouched',
	resolver: zodResolver(ContactFormSchema),
	defaultValues,
	values: reservation
});

const resetFormAndExit = () => {
	reset();
	navigate(`/booking/${reservationId}`);
};

const handleExitClick = () => {
	if (isDirty) {
		setIsConfirmOpen(true);
	}
	else {
		resetFormAndExit();
	}
};

const handleConfirmExit = () => {
	resetFormAndExit();
	setIsConfirmOpen(false);
};

const handleCancelExit = () => {
	setIsConfirmOpen(false);
};

const handleFormSubmit = async (data: ContactFormType) => {
	// TODO Full name not updated
	const response = await modifyReservationMutation.mutateAsync({
		...data,
		restaurantId: reservation.restaurantId,
		id: reservation.id
	});
	if (response.success) {
		navigate(`/booking/${reservationId}`);
		trackReservationModifications({ reservation, itemsChanged: { ...data } });
		toast.success('Changes saved successfully!');
	}
};

return (
	<>
		<div className={layoutStyles.Header}>
			<Header
				title='Change Contact Details'
				subtitle='Modify Booking'
				iconLeft={<></>}
				iconRight={<SquareIconButton variant='exit' />}
				iconRightOnClick={handleExitClick}
			/>
		</div>

		<main className={layoutStyles.Body}>
			<form
				id='contact-details-form'
				className={`${layoutStyles.Section} ${layoutStyles['Section--transparent']}`}
				onSubmit={handleSubmit(handleFormSubmit)}
			>
				<RHFTextInput
					label='First Name'
					name='firstName'
					register={register}
					error={errors.firstName?.message}
					onChange={(evt) => {
						const lastName = getValues(['lastName']);
						setValue('fullName', `${evt.target.value} ${lastName}`, {
							shouldDirty: true
						});
					}}
				/>
				<RHFTextInput
					label='Last Name'
					name='lastName'
					register={register}
					error={errors.lastName?.message}
					onChange={(evt) => {
						const firstName = getValues(['firstName']);
						setValue('fullName', `${firstName} ${evt.target.value}`, {
							shouldDirty: true
						});
					}}
				/>
				<RHFTextInput label='Email' name='email' register={register} error={errors.email?.message} />
				<RHFPhoneInput
					label='Phone Number'
					name='phone'
					control={control}
					defaultCountry={countryCodeConverter(restaurant.country ?? restaurant.stripeRegion)}
					error={errors.phone?.message}
				/>
			</form>
		</main>

		<footer className={layoutStyles.Footer}>
			<SubmitButton
				form='contact-details-form'
				variant='transparent'
				buttonText='Save Changes'
				disabled={!isDirty || modifyReservationMutation.isPending || (isDirty && !isValid)}
				loading={modifyReservationMutation.isPending}
			/>
		</footer>

		<DiscardChanges
			open={isConfirmOpen}
			onClose={() => setIsConfirmOpen(false)}
			onDiscard={handleConfirmExit}
			onCancel={handleCancelExit}
		/>
	</>
);
}