import { motion } from 'framer-motion';
import last from 'lodash/last';
import React, { useEffect } from 'react';
import { TDR } from 'tdr-common';
import { SearchFilters } from '../routes/AvailabilityGuard';

export type ServiceSelectorProps = {
	searchFilters: SearchFilters;
	selectedServiceName: string;
	setSelectedServiceName: React.Dispatch<React.SetStateAction<string>>;
	servicesMap: TDR.ServiceTimeSlotMap;
	onChange: (newServiceName: string) => void;
};

const ServiceSelector = ({
	searchFilters,
	selectedServiceName,
	setSelectedServiceName,
	servicesMap,
	onChange
}: ServiceSelectorProps) => {
	// If no selected time, select the last service that has availability
	useEffect(() => {
		if (servicesMap && !searchFilters.time) {
			const serviceOptions = Object.entries(servicesMap);
			const availableServices =
        serviceOptions.filter(([, { timeSlots }]) => Object.keys(timeSlots).length > 0)?.map(([name]) => name) || [];
			const defaultServiceName = last(availableServices) || null;

			if (!serviceOptions.map(([name]) => name).includes(selectedServiceName)) {
				onChange(defaultServiceName);
			}
		}
	}, [selectedServiceName, servicesMap]);

	// If we have a selected time, try to select the service that contains that time
	useEffect(() => {
		if (searchFilters.time && servicesMap) {
			const serviceOptions = Object.entries(servicesMap);

			const servicesWithCurrentSelectedTime = serviceOptions.filter(
				([, { timeSlots }]) => timeSlots && searchFilters?.time in timeSlots
			) || [null];
			if(servicesWithCurrentSelectedTime.some(([name]) => name === selectedServiceName)){
				return;
			}
			else {
				const serviceWithCurrentSelectedTime = servicesWithCurrentSelectedTime[0]?.[0];
				if (serviceWithCurrentSelectedTime) {
					setSelectedServiceName(serviceWithCurrentSelectedTime);
					onChange(serviceWithCurrentSelectedTime);
				}
			}
		}
	}, [searchFilters.time]);

	const handleChange = (serviceName: string) => {
		setSelectedServiceName(serviceName);
		onChange(serviceName);
	};

	return (
		<motion.ol
			aria-label='Time of day selector menu'
			className='flexRow mealContainer'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			{servicesMap &&
        Object.entries(servicesMap).map(([serviceName]) => {
        	const selectedClass = selectedServiceName && selectedServiceName === serviceName ? 'selectorSelected' : '';

        	return (
        		<li key={serviceName} style={{ flexGrow: 1 }} className={`selectorDiv ${selectedClass}`}>
        			<button style={{ margin: '0 auto' }} onClick={() => handleChange(serviceName)}>
        				{serviceName}
        			</button>
        		</li>
        	);
        })}
		</motion.ol>
	);
};

export default ServiceSelector;
