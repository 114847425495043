import { TDR } from 'tdr-common';

export function distanceBetweenPoints(point1, point2) {
	const { x: x1, y: y1,z: z1 } = point1;
	const { x: x2, y: y2, z: z2 } = point2;

	const dx = x2 - x1;
	const dy = y2 - y1;
	const dz = z2 - z1;

	return Math.sqrt(dx*dx + dy*dy + dz*dz);
}

export function isPointInView(cameraPosition, cameraRotation, point) {

	// Calculate camera direction vector
	const cameraDirection = getDirectionVector(cameraRotation.y, cameraRotation.x);

	// Calculate vector from camera to point
	const directionVector = {
		x: point.x - cameraPosition.x,
		y: point.y - cameraPosition.y,
		z: point.z - cameraPosition.z
	};
	const length = Math.sqrt(directionVector.x * directionVector.x + directionVector.y * directionVector.y + directionVector.z * directionVector.z);
	directionVector.x /= length;
	directionVector.y /= length;
	directionVector.z /= length;
	const cameraLength = Math.sqrt(cameraDirection.x * cameraDirection.x + cameraDirection.y * cameraDirection.y + cameraDirection.z * cameraDirection.z);
	cameraDirection.x /= cameraLength;
	cameraDirection.y /= cameraLength;
	cameraDirection.z /= cameraLength;
	const dotProduct = cameraDirection.x * directionVector.x + cameraDirection.y * directionVector.y + cameraDirection.z * directionVector.z;
	return dotProduct > Math.cos(Math.PI/4);//This threshold is calibrated ish for desktop, will probably have to be a seperate val for mobile
}

export function getDirectionVector(azimuthDegrees, elevationDegrees) {
	// Convert degrees to radians
	const azimuthRad = (azimuthDegrees) * Math.PI / 180;
	const elevationRad = (elevationDegrees) * Math.PI / 180;

	// Compute direction vector components
	const z = -Math.cos(elevationRad) * Math.cos(azimuthRad);
	const x = -Math.cos(elevationRad) * Math.sin(azimuthRad);
	const y = Math.sin(elevationRad);

	// Return the direction vector as an array
	return { x, y, z };
}

export const sortTablesByPos = (tables: TDR.Table[], pos) => {
	return tables.sort((table1, table2) => {
		return distanceBetweenPoints(table1.pos, pos) - distanceBetweenPoints(table2.pos, pos);
	});
};