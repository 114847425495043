import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { TDR } from 'tdr-common';

export default async function getFirstBookableMonthByTable(
	restaurantId: string
): Promise<TDR.Availability.FirstBookableMonth | null> {
	const db = getFirestore();

	const q = doc(db, 'FirstAvailableMonth', restaurantId);
	const docSnapshot = await getDoc(q);

	if (docSnapshot.exists()) {
		return docSnapshot.data() as TDR.Availability.FirstBookableMonth;
	}
	else {
		return null;
	}
}

export function getEarliestMonth(firstMonthsForTables: TDR.Availability.FirstBookableMonth): string | null {
	const months = Object.values(firstMonthsForTables.byTable);
	if (months.length === 0) {
		return null;
	}
	const earliestMonth = months.reduce((earliest, current) => earliest < current ? earliest : current);
	return earliestMonth as string;
}