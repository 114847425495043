import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import PhoneInput, { Country } from 'react-phone-number-input';
import { InputValidationError } from './InputValidationError';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './RHFTextInput.module.scss';
import 'react-phone-number-input/style.css';

type RHFPhoneInputProps<TFieldValues extends FieldValues = FieldValues> = {
	control: Control<TFieldValues>;
	name: string;
	label: string;
	defaultCountry: Country;
	error?: string;
}

export const RHFPhoneInput = ({ control, label, name, error, defaultCountry }: RHFPhoneInputProps ) => {
	return (
		<div className={styles.Container}>
			<div className={styles.Input}>
				<label id={`${name}-label`} hidden>{label}</label>
				<Controller
					name={name}
					control={control}
					rules={{
						required: true
					}}
					render={({ field }) => (
						<PhoneInput
							{...field}
							className={styles.PhoneInput}
							aria-labelledby={`${name}-label`}
							defaultCountry={defaultCountry}
							placeholder={label}
							limitMaxLength
							data-cy='phoneInput'
						/>
					)}
				/>
			</div>

			<AnimatePresence>
				{error && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<InputValidationError message={error} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
