import React from 'react';
import SuccessIcon from '../../icons/check-circle.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { ManageBooking, ReturnToBookingPortal } from './buttons';
import { BookingConfirmedProps } from './types';

export const BookingConfirmed = ({ guests, time, date, restaurantName, bookingId, linkTo }: BookingConfirmedProps) => {
	return (
		<ResultPageLayout headerIcon={SuccessIcon} iconAltText='Booking confirmed'>
			<ResultPageLayout.Title>Booking Confirmed</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>
          Your booking for <b>{guests}</b> guest{guests > 1 ? 's' : ''} on <b>{date}</b> at <b>{time}</b> at{' '}
					<b>{restaurantName}</b> is confirmed.
				</p>
				<p>You will receive an email shortly with your booking details.</p>
			</ResultPageLayout.Body>

			<ResultPageLayout.Footer>
				<ReturnToBookingPortal to={linkTo} />
				<ManageBooking to={`/booking/${bookingId}`} />
			</ResultPageLayout.Footer>
		</ResultPageLayout>
	);
};
