import { collection, getDocs, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { TDR } from 'tdr-common';

export default async function getReservationInvoice(reservationID: string): Promise<TDR.Invoice | null> {
	const db = getFirestore();

	const q = query(
		collection(db, 'Invoices'),
		where('reservationId', '==', reservationID),
		where('type', '==', 'reservation'),
		where('toAccount.type', '==', 'tk'),
		where('fromAccount.type', '==', 'user'),
		orderBy('createdAt', 'desc')
	);
	const querySnapshot = await getDocs(q);

	if (querySnapshot.empty) {
		return null;
	}

	// Filter out invoices with status 'pending'
	const filteredDocs = querySnapshot.docs.filter((doc) => doc.data().status !== 'pending');

	if (filteredDocs.length === 0) {
		return null;
	}

	return filteredDocs[0].data() as TDR.Invoice;
}
