

// Constants - variables reused in multiple places for tracking events
export const CANCEL_BOOKING_SUCCESS = 'Cancel Booking - Success'; // Cancel Booking Success
export const CANCEL_BOOKING_FAILED_TIME_PASSED = 'Cancel Booking - Failed - Time Passed';
export const CANCEL_BOOKING_FAILED_ERROR = 'Cancel Booking - Failed - Error';
export const CANCEL_BOOKING_FAILED_ALREADY_CANCELLED = 'Cancel Booking - Failed - Already Cancelled';
export const FILTER_CHANGED = 'Filter Change'; // Filter (GroupSize, Calendar, Timeslot) changed
export const TABLES_SEARCHED = 'Tables Searched';
export const SHOW_RESULTS = 'Show Results';
export const MODIFICATION_FAILED = 'Modification Failed'; // Modification Failed
export const MODIFICATION_SUCCESS_CHANGES = 'Modification Success - Changes'; // Modification Success - Changes to Info
export const MODIFICATION_SUCCESS_PAYMENT = 'Modification Success - Payment'; // Modification Success - Changes to Payment
export const PARTNERSHIP_REFERRAL = 'Referred to Partner Link'; // Modification Success - Changes to Payment
export const CLOSE_RESERVATION_MODAL = 'Close Reservation Modal'; // Close Reservation Modal
export const EXPERIMENT_VIEWED = 'Experiment Viewed';
export const MP_OVERLAY_DISMISSED = 'Matterport Overlay Dismissed';
export const MP_TAG_CLICKED = 'Matterport Tag Clicked';

export const MP_OVERLAY_EXPERIMENT = {
	storage_key: 'experiment-matterport-overlay',
	experiment_name: 'Matterport Educational Overlay A/B Test',
	variants: {
		enabled: {
			name: 'Overlay Enabled'
		},
		disabled: {
			name: 'Overlay Disabled'
		}
	}
};

export const INVENTORY_NOTICE_EXPERIMENT = {
	storage_key: 'experiment-inventory-notices',
	experiment_name: 'Low/No Inventory Notice A/B Test',
	variants: {
		enabled: {
			name: 'Notices Enabled'
		},
		disabled: {
			name: 'Notices Disabled'
		}
	}
};

export const SIMPLIFIED_CTA_EXPERIMENT = {
	storage_key: 'experiment-simplified-cta',
	experiment_name: 'simplified cta A/B Test',
	variants: {
		simplified: {
			name: 'Simplified CTA'
		},
		original: {
			name: 'Original CTA'
		}
	}
};

export const PAYMENT_EXPANSION_EXPERIMENT = {
	storage_key: 'experiment-payment-expansion',
	experiment_name: 'Payment Expansion A/B Test',
	variants: {
		expanded: {
			name: 'Payment Expanded'
		},
		collapsed: {
			name: 'Payment Collapsed'
		}
	}
};
// export const UX_FLOW_EXPERIMENT = {
// 	storage_key: 'experiment-ux-flow-start',
// 	weight: 0.5,
// 	experiment_name: 'UX Flow Start A/B Test',
// 	variants: {
// 		inventory: {
// 			name: 'Inventory First'
// 		},
// 		availability: {
// 			name: 'Availability First'
// 		}
// 	}
// };

// info modals
export const LARGER_GATHERING_INFO_MODAL = 'Larger Gathering Info Modal';
export const FIND_DATE_INFO_MODAL = 'Find Date Info Modal';
export const BOOKING_FEES_INFO_MODAL = 'Booking Fees Info Modal';
export const LINK_CLICKED_EVENT = 'Link Clicked';
export const MODAL_OPENED_EVENT = 'Modal Opened';