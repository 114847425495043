import React from 'react';
import styles from './CTAButton.module.scss';
import Spinner from '../Spinner';

export type ExternalLinkVariant = 'primary' | 'secondary' | 'text' | 'dark';

type ExternalLinkProps = {
	href: string;
	target?: string
	variant?: ExternalLinkVariant;
	text: string;
	textColor?: string;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	ariaLabel?: string;
	iconBefore?: React.ReactNode;
	styleOverride?: any,
	iconAfter?: React.ReactNode;
	disabled?: boolean;
	loading?: boolean;
}

const ExternalLink = ({
	href,
	target,
	variant = 'primary',
	text,
	textColor,
	onClick,
	styleOverride = {},
	ariaLabel,
	iconBefore,
	iconAfter,
	disabled = false,
	loading = false
}: ExternalLinkProps) => {

	const classes = `${styles.Button} ${styles[`Button--${variant}`]} ${disabled ? styles['Button--disabled'] : ''}`;

	return (
		<a
			href={href}
			target={target}
			rel='noreferrer'
			type='button'
			className={classes}
			onClick={onClick}
			title={text}
			aria-label={ariaLabel || text}
			aria-busy={loading}
			aria-disabled={disabled}
			style={{
				color: textColor,
				...styleOverride
			}}
		>
			{loading ? <Spinner size='xs' /> : (
				<span>
					{iconBefore && iconBefore}
					{text}
					{iconAfter && iconAfter}
				</span>
			)}
		</a>
	);
};

export default ExternalLink;