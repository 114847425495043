import React, { useEffect, useRef } from 'react';
import Stripe from 'stripe';
import { analytics } from '../../helpers/analytics';
import AmexIcon from '../../icons/amex.svg';
import CardIcon from '../../icons/card.svg';
import MastercardIcon from '../../icons/mastercard.svg';
import VisaIcon from '../../icons/visa.svg';

import styles from './PrefilledCardInput.module.scss';

type PrefilledCardInputProps = {
	stripePaymentMethod: Stripe.PaymentMethod;
	handleChange: () => void;
};

export const PrefilledCardInput: React.FC<PrefilledCardInputProps> = ({ stripePaymentMethod, handleChange }) => {
	const prefilledInputRef = useRef<HTMLDivElement>();

	const obfuscateCard = (last4: string) => {
		return `**** **** **** ${last4}`;
	};

	const getCardIcon = (paymentMethod: Stripe.PaymentMethod) => {
		switch (paymentMethod?.card?.brand) {
			case 'visa':
				return VisaIcon;
			case 'mastercard':
				return MastercardIcon;
			case 'amex':
				return AmexIcon;
			default:
				return CardIcon;
		}
	};

	// scroll into view on mount
	useEffect(() => {
		if (prefilledInputRef?.current) {
			prefilledInputRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	return (
		<div className={styles.container} ref={prefilledInputRef}>
			<img src={getCardIcon(stripePaymentMethod)} className={styles.card} alt={'card-icon'} />
			<div className={styles.number}>{obfuscateCard(stripePaymentMethod.card.last4)}</div>
			<button
				type={'button'}
				aria-label={'Change Payment Method'}
				className={styles.changeButton}
				onClick={() => {
					analytics?.track('Button Clicked', { label: 'Change Payment Method' });
					handleChange();
				}}
			>
        Change
			</button>
		</div>
	);
};
