import * as Collapsible from '@radix-ui/react-collapsible';
import React, { useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { TDR } from 'tdr-common';
import { analytics } from '../../helpers/analytics';
import info from '../../images/dietaryicons/dietinfo.svg';
import { SubHeader } from '../headers/SubHeader';
import { AddOptionButton } from './AddOptionButton';
import { AddOptionModal } from './AddOptionModal';
import { AddOptionSelections } from './AddOptionSelections';
import DietaryOption from './DietaryOption';

import layoutStyles from '../../layouts/CheckoutLayout.module.scss';

type DietaryInputProps = {
	form: UseFormReturn;
	restaurant: TDR.Restaurant;
	currentNote: string;
	currentRestrictions: (boolean | TDR.DietaryRestriction)[];
	isEditEnabled?: boolean;
	isSaveEnabled: boolean;
	onSave?: () => void;
}

export const DietaryInput = ({ form, restaurant, currentNote, currentRestrictions, isEditEnabled = true, isSaveEnabled, onSave }: DietaryInputProps) => {
	const [isEditing, setIsEditing] = useState(false);

	const options = restaurant.dietaryRestrictions?.restrictions || [];
	const isDisclaimerEnabled = restaurant.dietaryRestrictions?.showDisclaimer || false;

	const selectedCount = currentRestrictions.filter((value) => !!value).length;
	const dietaryNoteLength = currentNote.length;

	const isDisclaimerOpen = isDisclaimerEnabled && (selectedCount > 0 || dietaryNoteLength > 0);

	return (
		<section className={layoutStyles.Section}>
			<SubHeader title='Dietary Preference' counter={selectedCount} isOptional />

			<div className={layoutStyles.SubSection}>
				{isEditEnabled && (
					<AddOptionButton
						label='Add selection'
						onClick={() => setIsEditing(true)}
						aria-label='Add dietary preferences (optional)'
					/>
				)}

				<AddOptionSelections
					items={currentRestrictions.filter((item) => !!item)
						.map((item) => item.valueOf().toString())}
					note={currentNote}
				/>
			</div>

			<AddOptionModal
				title='Add Dietary Preferences'
				screenReaderDescription='Select your dietary preferences'
				open={isEditing}
				setOpen={setIsEditing}
				isSaveEnabled={isSaveEnabled}
				onSave={onSave}
			>
				<div className='OverviewSelectionContent'>
					<ul className='OverviewSelections'>
						{options.map((option, i) => (
							<Controller
								key={option}
								control={form.control}
								name={`dietaryRestrictions.${i}`}
								defaultValue={false}
								render={({ field: { value, onChange } }) => (
									<DietaryOption
										label={option}
										onClick={() => {
											onChange(!value ? option : false);
											analytics.track('Dietary Information Clicked', {
												option
											});
										}}
										selected={value === option}
									/>
								)}
							/>
						))}
					</ul>

					<div className='InputContainer'>
						<label id='dietary-note-label' hidden>
              Dietary Note
						</label>
						<textarea
							{...form.register('dietaryNote')}
							aria-labelledby='dietary-note-label'
							rows={1}
							placeholder='Add note'
							maxLength={300}
						/>
					</div>

					<Collapsible.Root open={isDisclaimerOpen}>
						<Collapsible.Content className='Callout'>
							<img className='Callout__Icon' src={info} alt='Dietary information' />
							<p className='Callout__Text'>
                The restaurant may not be able to accommodate all selections, please call{' '}
								<a
									className='Callout__Link'
									href={`tel:${restaurant.phoneNumber}`}
									onClick={() => {
										analytics.track('Dietary Information: Phone Number Clicked');
									}}
								>
									{restaurant.phoneNumber}
								</a>{' '}
                to confirm.
							</p>
						</Collapsible.Content>
					</Collapsible.Root>
				</div>
			</AddOptionModal>
		</section>
	);
};
