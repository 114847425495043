import React from 'react';
import ErrorIcon from '../../icons/error.svg';

type InputValidationErrorProps = {
	message?: string;
};

export const InputValidationError = ({ message }: InputValidationErrorProps) => {
	return (
		<div className='InputValidation'>
			<img className='InputValidation__Icon' src={ErrorIcon} alt='Error icon' />
			<div role='alert' className='InputValidation__Message InputValidation__Message--error'>
				{message}
			</div>
		</div>
	);
};
