import React from 'react';
import Styles from './Notice.module.scss';


export const Notice = ({ text }: {text: string}) => {
	return (
		<div className={Styles.notice}>
			{text}
		</div>
	);
};