import React from 'react';
import CTALink from '../../components/buttons/CTALink';
import { TDR } from 'tdr-common';

export const InternalErrorToast = () => {
	return (
		<>
			<p>Oops, something went wrong on our end. Please try again, or let us know how we can help.</p>
			<CTALink text='Get help' to={`mailto:${TDR.SUPPORT_EMAIL}`} variant='dark' />
		</>
	);
};
