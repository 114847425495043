import React, { createContext, useContext, useState } from 'react';
import { analytics } from '../helpers/analytics';

const ViewToggleContext = createContext({
	isVirtualView: false,
	toggleView: null,
	turnOffVirtualView: null
});

export const ViewToggleProvider = ({ children }: { children: React.ReactNode}) => {
	const [isVirtualView, setIsVirtualView] = useState(false);

	const toggleView = () => {
		const newState = !isVirtualView;
		analytics.track('View Toggled', {
			screen: newState ? 'Matterport': 'Tablz'
		});

		setIsVirtualView(prevState => !prevState);
	};

	const turnOffVirtualView = () => {
		setIsVirtualView(false);
	};

	return (
		<ViewToggleContext.Provider value={{ isVirtualView, toggleView, turnOffVirtualView }}>
			{children}
		</ViewToggleContext.Provider>
	);
};

export const useViewToggle = () => {
	const context = useContext(ViewToggleContext);
	if (!context) {
		throw new Error('useViewToggle must be used within a ViewToggleProvider');
	}
	return context;
};