import React from 'react';
import { SubHeader } from '../../headers/SubHeader';

type OtherRequestProps = {
	setRequests: React.Dispatch<React.SetStateAction<string>>;
	requests: string;
	placeholder?: string;
};

export const OtherRequests: React.FC<OtherRequestProps> = ({ setRequests, requests, placeholder }) => {
	return (
		<div className='CheckoutContent__Section'>
			<SubHeader title='Additional Information' isOptional/>

			<div className="InputContainer">
				<label id='specialRequests-label' htmlFor="specialRequests" hidden>Additional Information</label>
				<textarea
					id='specialRequests-textarea'
					name='specialRequests'
					aria-labelledby='specialRequests-label'
					value={requests}
					onChange={(evt) => setRequests(evt.target.value)}
					placeholder={placeholder ?? 'Please indicate anything you want the restaurant to know.'}
					maxLength={300}
				/>
			</div>

		</div>

	);
};
