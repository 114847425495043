import React, { ForwardedRef, forwardRef } from 'react';
import { motion } from 'framer-motion';
import styles from './Skeleton.module.scss';

export interface SkeletonProps {
	height: string;
	width: string;
	style?: React.CSSProperties;
}

export const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(function Skeleton({ style, ...props }: SkeletonProps, ref: ForwardedRef<HTMLDivElement>) {
	return (
		<motion.div
			ref={ref}
			className={styles.skeleton}
			style={{ ...style, ...props }}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
		</motion.div>
	);
});
