
import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';

/*
this shouldn't live in tdr-common because firebase is very persnickety
about firebase.firestore.Timestamp coming from EXACTLY the same version of firebase as is being used to make the query

see: https://stackoverflow.com/questions/52608930/detected-an-object-of-type-timestamp-that-doesnt-match-the-expected-instance

or: `Uncaught FirebaseError: Function Query.where() called with invalid data. Unsupported field value: a custom t object`

so, it's either duplicate this code, or else hard-code an exact version of firebase for each branch, and keep them in sync
on the balance, I expect that duplication will result in less heartache
consequently, when making changes to this file, be sure to update the OTHER instances as well
BUT, notice that `import firebase from 'whatever-package' may be different for each file - don't copy-paste that part blindly

Known Duplications:
    app/src/helpers/luxonToFirebaseTimestamp.ts
    admin/src/helpers/luxonToFirebaseTimestamp.ts
    functions/src/helpers/luxonToFirebaseTimestamp.ts

Also, if you dupe this again elsewhere, please update this list in all instances
*/


const luxonToFirebaseTimestamp = (luxonDate: DateTime) => {
	return Timestamp.fromMillis(luxonDate.toMillis());
};


export default luxonToFirebaseTimestamp;