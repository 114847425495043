import React, { createContext, useState } from 'react';
import { ProviderProps } from '../../common/types';
import noop from 'lodash/noop';

export namespace GlobalParameterContext {
	export type Value = {
		searchString: string,
		setSearchString: (searchString: string) => void,
	};
}

export const GlobalParameterContext = createContext<GlobalParameterContext.Value>({
	searchString: '',
	setSearchString: noop
});


export const GlobalParameterProvider = ({ children }: ProviderProps) => {
	const [searchString, setSearchString] = useState<string>('');

	const value: GlobalParameterContext.Value = {
		searchString, setSearchString
	};

	return (
		<GlobalParameterContext.Provider value={value}>
			{children}
		</GlobalParameterContext.Provider>
	);
};
