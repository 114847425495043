import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { SearchFilters, serializeSearchFiltersForURL } from '../../routes/AvailabilityGuard';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';
import CTALink from './CTALink';

export interface LeadGenProceedButtonProps {
	searchFilters: Partial<SearchFilters>
}

export function LeadGenProceedButton({ searchFilters }: LeadGenProceedButtonProps) {
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();
	const areAllFiltersSet = !!searchFilters?.groupSize && !!searchFilters?.date && !!searchFilters?.time;
	return (
		<CTALink
			variant='primary'
			text={'Proceed With Request'}
			disabled={!areAllFiltersSet}
			to={{
				pathname: `/${restaurant.slug}/explore/request/overview`,
				search: serializeSearchFiltersForURL(searchFilters).toString()
			}}
		/>
	);
}