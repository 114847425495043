export const baseCheckoutNavConfig = {
	policies: {
		order: 1,
		path: 'policies',
		title: 'Policies',
		prev: null,
		next: 'details'
	},
	details: {
		order: 2,
		path: 'details',
		title: 'Your Details',
		prev: 'policies',
		next: 'payment'
	},
	payment: {
		order: 3,
		path: 'payment',
		title: 'Payment',
		prev: 'details',
		next: 'confirmation'
	},
	confirmation: {
		order: 4,
		path: 'confirmation'
	}
};

export const fullCheckoutNavConfig = {
	...baseCheckoutNavConfig,
	details: {
		...baseCheckoutNavConfig.details,
		next: 'addons'
	},
	addons: {
		order: 3,
		path: 'addons',
		title: 'Personalize Your Visit',
		prev: 'details',
		next: 'payment'
	},
	payment: {
		...baseCheckoutNavConfig.payment,
		order: 4,
		prev: 'addons'
	},
	confirmation: {
		...baseCheckoutNavConfig.confirmation,
		order: 5
	}

};
