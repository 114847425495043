import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TDR, toLuxon } from 'tdr-common';
import { getReservation } from '../../api';
import { approveLargeGroupProposal } from '../../api/largeGroup';
import { URLParams } from '../../common/types';
import { analytics } from '../../helpers/analytics';
import { NotFoundErrorPage } from '../../pages/errors/NotFoundErrorPage';
import { BookingConfirmed, BookingUpdateError } from '../confirmation';
import WholeScreenLoading from '../whole-screen-loading/WholeScreenLoading';

export const ApproveModification = () => {
	const { reservationId } = useParams<URLParams>();

	const [reservation, setReservation] = useState<TDR.Reservation | null>();
	const [loading, setLoading] = useState(true);
	const [reservationNotFound, setReservationNotFound] = useState(false);
	const [error, setError] = useState(false);

	const dateString = reservation && toLuxon(reservation.time, reservation.timezone).toFormat('MMMM d');
	const timeString = reservation && toLuxon(reservation.time, reservation.timezone).toFormat('h:mm a');

	const returnToBookingPortalRoute = `/${reservation?.restaurantSlug}/explore`;

	useEffect(() => {
		const fetchReservation = async () => {
			try {
				const reservation = await getReservation(reservationId);
				if (reservation) {
					setReservationNotFound(false);
					setReservation(reservation);
				}
				else {
					setReservationNotFound(true);
					setReservation(null);
				}

				const res = await approveLargeGroupProposal(reservation);
				if (!res.success) {
					analytics.track('Proposal Approval Failed', { reservationId: reservationId, message: res.message });
					setError(true);
				}
				else {
					analytics.track('Proposal Approved', { reservationId: reservationId });
				}
			}
			catch (error) {
				setError(true);
				Sentry.captureException(error);
			}
			finally {
				setLoading(false);
			}
		};

		fetchReservation();
	}, [reservationId]);

	if (reservationNotFound) {
		return <NotFoundErrorPage />;
	}

	if (error) {
		return <BookingUpdateError linkTo={returnToBookingPortalRoute} />;
	}

	return loading ? (
		<WholeScreenLoading />
	) : (
		<BookingConfirmed
			bookingId={reservationId}
			restaurantName={reservation.restaurantName}
			guests={reservation.guests}
			time={timeString}
			date={dateString}
			linkTo={returnToBookingPortalRoute}
		/>
	);
};
