import React, { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { useRestaurantBySlug } from '../api/getRestaurantBySlug';
import { useFeatureFlags } from '../api/getRestaurantFeatureFlags';
import { useTables } from '../api/getRestaurantTables';
import { URLParams } from '../common/types';
import WholeScreenLoading from '../components/whole-screen-loading/WholeScreenLoading';
import { NotFoundErrorPage } from '../pages/errors/NotFoundErrorPage';
import { analytics, restaurantMiddlewareBuilder } from '../helpers/analytics';

export interface RestaurantGuardOutletContext {
	restaurant: TDR.Restaurant;
	featureFlags: TDR.FeatureFlags;
	tables: TDR.Table[];
}
export interface RestaurantGuardProps {
	children: ReactNode;
}
export const RestaurantGuardContext = React.createContext<RestaurantGuardOutletContext | undefined>(undefined);
export const RestaurantGuard = ({ children }: RestaurantGuardProps ) => {
	const { restaurantSlug } = useParams<URLParams>();

	const { data: restaurant, isLoading: isRestaurantLoading, isError: isRestaurantError } = useRestaurantBySlug({ slug:restaurantSlug });
	const { data: featureFlags, isLoading: areFlagsLoading, isError: areFlagsError } = useFeatureFlags(restaurant?.id);
	const { data: tables, isLoading: areTablesLoading, isError: areTablesError } = useTables(restaurant?.id);

	const isError = isRestaurantError || areFlagsError || areTablesError;
	const isLoading = isRestaurantLoading || areFlagsLoading || areTablesLoading;

	useEffect(() => {
		if (restaurant) {
			const trackingMiddleware = restaurantMiddlewareBuilder(restaurant.id, restaurant.name);
			analytics.addSourceMiddleware(trackingMiddleware);

			return () => analytics.removeSourceMiddleware(trackingMiddleware);
		}
	}, [restaurant]);

	if (isLoading && !isError) {
		return <WholeScreenLoading id='main-loading-page' />;
	}
	else {
		return !restaurant || isError ? <NotFoundErrorPage /> :
			<RestaurantGuardContext.Provider value={{ restaurant, featureFlags, tables }}>
				{children}
			</RestaurantGuardContext.Provider>;

	}
};
