import React from 'react';
import styles from './buttons.module.scss';

type InlineButtonLinkProps = {
	children: React.ReactNode,
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const InlineButtonLink = ({ children, ...rest }: InlineButtonLinkProps) => {
	return (
		<button className={`${styles.link} ${styles.buttonLink}`} {...rest}>
			{children}
		</button>
	);
};

export default InlineButtonLink;