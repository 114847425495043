import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { getReservation } from '../../api';
import { declineLargeGroupProposal } from '../../api/largeGroup';
import { URLParams } from '../../common/types';
import { analytics } from '../../helpers/analytics';
import { BookingCancelled, BookingUpdateError } from '../confirmation';
import WholeScreenLoading from '../whole-screen-loading/WholeScreenLoading';

export const DeclineModification = () => {
	const { reservationId } = useParams<URLParams>();
	const [reservation, setReservation] = useState<TDR.Reservation>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const returnToBookingPortalRoute = reservation?.restaurantSlug ? `/${reservation?.restaurantSlug}/explore`: null;

	useEffect(() => {
		const fetchReservation = async () => {
			try {
				const reservation = await getReservation(reservationId);
				setReservation(reservation);

				const res = await declineLargeGroupProposal(reservation);
				if (!res.success) {
					analytics.track('Proposal Decline Failed', { reservationId: reservationId, message: res.message });
					setError(true);
				}
				else {
					analytics.track('Proposal Declined', { reservationId: reservationId });
				}
			}
			catch (error) {
				setError(true);
				Sentry.captureException(error);
			}
			finally {
				setLoading(false);
			}
		};

		fetchReservation();
	}, [reservationId]);

	if (error) {
		return <BookingUpdateError linkTo={returnToBookingPortalRoute} />;
	}

	return loading ? (
		<WholeScreenLoading />
	) : (
		<BookingCancelled restaurantName={reservation.restaurantName} linkTo={returnToBookingPortalRoute} />
	);
};
