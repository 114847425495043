import { PaymentElement } from '@stripe/react-stripe-js';
import { StripeError, StripePaymentElementChangeEvent, StripePaymentElementOptions } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import Stripe from 'stripe';
import { PrefilledCardInput } from './PrefilledCardInput';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import {
	analytics,
	EXPERIMENT_VIEWED,
	PAYMENT_EXPANSION_EXPERIMENT
} from '../../helpers/analytics';
import { RANDOM_TRUE_OR_FALSE } from '../notice/experiments/constants';

type PaymentInputProps = {
	stripePaymentMethod?: Stripe.PaymentMethod;
	promoCode?: string;
	setIsPaymentInfoComplete: React.Dispatch<React.SetStateAction<boolean>>;
	onError: (e: StripeError) => void;
};

export const PaymentInput = ({
	stripePaymentMethod,
	promoCode,
	setIsPaymentInfoComplete,
	onError
}: PaymentInputProps) => {
	const [usePrefilledPayment, setUsePrefilledPayment] = useState<boolean>(!!stripePaymentMethod);

	const handlePaymentElementChange = (event: StripePaymentElementChangeEvent) => {
		onError(null);
		setIsPaymentInfoComplete(!event.collapsed && event.complete);
	};

	const [paymentExpansionExperiment] = useSessionStorage(
		PAYMENT_EXPANSION_EXPERIMENT.storage_key,
		{ enabled: RANDOM_TRUE_OR_FALSE }
	);

	useEffect(() => {
		analytics.track(EXPERIMENT_VIEWED, {
			experiment: PAYMENT_EXPANSION_EXPERIMENT.experiment_name,
			variant: paymentExpansionExperiment.enabled ?
				PAYMENT_EXPANSION_EXPERIMENT.variants.expanded.name :
				PAYMENT_EXPANSION_EXPERIMENT.variants.collapsed.name
		});
	},[paymentExpansionExperiment]);

	const handlePaymentCardChange = () => {
		setUsePrefilledPayment(false);
		setIsPaymentInfoComplete(false);
	};

	const options: StripePaymentElementOptions = {
		paymentMethodOrder: ['card', 'apple_pay', 'google_pay'],
		layout: {
			type: 'accordion',
			defaultCollapsed: !paymentExpansionExperiment?.enabled
		},
		wallets: {
			applePay: 'auto',
			googlePay: 'auto'
		}
	};

	return usePrefilledPayment && !promoCode ? (
		<PrefilledCardInput stripePaymentMethod={stripePaymentMethod} handleChange={handlePaymentCardChange} />
	) : (
		<PaymentElement
			id='payment-element'
			options={options}
			onLoadError={(event) => console.error({ paymentElementError: JSON.stringify(event) })}
			onChange={handlePaymentElementChange}
		/>
	);
};
