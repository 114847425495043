import React from 'react';
import styles from './SubHeader.module.scss';

type SubHeaderProps = {
	title: string;
	subtitle?: React.ReactNode;
	counter?: number;
	isOptional?: boolean;
	isRequired?: boolean;
};

export const SubHeader = ({ title, subtitle, counter, isOptional, isRequired }: SubHeaderProps) => {
	return (
		<div className={styles.Container}>
			<h3 className={styles.Title}>
				{title}
				{counter > 0 ? <span className={styles.Counter}> ({counter})</span> : null}
				{isOptional && <span className={styles.Optional}> (Optional)</span>}
				{!isOptional && isRequired && <span className={styles.Required}>&nbsp;*</span>}
			</h3>

			{subtitle && (
				<p className={styles.SubTitle}>
					{subtitle}
				</p>
			)}
		</div>
	);
};
