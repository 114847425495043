import React from 'react';
import styles from './Notice.module.scss';

type NoticeProps = {
	body: React.ReactNode,
	icon: React.ReactNode,
	position?: 'top' | 'bottom';
}

export const Notice: React.FC<NoticeProps> = ({ body , icon, position }) => {
	return (
		<div className={`${styles.NoticeInfoBox} ${ position ? styles[`NoticeInfoBox--${position}`] : ''}`}>
		    <span>{icon}</span>
		    <span>{body}</span>
	    </div>
	);
};
