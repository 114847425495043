import { TDR } from 'tdr-common';
import config from '../common/config';
import { useMutation } from '@tanstack/react-query';

type ApplyPromoCodeInput = {
	checkoutId: string;
	invoiceId: string;
	promoCode: string;
};

export function useApplyPromoCode() {
	const mutation = useMutation({
		retry: 3,
		mutationFn: (input: ApplyPromoCodeInput) => applyPromoCode(input),
		scope: {
			id: 'applyPromoCode'
		}
	});

	return mutation;
}

async function applyPromoCode(
	input: ApplyPromoCodeInput
): Promise<TDR.CheckoutSession.ApplyPromoCodeOutput> {
	const response = await fetch(`${config.apiHost}/applyPromoCode`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as TDR.CheckoutSession.ApplyPromoCodeOutput;
	if (response?.status >= 500) {
		throw response;
	}

	return responsePayload;
}
