import React from 'react';
import styles from './CTAButton.module.scss';
import Spinner from '../Spinner';

export type CTAButtonVariant = 'primary' | 'secondary' | 'text' | 'dark' | 'transparent';

type SubmitButtonProps = {
	variant?: CTAButtonVariant;
	buttonText: string;
	textColor?: string;
	form: string;
	ariaLabel?: string;
	iconBefore?: React.ReactNode;
	iconAfter?: React.ReactNode;
	disabled?: boolean;
	loading?: boolean;
	isSelected?: boolean;
}

const SubmitButton = ({
	variant = 'primary',
	buttonText,
	textColor,
	form,
	ariaLabel,
	iconBefore,
	iconAfter,
	disabled = false,
	loading = false,
	isSelected
}: SubmitButtonProps) => {

	const classes = `${styles.Button} ${styles[`Button--${variant}`]} ${isSelected ? styles.Selected : ''}`;

	return (
		<button
			form={form}
			type='submit'
			className={classes}
			title={buttonText}
			aria-label={ariaLabel || buttonText}
			aria-busy={loading}
			disabled={disabled}
			aria-disabled={disabled}
			style={{
				color: textColor
			}}
		>
			{loading ? <Spinner size='xs' /> : (
				<span>
					<span>{iconBefore && iconBefore}</span>
					<span>{buttonText}</span>
					<span>{iconAfter && iconAfter}</span>
				</span>
			)}
		</button>
	);
};

export default SubmitButton;