import React, { useState, useEffect } from 'react';
import { TDR } from 'tdr-common';
import styles from './PriceLabel.module.scss';
import { useOutletContext } from 'react-router-dom';
import { AvailabilityGuardContext } from '../../routes/AvailabilityGuard';

type SearchResultPricingProps = {
	restaurant: TDR.Restaurant;
	priceAmountTotaled: number | undefined;
};

const SimplifiedPriceLabel = ({ restaurant, priceAmountTotaled }: SearchResultPricingProps) => {
	const { placement, symbol } = TDR.CurrencyConfig[restaurant?.currency || TDR.Currency.USD];
	const { searchFilters } = useOutletContext<AvailabilityGuardContext>();
	const allFiltersSet = searchFilters?.groupSize && searchFilters?.date && searchFilters?.time;

	const currencySymbol = <span className={styles.Price__Symbol}>{symbol}</span>;

	const [prevPriceAmountTotaled, setPrevPriceAmountTotaled] = useState<number | undefined>(priceAmountTotaled);

	useEffect(() => {
		if (priceAmountTotaled !== undefined) {
			setPrevPriceAmountTotaled(priceAmountTotaled);
		}
	}, [priceAmountTotaled]);

	const displayPrice = !allFiltersSet ? undefined : priceAmountTotaled !== undefined ? priceAmountTotaled : prevPriceAmountTotaled;

	return (
		(displayPrice !== undefined) && (
			<div className={`${styles.Container}`} style={{ paddingRight: '16px' }}>
				<span className={styles.Price}>
					<span translate='no'>{placement === TDR.CurrencyPlacement.BEFORE && currencySymbol}</span>
					<span translate='no'>{displayPrice}</span>
					<span translate='no'>{placement === TDR.CurrencyPlacement.AFTER && currencySymbol}</span>
				</span>
			</div>
		)
	);
};

export default SimplifiedPriceLabel;
