import React from 'react';
import styles from './VolumeSlider.module.scss';

type VolumeSliderProps = React.HTMLAttributes<HTMLInputElement> & {
	videoRef: React.MutableRefObject<HTMLVideoElement| null>,
	isVisible: boolean;
}

const DEFAULT_VOLUME_LEVEL = 0.5;

const VolumeSlider = ({ videoRef, isVisible, ...props }: VolumeSliderProps) => {

	const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		event.stopPropagation();
		const video = videoRef?.current;

		if (video) {
			const newVolume = parseFloat(event.target.value);

			video.volume = newVolume;
		}
	};

	return (
		<div className={`${styles.VolumeSliderContainer} ${!isVisible && styles.Hidden}`}>
			<input
				{...props}
				aria-label='Volume Slider Input'
				className={styles.VolumeSlider}
				title='Volume'
				type='range'
				min='0'
				max='1'
				step='0.1'
				defaultValue={DEFAULT_VOLUME_LEVEL}
				onChange={handleVolumeChange}
				// disable click
				onDrag={() => false}
				onDrop={() => false}
				onClick={() => false}
			/>
		</div>
	);
};

export default VolumeSlider;