import React from 'react';
import styles from './AddOptionButton.module.scss';

interface OccasionOptionProps {
	label: string;
	onClick: () => void;
	selected: boolean;
}

const OccasionOption: React.FC<OccasionOptionProps> = ({ label, onClick, selected }) => {
	return (
		<li
			role='radio'
			tabIndex={0}
			aria-checked={selected}
			className={`${styles.Option} ${selected ? styles['Option--selected'] : ''}`}
			onClick={onClick}
		>
			<img src={`../../images/occasionIcons/${label}.svg`} alt={label} />
			<span>{label}</span>
		</li>
	);
};

export default OccasionOption;
