import React, { ReactElement, useEffect } from 'react';
import { To, useLocation, useOutletContext } from 'react-router-dom';
import CTALink from '../../components/buttons/CTALink';
import { analytics } from '../../helpers/analytics';
import PageNotFoundIcon from '../../icons/2-chain.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';

export interface NotFoundErrorPageProps {
	to?: To;
}

export function NotFoundErrorPage({ to }: NotFoundErrorPageProps) {
	const location = useLocation();
	// we can't deconstruct here ({ restaurant }) because the restaurant itself my be not found
	// which would make the context undefined
	const context = useOutletContext<RestaurantGuardOutletContext>();

	let button: ReactElement | null = null;

	if (to) {
		button = <CTALink text='Go Back' to={to} />;
	}
	else if (context?.restaurant) {
		button = <CTALink text='Go to Homepage' to={`/${context?.restaurant.slug}`} />;
	}

	useEffect(() => {
		analytics.track('Resource Not Found', {
			...location
		});
	}, []);

	return (
		<ResultPageLayout headerIcon={PageNotFoundIcon} iconAltText='Broken Link Icon'>
			<ResultPageLayout.Title>Oops</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable</p>
			</ResultPageLayout.Body>

			<ResultPageLayout.Footer>{button}</ResultPageLayout.Footer>
		</ResultPageLayout>
	);
}
