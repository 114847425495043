import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import React from 'react';
import { CTAButton } from '../buttons';
import {
	Dialog,
	DialogBody,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle
} from '../dialog/Dialog';

type AddOptionModalProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	title: string;
	screenReaderDescription: string;
	isSaveEnabled: boolean;
	onSave?: () => void;
	children: React.ReactNode;
};

export const AddOptionModal = ({
	open,
	setOpen,
	title,
	screenReaderDescription,
	isSaveEnabled,
	onSave,
	children
}: AddOptionModalProps) => {
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent hasCloseButton fillContainerWidth>
				<VisuallyHidden.Root>
					<DialogDescription>{screenReaderDescription}</DialogDescription>
				</VisuallyHidden.Root>

				<DialogHeader>
					<DialogTitle>{title}</DialogTitle>
				</DialogHeader>

				<DialogBody>{children}</DialogBody>

				<DialogFooter>
					<CTAButton
						variant='dark'
						buttonText='Save'
						disabled={!isSaveEnabled}
						onClick={() => {
							onSave?.();
							setOpen(false);
						}}
					/>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
