import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { TDR } from 'tdr-common';
import getLargeGroupSettings from '../../api/getLargeGroupSettings';
import Notice from '../../icons/notice.svg';
import Stop from '../../icons/stop.svg';
import Timer from '../../icons/timer.svg';
import Wallet from '../../icons/wallet.svg';
import { CheckoutGuardOutletContext } from '../../routes/CheckoutGuard';
import { PolicyItem } from '../policies/PolicyItem';
import { PolicyItemsDemo } from './PolicyItemsDemo';

import styles from './PolicyItems.module.scss';

export const PolicyItemsLGB = () => {
	const { checkoutSession, featureFlags, restaurant } = useOutletContext<CheckoutGuardOutletContext>();
	const [largeGroupSettings, setLargeGroupSettings] = useState<TDR.LargeGroupSettings | null>(null);

	const { tableId } = checkoutSession;

	useEffect(() => {
		let isMounted = true;

		const fetchLargeGroupSettings = async () => {
			try {
				const settings = await getLargeGroupSettings(tableId);
				if (isMounted) {
					setLargeGroupSettings(settings);
				}
			}
			catch (error) {
				Sentry.captureException(error);
			}
		};

		if (tableId) {
			fetchLargeGroupSettings();
		}

		return () => {
			isMounted = false;
		};
	}, [tableId]);

	if (!largeGroupSettings) {
		return null;
	}

	if (featureFlags.hideFreePricing)	 {
		return (
			<ul className={styles.PolicyList}>
				<PolicyItemsDemo settings={largeGroupSettings}/>
			</ul>
		);
	}

	const { response, cancellationWindow, bookingDeposit, minimumSpend, autoGratuity, gracePeriod, freeformPolicy } =
    largeGroupSettings;

	const currencyConfig = TDR.CurrencyConfig[restaurant?.currency || TDR.Currency.USD];
	const currencySymbol = currencyConfig.symbol;
	const currencyAndAmount = (amount: number) => `${currencyConfig.placement === 'before' ? currencySymbol: ''}${amount}${currencyConfig.placement === 'after' ? currencySymbol : ''}`;
	const depositText = bookingDeposit?.amount
		? `${currencyAndAmount(bookingDeposit.amount)}${bookingDeposit?.perPerson ? '/guest' : ''}`
		: '';
	const minimumSpendText = minimumSpend?.amount
		? `${currencyAndAmount(minimumSpend.amount)}${minimumSpend?.perPerson ? '/guest' : ''}`
		: '';


	return (
		<ul className={styles.PolicyList}>
			{response > 0 && (
				<PolicyItem icon={'Timer'} title={'Booking Request'}>
					<p>
						Your group booking will be confirmed within <strong>{response} hours</strong>.
					</p>
				</PolicyItem>
			)}

			<PolicyItem icon={Wallet} label='Payment' title={'Payment Processing'}>
				<p>Your payment will be processed once your booking is confirmed.</p>
			</PolicyItem>

			<PolicyItem icon={'ImportCircle'} title={'Seating Selection'}>
				<p>The seating <strong>selection fee is separate</strong> from the food and beverage deposit</p>
			</PolicyItem>

			{bookingDeposit?.amount > 0 && (
				<PolicyItem icon={'DollarSquare'} title='Deposit'>
					<p>A non-refundable deposit of <strong>{depositText}</strong>, deducted from the final bill at the restaurant.</p>
				</PolicyItem>
			)}

			{minimumSpend?.amount > 0 && (
				<PolicyItem icon={'Coin1'} title='Minimum spend'>
					<p>
						Your deposit will be deducted from a minimum spend of <strong>{minimumSpendText}</strong> on food and beverage, prior to tax
						and gratuity, at the restaurant.
					</p>
				</PolicyItem>
			)}


			{autoGratuity > 0 && (
				<PolicyItem icon={'ReceiptDiscount'} title={'Auto-gratuity'}>
					<p>
						For large group bookings, there is an auto-gratuity of <strong>{autoGratuity}%</strong> applied to your final
						bill at the restaurant.
					</p>
				</PolicyItem>
			)}

			{cancellationWindow > 0 && (
				<PolicyItem icon={Stop} label='Cancellation' title={'Cancellation Window'}>
					<p>
						Cancellations can be made with no penalty up to <strong>{cancellationWindow} hours</strong> in advance of your
						booking time.
					</p>
				</PolicyItem>
			)}

			{gracePeriod > 0 && (
				<PolicyItem icon={Timer} label='Grace period' title={'Grace Period'}>
					<p>
						All bookings have a <strong>{gracePeriod} minute</strong> grace period from your reserved seating time. Your
						booking may not be honored if you arrive past the {gracePeriod} minute grace period on the day of your
						reservation.
					</p>
				</PolicyItem>
			)}

			{!!freeformPolicy && (
				<PolicyItem icon={Notice} label='Other policy'>
					<p>{freeformPolicy}</p>
				</PolicyItem>
			)}
		</ul>
	);
};
