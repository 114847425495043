import { TDR } from 'tdr-common';

export const getTimeslotsFromService = (service: TDR.Service): {[time: string]: TDR.TimeSlot} => {
	const timeSlots: {[time: string]: TDR.TimeSlot} = {};

	if (!service?.seatings || service.seatings.length === 0) {
		return {};
	}

	//TODO in the future seatings will disapear and this code will need to be refactored
	service.seatings.map((seating) => {
		Object.entries(seating.timeSlots).forEach(([time, timeSlot]) => {
			timeSlots[time] = timeSlot;
		});
	});
	return timeSlots;
};

export const mockTimeSlots = (startTime: string, endTime: string): TDR.ServiceTimeSlotMap => {
	const timeSlotMap = {
		'All Day' : { timeSlots: {} }
	};
	const [startHour] = startTime.split(':').map(Number);
	const [endHour, endMinute] = endTime.split(':').map(Number);

	for (let hour = startHour; hour <= endHour; hour++) {
		for (let minute = 0; minute < 60; minute += 30) {
			if (hour === endHour && minute > endMinute) {
				break;
			}
			const formattedHour = hour.toString().padStart(2, '0');
			const formattedMinute = minute.toString().padStart(2, '0');
			timeSlotMap['All Day'].timeSlots[`${formattedHour}:${formattedMinute}`] = mockTimeSlot;
		}
	}
	return timeSlotMap;
};

const mockTimeSlot = {
	available: true,
	holidayPrice: 0,
	isPerGuest: false,
	priceTier: 0,
	regularPrice: 0,
	seating: 'First Seating',
	service: 'All Day',
	timeLabel: 'First Seating'
};

// export const serviceHasTimeslots = (service: TDR.Service) => {
// 	const timeslots = getTimeslotsFromService(service);

// 	return !isEmpty(timeslots);
// };

export const mockService = (startTime: string, endTime: string) => {
	return [{
		name: 'All Day',
		seatings: [{
			name: 'First Seating',
			service: 'All Day',
			available: true,
			startTime: startTime,
			endTime: endTime,
			timeSlots: { [startTime] : { available: true } }
		}]
	}] as TDR.Service[];
};
