import React from 'react';
import { Skeleton } from '../skeleton/Skeleton';

export const CarouselSkeleton = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
			<Skeleton height='240px' width='100%' style={{ borderRadius: '12px' }}/>
			<Skeleton height='240px' width='100%' style={{ borderRadius: '12px' }}/>
			<Skeleton height='240px' width='100%' style={{ borderRadius: '12px' }}/>
		</div>
	);
};
