import React from 'react';
import styles from './AvailabilityLegend.module.scss';

export const AvailabilityLegend = ({ disabled }: { disabled: boolean }) => {
	return (
		<div className={`${styles.Container} ${disabled ? styles.Disabled : ''}`}>
			<h3 className={styles.Title}>Seating Availability</h3>

			<ul className={styles.List}>
				<li className={styles.ListItem}>
					<div className={`${styles.Indicator} ${styles['Indicator--high']}`} />
					<span>High</span>
				</li>
				<li className={styles.ListItem}>
					<div className={`${styles.Indicator} ${styles['Indicator--limited']}`}/>
					<span>Limited</span>
				</li>
				<li className={styles.ListItem}>
					<div className={`${styles.Indicator} ${styles['Indicator--none']}`} />
					<span>None</span>
				</li>
				<li className={styles.ListItem}>
					<div className={`${styles.Indicator} ${styles['Indicator--closed']}`}/>
					<span>Closed</span>
				</li>
			</ul>
		</div>
	);
};