import ArrowRight from 'iconsax-react/dist/esm/ArrowRight';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { LANDING_PAGE_PLACEHOLDERS } from 'tdr-common';
import { analytics } from '../../../helpers/analytics';
import { getPatioSection } from '../../../helpers/getPatioSection';
import { mostCompatibleGroupSize } from '../../../helpers/groupSIze';
import { RestaurantGuardOutletContext } from '../../../routes/RestaurantGuard';
import { AnimatedImageGallery } from '../../animated-image-gallery/AnimatedImageGallery';
import { ProceedWithLogo } from '../../logos/ProceedWithLogo';
import styles from './BookWithTablz.module.scss';
import { BookingOptionTileWidgetVersion } from '../BookingOptionTileWidgetVersion';

export const RightArrowIcon = () => {
	return (
		<div className={styles.IconContainer}>
			<ArrowRight />
		</div>
	);
};

export const BookWithTablz = () => {
	const { featureFlags, tables, restaurant } = useOutletContext<RestaurantGuardOutletContext>();
	const groupSize = mostCompatibleGroupSize(tables);
	const searchString = `groupSize=${groupSize}`;
	const patioExists = Boolean(getPatioSection(tables));

	const handleClick = () => {
		analytics.track('home_proceed');
	};

	const getLinkTo = () => {
		const defaultPath = featureFlags?.autoSelectGroupSize ? `explore/filters?${searchString}` : 'explore/filters';
		return (featureFlags?.patio && patioExists) || restaurant.settings?.alternativeBookingOption?.label === 'Outdoor'
			? 'seating'
			: defaultPath;
	};

	const title = restaurant.settings?.landingPage?.tablz?.title || LANDING_PAGE_PLACEHOLDERS.tablz.title;
	const description = restaurant.settings?.landingPage?.tablz?.description || LANDING_PAGE_PLACEHOLDERS.tablz.description;
	const callToAction = restaurant.settings?.landingPage?.tablz?.callToAction || LANDING_PAGE_PLACEHOLDERS.tablz.callToAction;

	return (
		<BookingOptionTileWidgetVersion
			title={title}
			description={description}
			backgroundImage={<AnimatedImageGallery restaurant={restaurant} tables={tables} />}
			icon={<RightArrowIcon />}
			platformLogo={<ProceedWithLogo logo='tablz--gold' />}
			linkTo={getLinkTo()}
			onClick={handleClick}
			label={callToAction}
			variant='primary'
		/>
	);
};
