import React from 'react';
import ErrorIcon from '../../icons/error-grey.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { GetHelp, ReturnToBookingPortal } from './buttons';
import { RequestDeniedProps } from './types';

export const RequestDenied = ({ guests, date, time, restaurantName, bookingId, linkTo }: RequestDeniedProps) => {
	return (
		<ResultPageLayout headerIcon={ErrorIcon} iconAltText='Request Denied'>
			<ResultPageLayout.Title>Request Denied</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>
          We&apos;re sorry, it appears your booking request at <b>{restaurantName}</b> for <b>{guests}</b> guest
					{guests > 1 ? 's' : ''} on <b>{date}</b> at <b>{time}</b> has been denied.
				</p>
				<p>Please check your email for details, or reach out to us if you have any questions.</p>
			</ResultPageLayout.Body>

			<ResultPageLayout.Footer>
				<ReturnToBookingPortal to={linkTo} />
				<GetHelp bookingId={bookingId} />
			</ResultPageLayout.Footer>
		</ResultPageLayout>
	);
};
