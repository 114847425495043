import React from 'react';
import { TDR } from 'tdr-common';
import Styles from './AddOnOrderSummary.module.scss';


type AddOnOrderSummaryProps = {
	addOns: TDR.Reservation.Item[];
	currency: TDR.Currency;
}
export const AddOnOrderSummary = ({ addOns , currency }: AddOnOrderSummaryProps) => {
	return (
		<div className={Styles.container}>
			{addOns.map((addOn, index) => (
				<AddOnLineItem key={index} addOn={addOn} currency={currency} />
			))}
		</div>
	);
};

type AddOnLineItemProps = {
	addOn: TDR.Reservation.Item;
	currency: TDR.Currency;
}

const AddOnLineItem = ({ addOn, currency }: AddOnLineItemProps) => {
	const { placement, symbol } = TDR.CurrencyConfig[currency || TDR.Currency.USD];

	const priceWithCurrencySymbol = (totalPrice: number) => `${placement === TDR.CurrencyPlacement.BEFORE ? symbol : ''}${totalPrice}${placement === TDR.CurrencyPlacement.AFTER ? symbol : ''}`;
	const priceWithQuantity = (unitPrice: number, quantity: number)=> `x${quantity} • ${priceWithCurrencySymbol(unitPrice)}`;

	// If the quantity is 0, don't render the line item
	if (addOn.quantity === 0) {
		return null;
	}

	return (
		<div className={Styles.lineItem}>
			<div className={Styles.itemInfo}>
				<div
					style={{
						background: `url(${addOn.imageUrl}) center / cover no-repeat`
					}}
					className={Styles.infoMedia}
				/>
				<div className={Styles.infoText}>
					<div className={Styles.infoTitle}>{addOn.label}</div>
					<div className={Styles.infoPPU}>{priceWithQuantity(addOn.price, addOn.quantity)} / {addOn.unit}</div>
				</div>
			</div>
			<div className={Styles.itemPrice}>{priceWithCurrencySymbol(addOn.price * addOn.quantity)}</div>
		</div>
	);
};