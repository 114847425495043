import React from 'react';
import CTALink from './CTALink';
import { SearchFilters, serializeSearchFiltersForURL } from '../../routes/AvailabilityGuard';

export interface ShowResultsButtonProps {
	tableResults: number;
	searchFilters: Partial<SearchFilters>;
	isLoading?: boolean;
	onClick?: () => void;
}

export function ShowResultsButton({ tableResults, searchFilters, isLoading, onClick }: ShowResultsButtonProps) {
	const text = !tableResults && !isLoading ? 'No Results' : `Show ${tableResults} Result${tableResults === 1 ? '' : 's'}`;

	return (
		<CTALink
			variant='primary'
			text={text}
			loading={isLoading}
			disabled={isLoading}
			to={{
				pathname: '..',
				search: serializeSearchFiltersForURL(searchFilters).toString()
			}}
			onClick={onClick}
		/>
	);
}