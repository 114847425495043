import React, { CSSProperties, useState } from 'react';
import { TDR } from 'tdr-common';
import { useMediaQuery } from 'usehooks-ts';
import { getCdnImageUri } from '../../helpers/getCDNURI';

import styles from './AnimatedImageGallery.module.scss';

const DEFAULT_OVERLAY_OPACITY = 0.8;

type AnimatedImageGalleryProps = {
	restaurant: TDR.Restaurant;
	tables: TDR.Table[];
	staticImage?: boolean;
	staticPatioImage?: boolean;
};

export const AnimatedImageGallery = ({
	restaurant,
	tables,
	staticImage,
	staticPatioImage
}: AnimatedImageGalleryProps) => {
	const [imageError, setImageError] = useState(false);

	const landingImages = restaurant?.landingImages || [];

	// Our animation currently only works for 3 images, so if restaurant has less than 3, fallback to single background image
	const numImages = landingImages.length || 0;

	// If user's OS is set to 'reduce motion' also fallback to single static background image for accessibility
	const prefersReducedMotion = useMediaQuery(
		'(prefers-reduced-motion: reduce)'
	);

	const patioSection = tables.find((table) => table.isPatio);

	const getStaticImagePath = () => {
		if(staticPatioImage) {
			if (!!patioSection) {
				return patioSection.carouselImagePath;
			}
			else if (restaurant?.settings?.alternativeBookingOption?.landingImageUrl) {
				return restaurant.settings.alternativeBookingOption.landingImageUrl;
			}
			else {
				return restaurant.backgroundImagePath;
			}
		}
		else {
			return restaurant.backgroundImagePath;
		}
	};

	return (
		<>
			<div
				className={styles.ImageOverlay}
				style={{
					opacity: restaurant?.galleryOpacity || DEFAULT_OVERLAY_OPACITY
				}}
			/>
			{staticImage ||
      staticPatioImage ||
      prefersReducedMotion ||
      (numImages !== 3 && !imageError) ? (
					<img
						src={getCdnImageUri(getStaticImagePath())}
						srcSet={`${getCdnImageUri(getStaticImagePath(), { dpr: 2 })} 2x`}
						className={`${styles.Image} ${staticImage || staticPatioImage ? styles.ImageStatic : ''}`}
						onError={() => setImageError(true)}
						alt={`Sample of seating at ${restaurant.name}`}
					/>
				) : (
					!imageError &&
        landingImages.map((url, index) => (
        	<img
        		src={getCdnImageUri(url)}
        		srcSet={`${getCdnImageUri(url, { dpr: 2 })} 2x`}
        		key={url}
        		className={`${styles.Image} ${styles['Image--animated']}`}
        		onError={() => setImageError(true)}
        		style={{ '--index': index } as CSSProperties}
        		alt={`Sample of seating at ${restaurant.name}`}
        	/>
        ))
				)}
		</>
	);
};