import React from 'react';
import InfoCircle from 'iconsax-react/dist/esm/InfoCircle';
import styles from './InfoCallout.module.scss';

type InfoCalloutProps = {
	children: React.ReactNode;
	isVisible?: boolean;
}

export const InfoCallout = ({ children, isVisible = true }: InfoCalloutProps) => {
	return (
		<div data-state={isVisible ? 'visible': 'hidden'} role='status' className={styles.Container}>
			<div>
				<InfoCircle className={styles.Icon} />
			</div>

			<p role='status'>{children}</p>
		</div>
	);
};

type BoldTextProps = {
	children: React.ReactNode;
}
const BoldText = ({ children }: BoldTextProps) => {
	return (
		<span className={styles.BoldText}>
			{children}
		</span>
	);
};

InfoCallout.BoldText = BoldText;