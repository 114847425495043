import React from 'react';
import styles from './AddOptionButton.module.scss';

interface DietaryOptionProps {
	label: string;
	onClick: () => void;
	selected: boolean;
}

const DietaryOption: React.FC<DietaryOptionProps> = ({ label, onClick, selected }) => {
	return (
		<li>
			<button
				className={`${styles.Option} ${selected ? styles['Option--selected'] : ''}`}
				aria-pressed={selected}
				onClick={onClick}
			>
				<img src={`../../images/dietaryicons/${label}.svg`} alt={label} />
				<span>{label}</span>
			</button>
		</li>
	);
};

export default DietaryOption;
