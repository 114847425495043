import { useQuery } from '@tanstack/react-query';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { TDR } from 'tdr-common';

export function useRestaurant(restaurantId: string) {
	const query = useQuery({
		enabled: !!restaurantId,
		queryKey: ['getRestaurant', restaurantId],
		queryFn: () => getRestaurant(restaurantId)
	});

	return query;
}

export default async function getRestaurant(restaurantId: string): Promise<TDR.Restaurant | null> {
	const db = getFirestore();
	const docRef = doc(db, 'Restaurants', restaurantId);
	const snapshot = await getDoc(docRef);
	return (snapshot.exists || null) && snapshot.data() as TDR.Restaurant;
}