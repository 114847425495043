import { generateInvoice, legacyTimeslotsToPricingPolicies, TDR } from 'tdr-common';
import getReservationInvoice from '../api/getReservationInvoice';

export const getNewInvoiceForCancellation = async ({
	restaurant,
	table,
	reservation,
	basePolicies
}: {
	restaurant: TDR.Restaurant;
	table: TDR.Table;
	reservation: TDR.Reservation;
	basePolicies: TDR.PricingPolicy[];
}): Promise<Partial<TDR.Invoice>> => {
	const tableOverridePolicies = legacyTimeslotsToPricingPolicies(restaurant, [table]);
	const policies = [...tableOverridePolicies, ...basePolicies];
	const existingInvoice = await getReservationInvoice(reservation.id);

	const cancelledReservation: TDR.Reservation = {
		...reservation,
		items: [],
		status: TDR.Reservation.Status.Cancelled
	};

	const cancellationInvoice = generateInvoice(
		restaurant.currency,
		policies,
		cancelledReservation,
		existingInvoice,
		restaurant,
		reservation,
		table
	);

	return cancellationInvoice;
};
