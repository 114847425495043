import React, { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { SubHeader } from '../headers/SubHeader';
import { AddOptionButton } from './AddOptionButton';
import { AddOptionModal } from './AddOptionModal';
import { AddOptionSelections } from './AddOptionSelections';

import layoutStyles from '../../layouts/CheckoutLayout.module.scss';

type RequestsInputProps = {
	formRegister: UseFormRegisterReturn;
	currentNote: string;
	isEditEnabled?: boolean;
	isSaveEnabled: boolean;
	onSave?: () => void;
};

export const RequestsInput = ({ formRegister, isEditEnabled = true, isSaveEnabled, currentNote, onSave }: RequestsInputProps) => {
	const [isEditing, setIsEditing] = useState(false);

	return (
		<div className={layoutStyles.Section}>
			<SubHeader title='Special Request' isOptional />

			<div className={layoutStyles.SubSection}>
				{isEditEnabled && (
					<AddOptionButton
						label='Add a request'
						onClick={() => setIsEditing(true)}
						aria-label='Add special requests (optional)'
					/>
				)}

				<AddOptionSelections note={currentNote} />
			</div>

			<AddOptionModal
				title='Add a Request'
				screenReaderDescription='Provide any other special requests for your booking here'
				open={isEditing}
				setOpen={setIsEditing}
				onSave={onSave}
				isSaveEnabled={isSaveEnabled}
			>
				<div className='InputContainer'>
					<label id='srequests-label' htmlFor='requests-input' hidden>
            Special Requests
					</label>
					<textarea
						{...formRegister}
						id='requests-input'
						aria-labelledby='requests-label'
						placeholder='Write your note here...'
						rows={5}
						maxLength={300}
					/>
				</div>
			</AddOptionModal>
		</div>
	);
};
