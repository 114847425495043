import Clock from 'iconsax-react/dist/esm/Clock';
import Profile2User from 'iconsax-react/dist/esm/Profile2User';
import round from 'lodash/round';
import React from 'react';
import styles from './TableInfo.module.scss';

type TableInfoProps = {
	minGuests: number;
	maxGuests: number;
	resDuration?: number;
	iconSize?: number;
	iconColor?: string;
	shortForm?: boolean;
}

const TableInfo = ({ minGuests, maxGuests, resDuration, iconSize, iconColor, shortForm } :TableInfoProps) => {

	const guestCount = minGuests === maxGuests ? String(minGuests) : String(minGuests)+'-'+String(maxGuests);
	const resHours = round(resDuration / 60, 1);

	const iconWH = iconSize?.toString() ?? '20';
	const iconFillColor = iconColor ?? 'rgba(255, 255, 255, 0.6)';

	return (
		<p className={styles.Container}>
			<Profile2User
				size={iconWH}
				color={iconFillColor}
			/>

			<span role='note' aria-label='Table guest count'>{guestCount} {shortForm ? '' : 'Guests'}</span>

			{resDuration &&
				<>
					<span>&#x2022;</span>

					<Clock
						size={iconWH}
						color={iconFillColor}
					/>

					<span aria-label='Table seating duration'>{resHours} {!!shortForm ? 'HRS' : 'Hour Seating'}</span>
				</>
			}
		</p>
	);
};

export default TableInfo;