import { useIsMutating, useMutationState, useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { TDR } from 'tdr-common';
import config from '../../common/config';
import { MUTATION_KEY } from '../releaseCheckoutSession';
import { MUTATION_KEY as MUTATION_KEY_DELETE_PENDING_MODS } from '../deletePendingModifications';
import Availability = TDR.Availability;

export function hasConflict(results: Availability.searchOutput, table: TDR.Table): boolean {
	return !results?.resultMap?.[table.id]?.['conflict'];
}

async function search(input: Availability.searchInput): Promise<Availability.searchOutput> {
	const response = await fetch(`${config.apiHost}/search`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});


	const responsePayload = await response.json() as Availability.searchOutput;
	if (!response.ok) {
		throw response;
	}
	else if (!responsePayload?.success) {
		throw new Error('Unable To Find Availability');
	}

	return responsePayload;
}

export function useSearch(input: Availability.searchInput) {
	const isReleasing = useIsMutating({
		mutationKey: [MUTATION_KEY],
		exact: true
	});
	const deletePendingMutationState = useMutationState({
		filters: { mutationKey: [MUTATION_KEY_DELETE_PENDING_MODS] },
		select: (mutation) => mutation.state.status
	})[0]; // useMutationState always returns an array

	const [matchingResults, setMatchingResults] = useState<string[]>();

	const { params, restaurantId, space, options } = input;

	const query = useQuery({
		enabled: !isReleasing && deletePendingMutationState !== 'pending',
		queryKey: ['search', params, restaurantId, space, options],
		queryFn: () => search({ params, restaurantId, space, options })
	});

	useEffect(() => {
		if (!query.data) {
			return;
		}

		const newResults = Object.entries(query.data?.resultMap ?? []).filter(
			([, result]: [string, Availability.searchResult]) => result.match?.length > 0 || !result.conflict
		).map(([id] )=> id).sort();

		if (!isEqual(newResults, matchingResults)) {
			setMatchingResults(newResults);
		}
	}, [query.data]);

	return {
		results: query.data,
		isLoading: query.isLoading || isReleasing,
		...query
	};
}
