import React from 'react';
import styles from './TimeslotSkeleton.module.scss';
import { NUMBER_OF_COLUMNS } from './TimeslotList';
import { motion } from 'framer-motion';


const DEFAULT_ROW_COUNT = 3;

export interface TimeslotSkeletonProps {
	count?: number;
	halfHeight?: boolean;
}

function getSkeletonItem(index: number, halfHeight: boolean) {
	const suffix = halfHeight ? 'half' : 'full';
	return <li key={`skeleton-item-${index}`} className={`${styles.TimeSlotSkeletonItem} ${styles[`TimeSlotSkeletonItem--${suffix}`]}`}></li>;
}

export const TimeslotSkeleton = ({ halfHeight=true, count = (NUMBER_OF_COLUMNS * DEFAULT_ROW_COUNT) }: TimeslotSkeletonProps) => {
	return (
		<motion.ol
			className={`${styles.TimeSlotSkeleton}${!halfHeight ? ` ${styles['TimeSlotSkeleton--full']}`: ''}`}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			{(Array(count).fill(0).map((_, index) => getSkeletonItem(index, halfHeight)))}
		</motion.ol>
	);
};