import React from 'react';
import { Notice } from './Notice';
import AlertTriangle from '../../icons/yellow-triangle.svg';

type ModificationNoticeProps = {
	variant: 'seatingChange' | 'priceChange' | null;
};

export const ModificationNotice = ({ variant }: ModificationNoticeProps) => {
	const body: Record<typeof variant, React.ReactNode> = {
		seatingChange: (
			<p>
        Your selected seating cannot accommodate this modification. Proceed to view alternative seating options.
			</p>
		),
		priceChange: <p>Your modification involves a price change. Please proceed to review.</p>
	};

	const icon: Record<typeof variant, React.ReactNode> = {
		seatingChange: <img src={AlertTriangle} alt='View seating options' style={{ width: '24px', height: '24px' }} />,
		priceChange: <img src={AlertTriangle} alt='Review required' style={{ width: '24px', height: '24px' }} />
	};

	if (!variant) {
		return null;
	}

	return <Notice body={body[variant]} icon={icon[variant]} />;
};
