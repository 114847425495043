import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { useCheckoutSession } from '../api/getCheckoutSession';
import { ErrorResponse } from '../common/ErrorResponse';
import { CheckoutURLParams } from '../common/types';
import Spinner from '../components/Spinner';
import { CheckoutProvider } from '../context/new/CheckoutContext';
import { analytics, checkoutMiddlewareBuilder } from '../helpers/analytics';
import TablzLogo from '../images/poweredBy/tablz.svg';
import { ResultPageLayout } from '../layouts/ResultPageLayout';
import { NotFoundErrorPage } from '../pages/errors/NotFoundErrorPage';
import { UnexpectedErrorPage } from '../pages/errors/UnexpectedErrorPage';
import { ValidationErrorPage } from '../pages/errors/ValidationErrorPage';
import { RestaurantGuardOutletContext } from './RestaurantGuard';
import { CheckoutLayout } from '../layouts/CheckoutLayout';
import { baseCheckoutNavConfig, fullCheckoutNavConfig } from '../components/checkout/steps/config';

export interface CheckoutGuardOutletContext extends RestaurantGuardOutletContext {
	checkoutSession: TDR.CheckoutSession.CheckoutSession;
	invoice: TDR.Invoice;
	timeslot?: TDR.TimeSlot;
	guestDetails?: Pick<TDR.Guest.Guest, 'firstName' | 'lastName' | 'email' | 'phone' | 'hasFastCheckoutEnabled'>;
}

export const CheckoutGuard = () => {
	const parentContext = useOutletContext<RestaurantGuardOutletContext>();
	const { checkoutId } = useParams<CheckoutURLParams>();
	const { data, isLoading, isError, error } = useCheckoutSession(checkoutId);

	const navigate = useNavigate();

	useEffect(() => {
		if (data && data.checkoutSession.id) {
			const trackingMiddleware = checkoutMiddlewareBuilder(data.checkoutSession.id);
			analytics.addSourceMiddleware(trackingMiddleware);

			return () => analytics.removeSourceMiddleware(trackingMiddleware);
		}
	}, [data]);

	// Navigate to confirmation step if checkout session is already completed
	useEffect(() => {
		if (data && data.checkoutSession?.status === 'completed') {
			navigate(`/${parentContext.restaurant.slug}/checkout/${data.checkoutSession.id}/confirmation`);
		}
	}, [data]);

	if (isLoading) {
		return <CheckoutLoadingStep />;
	}

	if (!isLoading) {
		if (error && error instanceof Response) {
			if (error.status === 404) {
				return <NotFoundErrorPage />;
			}
		}
		else if (error && error instanceof ErrorResponse) {
			if (error.response.status === 404) {
				return <NotFoundErrorPage />;
			}
			else if (error.response.status === 400 && error.body) {
				return <ValidationErrorPage to='..' error={error} />;
			}
		}
	}

	if (!isLoading && isError) {
		const eventID = Sentry.captureException(error);
		return <UnexpectedErrorPage to='..' sentryErrorID={eventID} />;
	}
	let navConfig = baseCheckoutNavConfig;

	if (parentContext?.featureFlags?.privateEvents) {
		navConfig = baseCheckoutNavConfig;
	}
	else if (parentContext?.featureFlags?.enableAddOns) {
		navConfig = fullCheckoutNavConfig;
	}

	return (
		<CheckoutProvider session={data?.checkoutSession} navConfig={navConfig}>
			{data ? (
				<CheckoutLayout session={data?.checkoutSession}>
					<Outlet
						context={
              {
              	...parentContext,
              	checkoutSession: data?.checkoutSession,
              	invoice: data?.checkoutInvoice,
              	timeslot: data?.timeslot,
              	guestDetails: data?.guestDetails
              } satisfies CheckoutGuardOutletContext
						}
					/>
				</CheckoutLayout>
			) : (
				<CheckoutLoadingStep />
			)}
		</CheckoutProvider>
	);
};

export const CheckoutLoadingStep = () => (
	<ResultPageLayout>
		<img src={TablzLogo} alt='Tablz logo' />
		<ResultPageLayout.Title>Loading your booking details...</ResultPageLayout.Title>
		<ResultPageLayout.Body>
			<Spinner size='xs' />
		</ResultPageLayout.Body>
	</ResultPageLayout>
);
