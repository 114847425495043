import * as Select from '@radix-ui/react-select';
import React, { ReactNode, useState } from 'react';
import ChevronDown from '../../icons/down-chevron.svg';
import ChevronUp from '../../icons/up-chevron.svg';

import styles from './Dropwdown.module.scss';

type DropdownProps = {
	value: string;
	label: string;
	placeholder?: ReactNode;
	onValueChange: (value: string) => void;
	options: { label: string, value: string, disabled: boolean }[];
}

export const Dropdown = ({ value, label, placeholder, options, onValueChange }: DropdownProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpenChange = (open: boolean) => {
		setIsOpen(open);
	};

	//Radix makes it really hard to clear so you have to explicitly set it to '' if you want to get back to the placeholder https://github.com/radix-ui/primitives/issues/1569
	return (
		<Select.Root value={value || ''} onValueChange={onValueChange} onOpenChange={handleOpenChange}>
			<Select.Trigger className={styles.Trigger} aria-label={label} id='radix-select-trigger'>
				<Select.Value placeholder={placeholder} />
				<Select.Icon className={styles.Icon}>
					<img src={isOpen ? ChevronUp : ChevronDown} />
				</Select.Icon>
			</Select.Trigger>

			<Select.Portal>
				<Select.Content className={styles.Content} position='popper'>
					<Select.Viewport className={styles.Viewport}>
						{options.map(({ value, label: text, disabled }) => (
							<Select.Item key={value} value={value} disabled={disabled} className={styles.Item} >
								<Select.ItemText><span>{text}</span></Select.ItemText>
							</Select.Item>
						))}
					</Select.Viewport>
				</Select.Content>
			</Select.Portal>
		</Select.Root>
	);
};