import React, { useState } from 'react';
import ToggleFullscreen from './ToggleFullscreen';
import ToggleMute from './ToggleMute';
import TogglePlay from './TogglePlay';
import VolumeSlider from './VolumeSlider';

import styles from './VideoPlayerControls.module.scss';
import { useScreenSize } from '../../../hooks/useScreenSize';

type VideoPlayerControlsProps = {
	videoRef: React.MutableRefObject<HTMLVideoElement| null>,
	size: {
		isFullscreen: boolean;
		toggleFullscreen: () => void;
	}
}

export const VideoPlayerControls = ({
	videoRef,
	size
}: VideoPlayerControlsProps) => {
	const { isSmallScreen } = useScreenSize();
	const [isPlaybackButtonVisible, setIsPlaybackButtonVisible] = useState(false);
	const [isSliderVisible, setIsSliderVisible] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);

	const showVolumeSlider = () => {
		setIsSliderVisible(true);
	};

	const hideVolumeSlider = () => {
		setIsSliderVisible(false);
	};

	const showPlaybackButton = () => {
		setIsPlaybackButtonVisible(true);
	};

	const hidePlaybackButton = () => {
		setIsPlaybackButtonVisible(false);
	};

	return (
		<>
			<div
				className={styles.Playback}
				onMouseEnter={isPlaying ? showPlaybackButton : null}
				onMouseLeave={isPlaying ? hidePlaybackButton : null}
			>
				<TogglePlay onPlaybackToggle={setIsPlaying} videoRef={videoRef}	isVisible={!isPlaying || isPlaybackButtonVisible} />
			</div>

			<div
				className={styles.AudioMute}
				onMouseLeave={hideVolumeSlider}
			>
				<ToggleMute
					videoRef={videoRef}
					onMouseEnter={showVolumeSlider}
					onFocus={showVolumeSlider}
					onBlur={hideVolumeSlider}
				/>

				{!isSmallScreen ? (
					<VolumeSlider
						videoRef={videoRef}
						isVisible={isSliderVisible}
						onFocus={showVolumeSlider}
						onBlur={hideVolumeSlider}
						onMouseLeave={hideVolumeSlider}
					/>): null}
			</div>
			<div
				className={styles.FullScreen}>
				<ToggleFullscreen
					isFullscreen={size.isFullscreen}
					onClick={size.toggleFullscreen}
				/>
			</div>
		</>
	);
};