import React from 'react';

import { useOutletContext } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';
import styles from './ProceedWithLogo.module.scss';

type ProceedWithLogoProps = {
	logo: 'tablz' | 'tablz--gold' | 'alternate';
};

export const ProceedWithLogo = ({ logo }: ProceedWithLogoProps) => {
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	const altPlatform = restaurant.settings?.alternativePlatform?.vendor;

	const logoSrc =
    logo === 'tablz' || logo === 'tablz--gold'
    	? '../../images/poweredBy/tablz.svg'
    	: `../../images/poweredBy/${altPlatform}.svg`;

	return (
		<div className={styles.Container}>
			<span className={styles.Label}>Powered By</span>
			<img
				src={logoSrc}
				alt={altPlatform}
				className={`${styles.Logo} ${logo === 'tablz' ? styles.LogoTablz : ''} ${
					logo === 'tablz--gold' ? styles.LogoTablzGold : ''
				} ${logo === 'alternate' && altPlatform === TDR.AlternativePlatform.Tripleseat ? styles.LogoTripleseat : ''}`}
			/>
		</div>
	);
};
