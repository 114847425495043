import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { TDR } from 'tdr-common';
import submitRequest from '../../api/largeGroupRequest';
import { parseSearchParamsFilters, SearchFilters } from '../../routes/AvailabilityGuard';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';
import { SquareIconButton } from '../buttons/SquareIconButton';
import { Header } from '../checkout/Header';
import { ContactWrapper } from './components/ContactWrapper';
import { Details } from './components/Details';
import { Footer } from './components/Footer';
import { Occasions } from './components/Occasions';
import { OtherRequests } from './components/OtherRequests';

import styles from '../../layouts/InboundRequestLayout.module.scss';

export const LargeGroupRequestOverview = () => {
	const navigate = useNavigate();
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();
	const [hasScrolledPastContactForm, setHasScrolledPastContactForm] = useState(false);

	const handleClose = () => {
		navigate(-1);
	};

	return (
		<>
			<Header
				title='Group Dining Request'
				subtitle={restaurant.name}
				iconLeft={<SquareIconButton variant='back' />}
				iconLeftOnClick={handleClose}
			/>

			<BookingContent
				{...{
					restaurant,
					hasScrolledPastContactForm,
					setHasScrolledPastContactForm,
					IsPoliciesDisabled: true
				}}
			/>
		</>
	);
};

type BookingContentProps = {
	restaurant: TDR.Restaurant;
	setHasScrolledPastContactForm: React.Dispatch<React.SetStateAction<boolean>>;
	hasScrolledPastContactForm: boolean;
};

const BookingContent = ({
	restaurant,
	setHasScrolledPastContactForm,
	hasScrolledPastContactForm
}: BookingContentProps) => {
	const { featureFlags } = useOutletContext<RestaurantGuardOutletContext>();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [isContactFormValid, setIsContactFormValid] = useState(false);
	const [contactDetails, setContactDetails] = useState<{
		email: string;
		phone: string;
		firstName: string;
		lastName: string;
	}>({
		phone: '',
		email: '',
		firstName: '',
		lastName: ''
	});
	const [occasion, setOccasion] = useState<TDR.Occasion>(undefined);
	const [requests, setRequests] = useState('');
	const [searchFilters, setSearchFilters] = useState<SearchFilters>({
		groupSize: undefined,
		date: undefined,
		time: undefined
	});
	const [filtersValid, setFiltersValid] = useState(false);
	const [confirming, setConfirming] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const handleSubmit = () => {
		setConfirming(true);
		submitRequest({
			...contactDetails,
			guests: searchFilters.groupSize,
			day: searchFilters.date,
			time: searchFilters.time,
			occasion,
			requests,
			restaurantId: restaurant.id
		})
			.then((r) => {
				if (r.success) {
					navigate(`/${restaurant.slug}/explore/request/result`, {
						state: {
							day: searchFilters.date,
							groupSize: searchFilters.groupSize,
							time: searchFilters.time,
							restaurantName: restaurant.name
						},
						replace: true
					});
				}
				else {
					setErrorMessage(r.message);
				}
			})
			.catch((e) => {
				setErrorMessage(e);
			})
			.finally(() => {
				setConfirming(false);
			});
	};

	useEffect(() => {
		if (searchParams) {
			const parsed = parseSearchParamsFilters(searchParams, restaurant?.timezone);
			if (Object.values(parsed).some((value) => !value)) {
				setFiltersValid(false);
			}
			else {
				setSearchFilters(parsed);
				setFiltersValid(true);
			}
		}
	}, [searchParams]);

	const readyToProceed =
    isContactFormValid &&
    contactDetails.email &&
    contactDetails.phone &&
    contactDetails.firstName &&
    contactDetails.lastName &&
    filtersValid;
	return (
		<>
			<div className={styles.Body}>
				<Details guests={searchFilters.groupSize} date={searchFilters.date} time={searchFilters.time} />
				<ContactWrapper
					{...{ setIsContactFormValid, setHasScrolledPastContactForm, hasScrolledPastContactForm, setContactDetails }}
				/>
				<Occasions occasion={occasion} setOccasion={setOccasion} />
				<OtherRequests
					requests={requests}
					setRequests={setRequests}
					placeholder={featureFlags?.largeGroupInbounds?.options?.formDescription}
				/>
			</div>

			<div className={styles.Footer}>
				<Footer
					isCtaDisabled={!readyToProceed}
					confirming={confirming}
					errorMessage={errorMessage}
					handleCtaClick={handleSubmit}
				/>
			</div>
		</>
	);
};
