import React from 'react';
import { TDR } from 'tdr-common';
import { InfoDialog } from './InfoDialog';
import { UnderlinedText } from '../../typography/UnderlinedText';
import styles from './TermsAndConditions.module.scss';

export const TermsAndConditions = () => {
	const body = (
		<div className={styles.TermsPage}>
			<strong>Background</strong>
			<p>This End User License Agreement (this “Agreement” or “Standard EULA”) sets forth the legally binding terms between Tablz Inc. (“Licensor” “Tablz” “us” or “we”) and you, as an end user of its web application or mobile payment application for Mac OS, iOS, Windows, or Android (collectively, the “App” or “Licensed Application”). </p>
			<p>	By clicking the “I AGREE” button, you agree, effective as of such date (the “Effective Date”) to be bound by this Agreement. You also agree to our Privacy Policy, which is incorporated by this reference, and consent to the practices concerning data collection, transmission and use set forth therein.</p>
			<p>YOU ARE ONLY AUTHORIZED TO USE THE APP IF YOU AGREE TO ABIDE BY ALL APPLICABLE LAWS AND THIS AGREEMENT. IF YOU DO NOT AGREE WITH IT, YOU SHOULD DISCONTINUE USE IMMEDIATELY.</p>

			<strong>License Grant</strong>
			<p>Subject to the terms of this Agreement, Tablz hereby grants you a limited, non-exclusive and non-transferable license to access and use the App on a smart phone, tablet or other mobile device that you own or control for your personal, non-commercial use solely within Canada or the United States.</p>

			<strong>License Restrictions</strong>
			<p>You shall not: (i) copy the App or any element thereof, except as expressly permitted by this license; (ii) modify, translate, adapt, or otherwise create derivative works or improvements of the App or any element thereof; (iii) reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the App or any element thereof; (iv) remove, delete, alter or obscure any trademarks or any copyright, trademark, patent or other intellectual property or proprietary rights notice from the App; (v) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available the App or any features or functionality of the App, to any third party for any reason, including making the App available on a network where it is capable of being accessed by more than one device at any time; or (vi) use the App to violate any applicable law, including without limitation any law regarding the purchase or sale of alcoholic beverages. If for some reason these restrictions are prohibited by applicable law or by an agreement we have with one of our licensors, then the activities are permitted only to the extent necessary to comply with such law or license(s).</p>

			<strong>Consent to Use of Data</strong>
			<p>You agree that Tablz may collect and use technical data and related information—including but not limited to technical information about your device, system and application software, and peripherals—that is gathered periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to the Licensed Application. Tablz may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</p>

			<strong>Termination</strong>
			<p>This Standard EULA is effective until terminated by you or Tablz. Your rights under this Standard EULA will terminate automatically if you fail to comply with any of its terms.</p>

			<strong>Reservation of Rights</strong>
			<p>You acknowledge that the App is licensed, not sold, to you. Tablz reserves all rights in and to the App not expressly granted to you under this Agreement. You do not acquire any ownership interest in the App under this Agreement. Tablz and its licensors and service providers reserve and retain the entire right title and interest in and to the App, including all copyrights, trademarks, and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement. If you submit comments, suggestions, or other feedback regarding the App (“Feedback”), Tablz will be free to use such Feedback for any purpose. The word marks “Tablz”, “Tablz”, the “Tablz” logos and all associated marks and logos displayed within the App are our trademarks (unless otherwise noted).</p>

			<strong>Third Party Establishments</strong>
			<p>You acknowledge and agree that the App is a tool that enables you to make purchases and to settle, pay for or close (“Close Out”) a check, bill, tab, owed payment or other transaction, whether individually or as a “split” transaction with other users (each a “Transaction”), with third-party establishments, including but not limited to restaurants or bars (each, an “Establishment”). You acknowledge that all Transactions are between you and the Establishments and that Tablz is not a party to such Transactions. Tablz is not responsible for examining or evaluating the quality, or any other aspect of such products or services of any Establishment and will not have any liability or responsibility to you or any other person therefor. Any Establishment that allows you to use the App to Close Out a Transaction retains the right to settle your payment should you fail to properly Close Out your Transaction either as a result of your failure to use the App to pay for the payment or if the App does not properly pay such payment. Any Establishment that allows to use the App to close out any Transaction retains the right to request that you authorize such payment by other means including, but not limited to, signing a printed receipt or delivering a final check, bill or tab to you. You acknowledge that, should you dispute any charge that appears on your credit or debit card statement related to your use of the App to pay for a Transaction in an Establishment, any chargeback for such charge shall apply to such Establishment and that we will not have any liability to you.</p>

			<strong>Payment Vendors</strong>
			<p>Tablz is a technology provider. It is not a bank, credit union, payment processor or other financial institution. Transactions are processed via a payment processor and/or point of sale (“POS”) system vendor (each a “Payment Vendor”). By processing a Transaction via the App, you authorize the Payment Vendor to charge your credit card or other payment method. Transactions processed via the App may also be subject to the terms and conditions of the applicable Payment Vendor.</p>

			<strong>Payment</strong>
			<p>Should you fail to Close Out a Transaction in an Establishment, Tablz reserves the right to Close Out the transaction on your behalf. Should you wish to dispute a charge, you may Contact Us.</p>

			<strong>Refunds</strong>
			<p>You acknowledge and agree that Tablz does not provide refunds via the App. If you choose to seek any refunds of Transactions, you may Contact Us.</p>

			<strong>External Services</strong>
			<p>The Licensed Application may enable access to Licensor’s and/or third-party services and websites (collectively and individually, “External Services”). You agree to use the External Services at your sole risk. Licensor is not responsible for examining or evaluating the content or accuracy of any third-party External Services, and shall not be liable for any such third-party External Services. Data displayed by any Licensed Application or External Service, including but not limited to financial, medical and location information, is for general informational purposes only and is not guaranteed by Licensor or its agents. You will not use the External Services in any manner that is inconsistent with the terms of this Standard EULA or that infringes the intellectual property rights of Licensor or any third party. You agree not to use the External Services to harass, abuse, stalk, threaten or defame any person or entity, and that Licensor is not responsible for any such use. External Services may not be available in all languages or in your Home Country, and may not be appropriate or available for use in any particular location. To the extent you choose to use such External Services, you are solely responsible for compliance with any applicable laws. Licensor reserves the right to change, suspend, remove, disable or impose access restrictions or limits on any External Services at any time without notice or liability to you.</p>

			<strong>NO WARRANTY</strong>
			<p>YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED “AS IS” AND “AS AVAILABLE,” WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD-PARTY RIGHTS. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE LICENSED APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.</p>

			<strong>Limitation of Liability</strong>
			<p>TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. In no event shall Licensor’s total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars ($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose</p>

			<strong>Maintenance</strong>
			<p>Scheduled system maintenance shall take place from time to time, and during such time, updates to the App or elements thereof may be unavailable. Emergency maintenance may be required at other times in the event of system failure. You specifically acknowledge that outage and downtime may occur. If the App is down when you attempt to complete a Transaction, you must make alternative arrangements to pay the applicable Establishment.</p>

			<strong>Updates</strong>
			<p>Tablz may, but is not required to, develop and provide App updates from time to time, which may include upgrades, bug fixes, patches and other corrections or new features (collectively, “Updates”). Updates may also modify or delete in their entirety certain features or functionality. You agree that Tablz has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. Based on the settings of your mobile device, when your device is connected to the Internet, the App may automatically download and install available Updates, or you will be prompted to download an install available Updates. You acknowledge that the App or any of its features may not operate properly if you fail to install Updates and Tablz may, in its sole discretion, cease supporting prior versions of the App after an Update is made available. You further agree that all Updates will be deemed part of the App and subject to the terms of this Agreement.</p>

			<strong>Communications</strong>
			<p>You consent to receive communications, including commercial communications (whether by phone, email or text) from Tablz and our third-party partners, which may include Establishments and other third parties, including without limitation brands. You acknowledge and agree that your primary phone numbers and email addresses and other information may be used for the purpose of initiating commercial messages.  Your consent to receipt of such messages is not, and will not be, a condition to any purchase. If at any time you change your mind regarding your consent to such messages, you must Contact Us you may opt out of receiving messages that are primarily commercial in nature, but in order to stop receiving all messages from Tablz (including messages related to Transactions and your account), you will need to terminate your account. If the App enables you to send or receive SMS messages, standard text messaging rates or other carrier charges may apply to such use.</p>

			<strong>Push Notifications</strong>
			<p>Tablz may send you “push notifications” if your device supports such communications. By downloading the App and clicking to allow push notifications, you “opt-in” to receive these push notifications via the App, including notifications that contain commercial messages from App8 and our third-party partners. Should you wish to stop receiving push notifications via the App, you may turn off these notifications through the applicable settings on your device.</p>

			<strong>Disputes Between You and an Establishment or other User</strong>
			<p>You are solely responsible for your interactions and Transactions with Establishments or other users of the App. You hereby agree to look solely to such Establishments and other users for any claim, damage or liability associated with any Transaction commissioned via the App and expressly waive and release Tablz from any and all claims, damages and liabilities arising out of any act or omission of any Establishment, other user or third party.</p>

			<strong>Export and Other Restrictions</strong>
			<p>The Licensed Application delivered to You under this Agreement is subject to Canadian and U.S. export control laws and regulations and may also be subject to import and export laws of the jurisdiction in which it was accessed, used, or obtained, if outside the United States and Canada. You may not use or otherwise export or re-export the Licensed Application except as authorized by the laws of the jurisdiction in which the Licensed Application was obtained. In particular, but without limitation, the Licensed Application may not be exported or re-exported (a) into any U.S.-embargoed countries or (b) to anyone on the U.S. Treasury Department’s Specially Designated Nationals List or the U.S. Department of Commerce Denied Persons List or Entity List. By using the Licensed Application, you represent and warrant that you are not located in any such country or on any such list. You also agree that you will not use these products for any purposes prohibited by United States law, including, without limitation, the development, design, manufacture, or production of nuclear, missile, or chemical or biological weapons.</p>

			<p>The Licensed Application and related documentation are “Commercial Items”, as that term is defined at 48 C.F.R. §2.101, consisting of “Commercial Computer Software” and “Commercial Computer Software Documentation”, as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users (a) only as Commercial Items and (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein</p>

			<strong>Governing Law and Venue</strong>
			<p>This Agreement will be governed and construed in all respects by the laws of the Province of Ontario without regard to its conflict of laws and provisions, and the federal laws of Canada applicable therein. You and Tablz agree to submit to the exclusive jurisdiction of the Ontario Superior Court of Justice located within the City of Ottawa, Ontario Canada, to resolve any legal matter arising from this Agreement.</p>

			<strong>General</strong>
			<p>This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof. Any headings are provided for convenience only. You may not assign this Agreement or any of your rights or obligations hereunder, but Tablz may assign this Agreement and any of its rights and obligations hereunder and this Agreement shall insure to the benefit of and is binding on Tablz’s respective successors and permitted assigns. The failure of Tablz to exercise or enforce any right under this Agreement shall not constitute a waiver of such right. All rights and remedies granted to Tablz are cumulative and not alternate. If any provision of this Agreement is found invalid or unenforceable, such invalidity or unenforceability shall not affect the remaining terms and conditions of this Agreement, and the parties shall substitute a valid provision that most nearly approximates the intent and economic effect of the invalid or unenforceable one.</p>

			<strong>Contact Us</strong>
			<p>Please contact us at {TDR.SUPPORT_EMAIL} if you have any questions regarding this Agreement.</p>
		</div>
	);

	return (
		<InfoDialog
			title='Terms and Conditions'
			body={body}
			triggerButton={<UnderlinedText>Terms & Conditions</UnderlinedText>}
		/>
	);
};


