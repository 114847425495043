import React, { useEffect } from 'react';
import { MONTHSTR_FMT } from 'tdr-common';
import { DateTime } from 'luxon';
import { Notice } from './Notice';
import { analytics } from '../../helpers/analytics';
import ExclamationIcon from '../../icons/exclamation.svg';

type LowAvailabilityNoticeProps = {
	selectedMonth: string,
}

export const LowAvailabilityNotice: React.FC<LowAvailabilityNoticeProps> = ({ selectedMonth }) => {

	useEffect(() => {
		analytics.track('Notice Viewed', {
			notice: 'LowAvailabilityNotice',
			selectedMonth
		});
	}, []);

	const noticeBody = <p><span>{DateTime.fromFormat(selectedMonth, MONTHSTR_FMT).toLocaleString({ month: 'long' })}</span> Is filling up fast! Explore more availability in upcoming months.</p>;
	return (
	    <Notice body={noticeBody} icon={<img src={ExclamationIcon} alt={'warning'}/>}/>
	);
};
