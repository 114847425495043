import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TDR } from 'tdr-common';
import CTALink from '../../components/buttons/CTALink';
import ErrorIcon from '../../icons/error-grey.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { analytics } from '../../helpers/analytics';

type ProceedFailedProps = {
	sessionId: string;
	restaurantSlug: string;
};

export const ProceedFailed = ({ sessionId, restaurantSlug }: ProceedFailedProps) => {
	const location = useLocation();

	const goBackHref = `/${restaurantSlug}/checkout/${sessionId}/policies`;
	const needHelpHref = `mailto:${TDR.SUPPORT_EMAIL}?subject=Need help with checkout sessionID: ${sessionId}`;

	useEffect(() => {
		analytics.track('Proceed to payment failed', {
			...location
		});
	}, []);

	return (
		<ResultPageLayout headerIcon={ErrorIcon}>
			<ResultPageLayout.Title>Oops</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>
          We&apos;re having trouble proceeding with your booking. Please try again, or contact Tablz customer support for assistance.
				</p>
			</ResultPageLayout.Body>

			<ResultPageLayout.Footer>
				<CTALink text='Go back' to={goBackHref} reloadDocument={true} />
				<CTALink text='Get help' to={needHelpHref} variant='dark' />
			</ResultPageLayout.Footer>
		</ResultPageLayout>
	);
};
