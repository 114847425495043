import React from 'react';
import ClockIcon from '../../icons/clock.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { ReturnToBookingPortal } from './buttons';
import { RequestSubmittedProps } from './types';

export const RequestSubmitted = ({ guests, date, time, restaurantName, linkTo }: RequestSubmittedProps) => {
	return (
		<ResultPageLayout headerIcon={ClockIcon} iconAltText='Request Submitted'>
			<ResultPageLayout.Title>Request Submitted</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>
          Your booking request for <b>{guests}</b> guest{guests > 1 ? 's' : ''} on <b>{date}</b> at <b>{time}</b> has
          been submitted.
				</p>
				<p>
          The team at <b>{restaurantName}</b> will be reaching back out via email to confirm whether they can
          accommodate you.
				</p>
			</ResultPageLayout.Body>

			<ResultPageLayout.Footer>
				<ReturnToBookingPortal to={linkTo} />
			</ResultPageLayout.Footer>
		</ResultPageLayout>
	);
};