import React from 'react';
import ErrorIcon from '../../icons/x-hex.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { CustomerSupportLink } from '../buttons';
import { ReturnToBookingPortal } from './buttons';
import { ExpiredBookingProps } from './types';

export const ExpiredBooking = ({ linkTo, restaurantName }: ExpiredBookingProps) => {
	return (
		<ResultPageLayout headerIcon={ErrorIcon} iconAltText='Modification Unavailable'>
			<ResultPageLayout.Title>Modification Unavailable</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>
          Unfortunately, your booking at <b>{restaurantName}</b> has already passed and is no longer modifiable.
				</p>
				<p>
          Please contact us at <CustomerSupportLink /> if you have any questions.
				</p>
			</ResultPageLayout.Body>

			<ResultPageLayout.Footer>
				<ReturnToBookingPortal to={linkTo} />
			</ResultPageLayout.Footer>
		</ResultPageLayout>
	);
};
