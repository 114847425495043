import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { TDR } from 'tdr-common';
import { analytics } from '../../helpers/analytics';
import { SubHeader } from '../headers/SubHeader';
import { AddOptionButton } from './AddOptionButton';
import { AddOptionModal } from './AddOptionModal';
import { AddOptionSelections } from './AddOptionSelections';
import OccasionOption from './OccasionOption';

import layoutStyles from '../../layouts/CheckoutLayout.module.scss';

const OPTIONS = Object.values(TDR.Occasion);

type OccasionInputProps = {
	formControl: Control;
	currentSelected: string;
	isEditEnabled?: boolean;
	isSaveEnabled: boolean;
	onSave?: () => void;
};

export const OccasionInput = ({
	formControl,
	currentSelected,
	isEditEnabled = true,
	isSaveEnabled,
	onSave
}: OccasionInputProps) => {

	const [isEditing, setIsEditing] = useState(false);

	if (!isEditEnabled && !currentSelected) {
		return null;
	}

	return (
		<section className={layoutStyles.Section}>
			<SubHeader title='Occasion' isOptional />

			<div className={layoutStyles.SubSection}>
				{isEditEnabled && (
					<AddOptionButton
						label='Add selection'
						onClick={() => setIsEditing(true)}
						aria-label='Select the occasion for your booking (optional)'
					/>
				)}

				<AddOptionSelections items={[currentSelected]} />
			</div>

			<AddOptionModal
				title='Add an Occasion'
				screenReaderDescription='Select your dining occasion'
				open={isEditing}
				setOpen={setIsEditing}
				onSave={onSave}
				isSaveEnabled={isSaveEnabled}
			>
				<div className='OverviewSelectionContent'>
					<ul className='OverviewSelections'>
						{OPTIONS.map((option) => (
							<Controller
								key={option}
								control={formControl}
								name={'occasion'}
								render={({ field: { value, onChange } }) => (
									<OccasionOption
										label={option}
										onClick={() => {
											onChange(value === option ? undefined : option);
											analytics.track('Occasion Selection Clicked', {
												option
											});
										}}
										selected={value === option}
									/>
								)}
							/>
						))}
					</ul>
				</div>
			</AddOptionModal>
		</section>
	);
};
