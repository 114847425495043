import React from 'react';
import TablzLogo from '../../images/poweredBy/tablz.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import Spinner from '../Spinner';

export const Loading = () => {
	return (
		<ResultPageLayout>
			<img src={TablzLogo} />

			<ResultPageLayout.Title>Confirming your booking...</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<Spinner size='xs' />
			</ResultPageLayout.Body>
		</ResultPageLayout>
	);
};
