import React, { InputHTMLAttributes } from 'react';
import { InputValidationError } from './InputValidationError';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styles from './RHFTextInput.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

type RHFTextInputProps<TFieldValues extends FieldValues = FieldValues> = InputHTMLAttributes<HTMLInputElement> & {
	label: string;
	name: string;
	error?: string;
	register: UseFormRegister<TFieldValues>;
	icon?: React.ReactNode;
}

export const RHFTextInput = ({ label, name, error, register, icon, ...props }: RHFTextInputProps ) => {
	return (
		<div className={styles.Container}>
			<div className={styles.Input}>
				<label id={`${name}-label`} hidden>{label}</label>
				<input
					{...register(name, { required: true })}
					aria-labelledby={`${name}-label`}
					type='text'
					placeholder={label}
					className={error ? styles.Invalid : ''}
					{...props}
				/>
				{icon && (
					<div className={styles.Icon}>{icon}</div>
				)}
			</div>

			<AnimatePresence>
				{error && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<InputValidationError message={error} />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
