import React, { createContext, useContext, useEffect, useState } from 'react';
import Stripe from 'stripe';
import { TDR, toLuxon } from 'tdr-common';
import { CheckoutProviderProps, NavConfig } from '../../common/types';
import { GuestDetailsDTO } from '../../components/checkout/types';
import { AddOnItemDTO } from '../../components/checkout/steps/AddOns';

export enum CheckoutCTALabel {
	SKIP = 'Skip and Decide on the Day!',
	ADDON_PROCEED = 'Proceed with Selection',
	PAYMENT_PROCEED = 'Proceed to Payment',
	PROCEED = 'Proceed',
	ACCEPT = 'Accept & Continue',
	CONFIRM = 'Confirm Booking',
	SUBMIT = 'Submit Request',
	MODIFY = 'Confirm Change'
}

type CheckoutContextType = {
	navConfig: NavConfig;
	checkoutState: CheckoutState;
	updateCheckoutState: (value: Partial<CheckoutState>) => void;
	isSaveContactChecked: boolean;
	setIsSaveContactChecked: React.Dispatch<React.SetStateAction<boolean>>;
	ctaText: string;
	setCtaText: React.Dispatch<React.SetStateAction<string>>;
	isCtaEnabled: boolean;
	setIsCtaEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	clearSession: () => void;
};

export type CheckoutState = {
	guest: TDR.CheckoutSession.CreateCheckoutSessionOutput['guestDetails'];
	promoCode: string;
	isLargeGroup: boolean;
	stripeClientSecret: string;
	stripePaymentMethod: Stripe.PaymentMethod;
	firebaseToken: string;
	timer: {
		secondsLeft: number;
	};
	guestDetailsDTO?: GuestDetailsDTO;
	addOnItemsDTO?: AddOnItemDTO[];
};

const initialState: CheckoutState = {
	guest: undefined,
	isLargeGroup: false,
	promoCode: '',
	stripeClientSecret: '',
	stripePaymentMethod: undefined,
	firebaseToken: '',
	timer: {
		secondsLeft: undefined
	},
	guestDetailsDTO: undefined,
	addOnItemsDTO: []
};

const CheckoutContext = createContext<CheckoutContextType | undefined>(undefined);

export const useCheckoutContext = () => {
	const context = useContext(CheckoutContext);
	if (!context) {
		throw new Error('useCheckoutContext must be used within a CheckoutProvider');
	}
	return context;
};

export const CheckoutProvider = ({ children, session, navConfig }: CheckoutProviderProps) => {
	const [checkoutState, setCheckoutState] = useState<CheckoutState>(initialState);
	const [isSaveContactChecked, setIsSaveContactChecked] = useState(false);
	const [ctaText, setCtaText] = useState<CheckoutCTALabel>();
	const [isCtaEnabled, setIsCtaEnabled] = useState(false);

	const updateCheckoutState = (updatedState: Partial<CheckoutState>) => {
		setCheckoutState((prevState) => ({
			...prevState,
			...updatedState
		}));
	};

	const clearSession = () => {
		setCheckoutState(initialState);
	};

	// Update checkout timer
	useEffect(() => {
		if (!session || !session.expiration) {
			return;
		}

		const expiresAt = toLuxon(session.expiration, session.timezone).toMillis();

		const interval = setInterval(() => {
			const now = Date.now();
			const diff = expiresAt - now - 5000; // 5 second buffer to prevent race conditions
			const secondsLeft = Math.max(0, Math.floor(diff / 1000)); // Convert milliseconds to seconds

			setCheckoutState((prevState) => ({
				...prevState,
				timer: {
					secondsLeft
				}
			}));
		}, 1000); // Update every second

		return () => clearInterval(interval);
	}, [session]);

	useEffect(() => {
		if (!session || !session?.items) {
			return;
		}
		updateCheckoutState({
			isLargeGroup: session.items.some(item => !!item.request)
		});
	}, [session?.items]);

	return (
		<CheckoutContext.Provider
			value={{
				navConfig,
				checkoutState,
				updateCheckoutState,
				isSaveContactChecked,
				setIsSaveContactChecked,
				ctaText,
				setCtaText,
				isCtaEnabled,
				setIsCtaEnabled,
				clearSession
			}}
		>
			{children}
		</CheckoutContext.Provider>
	);
};
