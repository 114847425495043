import { TDR } from 'tdr-common';

/*
    Because .json and .text in the fetch response return promises it makes it really annoying to parse the body of a errored response in componenents
    to retrieve .message properties from 400 error calls, for example.
    instead we try to parse the payload in the react query wrapper hooks, which as async already,
    and wrap both the full response and the parsed body using this class so it can be thrown and use in components without async.
*/
export class ErrorResponse<T = TDR.ApiResponse & Record<string, any>> {
	constructor(public readonly response: Response, public readonly body: T) {}
}

export function isBadRequestErrorResponse(error: Error) {
	return error && error instanceof ErrorResponse && error.response.status === 400;
}