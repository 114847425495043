import React, { useEffect } from 'react';
import { TDR } from 'tdr-common';
import { analytics, MODAL_OPENED_EVENT } from '../../../helpers/analytics';
import { ControlledDialog } from './ControlledDialog';
import { CTAButton, CustomerSupportLink } from '../../buttons';

type RefundPeriodPassedProps = {
	isOpen: boolean;
	onClose: () => void;
	onProceed: () => void;
	restaurant: TDR.Restaurant;
};

export const RefundPeriodPassed = ({ isOpen, onClose, onProceed, restaurant }: RefundPeriodPassedProps) => {
	let body = (
		<>
			<span>
        Unfortunately, your booking is no longer eligible for a refund if modified or cancelled, as per{' '}
				the venue&apos;s Cancellation Policy.
			</span>
			<span>
        If you have any questions, please contact us at <CustomerSupportLink /> for further assisance.
			</span>
		</>
	);

	if (restaurant?.name) {
		body = (
			<>
				<span>
			Unfortunately, your booking is no longer eligible for a refund if modified or cancelled, as per{' '}
					<strong>{restaurant.name}&apos;s</strong> Cancellation Policy.
				</span>
				<span>
			If you have any questions, please contact us at <CustomerSupportLink /> for further assisance.
				</span>
			</>
		);
	}

	useEffect(() => {
		if (isOpen) {
			analytics.track(MODAL_OPENED_EVENT, { title: 'Refund Period Passed' });
		}
	}, [isOpen]);

	return (
		<ControlledDialog
			isOpen={isOpen}
			onClose={onClose}
			title='Refund Period Passed'
			icon='danger'
			body={body}
			footer={<CTAButton buttonText='Proceed' onClick={onProceed} variant='dark' />}
		/>
	);
};
