import React from 'react';
import {
	INVENTORY_NOTICE_EXPERIMENT
} from '../../helpers/analytics';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { LowInventoryNotice } from './LowInventoryNotice';
import { NoInventoryNotice } from './NoInventoryNotice';
import { RANDOM_TRUE_OR_FALSE } from './experiments/constants';
import { useOutletContext } from 'react-router-dom';
import { AvailabilityGuardContext } from '../../routes/AvailabilityGuard';

export enum AvailabilityLevel {
	LOW = 'low',
	NONE = 'none',
}

type TableInventoryNoticeProps = {
	noticeType: AvailabilityLevel;
}

export const TableInventoryNotice = ({ noticeType }: TableInventoryNoticeProps) => {
	const { searchFilters } = useOutletContext<AvailabilityGuardContext>();
	const [noticesExperiment] = useSessionStorage(
		INVENTORY_NOTICE_EXPERIMENT.storage_key,
		{ enabled: RANDOM_TRUE_OR_FALSE }
	);

	const isNoticeEnabled = noticesExperiment?.enabled;

	const inventoryNotices: Record<AvailabilityLevel, JSX.Element> = {
		[AvailabilityLevel.LOW]: <LowInventoryNotice guests={searchFilters.groupSize} />,
		[AvailabilityLevel.NONE]: <NoInventoryNotice guests={searchFilters.groupSize} />
	};

	if (!isNoticeEnabled) {
		return null;
	}

	return (
		<div key={noticeType}>
			{inventoryNotices[noticeType] || null}
		</div>
	);
};