import * as Sentry from '@sentry/react';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { TDR } from 'tdr-common';
import { useReservation } from '../../api/getReservation';
import { useModifyReservation } from '../../api/modifyReservation';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import { trackReservationModifications } from '../../helpers/reservationEventTrackers';
import { DietaryInput } from '../checkout/DietaryInput';

type DietaryPreferencesProps = {
	isEditEnabled: boolean;
	restaurant: TDR.Restaurant;
	reservation: TDR.Reservation;
	form: UseFormReturn;
};

export const DietaryPreferences = ({ isEditEnabled, restaurant, reservation, form }: DietaryPreferencesProps) => {
	const modifyReservationMutation = useModifyReservation(reservation.id);
	const { refetch: refetchReservation } = useReservation(reservation.id);
	const { setNewInvoice } = useModificationFlowState();

	const handleDietaryUpdate = async () => {
		const updatedRestrictions = form
			.getValues('dietaryRestrictions')
			?.filter((item): item is TDR.DietaryRestriction => !!item);
		const updatedNotes = form.getValues('dietaryNote');

		const response = await toast.promise(
			modifyReservationMutation.mutateAsync({
				id: reservation.id,
				dietaryRestrictions: {
					restrictions: updatedRestrictions,
					notes: updatedNotes
				}
			}),
			{
				pending: 'Saving your dietary preferences',
				success: 'Dietary preferences saved!',
				error: 'Failed to save your preferences'
			}
		);

		if (response.success) {
			trackReservationModifications({
				reservation,
				itemsChanged: { dietaryRestrictions: { restrictions: updatedRestrictions, notes: updatedNotes } }
			});
			refetchReservation();
			setNewInvoice(undefined);
		}
		else {
			const errorMessage = `modifyReservation Error: ${response.message}`;
			const sentryErrorID = Sentry.captureException(new Error(errorMessage));
			trackReservationModifications({ reservation, errorMessage, sentryErrorID });
		}
	};

	return (
		<DietaryInput
			isEditEnabled={isEditEnabled}
			restaurant={restaurant}
			currentNote={reservation.dietaryRestrictions?.notes || ''}
			currentRestrictions={reservation.dietaryRestrictions?.restrictions || []}
			form={form}
			isSaveEnabled={
				form.formState.dirtyFields?.dietaryRestrictions?.length > 0 || form.formState.dirtyFields?.dietaryNote
			}
			onSave={handleDietaryUpdate}
		/>
	);
};
