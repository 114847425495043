import React, { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { validate as isValidUUID } from 'uuid';
import { URLParams } from '../common/types';

export interface SMSBookingGuardProps {
	children: ReactNode;
}

/**
 * SMSBookingGuard ensures the user is directed to the appropriate page based on the first URL parameter,
 * which can be either a UUID or a slug.
 */
export const SMSBookingGuard = ({ children }: SMSBookingGuardProps) => {
	const { restaurantSlug: firstURLParam } = useParams<URLParams>();

	if (isValidUUID(firstURLParam)) {
		window.location.replace(`/booking/${firstURLParam}`);
		setTimeout(() => {
			window.location.reload();
		}, (200));

		return (
			<Navigate
				to={{
					pathname: `/booking/${firstURLParam}`
				}}
				replace
			/>
		);
	}
	else {
		return <>{ children }</>;
	}
};