import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { TDR } from 'tdr-common';
import { useViewToggle } from '../../context/ViewToggleProvider';
import { useMatterport } from '../../context/new/MatterportContext';
import { analytics } from '../../helpers/analytics';
import { getCdnImageUri, getCdnVideoUri } from '../../helpers/getCDNURI';
import { useScreenSize } from '../../hooks/useScreenSize';
import { HorizontalCarouselNav } from '../buttons';
import { BookingFlowVariant } from '../table-drawer/TableDrawer';
import VideoPlayer from '../video-player/VideoPlayer';
import { getVideoElementID } from '../video-player/helper';
import CarouselIndicator from './MediaCarouselIndicator';
import { VirtualViewButton } from './VirtualViewButton';

import styles from './MediaCarousel.module.scss';

type MediaCarouselProps = {
	tableLabel: string;
	items: TDR.MediaContent[];
	itemHeight: number;
	brightness?: number;
	contrast?: number;
	variant?: BookingFlowVariant;
};

const MediaCarousel = ({ tableLabel, items = [], itemHeight, brightness, contrast, variant = 'default' }: MediaCarouselProps) => {
	const { isSmallScreen } = useScreenSize();
	const { toggleView } = useViewToggle();
	const { isError: matterportError, isLoading: matterportLoading } = useMatterport();

	const videosRefs = useRef<HTMLVideoElement[]>([]);

	const [isVideoPlaying, setIsVideoPlaying] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [showVirtualViewButton, setShowVirtualViewButton] = useState(false);

	const hideNavButtons = items.length < 2 || (isVideoPlaying && !isHovered);

	useEffect(() => {
		videosRefs.current = videosRefs.current.slice(0, items.length);
	}, [items]);

	useEffect(() => {
		// only show virtual view button when matterport is loaded and not in modification flow
		if (!matterportLoading && !matterportError && variant === 'default') {
			setShowVirtualViewButton(true);
		}
		else {
			setShowVirtualViewButton(false);
		}
	}, [matterportLoading, matterportError, variant]);

	const handleChange = (index: number) => {
		setShowVirtualViewButton(index === 0 && variant === 'default');

		if (index) {
			try {
				videosRefs?.current?.forEach((v, i) => (i !== index ? v?.pause?.() : v?.play?.().catch?.(() => {
					//Ignore the play errors.
				})));
			}
			catch {
				//Ignore the pause errors. Some browsers will deny native play pause javascript actions.
			}
		}
	};

	const handleNavClick = (e: React.MouseEvent) => {
		e.preventDefault();
		analytics.track('Media Carousel - Navigation Click');
	};


	return (
		<div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} className={styles.Container}>
			<Carousel
				className={styles.MediaCarousel}
				showThumbs={false}
				showStatus={false}
				autoPlay={false}
				infiniteLoop={true}
				swipeable={true}
				emulateTouch={true}
				showIndicators={items.length > 1}
				renderIndicator={CarouselIndicator}
				preventMovementUntilSwipeScrollTolerance={true}
				onChange={handleChange}
				renderArrowNext={function (clickHandler: () => void) {
					return (
						<MediaCarouselNavLayout direction='next'>
							<HorizontalCarouselNav
								onClick={(e) => {
									handleNavClick(e);
									clickHandler();
								}}
								direction={'next'}
								hidden={hideNavButtons}
							/>
						</MediaCarouselNavLayout>
					);
				}}
				renderArrowPrev={(clickHandler: () => void) => {
					return (
						<MediaCarouselNavLayout direction='prev'>
							<HorizontalCarouselNav
								onClick={(e) => {
									handleNavClick(e);
									clickHandler();
								}}
								direction='prev'
								hidden={hideNavButtons}
							/>
						</MediaCarouselNavLayout>
					);
				}}
			>
				{items.map((item, idx) => {
					if (item.type === TDR.MediaType.IMAGE) {
						return (
							<React.Fragment key={`${idx} - ${item.path}`}>
								{isSmallScreen && showVirtualViewButton && <VirtualViewButton onClick={toggleView} type={tableLabel} />}
								<img
									src={getCdnImageUri(item.path, {
										height: itemHeight
									})}
									alt={`Item ${idx + 1}`}
									className={styles['MediaCarousel--image']}
									style={
										showVirtualViewButton
											? {
												filter: `brightness(${brightness ?? 1}) contrast(${contrast ?? 1})`
											}
											: {}
									}
								/>
							</React.Fragment>
						);
					}
					else if (item.type === TDR.MediaType.VIDEO) {
						return (
							<VideoPlayer
								id={getVideoElementID(idx)}
								ref={(el) => (videosRefs.current[idx] = el)}
								src={getCdnVideoUri(item.path)}
								key={`${idx} - ${item.path}`}
								onPlaybackChange={setIsVideoPlaying}
							/>
						);
					}
				})}
			</Carousel>
		</div>
	);
};

export default MediaCarousel;

const MediaCarouselNavLayout = ({ direction, children }: { direction: 'next' | 'prev'; children: React.ReactNode }) => {
	return <div className={`${styles.NavLayout} ${styles[`NavLayout--${direction}`]}`}>{children}</div>;
};
