import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { analytics } from '../../helpers/analytics';
import VideoUnsupportedMessage from './VideoUnsupportedMessage';
import { VideoPlayerControls } from './controls/VideoPlayerControls';
import { VideoPlayerProps } from './types';

import styles from './VideoPlayer.module.scss';
import { togglePlayback } from './helper';
import { useMediaQuery } from 'usehooks-ts';

/**
 * NOTE on video format
 *
 * HTML5 only supports 3 video formats: MP4, WebM, OGG
 * (https://www.w3schools.com/tags/tag_video.asp#:~:text=There%20are%20three%20supported%20video,MP4%2C%20WebM%2C%20and%20OGG.)
 *
 * But we can use MIME type video/mp4 for .mov files reliably
 * (https://stackoverflow.com/questions/31380695/how-to-open-mov-format-video-in-html-video-tag)
 */

/**
 * VideoPlayer Component
 *
 * Custom video player that allows users to play, pause, control volume, and enter/exit fullscreen mode.
 *
 */
const VideoPlayer = forwardRef(function VideoPlayer({
	src,
	onPlaybackChange,
	...props
}: VideoPlayerProps, videoRef: ((instance: HTMLVideoElement | null) => void)) {
	const [isFullscreen, setIsFullscreen] = useState(false);
	const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');

	const containerRef = useRef<HTMLDivElement>(null);
	const internalVideoRef = useRef<HTMLVideoElement>(null);

	const toggleFullscreen = () => {
		isFullscreen ? exitFullscreen() : enterFullscreen();
	};

	const enterFullscreen = () => {
		try {
			const video = internalVideoRef?.current;
			const videoContainer = containerRef.current;

			if (videoContainer?.requestFullscreen) {
				videoContainer.requestFullscreen();
			}
			else if (videoContainer?.webkitRequestFullscreen) {
				videoContainer.webkitRequestFullscreen();
			}
			else if (videoContainer?.mozRequestFullScreen) {
				videoContainer.mozRequestFullScreen();
			}
			else if (videoContainer?.msRequestFullscreen) {
				videoContainer.msRequestFullscreen();
			}
			// Mobile safari
			else if (video?.webkitEnterFullscreen) {
				video.webkitEnterFullscreen();
			}
		}
		catch {
			// ignore
		}
	};

	const exitFullscreen = () => {
		try {
			document.exitFullscreen?.() ||
			document.webkitExitFullscreen?.() ||
			internalVideoRef?.current.webKitExitFullscreen?.();
		}
		catch {
			// ignore
		}
	};

	// Event listeners to update isFullscreen state
	useEffect(() => {
		const updateFullscreenState = () => {
			setIsFullscreen(
				!!document.fullscreenElement ||
	        !!document.webkitFullscreenElement ||
					!!internalVideoRef?.current.webkitDisplayingFullscreen
			);
		};

		const handleKeydown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				updateFullscreenState();
			}
		};

		document.addEventListener('fullscreenchange', updateFullscreenState);
		document.addEventListener('webkitfullscreenchange', updateFullscreenState); // safari
		document.addEventListener('keydown', handleKeydown);

		return () => {
			document.removeEventListener('fullscreenchange', updateFullscreenState);
			document.removeEventListener('webkitfullscreenchange',updateFullscreenState);
			document.removeEventListener('keydown', handleKeydown);
		};
	}, []);

	// Event listener to pause video when browser tab is not active
	useEffect(() => {
		const video = internalVideoRef?.current;

		const handleVisibilityChange = () => {
			if (video && document.hidden) {
				video.pause();
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	},[]);


	// Track play/pause events
	useEffect(() => {
		const video = internalVideoRef?.current;

		const trackPlayEvent = () => {
			analytics.track('Media Carousel - Play Video');
		};

		const trackPauseEvent = () => {
			analytics.track('Media Carousel - Pause Video');
		};

		video?.addEventListener('play', trackPlayEvent);
		video?.addEventListener('pause', trackPauseEvent);

		return () => {
			video?.removeEventListener('play', trackPlayEvent);
			video?.removeEventListener('pause', trackPauseEvent);
		};
	}, []);

	// Track fullscreen events
	useEffect(() => {
		const video = internalVideoRef?.current;

		const trackEnterFullscreenEvent = () => {
			analytics.track('Media Carousel - Enter Fullscreen');
		};

		const trackExitFullscreenEvent = () => {
			analytics.track('Media Carousel - Exit Fullscreen');
		};

		const handleFullscreenChange = () => {
			if (!!document.fullscreenElement || !!document.webkitFullscreenElement) {
				trackEnterFullscreenEvent();
			}
			else {
				trackExitFullscreenEvent();
			}
		};

		document.addEventListener('fullscreenchange', handleFullscreenChange); // chrome, firefox, edge
		document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // safari
		video?.addEventListener('webkitbeginfullscreen', trackEnterFullscreenEvent); // mobile safari
		video?.addEventListener('webkitendfullscreen', trackExitFullscreenEvent); // mobile safari

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange);
			document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
			video?.removeEventListener('webkitbeginfullscreen', trackEnterFullscreenEvent);
			video?.removeEventListener('webkitendfullscreen', trackExitFullscreenEvent);
		};
	}, []);

	// Track when full video is viewed
	useEffect(() => {
		const video = internalVideoRef?.current;

		const trackVideoLoopEndedEvent = () => {
			analytics.track('Media Carousel - Video Completed');
		};

		const handleVideoTimeUpdate = () => {
			// Because the frequency of the timeupdate event is dependent on the system load, we use BUFFER_TIME to check
			// if the currentTime is CLOSE to the end of the video rather than checking if currentTime is AT the end, which may never actually occur.
			// Reference: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/timeupdate_event
			const BUFFER_TIME = 0.3;

			if (video?.currentTime + BUFFER_TIME >= video.duration) {
				trackVideoLoopEndedEvent();
			}
		};

		video?.addEventListener('timeupdate', handleVideoTimeUpdate);

		return () => {
			video?.removeEventListener('timeupdate', handleVideoTimeUpdate);
		};
	}, []);

	function handleVideoClick(e: React.MouseEvent<HTMLVideoElement, MouseEvent>) {
		togglePlayback(e.currentTarget, () => {
			onPlaybackChange(false);
		}, () => {
			onPlaybackChange(true);
		});
	}

	return (
		<div ref={containerRef} className={styles.VideoContainer}>
			<video
				src={src + '#t=0.001'} //so the #t=0.001 skips the first millisecond of the video, basically forcing ios to preload the thumbnail https://forums.developer.apple.com/forums/thread/129377
				loop
				playsInline
				autoPlay={prefersReducedMotion ? false: true}
				muted={true}
				preload='metadata' //explicitly state because ios automatically sets this to none to save on bandwidth
				className={isFullscreen ? styles.VideoFull : styles.VideoMinimized}
				ref={(el) => {
					internalVideoRef.current = el;
					videoRef?.(el);
				}}
				onPlaying={() => onPlaybackChange(true)}
				onPause={() => onPlaybackChange(false)}
				title='Overview of the ambiance inside the restaurant'
				onClick={handleVideoClick}
				{...props}
			>
				<VideoUnsupportedMessage href={src} />
			</video>

			<VideoPlayerControls
				videoRef={internalVideoRef}
				size={{
					isFullscreen,
					toggleFullscreen
				}}
			/>
		</div>
	);
});

export default VideoPlayer;
