import React from 'react';
import { TDR } from 'tdr-common';
import { analytics, LINK_CLICKED_EVENT } from '../../helpers/analytics';
import ArrowOutward from '../../icons/arrow-outward.svg';
import RestaurantEmailLink from '../buttons/RestaurantEmailLink';
import RestaurantPhoneLink from '../buttons/RestaurantPhoneLink';
import styles from './AlternativeBookingOption.module.scss';
import { InfoCallout } from './InfoCallout';

export interface AlternativeBookingOptionProps {
	restaurant: TDR.Restaurant;
}

export const AlternativeBookingOption = ({ restaurant }: AlternativeBookingOptionProps) => {
	const altPlatform = restaurant.settings?.alternativePlatform?.vendor;
	const altPlatformHref = restaurant.settings?.alternativePlatform?.href;

	const contactByPhone = altPlatform === 'phone';
	const contactByEmail = altPlatform === 'email';

	const canBookWithAltPlatform = !contactByEmail && !contactByPhone && !!altPlatform && !!altPlatformHref;

	const handleThirdPartyRMSClick = () => {
		analytics.track(LINK_CLICKED_EVENT, {
			href: altPlatformHref,
			purpose: 'Redirect to RMS from Alternative Booking Callout',
			platform: altPlatform
		});
	};

	const getActionText = () => {
		if (contactByPhone && !!restaurant.phoneNumber) {
			return (
				<>
					<span className={styles.Block}>
          Contact the restaurant directly at
					</span>
					<RestaurantPhoneLink phoneNumber={restaurant.phoneNumber} />
				</>
			);
		}

		if (contactByEmail && restaurant.contactEmail) {
			return (
				<span className={styles.Block}>
          Contact the restaurant directly at <RestaurantEmailLink email={restaurant?.contactEmail} />.
				</span>
			);
		}

		if (canBookWithAltPlatform) {
			return (
				<span className={styles.Block}>
          Search more availability on{' '}
					<a
						href={altPlatformHref}
						target='_blank'
						onClick={handleThirdPartyRMSClick}
						rel='noreferrer'
						className={styles.Link}
					>
						{TDR.RMSDisplayNameMap[altPlatform]} <img src={ArrowOutward} />
					</a>
				</span>
			);
		}
	};

	return (
		<InfoCallout>
			<span>Can&apos;t find the right time through Tablz?</span>

			{getActionText()}
		</InfoCallout>
	);
};
