// REFERENCE: https://robertmarshall.dev/blog/dynamically-set-an-account-id-in-stripe-using-loadstripe/

import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, Stripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';
import { TDR } from 'tdr-common';
import config from '../../common/config';
import { useCheckoutContext } from '../../context/new/CheckoutContext';
import { UnexpectedErrorPage } from '../../pages/errors/UnexpectedErrorPage';
import { CheckoutGuardOutletContext } from '../../routes/CheckoutGuard';
import CTALink from '../buttons/CTALink';
import { baseCheckoutNavConfig } from './steps/config';

type StripeWrapperProps = {
	children: React.ReactNode;
};

export const StripeWrapper = ({ children }: StripeWrapperProps) => {
	const { restaurant, checkoutSession } = useOutletContext<CheckoutGuardOutletContext>();
	const { checkoutState } = useCheckoutContext();

	const [stripeObject, setStripeObjecrt] = useState<Stripe>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const initialStep = baseCheckoutNavConfig.policies.path;

	const initialCheckoutStepPath = `/${restaurant.slug}/checkout/${checkoutSession.id}/${initialStep}`;
	const needHelpHref = `mailto:${TDR.SUPPORT_EMAIL}?subject=Need help with checkout sessionID: ${checkoutSession.id}`;

	useEffect(() => {
		const fetchStripeObject = async () => {
			if (!restaurant.stripeRegion) {
				setError(true);
			}
			const res = await loadStripe(config.stripe[restaurant.stripeRegion].pub_key);
			if (!res) {
				setError(true);
			}
			else {
				setStripeObjecrt(res);
			}
			setLoading(false);
		};
		fetchStripeObject();
	}, []);


	if (error) {
		return (
			<UnexpectedErrorPage to={initialCheckoutStepPath}>
				<CTALink text='Get help' to={needHelpHref} variant='dark' />
			</UnexpectedErrorPage>
		);
	}

	// If trying to access payment page without clientSecret, redirect back to initial checkout step
	if (!checkoutState.stripeClientSecret || checkoutState.stripeClientSecret.length === 0) {
		return <Navigate to={initialCheckoutStepPath} />;
	}

	return loading ? null : (
		<Elements
			key={checkoutState.stripeClientSecret}
			stripe={stripeObject}
			options={{
				fonts: [{
					family: 'Poppins',
					src: 'url(/Codec-Pro-Regular.ttf)',
					weight: '400'
				}],
				locale: 'en',
				clientSecret: checkoutState.stripeClientSecret,
				appearance: appearanceOptions
			}}
		>
			{children}
		</Elements>
	);
};

const appearanceOptions: Appearance = {
	theme: 'stripe',
	labels: 'floating',
	variables: {
		colorBackground: '#121212',
		colorText: '#fff',
		fontFamily: 'Poppins',
		colorPrimary: '#fff',
		borderRadius: '12px'
	},
	rules: {
		'.AccordionItem' : {
			backgroundColor: '#1B1B1B',
			letterSpacing: '2px'
		},
		'.Label' : {
			letterSpacing: '0.2px'
		},
		'.Input' : {
			paddingLeft: '24px'
		},
		'.Input--invalid': {
			color: '#a7300b'
		},
		'.Input:focus': {
			outline: 'none',
			boxShadow: '0 0 2px rgba(255, 255, 255, 0.4)'
		},
		'.Input:focus::placeholder': {
			color: 'transparent'
		},
		'.Error': {
			fontSize: '12px',
			color: '#a7300b'
		}
	}
};
