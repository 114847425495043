import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { TDR } from 'tdr-common';
import { useApplyPromoCode } from '../../api/applyPromoCode';
import { useCheckoutContext } from '../../context/new/CheckoutContext';
import { analytics } from '../../helpers/analytics';
import { CTAButton } from '../buttons';
import { InputClearButton } from '../buttons/InputClearButton';
import { InputValidationSuccess } from '../form-inputs/InputValidationSuccess';
import { InputValidationError } from '../form-inputs/InputValidationError';

export interface PromoCodeInputProps {
	invoiceId: string;
	checkoutSessionId: string;
	onSuccess?: (result: TDR.CheckoutSession.ApplyPromoCodeOutput) => void
}

export const PromoCodeInput = ({ invoiceId, checkoutSessionId, onSuccess }: PromoCodeInputProps) => {
	const { checkoutState, updateCheckoutState } = useCheckoutContext();
	const queryClient = useQueryClient();
	const { promoCode } = checkoutState;

	const { mutateAsync: applyPromoCode, isPending: isLoading, reset } = useApplyPromoCode();

	const [promoCodeValid, setPromoCodeValid] = useState(false);
	const [promoCodeError, setPromoCodeError] = useState('');

	const handleClearPromoCode = async () => {
		updateCheckoutState({
			promoCode: ''
		});
		setPromoCodeError('');
		reset();
	};

	const handlePromoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		updateCheckoutState({ promoCode: event.target.value.toUpperCase() });
	};

	const handleSubmitPromoCode = async () => {
		try {
			const result = await applyPromoCode({ checkoutId: checkoutSessionId, invoiceId, promoCode });
			if (result.success) {
				setPromoCodeValid(true);
				updateCheckoutState({
					promoCode,
					...(result.clientSecret && { stripeClientSecret: result.clientSecret })
				});

				analytics.track('PromoCode Applied', {
					promoCode
				});

				queryClient.invalidateQueries({ queryKey: ['checkoutSession'] });

				onSuccess?.(result);
			}
			else {
				setPromoCodeError('Invalid promo code');
				setPromoCodeValid(false);
				analytics.track('PromoCode Failed', {
					promoCode
				});
			}
		}
		catch (error) {
			const eventID = Sentry.captureException(error);
			setPromoCodeError('Error processing promo code');
			setPromoCodeValid(false);
			analytics.track('PromoCode Failed', {
				promoCode,
				sentryErrorID: eventID
			});
		}
	};

	return (
		<div>
			<div className='PromoCodeWrapper'>
				<div className='InputContainer'>
					<label id='promo-code-label' htmlFor='promoCode' hidden>
            Promo Code
					</label>
					<input
						name='promoCode'
						aria-labelledby='promo-code-label'
						value={promoCode}
						className={promoCodeError ? 'invalidInput' : ''}
						disabled={isLoading}
						type='text'
						placeholder='Enter promo code'
						onChange={handlePromoCodeChange}
						required={false}
					/>

					<InputClearButton onClick={handleClearPromoCode} isVisible={!!promoCode} />
				</div>

				<div style={{ width: '100px' }}>
					<CTAButton
						buttonText='Apply'
						loading={isLoading}
						disabled={!promoCode || isLoading}
						onClick={handleSubmitPromoCode}
						ariaLabel='Apply promo code'
					/>
				</div>
			</div>

			{promoCode && promoCodeValid ? (
				<InputValidationSuccess message={'Promo Code Added'} />
			) : (
				promoCodeError && <InputValidationError message={promoCodeError} />
			)}
		</div>
	);
};
