import React from 'react';
import Spinner from '../Spinner';

import styles from './WholeScreenLoading.module.scss';

export interface WholeScreenLoadingProps {
	id?: string;
}

const WholeScreenLoading = ({ id }: WholeScreenLoadingProps) => {
	return (
		<div className={styles.container}>
			<Spinner id={id} text='LOADING' size='fullpage' />
		</div>
	);
};

export default WholeScreenLoading;
