import * as Sentry from '@sentry/react';
import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { To, resolvePath, useOutletContext, useParams } from 'react-router-dom';
import { CTAButton } from '../../components/buttons';
import CTALink from '../../components/buttons/CTALink';
import { analytics } from '../../helpers/analytics';
import PageNotFoundIcon from '../../icons/error-grey.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';

export interface ErrorBoundaryProps extends UnexpectedErrorPageProps {
	error: Error;
	componentStack: string;
	eventId: string;
	resetError(): void;
}

type ErrorBoundary = (props: ErrorBoundaryProps) => React.ReactElement;

// eslint-disable-next-line react/prop-types
export const ErrorBoundary: ErrorBoundary = ({ eventId, resetError, ...props }) => {
	 const firstUpdate = useRef(true);

	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		  }
		  else {
			resetError();
		  }
	}, [location.pathname]);

	try {
		return (
			<UnexpectedErrorPage onClick={() => resetError()} sentryErrorID={eventId} {...props}>
				<CTAButton
					buttonText='Report a Bug'
					variant='dark'
					onClick={() => {
						Sentry.showReportDialog({ eventId });
					}}
				/>
			</UnexpectedErrorPage>
		);
	}
	catch (e) {
		console.error(e);
		return null;
	}
};

export interface UnexpectedErrorPageProps {
	to?: To;
	text?: string;
	onClick?: () => void;
	children?: ReactNode;
	sentryErrorID?: string;
}

export function UnexpectedErrorPage({ to, text, children, onClick, sentryErrorID }: UnexpectedErrorPageProps) {
	// we can't deconstruct here ({ restaurant }) because the restaurant itself my be not found
	// which would make the context undefined

	const context = useOutletContext<RestaurantGuardOutletContext>();
	const { restaurantSlug } = useParams();

	useEffect(() => {
		analytics.track('Unexpected Error', {
			...JSON.parse(JSON.stringify(window.location)),
			sentryErrorID: sentryErrorID
		});
	}, []);

	let button: ReactElement | null = null;

	if (to) {
		const path = resolvePath(to, location.pathname);

		button = <CTALink onClick={onClick} text={text || 'Go Back'} to={path} />;
	}
	else if (context?.restaurant) {
		button = <CTALink onClick={onClick} text={text || 'Go to Homepage'} to={`/${context?.restaurant.slug}`} />;
	}
	else if (restaurantSlug) {
		button = <CTALink onClick={onClick} text={text || 'Go to Homepage'} to={`/${restaurantSlug}`} />;
	}

	return (
		<ResultPageLayout headerIcon={PageNotFoundIcon} iconAltText='Broken Link Icon'>
			<ResultPageLayout.Title>Oops</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>Something went wrong on our end.</p>
			</ResultPageLayout.Body>

			<ResultPageLayout.Footer>
				{button}
				{children}
			</ResultPageLayout.Footer>
		</ResultPageLayout>
	);
}
