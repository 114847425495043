import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useOutletContext } from 'react-router-dom';
import { z } from 'zod';
import { countryCodeConverter } from '../../../helpers/countryCodeConverter';
import { RestaurantGuardOutletContext } from '../../../routes/RestaurantGuard';
import { RHFPhoneInput } from '../../form-inputs/RHFPhoneInput';
import { RHFTextInput } from '../../form-inputs/RHFTextInput';
import { SubHeader } from '../../headers/SubHeader';

import styles from '../../checkout/ContactInput.module.scss';

const NAME_REGEX = new RegExp(/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,20}$/);

// Schema & Types - Contact Info
const ContactFormSchema = z.object({
	firstName: z
		.string({ required_error: 'First name is required' })
		.min(2, 'First name must be 2 characters or more')
		.max(20, 'First name must be 20 characters or less')
		.refine(
			(value) => {
				// Using the following syntax to avoid issues with names not using english alphabet, (e.g. Chinese, Japanese, Eastern EU etc.)
				return NAME_REGEX.test(value);
			},
			{ message: 'Name can\'t contain special characters' }
		),

	lastName: z
		.string({ required_error: 'Last name is required' })
		.min(2, 'Last name must be 2 characters or more')
		.max(20, 'Last name must be 20 characters or less')
		.refine(
			(value) => {
				// Using the following syntax to avoid issues with names not using english alphabet, (e.g. Chinese, Japanese, Eastern EU etc.)
				return NAME_REGEX.test(value);
			},
			{ message: 'Name can\'t contain special characters' }
		),
	email: z.string({ required_error: 'Email is required' }).email('Invalid email'),
	phone: z.string({ required_error: 'Phone number is required' }).refine((val) => isValidPhoneNumber(val), {
		message: 'Invalid phone number'
	})
});

type ContactFormType = z.infer<typeof ContactFormSchema>;

type ContactDetailsFormProps = {
	setIsContactFormValid: React.Dispatch<React.SetStateAction<boolean>>;
	setContactInformation: React.Dispatch<React.SetStateAction<any>>;
};

export const Contact: React.FC<ContactDetailsFormProps> = ({ setIsContactFormValid, setContactInformation }) => {
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	const defaultValues = {
		firstName: '',
		lastName: '',
		email: '',
		phone: ''
	};
	// Form Logic & State
	const {
		register,
		control,
		formState: { errors, isValid, isValidating },
		getValues
	} = useForm<ContactFormType>({
		mode: 'onTouched',
		resolver: zodResolver(ContactFormSchema),
		defaultValues
	});

	useEffect(() => {
		if (!isValidating && isValid) {
			if (!errors.phone && !errors.firstName && !errors.lastName && !errors.email) {
				setContactInformation({ ...defaultValues, ...getValues() });
			}
		}
	}, [isValidating, isValid, errors]);

	useEffect(() => {
		setIsContactFormValid?.(isValid);
		() => {};
	}, [isValid]);

	return (
		<section>
			<SubHeader title='Contact Information' isRequired={true} />
			<span id='sign-in-button' style={{ display: 'none' }} />

			<div className={styles.ContactInfo}>
				<RHFTextInput label='First Name' name='firstName' register={register} error={errors.firstName?.message} />
				<RHFTextInput label='Last Name' name='lastName' register={register} error={errors.lastName?.message} />
				<RHFTextInput label='Email' name='email' register={register} error={errors.email?.message} />
				<RHFPhoneInput
					label='Phone Number'
					name='phone'
					control={control}
					defaultCountry={countryCodeConverter(restaurant.country ?? restaurant.stripeRegion)}
					error={errors.phone?.message}
				/>
			</div>
		</section>
	);
};
