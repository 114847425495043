import round from 'lodash/round';
import React from 'react';
import { TDR } from 'tdr-common';
import { TableTags } from '../table-tags/TableTags';

type TableDrawerTagsProps = {
	table: TDR.Table;
	bookingDuration: number;
};

export const TableDrawerTags = ({ table, bookingDuration }: TableDrawerTagsProps) => {
	const guestCount =
    table.minGuests === table.maxGuests
    	? String(table.minGuests)
    	: String(table.minGuests) + '-' + String(table.maxGuests);

	const resHours = bookingDuration && `${round(bookingDuration / 60, 1)} HRS`;

	return <TableTags attributes={table.attributes ?? {}} guestCount={guestCount} resHours={resHours} />;
};
