import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { RestaurantGuardOutletContext } from './RestaurantGuard';

const GROUP_SIZE_KEY = 'groupSize';
const DATE_KEY = 'date';
const TIME_KEY = 'time';
export interface AvailabilityGuardContext extends RestaurantGuardOutletContext {
	searchFilters: SearchFilters;
	tables: TDR.Table[];
	updateSearchParams: (searchFilters: Partial<SearchFilters>, options?: { pathname?: string, hash?: string }) => void;
}

export interface SearchFilters {
	groupSize: number;
	date: string;
	time: string;
}

export function parseSearchParamsFilters(searchParams: URLSearchParams, timezone?: string): SearchFilters {
	let groupSize: number | undefined;
	let date: string | undefined;
	let time: string | undefined;

	if (searchParams.has(GROUP_SIZE_KEY)) {
		const tempGroupSize = parseInt(searchParams.get(GROUP_SIZE_KEY) || '');
		if (!isNaN(tempGroupSize)) {
			groupSize = tempGroupSize;
		}
	}

	if (searchParams.has(DATE_KEY)) {
		const tempDay = searchParams.get(DATE_KEY);
		if (tempDay) {
			const parsedDay = DateTime.fromISO(tempDay);
			const now = DateTime.now().setZone(timezone);
			if (parsedDay.isValid && parsedDay.startOf('day') >= now.startOf('day')) {
				date = parsedDay.toISODate(); // Ensures YYYY-MM-DD format
			}
		}
	}

	if (searchParams.has(TIME_KEY)) {
		const tempTime = searchParams.get(TIME_KEY);
		if (tempTime && /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(tempTime)) {
			// Validates HH:MM format
			time = tempTime;
		}
	}

	return {
		groupSize,
		date,
		time
	};
}

export function serializeSearchFiltersForURL(searchFilters: Partial<SearchFilters>): URLSearchParams {
	const params = new URLSearchParams();

	if (searchFilters.groupSize !== undefined) {
		// We only add parameters that are not undefined
		params.set('groupSize', searchFilters.groupSize.toString());
	}

	if (searchFilters.date !== undefined) {
		// Ensuring the day is in the correct format should have been handled in the parsing stage
		params.set('date', searchFilters.date);
	}

	if (searchFilters.time !== undefined) {
		// Time should also be in the correct format as per the parsing function's handling
		params.set('time', searchFilters.time);
	}

	return params;
}

export const AvailabilityGuard = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const navigate = useNavigate();
	const parentContext = useOutletContext<RestaurantGuardOutletContext>();

	const searchFilters = parseSearchParamsFilters(searchParams, parentContext.restaurant?.timezone);

	// If group size not set, clear the date and time keys
	useEffect(() => {
		if (!searchParams.has(GROUP_SIZE_KEY)) {
			searchParams.delete(DATE_KEY);
			searchParams.delete(TIME_KEY);
			setSearchParams(searchParams);
		}
	}, [searchParams, setSearchParams]);

	// If time set without date, clear the time key
	useEffect(() => {
		if (searchParams.has(TIME_KEY) && !searchParams.has(DATE_KEY)) {
			searchParams.delete(TIME_KEY);
			setSearchParams(searchParams);
		}
	}, [searchParams, setSearchParams]);

	function setSearchFilters(newSearchFilters: Partial<SearchFilters>, options?: { pathname?: string, hash?: string }) {
		const serializedParams = serializeSearchFiltersForURL(newSearchFilters);
		navigate(
			{
				pathname: options?.pathname || location.pathname,
				search: serializedParams.toString(),
				hash: options?.hash || location.hash
			},
			{ replace: true }
		);
	}

	const updateSearchParams = (newFilters: Partial<SearchFilters>, options?: { pathname?: string, hash?: string}) =>
		setSearchFilters({ ...searchFilters, ...newFilters }, options);

	return (
		<>
			{
			/*
				TODO
				This <AnimatedOutlet /> will have to be rethought to achieve smooth page transitions.
				In the checkout flow, it causes the <CheckoutProvider /> to re-render and reset the checkoutState, breaking the flow 🙁
			*/
			}

			{/* <AnimatedOutlet context={{ searchFilters, updateSearchParams, ...parentContext } satisfies AvailabilityGuardContext} /> */}

			<Outlet context={{ searchFilters, updateSearchParams, ...parentContext } satisfies AvailabilityGuardContext} />
		</>
	);
};
