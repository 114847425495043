import { TDR } from 'tdr-common';
import config from '../common/config';
import { useMutation } from '@tanstack/react-query';
import { ErrorResponse } from '../common/ErrorResponse';

export const MUTATION_KEY = 'updateReservationItems';

export function useUpdateReservationItems() {
	const mutation = useMutation({
		mutationKey: [MUTATION_KEY],
		mutationFn: ({ input }: {input: TDR.Reservation.UpdateReservationItemsInput} ) => updateReservationItems(input),
		scope: {
			id: 'updateReservationItems'
		}
	});

	return mutation;
}

async function updateReservationItems(
	input: TDR.Reservation.UpdateReservationItemsInput
): Promise<TDR.Reservation.UpdateReservationItemsOutput> {
	const response = await fetch(`${config.apiHost}/updateReservationItems`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as TDR.Reservation.UpdateReservationItemsOutput;
	if (!response.ok) {
		throw new ErrorResponse(response, responsePayload);
	}

	return responsePayload;
}
