import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toLuxon } from 'tdr-common';
import { useReservation } from '../../api/getReservation';
import { useRestaurant } from '../../api/getRestaurant';
import { useTable } from '../../api/getTable';
import { URLParams } from '../../common/types';
import TableDrawerMods from '../../components/table-drawer/TableDrawerMods';
import { SearchFilters } from '../../routes/AvailabilityGuard';
import { ModifyBookingFormData } from '../../routes/BookingGuard';

export const ReviewTablePage = () => {
	const { reservationId } = useParams<URLParams>();

	const { data: reservation } = useReservation(reservationId);
	const { data: restaurant } = useRestaurant(reservation.restaurantId);

	const methods = useFormContext<ModifyBookingFormData>();

	const searchFilters: SearchFilters = {
		groupSize: methods.watch('guests'),
		date: toLuxon(methods.watch('epochInSeconds'), restaurant.timezone).toFormat('yyyy-MM-dd'),
		time: toLuxon(methods.watch('epochInSeconds'), restaurant.timezone).toFormat('HH:mm')
	};

	const { data: selectedTable, isLoading } = useTable(methods.watch('tableId'));

	return isLoading ? null : (
		<TableDrawerMods
			restaurant={restaurant}
			reservationId={reservationId}
			selectedTable={selectedTable}
			searchFilters={searchFilters}
		/>
	);
};
