import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReservation } from '../../api/getReservation';
import { URLParams } from '../../common/types';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import { BookingCancelled } from '../../components/confirmation';

export const CancellationSuccess = () => {
	const navigate = useNavigate();
	const { reservationId } = useParams<URLParams>();
	const { data: reservation } = useReservation(reservationId);
	const { hasStartedFlow } = useModificationFlowState();

	useEffect(() => {
		if (!hasStartedFlow) {
			navigate(`/booking/${reservationId}`);
		}
	});

	return (
		<BookingCancelled
			restaurantName={reservation.restaurantName}
			linkTo={`/${reservation.restaurantSlug}/explore`}
		/>
	);
};
