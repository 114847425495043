import Tag from 'iconsax-react/dist/esm/Tag';
import React, { ComponentType, forwardRef, lazy, Suspense } from 'react';
import Age21Plus from '../../icons/age-21-plus.svg';
import Beach from '../../icons/beach.svg';
import Calendar from '../../icons/calendar.svg';
import CircleArrowIn from '../../icons/circle-arrow-in.svg';
import Clock from '../../icons/clock.svg';
import Email from '../../icons/email.svg';
import EntranceFee from '../../icons/entrance-fee.svg';
import ForkKnife from '../../icons/fork-knife.svg';
import Info from '../../icons/info.svg';
import LightningCloud from '../../icons/lightning-cloud.svg';
import Patio from '../../icons/patio.svg';
import People from '../../icons/people.svg';
import Phone from '../../icons/phone.svg';
import Shirt from '../../icons/shirt.svg';
import Table from '../../icons/table.svg';
import WaterFront from '../../icons/waterfront.svg';


type BookingOverviewIconProps = {
	src: string;
	alt: string;
	style?: object;
}

const TagIcon = ({ src, alt, style = {} }: BookingOverviewIconProps) => (
	<img src={src} alt={alt} className='TagIcon' style={style} />
);


export const customIcons : (style?: object) => {[key:string] : React.ReactElement} = (style = {}) => ({
	'Tablz__Age21Plus': <TagIcon src={Age21Plus} alt='Age 21 plus icon' style={style} />,
	'Tablz__CircleArrowIn': <TagIcon src={CircleArrowIn} alt='Circle arrow in icon' style={style} />,
	'Tablz__ForkKnife': <TagIcon src={ForkKnife} alt='Fork knife icon' style={style} />,
	'Tablz__LightningCloud': <TagIcon src={LightningCloud} alt='Lightning cloud icon' style={style} />,
	'Tablz__Shirt': <TagIcon src={Shirt} alt='Shirt icon' style={style} />,
	'Tablz__Beach': <TagIcon src={Beach} alt='Beach icon' style={style} />,
	'Tablz__EntranceFee': <TagIcon src={EntranceFee} alt='Entrance Fee icon' style={style} />,
	'Tablz__Waterfront': <TagIcon src={WaterFront} alt='Waterfront icon' style={style} />
});


interface DynamicIconProps {
	iconName: string;
	size?: number;
	color?: string;
}

const FallbackComponent: React.FC<{ size: number; color?: string }> = ({ size }) => (
	<div style={{ width: size, height: size }} />
);

export const DynamicIcon: React.FC<DynamicIconProps> = ({ iconName, size = 24, color }) => {
	const IconComponent = lazy(() =>
		import('iconsax-react').then(module => {
			const component = module[iconName as keyof typeof module];
			if (!component) {
				return { default: FallbackComponent };
			}
			return { default: component as ComponentType<any> };
		})
	);

	return (
		<div key={`${iconName}_${color}_${size}`} style={{ height: size }}>
			<Suspense fallback={<div>Loading...</div>}>
				<IconComponent size={size} color={color} />
			</Suspense>
		</div>

	);
};

type InvoiceItemIconProps = {
	src: string;
	alt: string;
	onClick: () => void;
}

type ClickableInfoIcon = {
	onClick?: () => void;
}

type GuestCountIconProps = {
	guests: number;
}

export const InvoiceItemIcon = forwardRef<HTMLImageElement, InvoiceItemIconProps>(({ src, alt, onClick }, ref) => (
	<img
		src={src}
		alt={alt}
		onClick={onClick}
		className='InvoiceItemIcon'
		ref={ref}
	/>
));
InvoiceItemIcon.displayName = 'InvoiceItemIcon';

export const ProcessingFeeIcon = ({ onClick }: ClickableInfoIcon) => (
	<InvoiceItemIcon
		src={Info}
		alt={'Processing Info Icon'}
		onClick={onClick}
	/>
);

export const DepositIcon = forwardRef<HTMLImageElement, ClickableInfoIcon>(({ onClick }, ref) => (
	<InvoiceItemIcon
		src={Info}
		alt={'Deposit Info Icon'}
		onClick={onClick}
		ref={ref}
	/>
));
DepositIcon.displayName = 'DepositIcon';

export const SubtotalIcon = forwardRef<HTMLImageElement, ClickableInfoIcon>(({ onClick }, ref) => (
	<InvoiceItemIcon
		src={Info}
		alt={'Subtotal Icon'}
		onClick={onClick}
		ref={ref}
	/>
));
SubtotalIcon.displayName = 'SubtotalIcon';

export const GuestCountIcon = ({ guests }: GuestCountIconProps) => (
	<>
		{guests} {guests > 1 ? 'Guests' : 'Guest'}
	</>
);

export const CouponIcon = () => (
	<Tag size='17' color='#00AB55' style={{ transform: 'rotate(90deg)' }} />
);

export const TableSelectionFeeIcon = forwardRef<HTMLImageElement, ClickableInfoIcon>(({ onClick }, ref) => (
	<InvoiceItemIcon
		src={Info}
		alt={'Seating Selection Fee Info Icon'}
		onClick={onClick}
		ref={ref}
	/>
));
TableSelectionFeeIcon.displayName = 'TableSelectionFeeIcon';

export const InfoDrawerIcon = forwardRef<HTMLImageElement, object>((props, ref) => (
	<img
		src={Info}
		alt='More info'
		className='InvoiceItemIcon'
		ref={ref}
		{...props}
	/>
));
InfoDrawerIcon.displayName = 'InfoDrawerIcon';


const BookingOverviewIcon = ({ src, alt }: BookingOverviewIconProps) => (
	<img src={src} alt={alt} className='OverviewIcon' />
);

export const PatioIcon = () => (
	<BookingOverviewIcon src={Patio} alt='Patio icon' />
);

export const TableIcon = () => (
	<BookingOverviewIcon src={Table} alt='Table icon' />
);

export const GuestIcon = () => (
	<BookingOverviewIcon src={People} alt='Guest icon' />
);

export const CalendarIcon = () => (
	<BookingOverviewIcon src={Calendar} alt='Calendar icon' />
);

export const ClockIcon = () => (
	<BookingOverviewIcon src={Clock} alt='Clock icon' />
);

export const EmailIcon = () => (
	<BookingOverviewIcon src={Email} alt="Email icon"/>
);

export const PhoneIcon = () => (
	<BookingOverviewIcon src={Phone} alt="Phone icon"/>
);