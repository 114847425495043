import { TDR } from 'tdr-common';

export const mostCompatibleGroupSize = (tables: Pick<TDR.Table, 'minGuests' | 'maxGuests'>[]) => {
	if (!tables || tables.length === 0) {
		return -1;
	}

	let globalMin = Number.MAX_SAFE_INTEGER;
	let globalMax = Number.MIN_SAFE_INTEGER;

	tables.forEach(table => {
		if (table.minGuests < globalMin) {
			globalMin = table.minGuests;
		}
		if (table.maxGuests > globalMax) {
			globalMax = table.maxGuests;
		}
	});

	let maxTables = 0;
	let bestGuestSize = 0;

	for (let size = globalMin; size <= globalMax; size++) {
		let count = 0;
		tables.forEach(table => {
			if (size >= table.minGuests && size <= table.maxGuests) {
				count++;
			}
		});

		if (count > maxTables) {
			maxTables = count;
			bestGuestSize = size;
		}
	}

	return bestGuestSize;
};