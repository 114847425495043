import * as Sentry from '@sentry/react';
import { motion, Variants } from 'framer-motion';
import React, { ReactNode, useEffect } from 'react';
import { useMatch, useOutletContext, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AnimatedImageGallery } from '../components/animated-image-gallery/AnimatedImageGallery';
import { MatterportEnvironment } from '../components/matterport-environment/MatterportEnvironment';
import ScrollToHashElement from '../components/scroll-to-hash-element/ScrollToHashElement';
import { useMatterport } from '../context/new/MatterportContext';
import { useViewToggle } from '../context/ViewToggleProvider';
import { useScreenSize } from '../hooks/useScreenSize';
import { RestaurantGuardOutletContext } from '../routes/RestaurantGuard';

import 'react-toastify/dist/ReactToastify.css';
import styles from './AppLayout.module.scss';

export interface AppLayoutProps {
	children: ReactNode;
}

export function AppLayout({ children }: AppLayoutProps) {
	const parentContext = useOutletContext<RestaurantGuardOutletContext>();
	const { restaurant, tables } = parentContext;
	const { isError: matterportError } = useMatterport();

	const params = useParams();
	const checkoutRouteMatch = useMatch(`${restaurant.slug}/checkout/*`);
	const inboundRequestRouteMatch = useMatch(`${restaurant.slug}/explore/request/*`);
	const { isSmallScreen } = useScreenSize();
	const { isVirtualView, turnOffVirtualView } = useViewToggle();

	useEffect(() => {
		if (!isSmallScreen || matterportError) {
			turnOffVirtualView();
		}
	}, [isSmallScreen, matterportError]);

	const sidebarVariants: Variants = {
		hidden: {
			opacity: 0,
			visibility: 'hidden',
			transition: {
				duration: 0.6,
				// only add delay on table details route
				delay: params?.tableSlug ? 0.5 : 0
			}
		},
		visible: {
			opacity: 1,
			visibility: 'visible'
		}
	};

	return (
		<main className={styles.wrapper}>
			<ScrollToHashElement />

			<motion.section
				id='sidebar-container'
				className={styles.sidebar}
				initial={'visible'}
				variants={sidebarVariants}
				animate={isVirtualView ? 'hidden' : 'visible'}
			>
				{children}
				<ToastContainer position='bottom-left' theme='dark' pauseOnFocusLoss={false} />
			</motion.section>

			<section
				className={`${isVirtualView ? 'scrollLock' : ''} ${styles.matterport} ${
					checkoutRouteMatch || inboundRequestRouteMatch ? styles['matterport--disabled'] : ''
				}`}
			>
				<Sentry.ErrorBoundary
					fallback={() => <AnimatedImageGallery staticImage={true} restaurant={restaurant} tables={tables} />}
					beforeCapture={(scope) => {
						scope.setTag('location', 'App Layout');
					}}
				>
					{restaurant?.layouts?.map((layout) => (
						<MatterportEnvironment
							key={layout.model}
							restaurantId={restaurant.id}
							modelId={layout.model}
							brightness={layout.brightness}
							contrast={layout.contrast}
							condition={layout.condition}
						/>
					)) || <AnimatedImageGallery staticImage={true} restaurant={restaurant} tables={tables} />}
				</Sentry.ErrorBoundary>
			</section>
		</main>
	);
}
