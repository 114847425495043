import { useQuery } from '@tanstack/react-query';
import { doc, DocumentSnapshot, getDoc, getFirestore, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { TDR } from 'tdr-common';

export function useReservation(id: string) {
	const query = useQuery({
		enabled: !!id,
		queryKey: ['getReservation', id],
		queryFn: () => getReservation(id)
	});
	return query;
}

export default async function getReservation(reservationId: string): Promise<TDR.Reservation> {
	const db = getFirestore();
	const docRef = doc(db, 'Reservations', reservationId);
	const snapshot = await getDoc(docRef);
	return (snapshot.exists || null) && (snapshot.data() as TDR.Reservation);
}

export const getReservationOnSnapshot = (
	reservationId: string,
	callback: (doc: DocumentSnapshot) => void
): Unsubscribe => {
	const db = getFirestore();

	const unsub = onSnapshot(doc(db, 'Reservations', reservationId), (doc) => {
		callback(doc);
	});

	return unsub;
};
