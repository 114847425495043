import { DateTime } from 'luxon';
import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { DATESTR_FMT } from 'tdr-common';
import { HASH_ELEMENT_IDS } from '../../common/hashElementIds';
import DateChecked from '../../icons/date-checked.svg';
import DateUnchecked from '../../icons/date-unchecked.svg';
import GuestChecked from '../../icons/guest-checked.svg';
import GuestUnchecked from '../../icons/guest-unchecked.svg';
import TimeChecked from '../../icons/time-checked.svg';
import TimeUnchecked from '../../icons/time-unchecked.svg';
import { AvailabilityGuardContext, serializeSearchFiltersForURL } from '../../routes/AvailabilityGuard';
import { SquareIconButton } from '../buttons/SquareIconButton';
import styles from './SidebarFiltersHeader.module.scss';

type SidebarFiltersHeaderProps = {
	resetScroll: () => void;
};

export const SidebarFiltersHeader = ({ resetScroll }: SidebarFiltersHeaderProps) => {
	const { searchFilters } = useOutletContext<AvailabilityGuardContext>();
	const navigate = useNavigate();

	const handleClick = (hash: string) => {
		navigate({
			pathname: 'filters',
			search: serializeSearchFiltersForURL(searchFilters).toString(),
			hash
		});
	};

	const handleDeleteClick = () => {
		resetScroll();
		navigate({
			search: ''
		});
	};

	const isFiltersEmpty = !Object.values(searchFilters).some((filter) => filter !== undefined && filter !== null); // true
	const groupSizeIconURL: string = searchFilters.groupSize ? GuestChecked : GuestUnchecked;
	const dateIconURL: string = searchFilters.date ? DateChecked : DateUnchecked;
	const timeIconURL: string = searchFilters.time ? TimeChecked : TimeUnchecked;

	return (
		<div className={styles.FiltersHeader}>
			<FilterIcon
				key={`${searchFilters.groupSize}`}
				icon={<img src={groupSizeIconURL} alt={`Group Size ${searchFilters.groupSize ? 'set' : 'unset'} Icon`} />}
				text={`${searchFilters.groupSize}`}
				onClick={() => handleClick(HASH_ELEMENT_IDS.groupSize)}
			/>
			{isFiltersEmpty || !!searchFilters.date ? (
				<FilterIcon
					key={`${searchFilters.date}`}
					icon={<img src={dateIconURL} alt={`Date ${searchFilters.date ? 'set' : 'unset'} Icon`} />}
					text={formatDate(searchFilters.date)}
					onClick={() => handleClick('calendar')}
					grow
				/>
			) : null}
			{isFiltersEmpty || !!searchFilters.time ? (
				<FilterIcon
					key={`${searchFilters.time}`}
					icon={<img src={timeIconURL} alt={`Time ${searchFilters.time ? 'set' : 'unset'} Icon`} />}
					text={formatTime(searchFilters.time)}
					grow
					onClick={() => handleClick(HASH_ELEMENT_IDS.timeslots)}
				/>
			) : null}
			{!isFiltersEmpty && (!searchFilters?.time || !searchFilters?.date) ? (
				<>
					<FilterIcon
						key={'params_missing_params'}
						text={!searchFilters?.date ? 'Select Date' : 'Set Time'}
						icon={
							<img
								src={!!searchFilters?.date ? timeIconURL : dateIconURL}
								alt={`${!!searchFilters.date ? 'time' : 'date'} unset Icon`}
							/>
						}
						variant='filled'
						styleOverride={{ textTransform: 'capitalize', padding: '10px 7px' }}
						grow
						onClick={() => handleClick(!searchFilters?.date ? HASH_ELEMENT_IDS.calendar : HASH_ELEMENT_IDS.timeslots)}
					/>
				</>
			) : null}

			<SquareIconButton onClick={handleDeleteClick} variant='delete' ariaLabel='Reset Filters' />
		</div>
	);
};

const formatDate = (dateFilter?: string) => {
	return dateFilter ? DateTime.fromFormat(dateFilter, DATESTR_FMT).toFormat('LLL d') : '';
};

const formatTime = (timeFilter?: string) => {
	return timeFilter ? DateTime.fromFormat(timeFilter, 'HH:mm').toFormat('h:mma') : '';
};

type FilterIconProps = {
	icon?: React.ReactNode;
	variant?: 'filled' | 'outlined' | 'text';
	grow?: boolean;
	text?: string;
	ariaLabel?: string;
	onClick?: () => void;
	styleOverride?: React.CSSProperties;
};
const FilterIcon = ({ icon, variant = 'filled', grow, text, ariaLabel, onClick, styleOverride }: FilterIconProps) => {
	return (
		<button
			onClick={onClick}
			className={`${styles.IconContainer} ${styles[`IconContainer--${variant}`]} ${
				grow ? styles['IconContainer--grow'] : ''
			}`}
			style={{ ...styleOverride }}
			aria-label={ariaLabel}
		>
			{icon}
			<span className={styles.IconText}>{text}</span>
		</button>
	);
};
