import React from 'react';
import { TableSelectionFeeIcon } from '../../icons';
import { InfoDialog } from './InfoDialog';

export const TableSelectionFeeInfo = () => {
	return (
		<InfoDialog
			variant='feedback'
			title='Seating Selection Fees'
			triggerButton={<TableSelectionFeeIcon />}
			body={
				<>
					<p>The seating selection fee guarantees a specific area for your booking.</p>
					<p>
            This <strong>fee is separate from the deposit</strong>, and is not deducted from your minimum spend
            requirements on food and beverage, if applicable, for large group bookings.
					</p>
				</>
			}
		/>
	);
};
