import { useState } from 'react';

/** Custom hook for persisting state with session storage. */
export const useSessionStorage = <T>(
	key: string,
	initialValue: T
): [T, (newValue: T) => void, () => void] => {
	const [value, setValue] = useState(() => {
		try {
			const storedValue = sessionStorage.getItem(key);
			if (storedValue !== null) {
				return JSON.parse(storedValue);
			}
			else {
				sessionStorage.setItem(key, JSON.stringify(initialValue)); // Set initial value if not exists
				return initialValue;
			}

		}
		catch {
			// ignore
		}
	});

	const updateValue = (newValue: any) => {
		try {
			sessionStorage.setItem(key, JSON.stringify(newValue));
			setValue(newValue);
		}
		catch {
			// ignore
		}
	};

	const removeValue = () => {
		try {
			sessionStorage.removeItem(key);
		}
		catch {
			// ignore
		}
	};

	return [value, updateValue, removeValue];
};
