import React from 'react';
import { Link } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { getCdnImageUri } from '../../helpers/getCDNURI';
import Pencil from '../../icons/Edit.svg';
import DateChecked from '../../icons/date-checked.svg';
import GuestChecked from '../../icons/guest-checked.svg';
import TimeChecked from '../../icons/time-checked.svg';
import { ImageCard, ImageCardAsset, ImageCardHeader, ImageCardHeaderPrimary, ImageCardHeaderTitle } from '../image-card/ImageCard';
import styles from './BookingDetails.module.scss';

type BookingDetailsProps = {
	table: TDR.Table;
	restaurant: TDR.Restaurant;
	reservation: TDR.Reservation;
	displayDate: string;
	displayTime: string;
	checkCutoffs: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const BookingDetails = ({
	table,
	restaurant,
	reservation,
	displayDate,
	displayTime,
	checkCutoffs
}: BookingDetailsProps) => {
	const imageSrc = getCdnImageUri(table.carouselImagePath, {
		width: 359,
		fit: 'crop',
		dpr: 2
	});

	return (
		<div>
			{table && (
				<div className={styles.Card}>
					<ImageCard height={240} brightness={restaurant.brightness ?? 1} contrast={restaurant.contrast ?? 1}>
						<ImageCardAsset src={imageSrc} alt={table.name} />
						<ImageCardHeader>
							<ImageCardHeaderPrimary>
								<ImageCardHeaderTitle>
									<span>{table.name}</span>
								</ImageCardHeaderTitle>
							</ImageCardHeaderPrimary>
						</ImageCardHeader>
					</ImageCard>

					<div>
						<BookingDetailItem
							icon={<img src={GuestChecked} alt='Edit group size' />}
							text={`${reservation.guests} Guest${reservation.guests > 1 ? 's' : ''}`}
						/>

						<BookingDetailItem icon={<img src={DateChecked} alt='Edit date' />} text={displayDate} />

						<BookingDetailItem icon={<img src={TimeChecked} alt='Edit time' />} text={displayTime} />
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
						<Link to='modify' aria-label='Modify Booking' className={styles.Button} onClick={checkCutoffs}>
							<img src={Pencil} alt='Modify Booking' />
							<span>Modify Booking Details</span>
						</Link>

						<Link
							to='modify/seating-options'
							aria-label='Edit seating option'
							className={styles.Button}
							onClick={checkCutoffs}
						>
							<img src={Pencil} alt='Change seat selection' />
							<span>Change Seat Selection</span>
						</Link>
					</div>
				</div>
			)}
		</div>
	);
};

type BookingDetailItemProps = {
	icon?: React.ReactNode;
	text?: string;
};

const BookingDetailItem = ({ icon, text }: BookingDetailItemProps) => {
	return (
		<div className={styles.BookingDetailItem}>
			{icon}
			<span>{text}</span>
		</div>
	);
};
