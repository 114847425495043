import { useMutation, useQueryClient } from '@tanstack/react-query';
import config from '../common/config';

export const MUTATION_KEY = 'deletePendingMods';

export function useDeletePendingMods(reservationID: string) {
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationKey: [MUTATION_KEY, reservationID],
		mutationFn: (reservationId: string) => deletePendingModifications(reservationId),
		gcTime: 0,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['getReservation', reservationID] });
		}
	});

	return mutation;
}

export default async function deletePendingModifications(reservationID: string) {
	const response = await fetch(`${config.apiHost}/deletePendingModifications`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			reservationId: reservationID
		})
	});
	const result = await response.json();
	return result;
}
