import React, { useEffect, useState } from 'react';
import Pause from 'iconsax-react/dist/esm/Pause';
import Play from 'iconsax-react/dist/esm/Play';
import ControlButton from './ControlButton';
import { togglePlayback } from '../helper';

type TogglePlayProps = {
	videoRef: React.MutableRefObject<HTMLVideoElement| null>,
	isVisible: boolean;
	onPlaybackToggle: (isPlaying) => void;
}

const TogglePlay = ({ videoRef, isVisible, onPlaybackToggle }: TogglePlayProps) => {
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		if (videoRef?.current) {
			videoRef.current.addEventListener('play', () => {
				setIsPlaying(true);
				onPlaybackToggle(true);
			});

			videoRef.current.addEventListener('pause', () => {
				setIsPlaying(false);
				onPlaybackToggle(false);
			});
		}

	}, []);

	function handleButtonClick() {
		togglePlayback(videoRef.current);
	}

	return (
		<ControlButton
			type='primary'
			onClick={handleButtonClick}
			title={isPlaying ? 'Pause Button' : 'Play Button'}
			icon={isPlaying ? <Pause /> : <Play />}
			isVisible={isVisible}
		/>
	);
};

export default TogglePlay;


