import React, { useEffect, useState } from 'react';
import { TDR } from 'tdr-common';
import getLargeGroupSettings from '../../api/getLargeGroupSettings';
import { InvoiceItem } from '../checkout/InvoiceItem';
import {
	getTableSelectionFeeItem,
	sumDepositLineItems,
	sumTaxLineItems
} from '../checkout/util';
import { DepositInfo } from '../dialog/info-dialog/DepositInfo';
import { GroupBookingDepositInfo } from '../dialog/info-dialog/GroupBookingDepositInfo';

type OrderSummaryProps = {
	restaurant: TDR.Restaurant;
	invoice: TDR.Invoice;
	tableId: string;
	guests: number;
	isLargeGroup?: boolean;
};

export const OrderSummary = ({
	restaurant,
	invoice,
	tableId,
	guests,
	isLargeGroup
}: OrderSummaryProps) => {
	const { lineItems, deposit, subtotalPriceAdjustments } = invoice;

	const [largeGroupSettings, setLargeGroupSettings] = useState<TDR.LargeGroupSettings>(null);

	const tableFeeItem = getTableSelectionFeeItem(lineItems);
	const depositAmount = Math.max(sumDepositLineItems(lineItems), deposit);
	const taxAmount = sumTaxLineItems(subtotalPriceAdjustments);
	const newBookingFee = tableFeeItem.total;
	const previousBookingFee = invoice.modifiedFrom.subtotal;
	const previousPayment = invoice.modifiedFrom.total;
	const previousDeposit = invoice.modifiedFrom.deposit;

	useEffect(() => {
		if (tableId) {
			getLargeGroupSettings(tableId).then((result) => setLargeGroupSettings(result));
		}
	}, [tableId]);

	return (
		<>
			<ul className='OrderSummary'>
				<InvoiceItem
					title='Previous Booking Fee'
					amount={previousBookingFee}
					currency={restaurant.currency}
					isDivided={false}
					variant={'line-through'}
				/>

				<InvoiceItem
					title='New Booking Fee'
					amount={newBookingFee}
					currency={restaurant.currency}
				/>

				{/* Taxes */}
				<InvoiceItem title='Tax' amount={taxAmount} currency={restaurant.currency} isDivided={false} />

				{/* Deposit */}

				{previousDeposit !== depositAmount && (
					<InvoiceItem
						title='Previous Deposit'
						amount={previousDeposit}
						currency={restaurant.currency}
						variant={'line-through'}
						isDivided={false}
					/>
				)}

				{isLargeGroup ? (
					<InvoiceItem
						title='Group Booking Deposit'
						iconAfter={
							<GroupBookingDepositInfo
								currency={restaurant.currency}
								deposit={largeGroupSettings?.bookingDeposit}
								minimumSpend={largeGroupSettings?.minimumSpend}
							/>
						}
						amount={
							largeGroupSettings?.bookingDeposit?.amount
								? largeGroupSettings?.bookingDeposit?.amount *
                  (largeGroupSettings?.bookingDeposit?.perPerson ? guests : 1)
								: 0
						}
						currency={restaurant.currency}
					/>
				) : (
					<InvoiceItem
						title='Deposit'
						iconAfter={<DepositInfo restaurant={restaurant} invoice={invoice} />}
						amount={depositAmount}
						currency={restaurant.currency}
					/>
				)}

				{/* Total amount */}
				<InvoiceItem
					title={'Total'}
					amount={invoice.total}
					currency={restaurant.currency}
					variant={'bold'}
				/>

				{/* Show previous amount paid */}
				<InvoiceItem
					title='Amount Paid'
					amount={previousPayment}
					currency={restaurant.currency}
					isDivided={false}
					variant={'light'}
				/>

				{/* Outstanding amount */}
				<InvoiceItem
					title={invoice.payable > 0 ? 'Amount Due' : 'Amount to be Refunded'}
					amount={Math.abs(invoice.payable)}
					currency={restaurant.currency}
					isDivided={false}
					variant={'bold'}
				/>
			</ul>
		</>
	);
};
