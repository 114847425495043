import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import { useOutletContext } from 'react-router-dom';
import { DATESTR_FMT, MONTHSTR_FMT, isRestaurantClosed } from 'tdr-common';
import DownArrow from '../../icons/down-chevron.svg';
import { AvailabilityGuardContext, SearchFilters } from '../../routes/AvailabilityGuard';
import { AvailabilityIndicator } from './AvailabilityIndicator';
import styles from './Calendar.module.scss';
import { fromLocalJsDate } from './Calendar.utils';
import { HASH_ELEMENT_IDS } from '../../common/hashElementIds';
import { DAY_IN_MILISECONDS } from '../../common/constants';

export type CalendarProps = {
	onChange?: (date: DateTime, availabilityPercent: number) => void;
	disabled?: boolean;
	filters: SearchFilters;
}

const TODAY = new Date();

const Calendar = ({ onChange, disabled, filters }: CalendarProps) => {
	const { restaurant } = useOutletContext<AvailabilityGuardContext>();

	const THIS_MONTH_STR = DateTime.now().toFormat(MONTHSTR_FMT);
	const [selectedMonth, setSelectedMonth ] = useState<string>(THIS_MONTH_STR);

	const activeStartDate = selectedMonth && DateTime.fromFormat(selectedMonth, MONTHSTR_FMT).toJSDate();

	const handleDateChange = (date: Date) => {
		const luxonDate = fromLocalJsDate(date, restaurant?.timezone);
		return onChange(luxonDate, 100);
	};

	const handleActiveStartDateChange = ({ activeStartDate }) => {
		setSelectedMonth(DateTime.fromJSDate(activeStartDate).toFormat(MONTHSTR_FMT));
	};

	return (
		<div className={styles.Container} translate='no' id={HASH_ELEMENT_IDS.calendar}>
			<ReactCalendar
				locale={(new Intl.NumberFormat())?.resolvedOptions()?.locale || 'en-US'}
				view={'month'}
				value={filters.date ? DateTime.fromFormat(filters.date, DATESTR_FMT).toJSDate() : null}
				onChange={handleDateChange}
				activeStartDate={activeStartDate}
				onActiveStartDateChange={handleActiveStartDateChange}
				nextLabel={<NextMonthIcon />}
				prevLabel={<PrevMonthIcon />}
				calendarType='gregory' // displays Sunday as first day of week
				tileContent={({ date }) => (
					<AvailabilityIndicator
						{...{ date }}
						availability='high'
						disabled={
							date.setHours(0, 0, 0, 0) < TODAY.setHours(0, 0, 0, 0) ||
							disabled
						}
					/>
				)}
				tileDisabled={({ date }) =>
					disabled ||
					isRestaurantClosed(fromLocalJsDate(date), restaurant)
				}
				minDate={TODAY}
				maxDate={disabled ? TODAY : restaurant.bookableDaysInAdvance ? new Date(TODAY.getTime() + (restaurant.bookableDaysInAdvance * DAY_IN_MILISECONDS)) : null} // for disabling month navigation when calendar is disabled
				showNeighboringMonth={false}
				next2Label={null}
				prev2Label={null}
				className={disabled ? 'react-calendar--disabled' : ''}
			/>
		</div>
	);
};

export default Calendar;


/*********************
* Icons
*********************/
const NextMonthIcon = () => <img src={DownArrow} alt={'Next month button'} />;
const PrevMonthIcon = () => <img src={DownArrow} alt={'Previous month button'} />;