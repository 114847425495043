import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { querySnapshotToArray, TDR } from 'tdr-common';

export function useRestaurantBasePolicies(restaurantId: string) {
	const query = useQuery({
		queryKey: ['restaurantBasePolicies', restaurantId],
		queryFn: () => getRestaurantBasePolicies(restaurantId)
	});
	return query;
}

async function getRestaurantBasePolicies(restaurantId: string): Promise<TDR.PricingPolicy[]> {
	const db = getFirestore();
	const q = query(collection(db, 'PricingPolicies'), where('restaurantId', '==', restaurantId), where('active', '==', true));
	const querySnapshot = await getDocs(q);
	return querySnapshotToArray(querySnapshot) || [];
}