import React from 'react';
import { TDR } from 'tdr-common';
import { useTimeslot } from '../../api/getTimeslot';
import { getCdnImageUri } from '../../helpers/getCDNURI';
import { SearchFilters } from '../../routes/AvailabilityGuard';
import {
	ImageCard,
	ImageCardAsset,
	ImageCardFooter,
	ImageCardHeader,
	ImageCardHeaderPrimary,
	ImageCardHeaderTitle
} from '../image-card/ImageCard';
import TableInfo from '../table-drawer/TableInfo';
import styles from './CarouselCard.module.scss';
import { StatusTag } from './StatusTag';

type CarouselCardProps = {
	restaurant: TDR.Restaurant;
	table: TDR.Table;
	searchFilters: SearchFilters;
	disabled?: boolean;
	available: boolean;
	conflictingFilter?: 'date' | 'time' | '';
	onClick: () => void;
	tableSearchResult?: TDR.Availability.searchResult;
} & React.InputHTMLAttributes<HTMLInputElement>;

const CarouselCard = ({
	restaurant,
	table,
	searchFilters,
	available,
	onClick,
	disabled = false,
	conflictingFilter = '',
	tableSearchResult
}: CarouselCardProps) => {
	const { data } = useTimeslot(table?.id, searchFilters.date, restaurant.id, searchFilters.time);

	const timeslot = data?.timeSlot;
	const showStatusTags = !!searchFilters.groupSize && !!tableSearchResult;

	const imageSrc = getCdnImageUri(table.carouselImagePath, {
		width: 359,
		fit: 'crop',
		dpr: 2
	});

	return (
		<li style={{ listStyle: 'none' }}>
			<button
				className={styles.Button}
				role='link'
				onClick={onClick}
				tabIndex={0}
				onKeyDown={(e) => ['Enter', ' '].includes(e.key) && onClick()}
				data-slug={table.slug}
				data-cy='tableCarouselItem'
			>
				<ImageCard height={240} brightness={restaurant.brightness ?? 1} contrast={restaurant.contrast ?? 1}>
					<ImageCardAsset src={imageSrc} alt={table.name} />

					<ImageCardHeader>
						<ImageCardHeaderPrimary>
							<ImageCardHeaderTitle>{table.name}</ImageCardHeaderTitle>
							<StatusTag
								show={showStatusTags}
								tableAvailable={!disabled && available}
								supportLargeGroup={table.supportLargeGroup || false}
								onClick={onClick}
								conflictingFilter={conflictingFilter}
							/>
						</ImageCardHeaderPrimary>
					</ImageCardHeader>

					<ImageCardFooter>
						<TableInfo
							minGuests={table.minGuests}
							maxGuests={table.maxGuests}
							shortForm={true}
							resDuration={timeslot?.resDuration}
						/>
					</ImageCardFooter>
				</ImageCard>
			</button>
		</li>
	);
};

export default CarouselCard;
