import React, { ReactElement, ReactNode } from 'react';
import { To, resolvePath, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { ErrorResponse } from '../../common/ErrorResponse';
import CTALink from '../../components/buttons/CTALink';
import PageNotFoundIcon from '../../icons/error-grey.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';

export interface ValidationErrorPageProps {
	to?: To;
	text?: string;
	onClick?: () => void;
	children?: ReactNode;
	error: ErrorResponse<TDR.ApiResponse>;
}

export function ValidationErrorPage({ to, error, text, children, onClick }: ValidationErrorPageProps) {
	// we can't deconstruct here ({ restaurant }) because the restaurant itself my be not found
	// which would make the context undefined

	const context = useOutletContext<RestaurantGuardOutletContext>();
	const { restaurantSlug } = useParams();
	const location = useLocation();

	const errorMessage = error.body?.message || 'Something went wrong on our end. Try again later.';

	let button: ReactElement | null = null;

	if (to) {
		const path = resolvePath(to, location.pathname);

		button = <CTALink onClick={onClick} text={text || 'Go Back'} to={path} />;
	}
	else if (context?.restaurant) {
		button = <CTALink onClick={onClick} text={text || 'Go to Homepage'} to={`/${context?.restaurant.slug}`} />;
	}
	else if (restaurantSlug) {
		button = <CTALink onClick={onClick} text={text || 'Go to Homepage'} to={`/${restaurantSlug}`} />;
	}

	return (
		<ResultPageLayout headerIcon={PageNotFoundIcon} iconAltText='Broken Link Icon'>
			<ResultPageLayout.Title>Oops</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>{errorMessage}</p>
			</ResultPageLayout.Body>

			<ResultPageLayout.Footer>
				{button}
				{children}
			</ResultPageLayout.Footer>
		</ResultPageLayout>
	);
}
