import * as DialogPrimitive from '@radix-ui/react-dialog';
import React from 'react';
import { SquareIconButton } from '../buttons/SquareIconButton';
import { DialogChildProps } from './types';

import styles from './Dialog.module.scss';

const DEFAULT_CONTAINER_ID = 'sidebar-container';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
React.ElementRef<typeof DialogPrimitive.Overlay>,
React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ ...props }, ref) => <DialogPrimitive.Overlay ref={ref} className={styles.DialogOverlay} {...props} />);
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
React.ElementRef<typeof DialogPrimitive.Content>,
React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
	containerId?: string;
	hasCloseButton?: boolean;
	fillContainerWidth?: boolean;
}
>(
	(
		{ containerId = DEFAULT_CONTAINER_ID, hasCloseButton = true, fillContainerWidth = false, children, ...props },
		ref
	) => (
		<DialogPortal container={document.getElementById(containerId)}>
			<DialogOverlay className={styles.DialogOverlay} />
			<DialogPrimitive.Content
				ref={ref}
				className={`${styles.DialogContent} ${fillContainerWidth ? styles.FillContainerWidth : ''}`}
				{...props}
			>
				{hasCloseButton && (
					<DialogPrimitive.Close asChild>
						<div className={styles.DialogClose}>
							<SquareIconButton variant='exit' />
						</div>
					</DialogPrimitive.Close>
				)}
				{children}
			</DialogPrimitive.Content>
		</DialogPortal>
	)
);
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogTitle = React.forwardRef<
React.ElementRef<typeof DialogPrimitive.Title>,
React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ ...props }, ref) => (
	<DialogPrimitive.Title
		ref={ref}
		className={styles.DialogTitle}
		{...props}
	/>
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
React.ElementRef<typeof DialogPrimitive.Description>,
React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ ...props }, ref) => <DialogPrimitive.Description ref={ref} className={styles.DialogDescription} {...props} />);
DialogDescription.displayName = DialogPrimitive.Description.displayName;

const DialogHeader = ({ children }: DialogChildProps) => {
	return <div className={styles.DialogHeader}>{children}</div>;
};

const DialogBody = ({ children }: DialogChildProps) => {
	return <div className={styles.DialogBody}>{children}</div>;
};

const DialogFooter = ({ children }: DialogChildProps) => {
	return <div className={styles.DialogFooter}>{children}</div>;
};

export {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogOverlay,
	DialogPortal,
	DialogHeader,
	DialogBody,
	DialogFooter,
	DialogTitle,
	DialogTrigger
};
