import React, { useState } from 'react';
import styles from './InputStepper.module.scss';
import { SquareIconButton } from '../buttons/SquareIconButton';

type InputStepperProps = {
	onChange: (value: number) => void;
	initialCount?: number;
};

export const InputStepper = ({ onChange, initialCount = 0 }: InputStepperProps) => {
	const [count, setCount] = useState(initialCount);
	return (
		<div className={styles.Container}>
			<SquareIconButton
				variant='minus'
				onClick={() => {
					const newValue = count - 1;
					setCount(newValue);
					onChange(newValue);
				}}
				disabled={count === 0}
			/>
			<span className={styles.Count}>{count}</span>
			<SquareIconButton
				variant='add'
				onClick={() => {
					const newValue = count + 1;
					setCount(newValue);
					onChange(newValue);
				}}
			/>
		</div>
	);
};
