import { collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';
import { TDR, querySnapshotToArray } from 'tdr-common';

export default async function getLargeGroupSettings (tableId: string): Promise<TDR.LargeGroupSettings> {
	if(!tableId) {
		return Promise.reject('table Id undefined');
	}
	const db = getFirestore();
	const q = query(collection(db, 'LargeGroupSettings'), where('tableId', '==', tableId), limit(1));
	const querySnapshot = await getDocs(q);
	return querySnapshotToArray(querySnapshot)?.[0] || null;
}