import { useQuery } from '@tanstack/react-query';
import { TDR } from 'tdr-common';
import { useDebounce } from 'use-debounce';
import config from '../../common/config';
import Availability = TDR.Availability;

export const useSupportedGroupSizes = (input: Availability.getSupportedGroupSizesInput) => {
	const { tables } = input;

	const query = useQuery({
		enabled: !!tables,
		queryKey: ['supportedGroupSizes', tables],
		queryFn: () => getSupportedGroupSizes({ tables })
	});

	return {
		supportedGroupSizes: query.data?.supportedGroupSizes,
		...query
	};
};

async function getSupportedGroupSizes(input: Availability.getSupportedGroupSizesInput): Promise<Availability.getSupportedTableSizeOutput> {
	const response = await fetch(`${config.apiHost}/getSupportedGroupSizes`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as Availability.getSupportedTableSizeOutput;
	if (!response.ok) {
		throw response;
	}
	else if (!responsePayload?.success) {
		throw Error('Could Not Supported Group Sizes');
	}

	return responsePayload;
}

export function useCalendarAvailability(input: Availability.getCalendarAvailabilityInput, isReleasing = false) {
	const { tables, month, restaurantId, ignoreAvailability, time, reservationId } = input;
	const debouncedMonth = useDebounce(month, 300);
	const query = useQuery({
		enabled: !isReleasing,
		queryKey: ['calendarAvailability', tables, debouncedMonth[0], restaurantId, ignoreAvailability, time, reservationId],
		queryFn: () => getCalendarAvailability({ month: debouncedMonth[0], tables, restaurantId, ignoreAvailability, time, reservationId })
	});

	return query;
}

async function getCalendarAvailability(input: Availability.getCalendarAvailabilityInput): Promise<Availability.getCalendarAvailabilityOutput> {
	const response = await fetch(`${config.apiHost}/getCalendarAvailability`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as Availability.getCalendarAvailabilityOutput;

	if (!response.ok) {
		throw response;
	}
	else if (!responsePayload?.success) {
		throw Error('Could Not Get Monthly Availability');
	}

	return responsePayload;
}

