import React, { useEffect } from 'react';
import QuestionIcon from '../../icons/question-mark.svg';
import { Notice } from './Notice';
import { analytics } from '../../helpers/analytics';

type LowInventoryNoticeProps = {
	guests: number;
};

export const LowInventoryNotice = ({ guests }: LowInventoryNoticeProps) => {

	useEffect(() => {
		analytics.track('Notice Viewed', {
			notice: 'LowInventoryNotice',
			guests
		});
	}, []);

	const message = (
		<p>
      There are not many seating options that can accommodate{' '}
			<span>
				{guests} guest{guests > 1 ? 's' : ''}
			</span>
      . Try adjusting your group size for more options.
		</p>
	);

	return (
		<Notice body={message} icon={<img src={QuestionIcon} alt={'warning'} />} position='bottom' />
	);
};
