import React from 'react';
import SuccessIcon from '../../icons/check-circle.svg';

type InputValidationSuccessProps = {
	message?: string;
}

export const InputValidationSuccess = ({ message }: InputValidationSuccessProps) => {
	return (
		<div className='InputValidation'>
			<img className='InputValidation__Icon' src={SuccessIcon} alt='Success icon'/>

			<div role='status' className="InputValidation__Message InputValidation__Message--success">{message}</div>
		</div>
	);
};
