import React from 'react';
import SearchContainer from './SearchContainer';
import { useSearch } from '../../api/availability/search';
import { useOutletContext } from 'react-router-dom';
import { AvailabilityGuardContext } from '../../routes/AvailabilityGuard';
import { BookNowFooter } from './BookNowFooter';
import { TableGuardOutletContext } from '../../routes/TableGuard';
import { useSupportedGroupSizes } from '../../api/availability/availability';
import { buildGroupSizeOptions } from '../party-size-selector/PartySizeSelector';
import { useTimeslot } from '../../api/getTimeslot';

const SearchAvailabilities = () => {
	const { restaurant, searchFilters } = useOutletContext<AvailabilityGuardContext>();
	const { table: selectedTable } = useOutletContext<TableGuardOutletContext>();

	const searchSpace = selectedTable ? [selectedTable] : [];

	const { results, isLoading: isSearching } = useSearch({
		params: searchFilters,
		restaurantId: restaurant.id,
		space: searchSpace
	});

	const { data } = useTimeslot(
		selectedTable?.id,
		searchFilters.date,
		restaurant.id,
		searchFilters.time
	);
	const { supportedGroupSizes, isLoading: isLoadingGroupSizes } = useSupportedGroupSizes({
		tables: [selectedTable]
	});
	const [groupSizeOptions] = buildGroupSizeOptions(supportedGroupSizes, null, false);

	const timeslot = data?.timeSlot;

	return (
		<SearchContainer
			isLoading={isSearching || isLoadingGroupSizes}
			results={results}
			tableIds={[selectedTable.id]}
			groupSizeOptions={groupSizeOptions}
			footer={(
				<BookNowFooter
					disabled={isSearching || isLoadingGroupSizes}
					priceAmountTotaled={timeslot?.isPerGuest ? timeslot.regularPrice * searchFilters?.groupSize : timeslot?.regularPrice}
					priceAmount={timeslot?.regularPrice}
					displayUnit={timeslot?.isPerGuest ? 'guest' : `${selectedTable?.label ?? 'table'}`}
				/>
			)}
		/>
	);
};

export default SearchAvailabilities;