import React from 'react';
import { IconButtonSquare } from '../buttons/IconButton';
import styles from './ProximityInfo.module.scss';
import { useViewToggle } from '../../context/ViewToggleProvider';
import { useScreenSize } from '../../hooks/useScreenSize';

type ProximityInfoContainerProps = {
	title: string;
	body: any;
	icon: string;
	onClick: () => void;
	replaceIconWithLabel?: boolean;
};
export const ProximityInfoContainer: React.FC<ProximityInfoContainerProps> = ({ title, body, icon, onClick, replaceIconWithLabel }) => {
	const { isVirtualView } = useViewToggle();
	const { isSmallScreen } = useScreenSize();

	return (
		<div className={`${styles.Container} ${isVirtualView || isSmallScreen ? styles['Container--virtual-view'] : ''}`} onClick={onClick}>
			<div className={styles.Info}>
				<div className={styles.Title}>{title}</div>
				<div className={styles.Body}>{body}</div>
			</div>
			{replaceIconWithLabel? (
				<span className={styles.Label}>Details</span>
			): (
				<IconButtonSquare ariaLabel={`Go to ${title} button`} icon={icon} onClick={onClick} />
			)}
		</div>
	);
};
