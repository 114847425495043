import { useQuery } from '@tanstack/react-query';
import { TDR } from 'tdr-common';
import config from '../common/config';
import { getTableDto, getTableWithPolicies } from './getTable';

type GetRestaurantDto = {
	slug: string;
	searchParams?: {
		groupSize?: number;
		date?: string;
		time?: string;
	};
}
type GetAvailabilityRestrictingPoliciesDto = {
	slug?: string;
	id?: string;
	searchParams?: {
		groupSize?: number;
		date?: string;
		time?: string;
	};
}
export function useEntityWithAvailabilityRestrictingPolicies(input: GetAvailabilityRestrictingPoliciesDto){
	const query = useQuery({
		enabled: !!input?.slug || !!input?.id,
		queryKey: ['getAvailabilityRestrictingPolicies', input],
		queryFn: () => getEntityWithAvailabilityRestrictingPolicies(input)
	});
	return query;
}

async function getEntityWithAvailabilityRestrictingPolicies(input: GetAvailabilityRestrictingPoliciesDto): Promise<TDR.Restaurant | TDR.TableWithPolicies> {
	return input.id ? getTableWithPolicies(input as getTableDto) : getRestaurantBySlug(input as GetRestaurantDto);
}

export function useRestaurantBySlug(input: GetRestaurantDto) {

	const query = useQuery({
		enabled: !!input?.slug,
		queryKey: ['getRestaurantBySlug', input],
		queryFn: () => getRestaurantBySlug(input)
	});

	return query;
}

async function getRestaurantBySlug(
	input: GetRestaurantDto
): Promise<TDR.Restaurant> {
	const response = await fetch(`${config.adminApiHost}/restaurants`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	if (response.status === 404) {
		return null;
	}

	const responsePayload = await response.json() as TDR.Restaurant | null;

	return responsePayload;
}