import UAParser from 'ua-parser-js';
const parser = new UAParser();
export const ExtraUserContext = (): Record<string, unknown> => {
	//Manually retrieve extra context values from the user session that are not automatically populated by segment:
	//https://segment.com/docs/connections/spec/common/#context
	// Get Screen Size
	const screen = {
		width:
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth,
		height:
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight,
		density: window.devicePixelRatio
	};

	// Get Timezone
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	// Use UAParser to get Device Info and OS Info
	const device = {
		type: parser.getDevice().type || 'web', // This might be 'mobile', 'tablet', etc.
		model: parser.getDevice().model
	};

	let OS;
	try {
		OS = parser.getOS();
	}
	catch (e) {
		//ignore
	}

	let locale;
	try {
		if (navigator.languages !== undefined) {
			locale = navigator.languages[0];
		}
	}
	catch (e) {
		//ignore
	}

	const os = {
		name: OS?.name,
		version: OS?.version
	};

	return {
		'Locale': locale,
		'$screen_dpi': screen?.density,
		'$timezone': timezone,
		'Device Type': device?.type,
		'$model': device?.model,
		'$os': os?.name,
		'$os_version': os?.version
	};
};
