import merge from 'lodash/merge';
import { MiddlewareFunction } from '../../components/cookie-consent/types';
import { ExtraUserContext } from './utils';

export const appVersionMiddleware: MiddlewareFunction = ({ payload, next }) => {
	if (payload.obj.properties) {
		payload.obj.properties.version = process.env.RELEASE_VERSION;
	}
	else {
		payload.obj.properties = { version: process.env.RELEASE_VERSION };
	}

	next(payload);
};

export const userContextMiddleware: MiddlewareFunction = ({ payload, next }) => {
	if (payload.obj.properties) {
		payload.obj.properties = merge(ExtraUserContext(), payload.obj.properties);
	}
	else {
		payload.obj.properties = ExtraUserContext();
	}

	next(payload);
};

export const checkoutMiddlewareBuilder: (checkoutId: string) => MiddlewareFunction = (checkoutId: string) => {
	return ({ payload, next }) => {
		if (payload.obj.properties) {
			payload.obj.properties.checkoutId = checkoutId;
		}
		else {
			payload.obj.properties = { checkoutId };
		}

		next(payload);
	};
};

export const tableMiddlewareBuilder: (tableId: string, tableName: string) => MiddlewareFunction = (tableId: string, tableName: string) => {
	return ({ payload, next }) => {
		if (payload.obj.properties) {
			payload.obj.properties.tableId = tableId;
			payload.obj.properties.tableName = tableName;
		}
		else {
			payload.obj.properties = { tableId, tableName };
		}

		next(payload);
	};
};

export const restaurantMiddlewareBuilder: (restaurantId: string, restaurantName: string) => MiddlewareFunction = (restaurantId: string, restaurantName: string) => {
	return ({ payload, next }) => {
		if (payload.obj.properties) {
			payload.obj.properties.restaurantId = restaurantId;
			payload.obj.properties.restaurantName = restaurantName;
		}
		else {
			payload.obj.properties = { restaurantId, restaurantName };
		}

		next(payload);
	};
};

export const reservationMiddlewareBuilder: (reservationId: string) => MiddlewareFunction = (reservationId: string) => {
	return ({ payload, next }) => {
		if (payload.obj.properties) {
			payload.obj.properties.reservationId = reservationId;
		}
		else {
			payload.obj.properties = { reservationId };
		}

		next(payload);
	};
};

export const sanitizedPropertiesMiddleware: MiddlewareFunction = ({ payload, next }) => {
	//Safe guard because sometimes we spread entities into event properties and 'id' is a reserved word
	// i.e. analytics.track("blah", {...reservation})
	if (payload.obj.properties) {
		payload.obj.properties = { ...payload.obj.properties, document_id: payload.obj.properties.id, id: undefined };
		delete payload.obj.properties.id;
	}

	next(payload);
};