import React from 'react';
import { Navigate, useOutletContext, useParams } from 'react-router-dom';
import { useTableBySlug } from '../api/getTableBySlug';
import { URLParams } from '../common/types';
import WholeScreenLoading from '../components/whole-screen-loading/WholeScreenLoading';
import { NotFoundErrorPage } from '../pages/errors/NotFoundErrorPage';
import { RestaurantGuardOutletContext } from './RestaurantGuard';


export const V2TableLinksGuard = () => {
	const { tableSlug } = useParams<URLParams>();
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();
	const { data: table, isLoading: isTableLoading, isError } = useTableBySlug(restaurant?.id, tableSlug);

	if (isTableLoading && !isError) {
		return <WholeScreenLoading />;
	}
	else if (!isTableLoading && table?.slug) {
		return (
			<Navigate
				to={{
					pathname: `/${restaurant.slug}/explore/${table.slug}`
				}}
				replace
			/>
		);
	}
	else {
		return <NotFoundErrorPage />;
	}
};