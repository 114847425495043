import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';
import ArrowLeft2 from 'iconsax-react/dist/esm/ArrowLeft2';
import InfoCircle from 'iconsax-react/dist/esm/InfoCircle';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import toPairs from 'lodash/toPairs';
import toString from 'lodash/toString';
import { Color, CommonMpSdk, Mattertag, ShowcaseEmbedWindow, Tour } from 'matterport';
import { MpSdk } from 'matterport/dist/sdk';
import { default as React, useEffect, useReducer, useRef, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { policyConditionApplies, TDR } from 'tdr-common';
import { useSearch } from '../../api/availability/search';
import config from '../../common/config';
import { URLParams } from '../../common/types';
import { useViewToggle } from '../../context/ViewToggleProvider';
import { useMatterport } from '../../context/new/MatterportContext';
import { analytics, MP_TAG_CLICKED } from '../../helpers/analytics';
import { withTimeout } from '../../helpers/retry';
import { useScreenSize } from '../../hooks/useScreenSize';
import available from '../../images/pins/available.svg';
import normal from '../../images/pins/default.svg';
import disabled from '../../images/pins/disabled.svg';
import limited from '../../images/pins/limited.svg';
import unavailable from '../../images/pins/unavailable.svg';
import { SearchFilters, parseSearchParamsFilters } from '../../routes/AvailabilityGuard';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';
import { AnimatedImageGallery } from '../animated-image-gallery/AnimatedImageGallery';
import { CTAButton } from '../buttons';
import { MatterportEducationalOverlay } from '../matterport-educational-overlay/MatterportEducationalOverlay';
import { Skeleton } from '../skeleton/Skeleton';
import { MatterportEnvironmentNotice } from '../table-proximity/MatterportEnvironmentNotice';
import styles from './Matterport.module.scss';
import { DateTime } from 'luxon';


const PINS = {
	default: normal,
	disabled,
	available,
	limited,
	unavailable
};
export interface MatterportLayoutProps {
	restaurantId: string;
	brightness?: number;
	contrast?: number;
	modelId: string;
	condition: TDR.PricingPolicy.Condition
}

export const MatterportEnvironment = ({ restaurantId, brightness = 1, contrast = 1, modelId, condition }: MatterportLayoutProps) => {
	const { tables, restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	const { isError, setIsError, isLoading, setIsLoading } = useMatterport();
	const { isSmallScreen } = useScreenSize();
	const { isVirtualView } = useViewToggle();

	const { tableSlug } = useParams<URLParams>();
	const [searchParams] = useSearchParams();

	const [sdk, setSdk] = useState<MpSdk>(null);
	const [tableMap, setTableMap] = useState<Record<string, TDR.Table>>({});
	const [searchFilters, setSearchFilters] = useState<SearchFilters>();
	const [selectedTable, setSelectedTable] = useState<TDR.Table>(null);

	// If landing directly on table details page, we need to wait for matterport navigation to settle before setting up the overlay
	const [isSettled, setIsSettled] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	const locationRef = useRef(location);
	const tableMapRef = useRef(tableMap);
	const matterportFrame = useRef<HTMLIFrameElement>(null);
	const isMountedRef = useRef(true);

	useEffect(() => {
		locationRef.current = location;
	}, [location]);

	useEffect(() => {
		tableMapRef.current = tableMap;
	}, [tableMap]);

	useEffect(() => {
		if (searchParams) {
			setSearchFilters(parseSearchParamsFilters(searchParams, restaurant?.timezone));
		}
	}, [searchParams]);

	const { results } = useSearch({
		params: searchFilters,
		restaurantId: restaurantId,
		space: tables
	});

	// allow a forced refresh
	const [lastRefresh] = useReducer(() => {
		return new Date().getTime();
	}, new Date().getTime());

	// useEffect(() => {
	// 	if(closestVisibleTable && parrot) {
	// 		parrot.obj3D.position.set(closestVisibleTable.pos.x, closestVisibleTable.pos.y, closestVisibleTable.pos.z); //
	// 	}
	// }, [closestVisibleTable, parrot]);

	useEffect(() => {
		if (!sdk || isEmpty(tables) || !results?.metadata?.time || !tableMap) {
			return;
		}

		tables.forEach((contextTable) => {
			const table = tableMap[contextTable.slug];
			const result = results.resultMap[contextTable.id];
			let newPinType: TDR.PinType = TDR.PinType.DISABLED;
			if (!table || !result) {
				return;
			}
			if (result['unSet']?.length === 3) {
				newPinType = TDR.PinType.DEFAULT;
			}
			else if (result['match']?.includes('groupSize')) {
				if (result['conflict']?.length) {
					newPinType = TDR.PinType.LIMITED;
				}
				else {
					newPinType = TDR.PinType.AVAILABLE;
				}
			}

			if (table.pinType !== newPinType) {
				table.pinType = newPinType;
				return sdk?.Tag?.editIcon?.(table.sid, newPinType);
			}
		});
	}, [sdk, results?.metadata?.time, tableMap]);

	// sdk && sdk.App.state.waitUntil(function (state) {
	// 	return state.phase === 'appphase.playing' as CommonMpSdk.App.Phase.PLAYING;
	// });

	const gotoTable = async (sdk: MpSdk, table: TDR.Table): Promise<void | string> => {
		// match to our locally enhanced table if possible
		table = tableMap[table?.slug || table?.sid] || table;

		if (isNumber(table?.tourStop) && table?.tourStop >= 0) {
			return sdk.Tour.step(table.tourStop).catch((err) => {
				console.warn(err);
				sdk.Tour.stop();
			});
		}
		else if (table?.sid) {
			return sdk.Mattertag.navigateToTag(table.sid, sdk.Mattertag.Transition.FLY).catch((err) => console.warn(err));
		}
		else {
			return Promise.resolve();
		}
	};

	const addTable = async (
		sdk: MpSdk,
		table: TDR.Table,
		options: {
			preventPopup: boolean;
			color: Color;
		}
	): Promise<{ table: TDR.Table; newMap: any }> => {
		const sids: string[] = await sdk.Tag.add({
			label: table.name,
			anchorPosition: { ...table.pos },
			//disable the stem line.
			stemVector: { x: 0, y: 0, z: 0 },
			iconId: 'default'
		});

		// theoretically, I could have made a single call to `sdk.add` with ALL the tables, BUT
		// I don't know for a fact that batches the matterport IDs in the same order, so I'm going one at a time
		const [sid] = sids;
		const newMap = {};

		newMap[sid] = table;
		newMap[table.slug] = table;

		table['sid'] = sid;

		if (options.preventPopup) {
			await sdk.Tag.allowAction(sid, {
				docking: false,
				navigating: true,
				opening: false
			});
		}

		return { table, newMap };
	};

	const setTables = async (sdk: MpSdk, tables: TDR.Table[]): Promise<TDR.Table[]> => {
		try {
			const tourSnapshots: Tour.Snapshot[] = await sdk.Tour.getData();

			const result = await Promise.all(
				tables.filter(table => (modelId === table.model) && !table.disabled && !table.deleted).map((table) => {
					const stopIndex = tourSnapshots.findIndex((stop) => (stop.name === table.name) || (stop.name === table.internalName));
					return addTable(
						sdk,
						{ ...table, tourStop: stopIndex },
						{
							preventPopup: true,
							color: null
						}
					);
				})
			);

			let mergedMap = {};
			result
				.map((r) => r.newMap)
				.forEach((newMap) => {
					mergedMap = { ...mergedMap, ...newMap };
				});
			setTableMap(mergedMap);
		}
		catch (error) {
			setIsError(true);
			captureExceptionWithManualDetails(error, 'inside setTables');
		}

		return tables;
	};

	const onTagClick = (sid: string, tableMap: Record<string, TDR.Table>) => {
		if (!tableMap) {
			return;
		}
		const table = tableMap[sid];

		analytics.track(MP_TAG_CLICKED, {
			tableSlug: table.slug
		});

		navigate({
			pathname: `explore/${table.slug}`,
			search: locationRef.current.search
		});
	};

	//Used for debugging bad promises
	function captureExceptionWithManualDetails(e: Error, label: string) {
		console.error(label);
		const stackTrace = new Error().stack;
		console.error(stackTrace);
		captureException(e);
	}

	useEffect(() => {
		if (tableMap && !isNull(sdk)) {
			const handleClick = (sid: string) => onTagClick(sid, tableMapRef.current);
			sdk.on('tag.click' as Mattertag.Event.CLICK, handleClick);

			return () => {
				sdk.off('tag.click' as Mattertag.Event.CLICK, handleClick);
			};
		}
	}, [sdk]);

	useEffect(() => {
		if (!isNull(sdk) && !!tables) {
			const setup = async () => {
				// load the pin images
				try {
					await Promise.all(
						toPairs(PINS).map(([key, value]) => {
							return sdk.Asset.registerTexture(key, value);
						})
					);
				}
				catch (e) {
					if (e) {
						Sentry.captureMessage(`Unable to register all textures: ${e.message}`);
					}
				}

				await removeAllTags();
				await setTables(sdk, tables);
				// const [sceneObject] = await (sdk as any).Scene.createObjects(1);
				//
				// // add a scene node for the fbx model
				// const gltfNode = sceneObject.addNode('parrot-node');
				// setParrot(gltfNode);
				//
				// // adjust the position of the scene node
				// // add the gltf loader component that loads a parrot model. Adjust the model's scale to make it fit inside the model.
				// const gltfComponent = gltfNode.addComponent('mp.gltfLoader', {
				// 	url: 'https://cdn.jsdelivr.net/gh/mrdoob/three.js@dev/examples/models/gltf/Parrot.glb',
				// 	localScale: {
				// 		x: 0.03,
				// 		y: 0.03,
				// 		z: 0.03
				// 	}
				// }, 'parrot component');
				//
				// // Add a path id 'gltfUrl' to the gltf component url property (not used in the this example).
				// sceneObject.addInputPath(gltfComponent, 'url', 'gltfUrl');
				//
				// // add another scene node to contain the light objects.
				// const lightsNode = sceneObject.addNode();
				//
				// // Add directional and ambient lights
				// const directionalLightComponet = lightsNode.addComponent('mp.directionalLight', {
				// 	color: { r: 0.7, g: 0.7, b: 0.7 }
				// });
				// lightsNode.addComponent('mp.ambientLight', {
				// 	intensity: 0.5,
				// 	color: { r: 1.0, g: 1.0, b: 1.0 }
				// });
				//
				// // Add a path id 'ambientIntensity' to the intensity property of the directional light component.
				// // The path will be used to set the value later.
				// const ambientIntensityPath = sceneObject.addInputPath(directionalLightComponet, 'intensity', 'ambientIntensity');
				//
				// // Start the scene object and its nodes.
				// sceneObject.start();
			};

			setup();
		}
	}, [sdk, tables]);

	useEffect(() => {
		if (!isNull(tableSlug) && tables && tableMap) {
			const table = tables.find((table) => table.slug === tableSlug);
			if (table) {
				setSelectedTable(table);
				gotoTable(sdk, table);
			}
			else {
				setSelectedTable(null);
			}
		}
	}, [tableSlug, tables, tableMap]);

	const connect = async () => {
		const mpSDK = (matterportFrame?.current?.contentWindow as ShowcaseEmbedWindow)?.MP_SDK;
		return mpSDK?.connect?.(matterportFrame.current, config.matterport.sdkKey, '');
	};
	async function removeAllTags() {
		return sdk.Mattertag.getData()
			.then((tags) => {
				return sdk.Tag.remove(...tags.map((tag) => tag.sid));
			})
			.catch((e) => {
				captureExceptionWithManualDetails(e, 'reloadTags');
			});
	}

	useEffect(() => {
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	// Disable matterport during tests
	if (config.matterport.disabled) {
		return (
			<>
				<MatterportDisabled />
			</>
		);
	}

	if (isError) {
		return isSmallScreen ? null : <AnimatedImageGallery staticImage={true} restaurant={restaurant} tables={tables} />;
	}
	const shouldDisplay = selectedTable ? selectedTable?.model === modelId : policyConditionApplies(condition, { reservation: { time: DateTime.fromISO(`${searchFilters?.date}T${searchFilters?.time ?? '00:00'}`) } });

	return (
		<span style={shouldDisplay ? {} : { display: 'none' }}>
			{isLoading && <Skeleton width='100%' height='100%' />}
			<iframe
				title='Matterport restaurant 3d walkthrough'
				aria-hidden='true'
				ref={matterportFrame}
				id='mpframe'
				tabIndex={-1}
				className={styles.matterport}
				allowFullScreen
				allow='xr-spatial-tracking'
				// style={{ filter: `brightness(${brightness}) contrast(${contrast})`, border: 0, visibility: is3DSpaceReady ? 'visible' : 'hidden' }}
				style={{ filter: `brightness(${brightness}) contrast(${contrast})`, border: 0 }}
				src={`/bundle/showcase.html?${new URLSearchParams({
					m: modelId,
					hr: toString(0),
					qs: toString(1),
					title: toString(0),
					play: toString(1),
					newtags: '1',
					log: '0',
					brand: '0',
					tour: '0',
					ts: '-1',
					gt: '0',
					mls: '2',
					tagNav: '0',
					search: '0',
					tourcta: '0',
					vr: '0',
					// this is the only way to force the old tags implementation https://matterport.github.io/showcase-sdk/earlyaccess_home.html#get-the-early-access-bundle-sdk
					applicationKey: config.matterport.sdkKey,
					last_refresh: toString(lastRefresh) // change src with a harmless param to force a reload
				}).toString()}`}
				onError={() => {
					setIsError(true);
					setIsLoading(false);
				}}
				onLoad={async () => {
					try {
						const sdk: CommonMpSdk = await withTimeout(7000, connect());

						if (sdk) {
							sdk.App.state.subscribe((appState) => {
								if (appState.phase === sdk.App.Phase.PLAYING) {
									// Wait for camera to settle before showing overlay
									setTimeout(() => {
										setIsSettled(true);
									}, 6000);
									setTimeout(() => {
										sdk.Camera.zoomBy(-1);
									}, 100);
								}
							});

							if (isMountedRef.current) {
								setSdk(sdk);
							}
						}
						else {
							throw new Error(`Unable to load the matterport SDK, the connect call returned: ${sdk}`);
						}
					}
					catch (e) {
						setIsError(true);
						if (!(e && typeof e === 'string' && e.includes('Timed out'))) {
							const eventID = captureException(e);
							analytics.track('Matterport SDK Loading Failed', {
								sentryErrorID: eventID
							});
						}
						else {
							console.warn('Matterport SDK timeout');
							analytics.track('Matterport SDK Loading Failed', {
								message: e
							});
						}
					}
					finally {
						setIsLoading(false);
					}
				}}
			/>

			{isVirtualView || isSmallScreen ? <BackButton /> : null}

			<MatterportEnvironmentNotice sdk={sdk} tables={tables} gotoTable={gotoTable} />

			{!isSmallScreen && isSettled && <MatterportEducationalOverlay sdk={sdk} restaurantName={restaurant.name} />}
		</span>
	);
};

const MatterportDisabled = () => {
	return (
		<p className={styles['matterport-disabled-msg']}>
			<InfoCircle />
      Matterport iframe disabled for testing
		</p>
	);
};

const BackButton = () => {
	const { turnOffVirtualView } = useViewToggle();

	return (
		<div className={styles.BackButton}>
			<CTAButton variant='transparent' buttonText='Back' iconBefore={<ArrowLeft2 />} onClick={turnOffVirtualView} />
		</div>
	);
};
