import React from 'react';
import { TDR } from 'tdr-common';
import styles from './buttons.module.scss';
import { analytics } from '../../helpers/analytics';

const CustomerSupportLink = () => {
	return (
		<a
			href={`mailto:${TDR.SUPPORT_EMAIL}`}
			className={`${styles.link} ${styles.anchorLink}`}
			target='_blank'
			rel='noreferrer'
			onClick={() => {
				analytics.track('Customer Support Link Clicked');
			}}
		>
			{TDR.SUPPORT_EMAIL}
		</a>
	);
};

export default CustomerSupportLink;
