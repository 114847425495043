import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { RestaurantGuardOutletContext } from '../routes/RestaurantGuard';
import styles from './InboundRequestLayout.module.scss';

export const InboundRequestLayout = () => {
	const parentContext = useOutletContext<RestaurantGuardOutletContext>();

	return (
		<div className={styles.Container} >
			<Outlet context={{ ...parentContext }} />
		</div>
	);
};