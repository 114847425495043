import React from 'react';
import { TDR } from 'tdr-common';
import Card from '../../../icons/card.svg';
import Shield from '../../../icons/key-shield.svg';
import Lock from '../../../icons/pad-lock.svg';
import { InfoDialog } from './InfoDialog';
import { InfoDrawerIcon } from '../../icons';
import styles from './SecureEncryptInfo.module.scss';

const SecureEncryptInfo = () => {
	const body = (
		<ul className={styles.InfoList} style={{ textAlign: 'left' }}>
			<li>
				<p>Privacy and security are our upmost concern.</p>
			</li>
			<li>
				<img src={Lock} alt={'Lock Icon'} />
				<p>
          We utilize TLS 1.2 to encrypt communication between mobile apps and Tablz.{' '}
					<a className='bolded clickable' href='https://www.ietf.org/rfc/rfc5246.txt' target='_blank' rel='noreferrer'>
            Learn more.
					</a>
				</p>
			</li>
			<li>
				<img src={Card} alt={'Card Icon'} />
				<p>
          Stripe, a Level 1 PCI-Certified third party is used to manage all user’s credit card information.{' '}
					<a
						className='bolded clickable'
						href='https://stripe.com/en-ca/payments/checkout'
						target='_blank'
						rel='noreferrer'
					>
            Learn more.
					</a>
				</p>
			</li>
			<li>
				<img src={Shield} alt={'Shield Icon'} />
				<p>
          Tablz is securely storing data on Firestore.{' '}
					<a
						className='bolded clickable'
						href='https://firebase.google.com/docs/firestore'
						target='_blank'
						rel='noreferrer'
					>
            Learn more.
					</a>
				</p>
			</li>
			<li>
				<p style={{ textAlign: 'center' }}>
          If you have any questions about your security, please don’t hesitate to contact us at{' '}
					<a className='bolded clickable' href={`mailto:${TDR.SUPPORT_EMAIL}`}>
						{TDR.SUPPORT_EMAIL}
					</a>
				</p>
			</li>
		</ul>
	);

	return (
		<InfoDialog
			title='Secure & Encrypted'
			body={body}
			triggerButton={<InfoDrawerIcon />}
		/>
	);
};

export default SecureEncryptInfo;
