const VIDEO_EL_ID_PREFIX = 'carousel-video-player-';

export function getVideoElementID(idx: number) {
	return `${VIDEO_EL_ID_PREFIX}${idx}`;
}

export function getVideoElement(idx: number): HTMLVideoElement | undefined {
	return idx !== null && idx !== undefined ? document.getElementById(getVideoElementID(idx)) as HTMLVideoElement : undefined;
}

export function isPlaying(videoRef?: HTMLVideoElement) {
	return videoRef && videoRef.currentTime > 0 && videoRef.paused === false && videoRef.ended === false;
}

export async function togglePlayback(videoRef?: HTMLVideoElement, onPause?: () => void, onPlay?: () => void) {
	try {
		const isPlayingAlready = isPlaying(videoRef);
		const playPromise = videoRef?.play();

		if (playPromise !== undefined) {
			playPromise.then(() => {
				if (isPlayingAlready) {
					videoRef?.pause();
					onPause?.();
				}
				else {
					onPlay?.();
				}
			}).catch(() => {
				videoRef?.pause();
				onPause?.();
			});
		}
	}
	catch {
		//Ignore the error
	}
}