import React from 'react';
import Info from '../../../icons/info.svg';
import { InfoDialog } from '../../dialog/info-dialog/InfoDialog';

export const BookingFeesInfoIcon = () => <img src={Info} alt='More info' style={{ height: '20px', width: '20px' }} />;

const IconTriggerLink = (
	<>
		<BookingFeesInfoIcon />
    How do booking fees work?
	</>
);

export const BookingFeesInfo = () => {
	return (
		<InfoDialog
			variant='feedback'
			triggerButton={IconTriggerLink}
			title='Booking Fees'
			body={
				<>
					<p>
            Selecting a specific spot is a premium guest service. In addition to guaranteeing your preferred seat, the
            booking fee helps restaurants stay profitable.
					</p>
					<p>If you want a premium seat but don’t want to pay, try choosing an off-peak dining time.</p>
				</>
			}
		/>
	);
};
