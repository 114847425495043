import Call from 'iconsax-react/dist/esm/Call';
import ExportSquare from 'iconsax-react/dist/esm/ExportSquare';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { LANDING_PAGE_PLACEHOLDERS } from 'tdr-common';
import { analytics } from '../../../helpers/analytics';
import Email from '../../../icons/email.svg';
import { RestaurantGuardOutletContext } from '../../../routes/RestaurantGuard';
import { ProceedWithLogo } from '../../logos/ProceedWithLogo';
import styles from './BookWithAlternativePlatform.module.scss';
import { BookingOptionTileWidgetVersion } from '../BookingOptionTileWidgetVersion';

const SitAnywhereIcon = () => {
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	const icons = {
		phone: <Call />,
		email: <img src={Email} alt='email' />
	};

	return (
		<div className={styles.IconContainer}>
			{icons[restaurant?.settings?.alternativePlatform?.vendor] || <ExportSquare />}
		</div>
	);
};

export const BookWithAlternativePlatform = () => {
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	const altPlatform = restaurant.settings?.alternativePlatform?.vendor;
	const altPlatformHref = restaurant.settings?.alternativePlatform?.href;

	const contactByPhone = altPlatform === 'phone';
	const contactByEmail = altPlatform === 'email';

	const hasAltPlatform = !contactByPhone && !contactByEmail;

	const descriptionText = {
		phone: (
			<>
        Call <span className={styles.Highlight}>{restaurant?.phoneNumber}</span> to make a standard reservation.
			</>
		),
		email: (
			<>
        Email <span className={styles.Highlight}>{restaurant.contactEmail}</span> to make a standard reservation.
			</>
		)
	};

	const handleClick = () => {
		if (contactByPhone) {
			analytics.track('Redirected to Phone Number');
		}
		else if (contactByEmail) {
			analytics.track('Redirected to Email');
		}
		else {
			analytics.track('Redirected to Standard Booking Portal', {
				platform: altPlatform,
				exitLink: altPlatformHref
			});
		}
	};

	const title = restaurant.settings?.landingPage?.alternate?.title || LANDING_PAGE_PLACEHOLDERS.alternate.title;
	const description = restaurant.settings?.landingPage?.alternate?.description || descriptionText[altPlatform] || LANDING_PAGE_PLACEHOLDERS.alternate.description;
	const callToAction = restaurant.settings?.landingPage?.alternate?.callToAction || LANDING_PAGE_PLACEHOLDERS.alternate.callToAction;

	return (
		<BookingOptionTileWidgetVersion
			title={title}
			description={description}
			icon={<SitAnywhereIcon />}
			platformLogo={hasAltPlatform ? <ProceedWithLogo logo='alternate' /> : null}
			linkTo={altPlatformHref}
			target='_blank'
			onClick={handleClick}
			label={callToAction}
			variant={altPlatform}
		/>
	);
};
