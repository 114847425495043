import * as Switch from '@radix-ui/react-switch';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import React, { useEffect, useState } from 'react';
import { useScreenSize } from '../../hooks/useScreenSize';
import { CTAButton } from '../buttons';
import {
	Dialog,
	DialogBody,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle
} from '../dialog/Dialog';
import { Divider } from '../divider/Divider';
import { ACCEPT_ALL_PREFERENCES, conditionallyLoadAnalytics, DEFAULT_PREFERANCES_CONFIG, loadPreferences, savePreferences } from './preferences';

import styles from './CookieConsentModal.module.scss';
import { Preferences, PreferencesCategory } from './types';

enum CookieConsentStep {
	Accept,
	Manage,
}

type CookieStepProps = {
	saveConsent: (preferences: Preferences) => void;
	setOpen: (open: boolean) => void;
	setStep?: (step: CookieConsentStep) => void;
};

const AcceptCookiesModalContent = ({ saveConsent, setOpen, setStep }: CookieStepProps) => {
	const handleAllowAllClick = () => {
		saveConsent(ACCEPT_ALL_PREFERENCES);
		setOpen(false);
	};

	const handleCustomizeClick = () => {
		setStep?.(CookieConsentStep.Manage);
	};

	return (
		<>
			<DialogHeader>
				<DialogTitle>This Website Uses Cookies</DialogTitle>
				<DialogDescription>
          Tablz uses analytical data to personalize and enhance your site experience. By using our service, you agree to our use
          of cookies and data tracking.
				</DialogDescription>
			</DialogHeader>

			<DialogFooter>
				<CTAButton buttonText='Allow All' onClick={handleAllowAllClick} />
				<CTAButton buttonText='Customize' onClick={handleCustomizeClick} variant='text' textColor='#ffffff99' />
			</DialogFooter>
		</>
	);
};

const ManageCookiesModalContent = ({ saveConsent, setOpen }: CookieStepProps) => {
	const [preferences, setPreferences] = useState<Preferences>(ACCEPT_ALL_PREFERENCES);

	const handleAllowAllClick = () => {
		saveConsent(ACCEPT_ALL_PREFERENCES);
		setOpen(false);
	};

	const handleSaveSettingsClick = () => {
		saveConsent(preferences);
		setOpen(false);
	};

	return (
		<>
			<DialogHeader>
				<DialogTitle>Data Settings</DialogTitle>
				<DialogDescription>
					<VisuallyHidden.Root asChild>
            Adjust your data preferences here. Click Allow All Cookies to allow all, or click Allow Selection to save
            your preferences.
					</VisuallyHidden.Root>
				</DialogDescription>
			</DialogHeader>

			<Divider />

			<DialogBody>
				<div className={styles.CookieItems}>
					{Object.entries(DEFAULT_PREFERANCES_CONFIG).map(([category, p], i) => {
						return (
							<div className={styles.CookieItem} key={`preference-${category}-${i}`}>
								<div className={styles.SwitchContainer}>
									<label htmlFor={category} className={styles.SwitchLabel}>{p.title}</label>
									<Switch.Root
										id={category}
										className={styles.SwitchRoot}
										checked={preferences?.[category] ?? true}
										disabled={category === PreferencesCategory.FUNCTIONAL}
										onCheckedChange={(checked) => setPreferences((prevPreferances) => {
											return {
												...prevPreferances,
												[category]: checked
											};
										})}
									>
										<Switch.Thumb className={styles.SwitchThumb} />
									</Switch.Root>
								</div>

								<p className={styles.CookieDescription}>{p.description}</p>
							</div>
						);
					})}
				</div>
			</DialogBody>

			<DialogFooter>
				<CTAButton buttonText='Allow All Cookies' onClick={handleAllowAllClick} />

				<CTAButton buttonText='Allow Selection' variant='secondary' onClick={handleSaveSettingsClick} />
			</DialogFooter>
		</>
	);
};

const CookieConsent = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [step, setStep] = useState<CookieConsentStep>(CookieConsentStep.Accept);

	const { isSmallScreen } = useScreenSize();

	// only open if user has not already set their cookie preferences
	useEffect(() => {
		const preferences = loadPreferences();
		if (!preferences) {
			conditionallyLoadAnalytics({});
			setOpen(true);
		}
		else {
			conditionallyLoadAnalytics({ preferences });
		}
	}, []);

	function saveConsent(preferences: Preferences) {
		savePreferences({ preferences });
		conditionallyLoadAnalytics({ preferences });
	}

	return (
		<Dialog
			open={open}
			onOpenChange={setOpen}
			modal={isSmallScreen} // allow interaction of elements behind modal ONLY on desktop screen size
		>
			<DialogContent
				containerId='app'
				onInteractOutside={(event) => {
					event.preventDefault();
				}}
			>
				<>
					{step === CookieConsentStep.Accept && (
						<AcceptCookiesModalContent saveConsent={saveConsent} setOpen={setOpen} setStep={setStep} />
					)}

					{step === CookieConsentStep.Manage && (
						<ManageCookiesModalContent saveConsent={saveConsent} setOpen={setOpen} />
					)}
				</>
			</DialogContent>
		</Dialog>
	);
};

export default CookieConsent;
