import React from 'react';

import { isToday } from './Calendar.utils';

import styles from './AvailabilityIndicator.module.scss';
import { TDR } from 'tdr-common';

type AvailabilityIndicatorProps = {
	date: Date;
	availability: TDR.Availability.DateAvailability;
	disabled?: boolean;
}

export const AvailabilityIndicator = ({ date,availability, disabled }: AvailabilityIndicatorProps) => {

	const baseClass = styles.Indicator;
	const disabledClass = styles['Indicator--disabled'];
	const blockedClass = styles['Indicator--blocked'];
	const statusClass = styles[`Indicator--${availability}`];

	return (
		<>
			<div className={`${baseClass} ${statusClass} ${disabled ? `${disabledClass}` : ''}`}/>
			{!disabled && isToday(date) && availability === 'none' && (
				<div className={blockedClass} />
			)}
		</>
	);
};