import { DateTime } from 'luxon';
import { TDR, jsToTDRWeekday } from 'tdr-common';
import { CheckoutState } from '../context/new/CheckoutContext';
import { analytics } from './analytics';

export const trackReservationModifications = ({
	reservation,
	itemsChanged,
	errorMessage = '',
	sentryErrorID = ''
}: {
	reservation: Partial<TDR.Reservation>,
	itemsChanged?: Partial<TDR.Reservation>,
	errorMessage?: string;
	sentryErrorID?: string;
}) => {
	const { restaurantId, restaurantName, tableId, tableName } = reservation;
	const now: Date = new Date();
	const properties: any = {
		reservationId: reservation.id,
		restaurantId,
		restaurantName,
		tableId,
		tableName,
		modificationDateDOW: jsToTDRWeekday(now.getDay()),
		priceDelta: 0
	};

	const updatedPrice = itemsChanged?.price;
	const updatedEmail = itemsChanged?.email;
	const updatedFullName = itemsChanged?.fullName;
	const updatedPhone = itemsChanged?.phone;
	const updatedTime = itemsChanged?.time;
	const updatedTable = itemsChanged?.tableName;
	const updatedGuestCount = itemsChanged?.guests;
	const updatedDietaryRestrictions = itemsChanged?.dietaryRestrictions?.restrictions;
	const updatedDietaryNotes = itemsChanged?.dietaryRestrictions?.notes;
	const updatedOccasion = itemsChanged?.occasion;
	const updatedGuestNotes = itemsChanged?.guestNotes;

	if (updatedTable && (updatedTable !== reservation.tableName)) {
		properties.previousTable = reservation.tableName;
		properties.updatedTable = updatedTable;
	}

	if (updatedGuestCount && (updatedGuestCount !== reservation.guests)) {
		properties.previousGuestCount = reservation.guests;
		properties.updatedGuestCount = updatedGuestCount;
	}

	if (updatedPrice && (updatedPrice !== reservation.price)) {
		properties.previousPrice = reservation.price;
		properties.updatedPrice = updatedPrice;
		properties.priceDelta = reservation.price - updatedPrice;
	}

	if (updatedDietaryRestrictions) {
		properties.previousDietaryRestrictions = reservation.dietaryRestrictions?.restrictions;
		properties.updatedDietaryRestrictions = updatedDietaryRestrictions;
	}

	if (updatedDietaryNotes) {
		properties.previousDietaryNotes = reservation.dietaryRestrictions?.notes;
		properties.updatedDietaryNotes = updatedDietaryNotes;
	}

	if (updatedOccasion) {
		properties.previousOccasion = reservation.occasion;
		properties.updatedOccasion = updatedOccasion;
	}

	if (updatedGuestNotes) {
		properties.previousGuestNotes = reservation.guestNotes;
		properties.updatedGuestNotes = updatedGuestNotes;
	}

	if (updatedEmail && (updatedEmail !== reservation.email)) {
		properties.previousEmail = reservation.email;
		properties.updatedEmail = updatedEmail;
	}

	if (updatedFullName && (updatedFullName !== reservation.fullName)) {
		properties.previousEmail = reservation.fullName;
		properties.updatedEmail = updatedFullName;
	}

	if (updatedPhone && (updatedPhone !== reservation.phone)) {
		properties.previousEmail = reservation.phone;
		properties.updatedEmail = updatedPhone;
	}

	if (updatedTime && (updatedTime.seconds !== reservation.time.seconds)) {
		properties.previousDate = reservation.time.toDate().toDateString();
		properties.previousTime = reservation.time.toDate().toTimeString();
		properties.previousDateDOW = jsToTDRWeekday(reservation.time.toDate().getDay());
		properties.updatedDate = updatedTime.toDate().toDateString();
		properties.updatedTime = updatedTime.toDate().toTimeString();
		properties.updatedDateDOW = jsToTDRWeekday(updatedTime.toDate().getDay());
	}

	if (errorMessage) {
		properties.errorMessage = errorMessage;
		properties.sentryErrorID = sentryErrorID;
	}

	const event = errorMessage ? 'Reservation Modification Failed' : 'Reservation Modified';
	analytics.track(event, properties);
};

export const trackReservationCancelled = (reservation: Partial<TDR.Reservation>, errorMessage = '', sentryErrorID = '') => {
	const { restaurantId, restaurantName, tableId, tableName, price, fullName, email, phone } = reservation;
	const time: Date = reservation.time.toDate();
	const now: Date = new Date();

	const event = errorMessage ? 'Reservation Cancellation Failed' : 'Reservation Cancelled';


	const properties = {
		reservationId: reservation.id,
		restaurantId,
		restaurantName,
		tableId,
		tableName,
		time: time.toTimeString(),
		date: time.toDateString(),
		cancelledDateDOW: jsToTDRWeekday(now.getDay()),
		dateDOW: jsToTDRWeekday(time.getDay()),
		price,
		priceDelta: (price * -1), //Price in the DB is positive even for refunds
		fullName,
		email,
		phone,
		errorMessage: undefined,
		sentryErrorID: undefined
	};

	if (errorMessage) {
		properties.errorMessage = errorMessage;
		properties.sentryErrorID = sentryErrorID;
	}

	analytics.track(event, properties);
};

export const trackReservationCreation = (checkoutState: CheckoutState, session: TDR.CheckoutSession.CheckoutSession, invoice?: TDR.Invoice, errorMessage = '', sentryErrorID='') => {
	const { guests, restaurantId, tableId, time } = session;
	const reservationTime = DateTime.fromSeconds((time as any)._seconds);

	const daysInAdvance = reservationTime.diffNow('days').days.toFixed(1);

	const properties: any = {
		guests,
		reservationTime: reservationTime.toJSDate().toTimeString(),
		reservationDate: reservationTime.toJSDate().toDateString(),
		bookedDateDOW: jsToTDRWeekday(DateTime.local().toJSDate().getDay()),
		dateDOW: jsToTDRWeekday(reservationTime.toJSDate().getDay()),
		daysInAdvance,
		restaurantId,
		tableId,
		request: checkoutState.isLargeGroup,
		revenueType: 'reservation',
		currency: invoice?.currency,
		email: checkoutState.guest?.email,
		phone: checkoutState.guest?.phone,
		checkoutId: session.id
	};

	const event = errorMessage ? 'Confirm Reservation Failed' : 'Confirm Reservation';


	if (errorMessage) {
		properties.errorMessage = errorMessage;
		properties.sentryErrorID = sentryErrorID;
	}
	if(checkoutState.isLargeGroup) {
		analytics.track('Large Group Booking Requested', properties);
	}

	analytics.track(event, properties);
};

export const trackUnmodifiable = (reason: string, reservation?: Partial<TDR.Reservation>) => {
	const time: Date = reservation?.time.toDate();
	const now: Date = new Date();

	const properties: any = {
		...(reservation || {}),
		reservationId: reservation?.id,
		time: time?.toTimeString(),
		date: time?.toDateString(),
		unmodifiableDateDOW: jsToTDRWeekday(now.getDay()),
		dateDOW: jsToTDRWeekday(time?.getDay()),
		revenueType: 'reservation',
		reason
	};

	if (reservation?.status === TDR.Reservation.Status.Cancelled) {
		properties.cancelledOn = reservation.updatedAt.toDate();
		properties.unmodifiableReason = 'Cancelled';
	}
	else {
	 properties.unmodifiableReason = 'In the Past';
	}

	analytics.track('Accessed Unmodifiable Reservation', properties);
};