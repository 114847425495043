import { AppConfig } from './types';

const matterport = {
	sdkVersion: '3.5',
	sdkKey: '751c857ee3374fad8646b721053e2d4f',
	disabled: false
};

const production: AppConfig = {
	deployment: 'production',
	containerized: false,
	autofill_forms: false,

	apiHost: 'https://us-central1-threedres.cloudfunctions.net',
	cdnHost: 'https://cdn.tablz.net',
	adminApiHost: 'https://api.tablz.com/portal',
	firebase: {
		projectId: 'threedres',
		apiKey: 'AIzaSyCa47ud9AmmPnve3nVGcMdYNNdkSc5rL7g',
		authDomain: 'threedres.firebaseapp.com',
		databaseURL: 'https://threedres.firebaseio.com',
		storageBucket: 'cdn.tablz.net',
		messagingSenderId: '662023710191',
		appId: '1:662023710191:web:0a46492aae6783405a421a',
		measurementId: 'G-89Z81GRHZR',
		longPolling: false,
		emulators: {
			firestore: null,
			storage: null,
			auth: null
		}
	},
	analytics: {
		mixpanel: {
			token: '913ae2eaed3d6aff7fc92b299d530cb2',
			config: {
				debug: false
			}
		}
		//enable: false, // Completely disable analytics
	},
	sentry: {
		enable: true,
		dsn: 'https://005332b19583451ea4a15adbe708308a@o301373.ingest.sentry.io/5683407',
		environment: 'production',
		tracesSampleRate: 0.5,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1,
		//https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
		ignoreErrors: [
			//Matterport bundle SDK specific, network-related, non-addressable error.
			'Loading CSS chunk',
			//Outlook safe link triggered: https://github.com/getsentry/sentry-javascript/issues/3440
			'Non-Error promise rejection captured',
			// Random plugins/extensions
			'top.GLOBALS',
			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
			'originalCreateNotification',
			'canvas.contentDocument',
			'MyApp_RemoveAllHighlights',
			'http://tt.epicplay.com',
			'Can\'t find variable: ZiteReader',
			'jigsaw is not defined',
			'ComboSearch is not defined',
			'http://loading.retry.widdit.com/',
			'atomicFindClose',
			// Facebook borked
			'fb_xd_fragment',
			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
			// reduce this. (thanks @acdha)
			// See http://stackoverflow.com/questions/4113268
			'bmi_SafeAddOnload',
			'EBCallBackMessageReceived',
			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
			'conduitPage',
			//Common unrelevant Tablz Errors
			'https://i.imgur.com/',
			'Network Error',
			'network error',
			'safari-web-extension',
			'BetterJsPop',
			'gtag/js',
			'Failed to get document because the client is offline.',
			'INTERNAL ASSERTION FAILED: Unexpected state',
			'We could not retrieve data from the specified Element. Please make sure the Element you are attempting to use is still mounted',
			'Unable to fetch the sdk interface.',
			'webkit-masked-url',
			'ResizeObserver loop limit exceeded',
			'InvalidAccessError: Page already has an active payment session.',
			'Loading chunk',
			//disable internal matterport error
			'/bundle/js/',
			'/bundle/sdk/',
			'The network connection was lost',
			'Database deleted by request of the user',
			'Timeslot no longer available. Please select another.'
		],
		denyUrls: [
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Woopra flakiness
			/eatdifferent\.com\.woopra-ns\.com/i,
			/static\.woopra\.com\/js\/woopra\.js/i,
			//Safari Extensions
			/safari-web-extension/i,
			/safari-extension:\/\//i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			// Other plugins
			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
			/webappstoolbarba\.texthelp\.com\//i,
			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i
		],
		debug: false
	},
	matterport: {
		...matterport,
		assetOrigin: 'https://app.tablz.com'
	},
	stripe: {
		can: {
			pub_key: 'pk_live_51IxNyBAGhKmUXc8yhhOYMPDlCJcQUTsokOKdBW4P7hBCposyf9CjPnpKCDgEe82wrpQZhPb9NKjU8VSYZmkVsrat00y4YMrS1Z'
		},
		usa: {
			pub_key: 'pk_live_51JtkHqAy16LJuVrBj8PkYxjJp4gvplSNTuFnFanzEZkbLIlay1amSRjj6Qu1mPr7hzXPsYDIBLJITYNFwsVFum0o00Nqg9G321'
		}
	}
};

const staging: AppConfig = {
	deployment: 'staging',
	containerized: false,
	autofill_forms: true,

	// ??? firestore says that the sparkplan has cloud functions,
	// but the web interface says to upgrade the plan?
	apiHost: 'https://us-central1-threedres-staging.cloudfunctions.net',
	cdnHost: 'https://cdn.tablz.net/',
	adminApiHost: 'https://api.staging.tablz.com/portal',
	firebase: {
		apiKey: 'AIzaSyBNopPb2Pl0yhB264xz7br-cj9c01DEG-4',
		authDomain: 'threedres-staging.firebaseapp.com',
		projectId: 'threedres-staging',
		storageBucket: 'cdn-staging.tablz.net',
		messagingSenderId: '436872751445',
		appId: '1:436872751445:web:cee7e083a24d81881127dc',
		measurementId: 'G-D7JN6JKC1B',
		longPolling: false,
		emulators: {
			firestore: null,
			storage: null,
			auth: null
		}
	},
	sentry: {
		enable: true,
		dsn: 'https://005332b19583451ea4a15adbe708308a@o301373.ingest.sentry.io/5683407',
		environment: 'staging',
		enableTracing: false,
		denyUrls: production.sentry.denyUrls,
		ignoreErrors: production.sentry.ignoreErrors,
		debug: true
	},
	analytics: {
		mixpanel: {
			token: '9f8400e49c74aef49bc4aedc7222a0c4',
			config: {
				debug: true
			}
		},
		enable: true // Completely disable analytics
	},
	matterport: {
		...matterport,
		assetOrigin: 'https://app.staging.tablz.com'
	},
	stripe: {
		can: {
			pub_key: 'pk_test_51IxNyBAGhKmUXc8yzEHo9jCOCEddt39dsjl9W9NJj3r79CXftv3uQppfa6cdFf9bVTZ2PcHlN7aBG73pjYowWQ7q00P6hxUkFL'
		},
		usa: {
			pub_key: 'pk_test_51JtkHqAy16LJuVrBr8GXjzIAlcT1ntnf9SCYRAbsDnaaqZPfer9WxW7l4z6d6vaLRuCZVYoqfFWxcs4ccvSyJ4Ay00B963vcWv'
		}
	}
};

const TARGET_ENV = process.env.TARGET_FIREBASE;

export const PRODUCTION = TARGET_ENV === 'production';

const configOptions: Record<string, AppConfig> = {
	production,
	staging
};

export default configOptions[TARGET_ENV];





