import React, { useEffect, useState } from 'react';
import muted from '../../../icons/audio-muted.svg';
import unmuted from '../../../icons/audio-unmuted.svg';
import ControlButton from './ControlButton';
import { analytics } from '../../../helpers/analytics';

type ToggleMuteProps = React.HTMLAttributes<HTMLButtonElement> & {
	videoRef: React.MutableRefObject<HTMLVideoElement| null>,
}

const ToggleMute = ({ videoRef, ...props }: ToggleMuteProps) => {
	const [isMuted, setIsMuted] = useState<boolean>(!!videoRef?.current?.muted || videoRef?.current?.volume === 0 || true);

	const trackAudioUnmutedEvent = (isVideoElementMuted: boolean) => {
		if (isVideoElementMuted) {
			analytics.track('Media Carousel - Unmute Audio');
		}
		else {
			analytics.track('Media Carousel - Mute Audio');
		}
	};

	useEffect(() => {
		videoRef?.current?.addEventListener('volumechange', function () {
			if (this) {
				const isElementMuted = (this.muted || this.volume === 0);
				setIsMuted(isElementMuted);
			}
		});
	}, []);

	const toggleMute = () => {
		const video = videoRef?.current;

		if (video) {
			video.muted = !video.muted;
			setIsMuted(video.muted);
			trackAudioUnmutedEvent(video.muted);
		}
	};

	return (
		<ControlButton
			type='secondary'
			title={isMuted ? 'Unmute' : 'Mute'}
			iconSrc={isMuted ? muted : unmuted}
			onClick={toggleMute}
			{...props}
		/>
	);
};

export default ToggleMute;