import { TDR } from 'tdr-common';
import config from '../common/config';

export default async function submitRequest(request: TDR.RequestDTO) {
	const response = await fetch(`${config.apiHost}/submitRequest`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			request: request
		})
	});

	const result = await response.json();
	return result;
}
