import React from 'react';
import SuccessIcon from '../../icons/check-circle.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { ReturnToBookingPortal } from './buttons';
import { BookingCancelledProps } from './types';

export const BookingCancelled = ({ restaurantName, linkTo }: BookingCancelledProps) => {
	return (
		<ResultPageLayout headerIcon={SuccessIcon} iconAltText='Successfully cancelled'>
			<ResultPageLayout.Title>We&apos;re sorry to see you go</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>
          Your booking at <b>{restaurantName}</b> has been successfully cancelled.
				</p>
				<p>You will receive an email shortly confirming the cancellation details.</p>

				<ResultPageLayout.Footer>
					<ReturnToBookingPortal to={linkTo} />
				</ResultPageLayout.Footer>
			</ResultPageLayout.Body>
		</ResultPageLayout>
	);
};
