import React from 'react';
import { InfoDialog } from './InfoDialog';
import { InfoDrawerIcon } from '../../icons';
import { formatMonetaryValue, TDR } from 'tdr-common';

type GroupBookingDepositInfoProps = {
	deposit?: any;
	minimumSpend?: any;
	currency: TDR.Currency;
};

export const GroupBookingDepositInfo = ({ deposit, minimumSpend, currency }: GroupBookingDepositInfoProps) => {
	const depositText = deposit?.amount
		? `${formatMonetaryValue(deposit?.amount, currency, deposit?.amount === 0)} ${
			deposit?.perPerson ? ' per guest' : ''
		}`
		: '';

	const body = (
		<>
			<p>
        For group <strong>bookings of 8 or more</strong>, we require a{' '}
				{deposit?.amount && (
					<>
            deposit of{' '}
						{<strong>{depositText}</strong>}
					</>
				)}
				{deposit?.amount && minimumSpend?.amount && ' which is applied towards a'}
				{minimumSpend?.amount &&
          ` minimum spend on food and beverage of $${minimumSpend?.amount}${
          	minimumSpend?.perPerson ? ' per guest' : ''
          }`}
        .
			</p>

			<p>This is a necessary measure for us to coordinate and deliver an excellent dining experience for your group.</p>
		</>
	);

	return <InfoDialog variant='feedback' title='Group Booking Deposit' triggerButton={<InfoDrawerIcon />} body={body} />;
};
