import React from 'react';
import styles from './ImageCard.module.scss';

export const ImageCard = ({
	height,
	brightness = 1,
	contrast = 1,
	children
}: {
	height: number;
	brightness?: number;
	contrast?: number;
	children: React.ReactNode;
}) => {
	return (
		<div
			className={styles.Container}
			style={{ height: `${height}px`, '--brightness': brightness, '--contrast': contrast } as React.CSSProperties}
		>
			{children}
		</div>
	);
};

export const ImageCardAsset = ({ src, alt }: { src: string; alt }) => {
	return <img className={styles.Asset} src={src} alt={alt} />;
};

export const ImageCardHeader = ({ children }: { children: React.ReactNode }) => {
	return <div className={styles.Header}>{children}</div>;
};

export const ImageCardHeaderPrimary = ({ children }: { children: React.ReactNode }) => {
	return <div className={styles.PrimaryHeader}>{children}</div>;
};

export const ImageCardHeaderSecondary = ({ onClick, children }: { onClick: () => void; children: React.ReactNode }) => {
	return (
		<button className={styles.SecondaryHeader} onClick={onClick}>
			{children}
		</button>
	);
};

export const ImageCardHeaderTitle = ({ children }: { children: React.ReactNode }) => {
	return <div className={styles.HeaderTitle}>{children}</div>;
};

export const ImageCardDescription = ({ isVisible, children }: { isVisible: boolean; children: React.ReactNode }) => {
	return (
		<div
			className={`${styles.Description} ${isVisible ? styles['Description--visible'] : styles['Description--hidden']}`}
		>
			{children}
		</div>
	);
};

export const ImageCardFooter = ({ children }: { children: React.ReactNode }) => {
	return <div className={styles.Footer}>{children}</div>;
};
