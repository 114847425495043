import { useQuery } from '@tanstack/react-query';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { querySnapshotToArray, TDR } from 'tdr-common';

export function useRestaurantTaxPolicies(restaurant: TDR.Restaurant) {
	const query = useQuery({
		queryKey: ['restaurantTaxPolicies', restaurant],
		queryFn: () => getRestaurantTaxPolicies(restaurant)
	});
	return query;
}

async function getRestaurantTaxPolicies(restaurant: TDR.Restaurant): Promise<TDR.PricingPolicy[]> {
	const db = getFirestore();
	const q = query(collection(db, 'PricingPolicies'), where('tax', 'in', restaurant.taxes), where('active', '==', true));
	const querySnapshot = await getDocs(q);
	return querySnapshotToArray(querySnapshot) || [];
}
