import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';
import { InfoCallout } from './InfoCallout';

export const PatioInfoSection = () => {
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	return (
		<InfoCallout>
      By choosing this selection, you will be assigned to a table within{' '}
			{restaurant.name}
			{restaurant.name.endsWith('\'s')
				? ''
				: restaurant.name.endsWith('s')
					? '\''
					: '\'s'}{' '}
      Patio.
		</InfoCallout>
	);
};