import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import VerificationInput from 'react-verification-input';
import styles from './VerificationCode.module.scss';
import { CTAButton } from '../buttons';
import { analytics } from '../../helpers/analytics';


type VerificationCodeProps = {
	obfuscatedPhone: string,
	confirmationHandler: (code: string, setInvalidCode: React.Dispatch<React.SetStateAction<boolean>>) => Promise<void>;
	onClickResend: () => void;
	disableResend: boolean,
}

export const VerificationCode: React.FC<VerificationCodeProps> = ({ obfuscatedPhone, confirmationHandler, onClickResend, disableResend }) => {
	const [isInvalid, setIsInvalid] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [resendText, setResendText] = useState<'Resend Code' | 'Code Sent'>('Resend Code');

	const onChange = () => {
		setIsInvalid(false);
	};

	const onComplete = (code: string) => {
		setIsLoading(true);
		confirmationHandler(code, setIsInvalid).then(() => {
			setIsLoading(false);
		});
	};

	return(
		<section className={styles.verificationSection}>
			<div className={styles.headerText}>Checkout Faster with Tablz</div>
			<div className= {styles.bodyText}>
				<p>Enter the code sent to  <span>{obfuscatedPhone}</span> so we can retrieve you saved payment information in the next step.</p>
			</div>
			<VerificationInput classNames={{
				container: styles.verificationContainer,
				character: `${styles.verificationCharacter} ${isInvalid ? styles.verificationCharacterInvalid : ''}`,
				characterSelected: styles.verificationCharacterSelected
			}} onChange={onChange} onComplete={onComplete}/>
			<CTAButton
				buttonText={resendText}
				disabled={disableResend || resendText === 'Code Sent'}
				variant={'text'}
				loading={isLoading}
				onClick={() => {
					analytics?.track('Button Clicked', { label: 'Resend Code' });
					setResendText('Code Sent');
					onClickResend();
					setTimeout(() => setResendText('Resend Code'), 5000);
				}}
			/>
		</section>
	);
};