import React, { useEffect } from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { useTableBySlug } from '../api/getTableBySlug';
import { InactiveTable } from '../components/confirmation';
import { analytics, tableMiddlewareBuilder } from '../helpers/analytics';
import { NotFoundErrorPage } from '../pages/errors/NotFoundErrorPage';
import { AvailabilityGuardContext } from './AvailabilityGuard';

export interface TableGuardOutletContext extends AvailabilityGuardContext {
	table: TDR.Table;
}

export const TableGuard = () => {
	const parentContext = useOutletContext<AvailabilityGuardContext>();
	const { restaurant } = parentContext || {};
	const { tableSlug } = useParams();

	const { data: table, isLoading: loading, isError } = useTableBySlug(restaurant?.id, tableSlug);

	useEffect(() => {
		if (table) {
			const trackingMiddleware = tableMiddlewareBuilder(table.id, table.name);
			analytics.addSourceMiddleware(trackingMiddleware);

			return () => analytics.removeSourceMiddleware(trackingMiddleware);
		}
	}, [table]);

	if (!loading && !isError && !table) {
		return <NotFoundErrorPage to='..' />;
	}
	else if (!loading && table?.disabled) {
		return <InactiveTable linkTo={`/${restaurant.slug}/explore`} tableLabel={table.label} variant='default' />;
	}
	else {
		// TODO: replace null with skeleton?
		return loading ? null : <Outlet context={{ table, ...parentContext } satisfies TableGuardOutletContext} />;
	}
};
