import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import React, { useEffect, useState } from 'react';
import { TDR } from 'tdr-common';
import { useServiceTimeslots } from '../../api/getServiceTimeslots';
import { HASH_ELEMENT_IDS } from '../../common/hashElementIds';
import { SearchFilters } from '../../routes/AvailabilityGuard';
import ServiceSelector from '../ServiceSelector';
import { Skeleton } from '../skeleton/Skeleton';
import TimeslotList from '../timeslot-list/TimeslotList';
import { TimeslotSkeleton } from '../timeslot-list/TimeslotSkeleton';

export type TimeSelectorProps = {
	restaurant: TDR.Restaurant;
	existingReservationId?: string;
	table: TDR.Table;
	searchFilters: SearchFilters;
	tableIds: string[];
	onTimeslotChange: (time: string | null, timeslot: TDR.TimeSlot | null, price?: number) => void;
	onServiceChangeName?: (serviceName: string) => void;
	isReleasingSession?: boolean;
	hideFreePricing?: boolean;
};
export type ServiceTimeSlotMap = { [name: string]: { [time: string]: TDR.TimeSlot } };

export function TimeSelector({
	restaurant,
	existingReservationId,
	table,
	searchFilters,
	tableIds,
	onTimeslotChange,
	onServiceChangeName,
	isReleasingSession,
	hideFreePricing = false
}: TimeSelectorProps) {
	const [selectedServiceName, setSelectedServiceName] = useState<string>();
	const [timeslots, setTimeslots] = useState<[string, TDR.TimeSlot][]>();

	const { data, isLoading: isLoadingServices } = useServiceTimeslots({
		tableIds,
		dateStr: searchFilters.date,
		restaurantId: restaurant.id,
		options: { releaseReservationId: existingReservationId }
	});

	const services = data?.services || {};

	const handleTimeslotChange = (time: string | null, timeslot: TDR.TimeSlot | null, price?: number) => {
		onTimeslotChange(time, timeslot, price);
	};

	const handleServiceChange = (serviceName: string) => {
		setSelectedServiceName(serviceName);
		onServiceChangeName?.(serviceName);
	};

	// Set the default selected service
	useEffect(() => {
		if (!selectedServiceName && Object.keys(services).length > 0) {
			const lastServiceKey = Object.keys(services).pop();
			setSelectedServiceName(lastServiceKey);
		}
	}, [services, selectedServiceName, setSelectedServiceName]);

	useEffect(() => {
		if (selectedServiceName && services) {
			const timeSlotMap = services[selectedServiceName]?.timeSlots;
			if (timeSlotMap) {
				const timeslots: [string, TDR.TimeSlot][] = sortBy(toPairs(services[selectedServiceName].timeSlots), [([time]) => time]);
				setTimeslots(timeslots);
			}
		}
	}, [selectedServiceName, services]);

	return (
		<section aria-label='Time Settings Section' id={HASH_ELEMENT_IDS.timeslots}>
			{isLoadingServices || isReleasingSession ? (
				<Skeleton width='auto' height='33px' />
			) : (
				<ServiceSelector
					searchFilters={searchFilters}
					selectedServiceName={selectedServiceName}
					setSelectedServiceName={setSelectedServiceName}
					servicesMap={services}
					onChange={handleServiceChange}
				/>
			)}

			{isLoadingServices || isReleasingSession ? (
				<TimeslotSkeleton halfHeight={!table} />
			) : (
				<TimeslotList
					restaurant={restaurant}
					table={table}
					date={searchFilters.date}
					defaultValue={searchFilters.time}
					service={selectedServiceName}
					timeslots={timeslots}
					onChange={handleTimeslotChange}
					hideFreePricing={hideFreePricing}
				/>
			)}
		</section>
	);
}
