import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { StripeWrapper } from '../components/checkout/StripeWrapper';
import { AddOns } from '../components/checkout/steps/AddOns';
import { Confirmation } from '../components/checkout/steps/Confirmation';
import { Details } from '../components/checkout/steps/Details';
import { Payment } from '../components/checkout/steps/Payment';
import { Policies } from '../components/checkout/steps/Policies';
import { fullCheckoutNavConfig } from '../components/checkout/steps/config';
import CookieConsent from '../components/cookie-consent/CookieConsentModal';
import { ApproveModification } from '../components/large-group-guest-actions/ApproveModification';
import { DeclineModification } from '../components/large-group-guest-actions/DeclineModification';
import { LargeGroupRequestOverview } from '../components/large-group-requests/LargeGroupRequestOverview';
import { LargeGroupRequestResult } from '../components/large-group-requests/LargeGroupRequestResult';
import { StripeWrapper as ModsStripeWrapper } from '../components/modifications/StripeWrapper';
import { InboundRequest } from '../components/search/InboundRequest';
import SearchAvailabilities from '../components/search/SearchAvailabilities';
import SearchTables from '../components/search/SearchTables';
import TableCarousel from '../components/table-carousel/TableCarousel';
import { FirebaseProvider } from '../context/FirebaseContext';
import { ModificationFlowProvider } from '../context/ModificationFlowProvider';
import { ViewToggleProvider } from '../context/ViewToggleProvider';
import { GlobalParameterProvider } from '../context/new/GlobalParameterContext';
import { MatterportProvider } from '../context/new/MatterportContext';
import { analytics } from '../helpers/analytics';
import { AppLayout } from '../layouts/AppLayout';
import { InboundRequestLayout } from '../layouts/InboundRequestLayout';
import { RestaurantListPage } from '../pages';
import { LandingPageBookingOptions } from '../pages/LandingPageBookingOptions';
import { LandingPageSeatingOptions } from '../pages/LandingPageSeatingOptions';
import { TableDetailsPage } from '../pages/TableDetailsPage';
import { NotFoundErrorPage } from '../pages/errors/NotFoundErrorPage';
import { ErrorBoundary } from '../pages/errors/UnexpectedErrorPage';
import { CancellationSuccess } from '../pages/modifications/CancellationSuccess';
import { ChangeSeatingPage } from '../pages/modifications/ChangeSeatingPage';
import { ConfirmRefundPage } from '../pages/modifications/ConfirmRefundPage';
import { EditAddOnsPage } from '../pages/modifications/EditAddOnsPage';
import { GuestDetailsPage } from '../pages/modifications/GuestDetailsPage';
import { ManageBookingPage } from '../pages/modifications/ManageBookingPage';
import { ModificationPaymentPage } from '../pages/modifications/ModificationPaymentPage';
import ModificationSearchTables from '../pages/modifications/ModificationSearchTables';
import { ReviewTablePage } from '../pages/modifications/ReviewTablePage';
import { AvailabilityGuard } from './AvailabilityGuard';
import { BookingGuard } from './BookingGuard';
import { CheckoutGuard } from './CheckoutGuard';
import { ModifiableBookingGuard } from './ModifiableBookingGuard';
import { PatioGuard } from './PatioGuard';
import { RestaurantGuard } from './RestaurantGuard';
import { SMSBookingGuard } from './SMSBookingGuard';
import { SkipTrafficGuard } from './SkipTrafficGuard';
import { TableGuard } from './TableGuard';
import { V2TableLinksGuard } from './V2TableLinksGuard';

export const Router = () => {
	const location = useLocation();

	useEffect(() => {
		analytics.page(location.pathname, {
			path: location?.pathname,
			search: location?.search,
			url: window.location.href
		});
	}, [location?.pathname, location?.search]);

	return useRoutes([
		// === Tablz Index Page ===
		{
			path: '/',
			element: <RestaurantListPage />
		},

		// === Restaurant Portal ===
		{
			path: ':restaurantSlug',
			element: (
				<Sentry.ErrorBoundary
					fallback={(props) => <ErrorBoundary {...props} />}
					beforeCapture={(scope) => {
						scope.setTag('location', 'App Root');
					}}
				>
					<SMSBookingGuard>
						<MatterportProvider>
							<GlobalParameterProvider>
								<RestaurantGuard>
									<SkipTrafficGuard />
								</RestaurantGuard>
								<CookieConsent />
							</GlobalParameterProvider>
						</MatterportProvider>
					</SMSBookingGuard>
				</Sentry.ErrorBoundary>
			),
			children: [
				{
					path: '',
					element: <LandingPageBookingOptions />
				},
				{
					path: ':tableSlug',
					element: <V2TableLinksGuard />
				},
				{
					path: 'seating',
					element: (
						<PatioGuard>
							<LandingPageSeatingOptions />
						</PatioGuard>
					)
				},
				{
					path: 'find-a-table',
					element: <Navigate to='../explore' />
				},
				{
					element: (
						<ViewToggleProvider>
							<AppLayout>
								<Sentry.ErrorBoundary
									fallback={(props) => <ErrorBoundary to='../' {...props} />}
									beforeCapture={(scope) => {
										scope.setTag('location', 'Availability');
									}}
								>
									<AvailabilityGuard />
								</Sentry.ErrorBoundary>
							</AppLayout>
						</ViewToggleProvider>
					),
					children: [
						{
							path: 'explore',
							children: [
								{
									element: <TableCarousel />,
									index: true
								},
								{
									path: 'filters',
									element: <SearchTables />
								},
								{
									path: 'request',
									element: <InboundRequestLayout />,
									children: [
										{ index: true, element: <InboundRequest /> },
										{ path: 'overview', element: <LargeGroupRequestOverview /> },
										{ path: 'result', element: <LargeGroupRequestResult /> }
									]
								},
								{
									path: ':tableSlug',
									element: <TableGuard />,
									children: [
										{
											index: true,
											element: <TableDetailsPage />
										},
										{
											path: 'availability',
											element: <SearchAvailabilities />
										}
									]
								}
							]
						},
						{
							path: 'checkout',
							children: [
								{ path: '', index: true, element: <Navigate to='../explore' replace /> },
								{
									path: ':checkoutId',
									element: <CheckoutGuard />,
									children: [
										{ path: fullCheckoutNavConfig.addons.path, element: <AddOns /> },
										{ path: fullCheckoutNavConfig.policies.path, element: <Policies /> },
										{ path: fullCheckoutNavConfig.details.path, element: <Details /> },
										{
											path: fullCheckoutNavConfig.payment.path,
											element: (
												<StripeWrapper>
													<Payment />
												</StripeWrapper>
											)
										}
									]
								},
								{
									path: ':checkoutId/confirmation',
									element: <Confirmation />
								}
							]
						}
					]
				},
				{
					path: '*',
					element: <NotFoundErrorPage />
				}
			]
		},

		// === Large Group Requests ===
		// These are the urls sent to the guest by email if we propose a new time for their request
		// Allows guest to approve or decline the modification
		{
			path: 'booking/:reservationId/approve',
			element: (
				<FirebaseProvider>
					<ApproveModification />
					<CookieConsent />
				</FirebaseProvider>
			)
		},
		{
			path: 'booking/:reservationId/decline',
			element: (
				<FirebaseProvider>
					<DeclineModification />
					<CookieConsent />
				</FirebaseProvider>
			)
		},

		// === Modification Flow ===
		{
			path: '/booking/:reservationId',
			element: (
				<Sentry.ErrorBoundary
					fallback={(props) => <ErrorBoundary {...props} />}
					beforeCapture={(scope) => {
						scope.setTag('location', 'Modification Root');
					}}
				>
					<MatterportProvider>
						<ViewToggleProvider>
							<ModificationFlowProvider>
								<BookingGuard />
								<CookieConsent />
							</ModificationFlowProvider>
						</ViewToggleProvider>
					</MatterportProvider>
				</Sentry.ErrorBoundary>
			),
			children: [
				{
					path: '',
					element: (
						<ModifiableBookingGuard>
							<ManageBookingPage />
						</ModifiableBookingGuard>
					)
				},
				{
					path: 'modify',
					element: (
						<ModifiableBookingGuard>
							<ModificationSearchTables />
						</ModifiableBookingGuard>
					)
				},
				{
					path: 'modify/seating-options',
					element: (
						<ModifiableBookingGuard>
							<ChangeSeatingPage />
						</ModifiableBookingGuard>
					)
				},
				{
					path: 'modify/seating-options/details',
					element: (
						<ModifiableBookingGuard>
							<ReviewTablePage />
						</ModifiableBookingGuard>
					)
				},
				{
					path: 'modify/guest',
					element: (
						<ModifiableBookingGuard>
							<GuestDetailsPage />
						</ModifiableBookingGuard>
					)
				},
				{
					path: 'modify/addons',
					element: (
						<ModifiableBookingGuard>
							<EditAddOnsPage />
						</ModifiableBookingGuard>
					)
				},
				{
					path: 'payment',
					element: (
						<ModifiableBookingGuard>
							<ModsStripeWrapper>
								<ModificationPaymentPage />
							</ModsStripeWrapper>
						</ModifiableBookingGuard>
					)
				},
				{
					path: 'refund',
					element: (
						<ModifiableBookingGuard>
							<ConfirmRefundPage />
						</ModifiableBookingGuard>
					)
				},
				{
					path: 'cancel-success',
					element: <CancellationSuccess />
				}
			]
		},
		// === Fallback (Page `not `found) ===
		{
			path: '*',
			element: <NotFoundErrorPage />
		}
	]);
};
