import React from 'react';
import ErrorIcon from '../../icons/x-hex.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { ReturnToBookingPortal } from './buttons';
import { CustomerSupportLink } from '../buttons';
import { BookingUpdateErrorProps } from './types';

export const BookingUpdateError = ({ linkTo }: BookingUpdateErrorProps) => {
	return (
		<ResultPageLayout headerIcon={ErrorIcon} iconAltText='Something went wrong'>
			<ResultPageLayout.Title>Oops!</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>There was a problem updating your booking.</p>
				<p>Please contact us at <CustomerSupportLink /> to resolve this.</p>

				<ResultPageLayout.Footer>
					{linkTo ? <ReturnToBookingPortal to={linkTo} /> : null}
				</ResultPageLayout.Footer>
			</ResultPageLayout.Body>
		</ResultPageLayout>
	);
};
