import React from 'react';
import { TDR } from 'tdr-common';
import StarIcon from '../../icons/star.svg';
import styles from './PriceLabel.module.scss';

type AddOnPriceLabelProps = {
	priceAmount: number;
	currency: TDR.Currency;
	displayUnit: string;
	isSignatureProduct: boolean;
};

const AddOnPriceLabel = ({ priceAmount, currency, displayUnit, isSignatureProduct }: AddOnPriceLabelProps) => {
	const { placement, symbol } = TDR.CurrencyConfig[currency || TDR.Currency.USD];

	const currencySymbol = <span className={styles.Price__Symbol}>{symbol}</span>;

	return (
		<div className={styles.Container}>
			{!!priceAmount && (
				<>
					{isSignatureProduct && (
						<span className={styles.Signature}>
							<img src={StarIcon} /> <span>signature</span>{' '}
						</span>
					)}
					<span className={styles.Price}>
						{placement === TDR.CurrencyPlacement.BEFORE && currencySymbol}
						<span>{priceAmount}</span>
						{placement === TDR.CurrencyPlacement.AFTER && currencySymbol}
						<span className={styles.Price__Unit}>{`/ ${displayUnit}`}</span>
					</span>
				</>
			)}
		</div>
	);
};

export default AddOnPriceLabel;
