import React from 'react';
import { SearchFilters, serializeSearchFiltersForURL } from '../../routes/AvailabilityGuard';
import CTALink from './CTALink';

export interface ShowAllButtonProps {
	searchFilters: Partial<SearchFilters>
	onClick: () => void;
}


export function ShowAllButton({ searchFilters, onClick }: ShowAllButtonProps) {
	return (
		<CTALink
			variant='primary'
			text='Show All Tables'
			to={{
				pathname: '..',
				search: serializeSearchFiltersForURL(searchFilters).toString()
			}}
			onClick={onClick}
		/>
	);
}