import React from 'react';
import CalendarIcon from '../../icons/round-calendar.svg';
import { ResultPageLayout } from '../../layouts/ResultPageLayout';
import { ReturnToBookingPortal } from './buttons';
import { CancelledAlreadyProps } from './types';

export const CancelledAlready = ({ restaurantName, date, time, email, linkTo }: CancelledAlreadyProps) => {
	return (
		<ResultPageLayout headerIcon={CalendarIcon} iconAltText='Cancellation Confirmed'>
			<ResultPageLayout.Title>Cancellation Confirmed</ResultPageLayout.Title>

			<ResultPageLayout.Body>
				<p>
          It appears your booking at <b>{restaurantName}</b> on <b>{date}</b> at <b>{time}</b> has already been
          cancelled.
				</p>

				<p>
          You should have received an email confirming your cancellation at <b>{email}</b>.
				</p>

				<ResultPageLayout.Footer>
					<ReturnToBookingPortal to={linkTo} />
				</ResultPageLayout.Footer>
			</ResultPageLayout.Body>
		</ResultPageLayout>
	);
};
