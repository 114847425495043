import React, { ReactNode, forwardRef } from 'react';
import styles from './ControlButton.module.scss';

type ControlButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
	icon?: ReactNode;
	iconSrc?: string;
	type: 'primary' | 'secondary';
	title?: string;
	isVisible?: boolean;
	className?: string;
}

const ControlButton = forwardRef<HTMLButtonElement, ControlButtonProps>(({ icon, iconSrc, type, isVisible = true, className, ...props }, ref) => {
	const classes = {
		primary: styles.VideoControlPrimary,
		secondary: styles.VideoControlSecondary
	};

	let iconComponent = icon;

	if (!iconComponent && iconSrc) {
		iconComponent = <img src={iconSrc} alt="" />;
	}

	// Combine provided className with the one from classes object
	const combinedClassName = `${classes[type]} ${className || ''}`.trim();

	return (
		<button
			ref={ref}
			className={combinedClassName}
			aria-label={props.title}
			style={{ opacity: isVisible ? 1 : 0 }}
			{...props}
		>
			{iconComponent}
		</button>
	);
});

ControlButton.displayName = 'ControlButton';

export default ControlButton;
