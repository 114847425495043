import { Country } from 'react-phone-number-input';
import { TDR } from 'tdr-common';

// Takes Alpha3('CAN') OR TDR.Country ('Canada') and converts to Alpha2 ('CA')
export const countryCodeConverter = (country: TDR.StripeRegion | TDR.Country): Country | undefined => {
	return countryToAlpha2(country as TDR.Country) ?? alpha3ToAlpha2(country as TDR.StripeRegion);
};

export const countryToAlpha2 = (country: TDR.Country): Country | undefined => {
	const alpha2Codes: Record<string, Country> = {
		CANADA: 'CA',
		'UNITED STATES': 'US',
		'UNITED ARAB EMIRATES': 'AE'
	};
	return alpha2Codes[country.toLocaleUpperCase()];
};

export const alpha3ToAlpha2 = (alpha3: TDR.StripeRegion): Country | undefined => {
	const alpha2Codes: Record<string, Country> = {
		CAN: 'CA',
		USA: 'US',
		UAE: 'AE'
	};
	return alpha2Codes[alpha3.toLocaleUpperCase()];
};
