import React from 'react';
import Checked from '../../icons/checked.svg';
import UncheckedFilled from '../../icons/uncheckedfilled.svg';

import styles from './AgreementCheckbox.module.scss';

type AgreementCheckboxProps = {
	id: string;
	label?: string | React.ReactElement | React.ReactElement[];
	checked: boolean;
	labelVariant?: 'small' | 'default';
} & React.InputHTMLAttributes<HTMLInputElement>;

const AgreementCheckbox = React.forwardRef<HTMLInputElement, AgreementCheckboxProps>(({ id, label, checked, labelVariant = 'default', ...props }, ref) => {

	return (
		<div ref={ref} className={styles.Container}>
			<input
				id={id}
				type="checkbox"
				className={styles.Input}
				{...props}
				data-cy="agreementCheckbox"
			/>
			<img
				className={styles.Icon}
				src={checked ? Checked : UncheckedFilled}
				alt={checked ? 'Checked' : 'Unchecked'}
			/>
			{label && <label htmlFor={id} className={`${styles.Label} ${styles[`Label--${labelVariant}`]}`}>{label}</label>}
		</div>
	);
});

AgreementCheckbox.displayName = 'Agreement Checkbox';

export default AgreementCheckbox;