import { motion, useScroll, useTransform, Variants } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { useSearch } from '../../api/availability/search';
import { useTableWithPolicies } from '../../api/getTable';
import { useTimeslot } from '../../api/getTimeslot';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import { useViewToggle } from '../../context/ViewToggleProvider';
import { useScreenSize } from '../../hooks/useScreenSize';
import { SearchFilters } from '../../routes/AvailabilityGuard';
import { ModifyBookingFormData } from '../../routes/BookingGuard';
import { CloseButtonNonLink } from '../buttons/CloseButtonNonLink';
import { SquareIconButton } from '../buttons/SquareIconButton';
import { PolicyItemsLGBNonCheckout } from '../checkout/PolicyItemsLGBNonCheckout';
import { DiscardChanges } from '../dialog/controlled-dialog/DiscardChanges';
import { EventCard } from '../events/EventCard';
import MediaCarousel from '../media-carousel/MediaCarousel';
import PolicySection from '../policies/PolicySection';
import { ReviewFooter } from '../search/ReviewFooter';
import { Skeleton } from '../skeleton/Skeleton';
import { HeaderLarge } from './HeaderLarge';
import { HeaderSmall } from './HeaderSmall';
import styles from './TableDrawer.module.scss';
import { TableDrawerTags } from './TableDrawerTags';

const MEDIA_HEIGHT = 374;

type TableDrawerModsProps = {
	selectedTable: TDR.Table;
	restaurant: TDR.Restaurant;
	reservationId: string;
	searchFilters: SearchFilters;
};

const TableDrawerMods = ({ selectedTable, restaurant, reservationId, searchFilters }: TableDrawerModsProps) => {
	const { data: tableWithPolicies } = useTableWithPolicies({
		id: selectedTable.id,
		searchParams: searchFilters,
		location: 'table'
	});

	const { results, isLoading } = useSearch({
		params: searchFilters,
		restaurantId: restaurant.id,
		space: [selectedTable]
	});

	const navigate = useNavigate();

	const { data } = useTimeslot(selectedTable?.id, searchFilters.date, restaurant.id, searchFilters.time);
	const { isSmallScreen } = useScreenSize();
	const { isVirtualView } = useViewToggle();

	const { setNewInvoice } = useModificationFlowState();

	const scrollContentRef = useRef<HTMLDivElement>(null);

	const methods = useFormContext<ModifyBookingFormData>();

	const timeslot = data?.timeSlot;
	const displayUnit = timeslot?.isPerGuest ? 'guest' : `${selectedTable.label ?? 'table'}`;

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	const coverImagePath = selectedTable?.carouselImagePath;
	let mediaItems = selectedTable?.media || [];

	if (isSmallScreen || mediaItems.length === 0) {
		mediaItems = [{ type: TDR.MediaType.IMAGE, path: coverImagePath } as TDR.MediaContent, ...mediaItems];
	}
	else {
		mediaItems.length > 0 ? mediaItems : [{ type: TDR.MediaType.IMAGE, path: coverImagePath }];
	}
	const conflictingFilter = results?.resultMap?.[selectedTable.id]?.conflict?.[0] || undefined;

	const resetFormAndExit = () => {
		methods.reset();
		setNewInvoice(undefined);
		navigate(`/booking/${reservationId}`);
	};

	const handleExitClick = () => {
		if (methods.formState.isDirty) {
			setIsConfirmOpen(true);
		}
		else {
			resetFormAndExit();
		}
	};

	const handleConfirmExit = () => {
		resetFormAndExit();
		setIsConfirmOpen(false);
	};

	const handleCancelExit = () => {
		setIsConfirmOpen(false);
	};

	const mediaVariants: Variants = {
		visible: {
			height: `${MEDIA_HEIGHT}px`,
			minHeight: `${MEDIA_HEIGHT}px`,
			transition: {
				delay: 0.4,
				duration: 0.3
			}
		},
		hidden: {
			height: '100vh',
			transition: {
				duration: 0.5
			}
		}
	};

	const slideAndFade: Variants = {
		visible: {
			y: 0,
			opacity: 1,
			transition: {
				duration: 0.5,
				delay: 0.4
			}
		},
		hidden: {
			y: '100%',
			opacity: 0,
			transition: {
				duration: 0.5
			}
		}
	};

	const containerVariants: Variants = {
		visible: {
			y: 0,
			opacity: 1
		},
		hidden: {
			y: '100%',
			opacity: 0
		}
	};

	const { scrollY } = useScroll({
		container: scrollContentRef
	});

	const headerLgOpacity = useTransform(scrollY, [300, MEDIA_HEIGHT], [1, 0]);

	const headerSmOpacity = useTransform(scrollY, [350, MEDIA_HEIGHT], [0, 1]);
	const headerSmTranslateY = useTransform(scrollY, [300, MEDIA_HEIGHT], [-100, 0]);

	const mediaGalleryOpacity = useTransform(scrollY, [200, MEDIA_HEIGHT], [1, 0]);

	// For determining initial animation states of <motion /> elements
	//  - if component has not mounted yet, set initial to 'visible' to cancel animations
	//     (these animations are only used within the already mounted component, depending on isVirtualView state)
	useEffect(() => {
		setIsMounted(true);
	}, []);

	return (
		<>
			<motion.article
				className={styles.Container}
				variants={containerVariants}
				initial='hidden'
				animate='visible'
				exit={'hidden'}
				transition={{ duration: 0.4 }}
			>
				<CloseButtonNonLink className={styles.CloseButton} onClick={handleExitClick} />

				<div className={styles.BackButton}>
					<SquareIconButton variant='back' onClick={() => navigate(-1)} />
				</div>

				<div className={styles.ScrollContent} ref={scrollContentRef}>
					<motion.div
						initial={isMounted ? 'hidden' : 'visible'}
						style={{ opacity: mediaGalleryOpacity }}
						animate={isVirtualView ? 'hidden' : 'visible'}
						variants={mediaVariants}
					>
						{!coverImagePath ? (
							<Skeleton height='270px' width='100%' />
						) : (
							<MediaCarousel
								variant={'modification'}
								tableLabel={selectedTable.label}
								itemHeight={MEDIA_HEIGHT}
								items={mediaItems}
								brightness={restaurant.brightness}
								contrast={restaurant.contrast}
							/>
						)}
					</motion.div>

					<motion.div
						className={styles.HeaderLg}
						style={{ opacity: headerLgOpacity }}
						variants={slideAndFade}
						animate={isVirtualView ? 'hidden' : 'visible'}
					>
						<HeaderLarge table={selectedTable} />
					</motion.div>

					<motion.div className={styles.HeaderSm} style={{ opacity: headerSmOpacity, y: headerSmTranslateY }}>
						<HeaderSmall table={selectedTable} variant={'modification'} />
					</motion.div>

					<motion.div
						className={styles.Body}
						initial={isMounted ? 'hidden' : 'visible'}
						animate={isVirtualView ? 'hidden' : 'visible'}
						variants={slideAndFade}
					>
						<div className={styles.Description} tabIndex={0}>
							<p>{selectedTable?.description}</p>
						</div>

						<TableDrawerTags table={selectedTable} bookingDuration={timeslot?.resDuration} />
						{tableWithPolicies?.events?.length > 0 ? (
							<Carousel
								showArrows={false}
								showIndicators={false}
								swipeable={true}
								showStatus={false}
								emulateTouch={true}
								showThumbs={false}
								infiniteLoop={true}
								preventMovementUntilSwipeScrollTolerance={true}
								className={styles.EventCarousel}
							>
								{tableWithPolicies.events.map((event) => {
									return <EventCard event={event} key={event.name} />;
								})}
							</Carousel>
						) : null}
						{tableWithPolicies?.policies?.length > 0 ? (
							<div className={styles.Policies} tabIndex={0}>
								{tableWithPolicies?.policies?.length > 0 ? (
									<PolicySection table={tableWithPolicies} />
								) : selectedTable.supportLargeGroup ? (
									<PolicyItemsLGBNonCheckout tableId={selectedTable.id} restaurant={restaurant} />
								) : null}
							</div>
						) : null}
					</motion.div>
				</div>

				<motion.footer
					className={styles.Footer}
					initial={isMounted ? 'hidden' : 'visible'}
					animate={isVirtualView ? 'hidden' : 'visible'}
					variants={slideAndFade}
				>
					{isLoading ? (
						<Skeleton width='100%' height='56px' />
					) : (
						<ReviewFooter
							priceAmount={timeslot?.regularPrice}
							displayUnit={displayUnit}
							selectedTable={selectedTable}
							restaurant={restaurant}
							conflictingFilter={conflictingFilter}
						/>
					)}
				</motion.footer>
			</motion.article>

			<DiscardChanges
				open={isConfirmOpen}
				onClose={() => setIsConfirmOpen(false)}
				onDiscard={handleConfirmExit}
				onCancel={handleCancelExit}
			/>
		</>
	);
};

export default TableDrawerMods;
