import React from 'react';
import QuestionIcon from '../../icons/question-mark.svg';
import { Notice } from './Notice';

type NoInventoryNoticeProps = {
	guests: number;
};

export const NoInventoryNotice = ({ guests }: NoInventoryNoticeProps) => {
	const message = (
		<p>
      Currently, no tables are available for{' '}
			<span>
				{guests} guest{guests > 1 ? 's' : ''}{' '}
			</span>
      on your chosen date and time.
		</p>
	);

	return (
		<Notice body={message} icon={<img src={QuestionIcon} alt={'warning'} />} position='bottom' />
	);
};
