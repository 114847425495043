import React, { useEffect } from 'react';

import { useOutletContext } from 'react-router-dom';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';
import { TableGuardOutletContext } from '../../routes/TableGuard';
import { BookNowButton } from '../buttons/BookNowButton';
import { PatioInfoSection } from '../callouts/PatioInfoSection';
import SearchResultPricing from '../price-label/PriceLabel';

import styles from './BookNowFooter.module.scss';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import {
	analytics,
	EXPERIMENT_VIEWED,
	SIMPLIFIED_CTA_EXPERIMENT
} from '../../helpers/analytics';
import { motion } from 'framer-motion';
import { RANDOM_TRUE_OR_FALSE } from '../notice/experiments/constants';
import SimplifiedPriceLabel from '../price-label/SimplifiedPriceLabel';

export interface BookNowFooterProps {
	priceAmount: number;
	priceAmountTotaled?: number;
	displayUnit: string;
	disabled?: boolean;
}

export const BookNowFooter = ({ priceAmount, displayUnit, disabled = false, priceAmountTotaled }: BookNowFooterProps) => {
	const { table: selectedTable } = useOutletContext<TableGuardOutletContext>();
	const { restaurant, featureFlags } = useOutletContext<RestaurantGuardOutletContext>();
	const hidePricing = !!featureFlags?.hideFreePricing;
	const ctaExperimentEnabled = !!featureFlags?.ctaExperiment;

	const [simplifiedCtaExperiment] = useSessionStorage(
		SIMPLIFIED_CTA_EXPERIMENT.storage_key,
		{ enabled: RANDOM_TRUE_OR_FALSE }
	);

	useEffect(() => {
		if(ctaExperimentEnabled && simplifiedCtaExperiment) {
			analytics.track(EXPERIMENT_VIEWED, {
				experiment: SIMPLIFIED_CTA_EXPERIMENT.experiment_name,
				variant: simplifiedCtaExperiment.enabled ?
					SIMPLIFIED_CTA_EXPERIMENT.variants.simplified.name :
					SIMPLIFIED_CTA_EXPERIMENT.variants.original.name
			});
		}
	},[simplifiedCtaExperiment, ctaExperimentEnabled]);

	const showSimpleCTA = ctaExperimentEnabled && simplifiedCtaExperiment?.enabled;

	const text = selectedTable.supportLargeGroup ? hidePricing ? 'Proceed With Request' : 'Request' : 'Book Now';

	const simpleCtaText = selectedTable.supportLargeGroup ? `Request ${selectedTable.label || 'table'}`: `Select ${selectedTable.label || 'table'}`;

	return (
		<>
			{selectedTable?.isPatio && <PatioInfoSection />}

			{showSimpleCTA ?
				<div className={`${styles.SimplifiedContainer}`}>
					<motion.div
						layout
					>
						<SimplifiedPriceLabel
							restaurant={restaurant}
							priceAmountTotaled={priceAmountTotaled}
						/>
					</motion.div>
					<motion.div
						layout
						style={{
							flex: 1
						}}
					>
						<BookNowButton disabled={disabled} text={simpleCtaText} />
					</motion.div>
				</div>
				:
				<div className={`${styles.Container} ${hidePricing ? '' : styles['Container--grid']}`}>
					{!hidePricing && <SearchResultPricing
						restaurant={restaurant}
						priceAmount={priceAmount}
						displayUnit={displayUnit}
					/>}

					<BookNowButton disabled={disabled} text={text} />
				</div>
			}
		</>
	);
};
