import PlusIcon from 'iconsax-react/dist/esm/Add';
import React from 'react';
import { TDR } from 'tdr-common';
import X from '../../icons/x.svg';
import {
	ImageCard,
	ImageCardAsset,
	ImageCardDescription,
	ImageCardFooter,
	ImageCardHeader,
	ImageCardHeaderPrimary,
	ImageCardHeaderSecondary,
	ImageCardHeaderTitle
} from '../image-card/ImageCard';
import { InputStepper } from '../input-stepper/InputStepper';
import AddOnPriceLabel from '../price-label/AddOnPriceLabel';
import styles from './AddOnItem.module.scss';
import { AddOnProduct } from './steps/AddOns';

type AddOnItemProps = {
	product: AddOnProduct;
	restaurant: TDR.Restaurant;
	updateAddOns: (id: string, quantity: number, variantId: string) => void;
	initialQuantity?: number;
};

export const AddOnItem = ({ product, restaurant, updateAddOns, initialQuantity = 0 }: AddOnItemProps) => {
	const handleAddOnChange = (quantity: number) => {
		updateAddOns(product.id, quantity, product.variantId);
		product.description && setShowDescription(true);
	};
	const [showDescription, setShowDescription] = React.useState(false);

	return (
		<div className={styles.Item}>
			<ImageCard height={416}>
				<ImageCardAsset src={product.imageUrl} alt={product.title} />

				<ImageCardHeader>
					<ImageCardHeaderPrimary>
						<ImageCardHeaderTitle>{product.title}</ImageCardHeaderTitle>
					</ImageCardHeaderPrimary>

					<ImageCardHeaderSecondary onClick={() => setShowDescription(!showDescription)}>
						{showDescription ? 'Close' : 'Info'}
						{showDescription ? <img src={X} alt='Close button' /> : <PlusIcon />}
					</ImageCardHeaderSecondary>
				</ImageCardHeader>

				<ImageCardDescription isVisible={showDescription}>
					<p>{product.description}</p>
				</ImageCardDescription>

				{product.tag && (
					<ImageCardFooter>
						<span>{product.tag?.toUpperCase()}</span>
					</ImageCardFooter>
				)}
			</ImageCard>

			<div className={styles.Info}>
				<AddOnPriceLabel
					priceAmount={product.price.amount}
					currency={restaurant.currency}
					displayUnit={product.unit}
					isSignatureProduct={product.isSignatureItem}
				/>
				<InputStepper onChange={handleAddOnChange} initialCount={initialQuantity} />
			</div>
		</div>
	);
};
