import { deviceType } from 'react-device-detect';
import config from '../common/config';

// add better typing to library variable
type DeviceType = 'mobile' | 'tablet' | 'browser'; // browser == desktop
const DEVICE = deviceType as DeviceType;

const MAX_IMG_WIDTH_MAP: Record<DeviceType, number> = {
	mobile: 640,
	tablet: 960,
	browser: 1920
};

const DEFAULT_MAX_IMG_WIDTH = MAX_IMG_WIDTH_MAP.browser;

type ImageOptions = {
	fit?: 'cover' | 'contain' | 'crop' | 'scale-down' | 'pad';
	width?: number;
	height?: number;
	dpr?: 1 | 2;
};

export function getCdnImageUri(
	assetPath: string,
	options?: ImageOptions
): string {
	const cdnAssetPath = `/cdn-cgi/image/${buildOptionsString(options, DEVICE)}${assetPath}`;
	return new URL(cdnAssetPath, config.cdnHost).toString();
}

export function getCdnVideoUri(
	assetPath: string
): string {
	return new URL(assetPath, config.cdnHost).toString();
}

const buildOptionsString = (options: ImageOptions, device: DeviceType) => {
	const defaultWidth = MAX_IMG_WIDTH_MAP[device] || DEFAULT_MAX_IMG_WIDTH;

	const optionStrings = [];

	const addOption = (name: string, value?: string | number) => {
		if (value) {
			optionStrings.push(`${name}=${value}`);
		}
	};

	addOption('fit', options?.fit);
	addOption('dpr', options?.dpr);
	addOption('height', options?.height);
	addOption('width', options?.width || defaultWidth);

	return optionStrings.join(',');
};
