import { DateTime } from 'luxon';
import React from 'react';
import { TDR } from 'tdr-common';
import { analytics, LINK_CLICKED_EVENT } from '../../helpers/analytics';
import ArrowOutward from '../../icons/arrow-outward.svg';
import RestaurantEmailLink from '../buttons/RestaurantEmailLink';
import RestaurantPhoneLink from '../buttons/RestaurantPhoneLink';
import { InfoCallout } from './InfoCallout';
import styles from './NoTimeslotsAvailable.module.scss';

export interface NoTimeSlotsAvailableProps {
	restaurant: TDR.Restaurant;
	date: string;
	service: string;
	tableName?: string;
}

export const NoTimeSlotsAvailable = ({ restaurant, service, tableName, date }: NoTimeSlotsAvailableProps) => {
	const altPlatform = restaurant.settings?.alternativePlatform?.vendor;
	const altPlatformHref = restaurant.settings?.alternativePlatform?.href;

	const contactByPhone = altPlatform === 'phone';
	const contactByEmail = altPlatform === 'email';

	const canBookWithAltPlatform = !contactByEmail && !contactByPhone && !!altPlatform && !!altPlatformHref;

	const handleThirdPartyRMSClick = () => {
		analytics.track(LINK_CLICKED_EVENT, {
			href: altPlatformHref,
			purpose: 'Redirect to RMS from No Timeslots Available Callout',
			platform: altPlatform
		});
	};

	const getActionText = () => {
		if (contactByPhone && !!restaurant.phoneNumber) {
			return (
				<span className={styles.Block}>
          Please try another time, or call the restaurant directly at <RestaurantPhoneLink phoneNumber={restaurant.phoneNumber} />.
				</span>
			);
		}

		if (contactByEmail && restaurant.contactEmail) {
			return (
				<span className={styles.Block}>
          Please try another time, or email the restaurant at <RestaurantEmailLink email={restaurant?.contactEmail} />.
				</span>
			);
		}

		if (canBookWithAltPlatform) {
			return (
				<span className={styles.Block} >
          Please try another time, or search for availability on{' '}
					<a
						href={altPlatformHref}
						target='_blank'
						onClick={handleThirdPartyRMSClick}
						rel='noreferrer'
						className={styles.Link}
					>
						{TDR.RMSDisplayNameMap[altPlatform]} <img src={ArrowOutward} />
					</a>
				</span>
			);
		}
	};

	const formattedDate = DateTime.fromISO(date).toFormat('LLLL dd');

	return (
		<InfoCallout>
			<span>
        Unfortunately, {tableName ? `${tableName} is not available` : 'there is no availability'}{' '}
				{service ? `for ${service}` : ''} on {formattedDate} through Tablz.
			</span>

			{getActionText()}
		</InfoCallout>
	);
};
