import React from 'react';
import enterfullscreen from '../../../icons/fullscreen-enter.svg';
import exitfullscreen from '../../../icons/fullscreen-exit.svg';
import ControlButton from './ControlButton';

type ToggleFullscreenProps = React.HTMLAttributes<HTMLButtonElement> & {
	isFullscreen: boolean;
	onClick: () => void;
}

const ToggleFullscreen = ({ isFullscreen, onClick, ...props }: ToggleFullscreenProps) => {
	return (
		<ControlButton
			type='secondary'
			title={isFullscreen ? 'Exit full screen' : 'Full screen'}
			iconSrc={isFullscreen ? exitfullscreen : enterfullscreen}
			onClick={onClick}
			{...props}
		/>
	);
};

export default ToggleFullscreen;