import { TDR } from 'tdr-common';
import config from '../common/config';
import Guest = TDR.Guest;

export async function fastCheckoutCheck(input: Guest.fastCheckoutCheckInput): Promise<Guest.fastCheckoutCheckResponse> {
	try {
		const response = await fetch(`${config.apiHost}/fastCheckoutCheck`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(input)
		});
		return await response.json() as Guest.fastCheckoutCheckResponse;
	}
	catch (e) {
		console.error(e);
		return {
			success: false,
			message: e
		};
	}
}
export async function sendVerificationSMS(input: Guest.sendVerificationSMSInput): Promise<Guest.sendVerificationSMSResponse> {
	try {
		const response = await fetch(`${config.apiHost}/sendVerificationSMS`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(input)
		});
		return await response.json() as Guest.sendVerificationSMSResponse;
	}
	catch (e) {
		console.error(e);
		return {
			success: false,
			message: e
		};
	}
}

export async function verifyCode(input: Guest.verifyCodeInput): Promise<Guest.verifyCodeResponse> {
	try {
		const response = await fetch(`${config.apiHost}/verifyCode`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(input)
		});
		return await response.json() as Guest.verifyCodeResponse;
	}
	catch (e) {
		console.error(e);
		return {
			success: false,
			message: e
		};
	}
}
export async function enableFastCheckout(input: Guest.enableFastCheckoutInput): Promise<Guest.enableFastCheckoutResponse> {
	try {
		const response = await fetch(`${config.apiHost}/enableFastCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(input)
		});
		return await response.json() as Guest.enableFastCheckoutResponse;
	}
	catch (e) {
		console.error(e);
		return {
			success: false,
			message: e
		};
	}
}
