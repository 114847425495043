import React, { useContext, useState, useEffect } from 'react';
import { PRODUCTION } from '../common/config';
import { FirebaseContext } from '../context/FirebaseContext';
import { TDR } from 'tdr-common';
import styles from './RestaurantListPage.module.scss';
import getRestaurants from '../api/getRestaurants';

export const RestaurantListPage = () => {

	const { initialized } = useContext(FirebaseContext);
	const [restaurants, setRestaurants] = useState<TDR.Restaurant[]>([]);
	const [ search, setSearch ] = useState<string>('');
	const [ filteredRestaurants, setFilteredRestaurants ] = useState<TDR.Restaurant[]>([]);

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearch(value);

		const filtered = restaurants.filter(restaurant => {
			return restaurant.name.toLowerCase().includes(value.toLowerCase());
		});

		setFilteredRestaurants(filtered);
	};

	useEffect(() => {
		if (PRODUCTION || !initialized) {
			return;
		}
		getRestaurants().then(restaurants => {
			setRestaurants(restaurants);
		});
	}, [initialized]);

	return (
		<div style={{ height:'100%', width:'100%', display: 'flex', flexDirection:'column', alignItems: 'center' }}>

			<img src='/images/poweredBy/tablz.svg' height='auto' style={{ minHeight: '200px' }} width='50%' alt='Tablz' />

			{!PRODUCTION && (
				<div style={{ width: 'clamp(325px, 90%, 600px)' }}>
					{/* Create a search bar to find and sort restos */}
					<label>Search restaurants...</label>
					<input
						type='text'
						className={styles.restaurantSearchInput}
						value={search}
						onChange={handleSearch}
						color='secondary'
					/>
				</div>
			)}

			<ul className={styles.restaurantList} style={{ display: 'flex', flexDirection:'column', alignItems: 'center', overflow: 'scroll' }}>
				{!PRODUCTION && (!search ? restaurants : filteredRestaurants).map((restaurant) => (
					<li key={restaurant.id}>
						<a href={`/${restaurant.slug}`}>{restaurant.name}</a>
					</li>
				))}
			</ul>
		</div>
	);
};