import React from 'react';
import { Link } from 'react-router-dom';
import { TDR } from 'tdr-common';
import layoutStyles from '../../layouts/ModificationLayout.module.scss';
import { AddOnOrderSummary } from '../checkout/AddOnOrderSummary';
import { SubHeader } from '../headers/SubHeader';
import styles from './AddOns.module.scss';
import { InfoCallout } from '../callouts/InfoCallout';

type AddOnsProps = {
	addOns: TDR.Reservation.Item[];
	restaurant: TDR.Restaurant;
};

export const AddOns = ({ addOns, restaurant }: AddOnsProps) => {
	return (
		<div className={layoutStyles.Section}>
			<div className={layoutStyles.SectionHeader}>
				<SubHeader title='Add-Ons' />
				<Link to='modify/addons' aria-label='Edit Add Ons' className={styles.HeaderButton}>
					<span>Edit</span>
				</Link>
			</div>
			{addOns.every((item) => item.quantity === 0) ? (
				<InfoCallout>
					<span>No add-on items selected</span>
				</InfoCallout>
			) : (
				<AddOnOrderSummary addOns={addOns} currency={restaurant.currency} />
			)}
		</div>
	);
};
