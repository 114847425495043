import React, { ForwardedRef } from 'react';

type SpinnerProps = {
	text?: string,
	icon?: any,
	size?: 'xxs' | 'xs' | 'fullpage',
	id?: string;
}

const Spinner = React.forwardRef<HTMLDivElement, SpinnerProps>(
	({ text, icon, size, id }, ref: ForwardedRef<HTMLDivElement>) => {
		return (
			<div
				ref={ref}
				id={id} // Ensuring 'id' is applied to the div
				className={`spinner ${size ? `spinner--${size}` : ''}`}
				role="alert"
				aria-label={text || 'Loading'}
				aria-busy="true"
			>
				<div className='spinner__content'>
					{icon && <img src={icon} className='spinner__icon' alt='Loading icon' />}
					{text && <span className='spinner__text'>{text}</span>}
				</div>
			</div>
		);
	}
);

Spinner.displayName = 'Spinner';

export default Spinner;
