import People from 'iconsax-react/dist/esm/People';
import React, { useEffect } from 'react';
import { TDR } from 'tdr-common';
import RestaurantEmailLink from '../../buttons/RestaurantEmailLink';
import RestaurantPhoneLink from '../../buttons/RestaurantPhoneLink';
import { Notice } from '../../notice/Notice';
import { analytics } from '../../../helpers/analytics';

export const DirectLGBInboundNotice = ({
	restaurant,
	maxGroupSize
}: {
	restaurant: TDR.Restaurant;
	maxGroupSize: number;
}) => {

	useEffect(() => {
		analytics.track('Notice Viewed', {
			notice: 'DirectLGBInboundNotice',
			restaurantId: restaurant.id,
			maxGroupSize
		});
	}, []);

	return (
		<>
			<LargeGroupInboundNoticeInfoBox maxGroupSize={maxGroupSize} restaurantName={restaurant?.name} />
			{restaurant?.phoneNumber ? <RestaurantPhoneLink iconVariant phoneNumber={restaurant?.phoneNumber} /> : null}
			{restaurant?.contactEmail ? <RestaurantEmailLink iconVariant email={restaurant?.contactEmail} /> : null}
		</>
	);
};

export interface LargeGroupInboundNoticeInfoBoxProps {
	maxGroupSize?: number;
	restaurantName: string;
}

const LargeGroupInboundNoticeInfoBox = ({ maxGroupSize, restaurantName }: LargeGroupInboundNoticeInfoBoxProps) => {
	const body = (
		<p>
      For groups exceeding <span>{maxGroupSize}</span> guests, <span>{restaurantName}</span> requires you to reach out
      directly for inquires on availability.
		</p>
	);
	return <Notice body={body} icon={<People />} position='bottom' />;
};
