import React from 'react';
import { TDR, formatMonetaryValue } from 'tdr-common';
import NoShow from '../../../icons/additionalfee.svg';
import Clock from '../../../icons/clock.svg';
import Credit from '../../../icons/credit.svg';
import Deposit from '../../../icons/deposit.svg';
import Refund from '../../../icons/refund.svg';
import { InfoDialog } from './InfoDialog';
import { UnderlinedText } from '../../typography/UnderlinedText';
import styles from './CancellationPolicy.module.scss';

export type CancellationPolicyProps = {
	restaurant: TDR.Restaurant;
	cancelCutoff: string;
	invoice: Partial<TDR.Invoice>;
};

export const CancellationPolicy = ({ restaurant, cancelCutoff, invoice }: CancellationPolicyProps) => {
	const { lineItems } = invoice || { lineItems: [] };
	const depositsPayment = lineItems.reduce((totalDeposits, lineItem) => totalDeposits + (lineItem.deposit || 0), 0);

	const body = (
		<ul className={styles.InfoList}>
			<li>
				<img src={Clock} className='welcomePageContentImage' alt={'Clock Icon'} />
				{cancelCutoff === '0 hours' ? (
					<div className='welcomePageContentBody'> Cancellations made past your booked time will not be refunded.</div>
				) : (
					<div className='welcomePageContentBody'>
						{' '}
            The cancellation window is up to {cancelCutoff} prior to your scheduled booking. Cancellations made past
            this time will not be eligible for any refunds (if applicable).
					</div>
				)}
			</li>
			<li>
				<img src={Credit} className='welcomePageContentImage' alt={'Credit Icon'} />
				<div className='welcomePageContentBody'>
          Modifications, however, are allowed past the cancellation window. The original amount paid will be applied as
          credit towards your modified booking. It will remain non-refundable
				</div>
			</li>
			{!!depositsPayment && (
				<li>
					<img src={Deposit} className='welcomePageContentImage' alt={'Deposit Icon'} />
					{depositsPayment > 0 ? (
						<div className='welcomePageContentBody'>
              A deposit of {formatMonetaryValue(depositsPayment, restaurant.currency)} is required to confirm your
              booking, and it will be applied towards your final bill at the restaurant. The deposit is also
              non-refundable past the cancellation window.
						</div>
					) : (
						<div className='welcomePageContentBody'>
              Deposits are sometimes required to confirm your booking. This fee is charged to you upfront and will be
              applied toward your final bill at the restaurant. The deposit is also non-refundable past the cancellation
              window.
						</div>
					)}
				</li>
			)}
			{restaurant.allowNoShowFees && (
				<li>
					<img src={NoShow} className='welcomePageContentImage' alt={'No Show Icon'} />
					<div className='welcomePageContentBody'>{restaurant.feeDisclaimer}</div>
				</li>
			)}
			<li>
				<img src={Refund} className='welcomePageContentImage' alt={'Refund Icon'} />
				<div className='welcomePageContentBody'>
          Refunds will be issued to your original form of payment within <b style={{ fontFamily: 'Codec Pro Bold' }}>{TDR.DEFAULT_REFUND_PROCESSING_DAYS}</b>{' '}
          business days.
				</div>
			</li>
		</ul>
	);

	return (
		<InfoDialog
			title='Cancellation Policy'
			body={body}
			triggerButton={<UnderlinedText>Cancellation policy</UnderlinedText>}
		/>
	);
};
