import { ACCEPT_ALL_PREFERENCES } from '../../components/cookie-consent/preferences';
import { MiddlewareFunction, Preferences } from '../../components/cookie-consent/types';
import { appVersionMiddleware, sanitizedPropertiesMiddleware, userContextMiddleware } from './middleware';
import { ALL_TOOLS, BEHAVIORAL_TOOLS } from './tools';

export class AnalyticsWithMiddleware {
	private middlewares: MiddlewareFunction[] = [];
	private isLoaded = false;

	constructor() {
		this.addSourceMiddleware(appVersionMiddleware);
		this.addSourceMiddleware(sanitizedPropertiesMiddleware);
		this.addSourceMiddleware(userContextMiddleware);
	}

	load(userPreferances: Preferences = ACCEPT_ALL_PREFERENCES) {
		ALL_TOOLS.forEach(t => {
			t.load(userPreferances);
		});

		this.isLoaded = true;
	}

	addSourceMiddleware(middleware: MiddlewareFunction) {
		this.middlewares.push(middleware);
	}

	removeSourceMiddleware(middleware: MiddlewareFunction) {
		this.middlewares = this.middlewares.filter((m) => m !== middleware);
	}

	private applyMiddlewares(payload: any, next: (payload: any) => void) {
		const [firstMiddleware, ...rest] = this.middlewares;

		const chain = rest.reduceRight(
			(nextMiddleware, currentMiddleware) => {
				return (p) => currentMiddleware({ payload: p, next: nextMiddleware });
			},
			next
		);

		firstMiddleware({ payload, next: chain });
	}

	track(event: string, properties: any = {}) {
		if (!this.isLoaded) {
			return;
		}
		const payload = { obj: { event, properties } };

		this.applyMiddlewares(payload, (finalPayload) => {
			BEHAVIORAL_TOOLS.forEach(t => t.track(
				finalPayload.obj.event,
				finalPayload.obj.properties
			));
		});
	}

	identify(userId: string, traits: any = {}) {
		if (!this.isLoaded) {
			return;
		}
		const payload = { obj: { userId, traits } };

		this.applyMiddlewares(payload, (finalPayload) => {
			ALL_TOOLS.forEach(t => t.identify(finalPayload.obj.userId, finalPayload.obj.traits));
		});
	}

	page(name: string, properties: any = {}) {
		if (!this.isLoaded) {
			return;
		}
		const payload = { obj: { name, properties } };

		this.applyMiddlewares(payload, (finalPayload) => {
			BEHAVIORAL_TOOLS.forEach(t => t.track(
				'Loaded a Page',
				finalPayload.obj.properties
			));
		});
	}
}

const analytics = new AnalyticsWithMiddleware();

export { analytics };
