import { DateTime } from 'luxon';

export const timeStringToEpoch = (currentEpochInSeconds: number, timeString: string, timezone: string): number => {
	// Convert the given epoch time to a Luxon DateTime object
	const dateTime = DateTime.fromSeconds(currentEpochInSeconds).setZone(timezone);

	// Extract the date components
	const { year, month, day } = dateTime;

	// Parse the time string
	const [hour, minute] = timeString.split(':').map(Number);

	// Create a new DateTime object with the same date but new time
	const newDateTime = DateTime.fromObject(
		{
			year,
			month,
			day,
			hour,
			minute
		},
		{ zone: timezone }
	);

	// Convert the new DateTime object to an epoch time in seconds
	return newDateTime.toSeconds();
};
