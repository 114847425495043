import * as Sentry from '@sentry/react';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { TDR } from 'tdr-common';
import { useReservation } from '../../api/getReservation';
import { useModifyReservation } from '../../api/modifyReservation';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import { trackReservationModifications } from '../../helpers/reservationEventTrackers';
import { OccasionInput } from '../checkout/OccasionInput';

type OccasionProps = {
	reservation: TDR.Reservation;
	form: UseFormReturn;
	isEditEnabled: boolean;
}

export const Occasion = ({ reservation, form, isEditEnabled }: OccasionProps) => {
	const modifyReservationMutation = useModifyReservation(reservation.id);
	const { refetch: refetchReservation } = useReservation(reservation.id);
	const { setNewInvoice } = useModificationFlowState();

	const handleOccasionUpdate = async () => {
		const updatedOccasion = form.getValues('occasion');

		const response = await toast.promise(
			modifyReservationMutation.mutateAsync({
				id: reservation.id,
				occasion: updatedOccasion
			}),
			{
				pending: 'Updating your booking',
				success: 'Changes saved successfully!',
				error: 'Failed to update your booking'
			}
		);

		if (response.success) {
			trackReservationModifications({
				reservation,
				itemsChanged: { occasion: updatedOccasion }
			});
			refetchReservation();
			setNewInvoice(undefined);
		}
		else {
			const errorMessage = `modifyReservation Error: ${response.message}`;
			const sentryErrorID = Sentry.captureException(new Error(errorMessage));
			trackReservationModifications ({ reservation, errorMessage, sentryErrorID });
		}
	};
	return (
		<OccasionInput
			isEditEnabled={isEditEnabled}
			currentSelected={reservation.occasion}
			formControl={form.control}
			isSaveEnabled={form.formState.dirtyFields.occasion}
			onSave={handleOccasionUpdate}
		/>
	);
};
