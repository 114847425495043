import * as Sentry from '@sentry/react';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-toastify';
import { TDR } from 'tdr-common';
import { useReservation } from '../../api/getReservation';
import { useModifyReservation } from '../../api/modifyReservation';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import { trackReservationModifications } from '../../helpers/reservationEventTrackers';
import { RequestsInput } from '../checkout/RequestsInput';

type SpecialRequestsProps = {
	reservation: TDR.Reservation;
	form: UseFormReturn;
	isEditEnabled: boolean;
}

export const SpecialRequests = ({ reservation, form, isEditEnabled }: SpecialRequestsProps) => {
	const modifyReservationMutation = useModifyReservation(reservation.id);
	const { refetch: refetchReservation } = useReservation(reservation.id);
	const { setNewInvoice } = useModificationFlowState();

	const handleSpecialRequestsUpdate = async () => {
		const updatedGuestNotes = form.getValues('specialRequests');

		const response = await toast.promise(
			modifyReservationMutation.mutateAsync({
				id: reservation.id,
				guestNotes: updatedGuestNotes
			}),
			{
				pending: 'Saving your request',
				success: 'Request saved!',
				error: 'Failed to update your request'
			}
		);

		if (response.success) {
			trackReservationModifications({
				reservation,
				itemsChanged: { guestNotes: updatedGuestNotes }
			});
			refetchReservation();
			setNewInvoice(undefined);
		}
		else {
			const errorMessage = `modifyReservation Error: ${response.message}`;
			const sentryErrorID = Sentry.captureException(new Error(errorMessage));
			trackReservationModifications({ reservation, errorMessage, sentryErrorID });
		}
	};

	return (
		<RequestsInput
			isEditEnabled={isEditEnabled}
			formRegister={form.register('specialRequests')}
			currentNote={reservation.guestNotes}
			isSaveEnabled={form.formState.dirtyFields.specialRequests}
			onSave={handleSpecialRequestsUpdate}
		/>
	);
};
