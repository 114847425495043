import React from 'react';
import styles from './HeaderSmall.module.scss';
import { TDR } from 'tdr-common';
import { BookingFlowVariant } from './TableDrawer';

type HeaderSmallProps = {
	table: TDR.Table;
	variant?: BookingFlowVariant;
};

export const HeaderSmall = ({ table, variant = 'default' }: HeaderSmallProps) => {
	return (
		<header className={styles.OuterWrapper}>
			<div className={`${styles.InnerWrapper} ${variant === 'modification' ? styles['InnerWrapper--center'] : ''}`}>
				<h3 className={styles.Subtitle}>{table.label || 'Table'}</h3>
				<h2 className={styles.Title}>{table.name}</h2>
			</div>
		</header>
	);
};
