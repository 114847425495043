import React from 'react';
import { analytics } from '../../helpers/analytics';
import ArrowRight2 from 'iconsax-react/dist/esm/ArrowRight2';
import Sms from 'iconsax-react/dist/esm/Sms';
import styles from './buttons.module.scss';

export interface RestaurantEmailLinkProps {
	email: string;
	iconVariant?: boolean;
}

const RestaurantEmailLink = ({ email, iconVariant = false }: RestaurantEmailLinkProps) => {

	return (
		<a
			href={`mailto:${email}`}
			className={`${styles.link} ${styles.anchorLink} ${iconVariant ? styles.iconVariantLink : ''}`}
			target='_blank'
			rel='noreferrer'
			onClick={() => {
				analytics.track('Redirected to Email');
			}}
		>
			{iconVariant && <Sms />}
			<span>{email}</span>
			{iconVariant && <ArrowRight2 />}
		</a>
	);
};

export default RestaurantEmailLink;
