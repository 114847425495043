import { TDR } from 'tdr-common';
import config from '../common/config';
import Checkout = TDR.CheckoutSession;
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ErrorResponse } from '../common/ErrorResponse';

export const MUTATION_KEY = 'updateCheckoutSession';

export function useUpdateCheckoutSession() {
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationKey: [MUTATION_KEY],
		mutationFn: ({ userToken, input }: {input: Checkout.UpdateCheckoutSessionInput, userToken: string} ) => updateCheckoutSession(input, userToken),
		scope: {
			id: 'updateCheckoutSession'
		},
		onSuccess() {
			queryClient.invalidateQueries({ queryKey: ['checkoutSession'] });
		}
	});

	return mutation;
}

async function updateCheckoutSession(
	input: Checkout.UpdateCheckoutSessionInput,
	userToken: string
): Promise<Checkout.UpdateCheckoutSessionOutput> {
	const response = await fetch(`${config.apiHost}/updateCheckoutSession`, {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${userToken}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as Checkout.CreateCheckoutSessionOutput;
	if (!response.ok) {
		throw new ErrorResponse(response, responsePayload);
	}

	return responsePayload;
}
