import React, { createContext, useContext, useState } from 'react';
import { ProviderProps } from '../../common/types';

type MatterportContextType = {
	isLoading: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	isError: boolean;
	setIsError: React.Dispatch<React.SetStateAction<boolean>>;
};

const MatterportContext = createContext<MatterportContextType | undefined>(undefined);

export const useMatterport = () => {
	const context = useContext(MatterportContext);
	if (!context) {
		throw new Error('useMatterport must be used within a MatterportProvider');
	}
	return context;
};

export const MatterportProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);

	const value: MatterportContextType = {
		isLoading,
		setIsLoading,
		isError,
		setIsError
	};

	return (
		<MatterportContext.Provider value={value}>
			{children}
		</MatterportContext.Provider>
	);
};
