import { TDR } from 'tdr-common';
import config from '../common/config';

export default async function createReservation(reservation: Partial<TDR.Reservation>, userToken: string) {
	const response = await fetch(`${config.apiHost}/bookReservation`, {
		method: 'POST',
		headers: { 'Authorization': `Bearer ${userToken}` ,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			reservation: reservation
		})
	});

	const result = await response.json();

	// this is picked up by the integration tests
	window['bookReservation'] = result;

	return result;
}