import { zodResolver } from '@hookform/resolvers/zod';
import * as Sentry from '@sentry/react';
import { useIsMutating } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { MUTATION_KEY as confirmFreeKey, useConfirmFreeCheckoutSession } from '../../../api/confirmFreeChecktouSession';
import { MUTATION_KEY as releaseSessionKey } from '../../../api/releaseCheckoutSession';
import { MUTATION_KEY as updateSessionKey, useUpdateCheckoutSession } from '../../../api/updateCheckoutSession';
import { isBadRequestErrorResponse } from '../../../common/ErrorResponse';
import { CheckoutCTALabel, useCheckoutContext } from '../../../context/new/CheckoutContext';
import { analytics } from '../../../helpers/analytics';
import { trackReservationCreation } from '../../../helpers/reservationEventTrackers';
import { ConfirmationFailed } from '../../../pages/errors/ConfirmationFailed';
import { ProceedFailed } from '../../../pages/errors/ProceedFailed';
import { CheckoutGuardOutletContext } from '../../../routes/CheckoutGuard';
import { CTAButton } from '../../buttons';
import { ContactInput } from '../ContactInput';
import { DietaryInput } from '../DietaryInput';
import { OccasionInput } from '../OccasionInput';
import { RequestsInput } from '../RequestsInput';
import { GuestDetailsDTO, GuestInfo, GuestInfoSchema, SubmissionError } from '../types';

import layoutStyles from '../../../layouts/CheckoutLayout.module.scss';

const defaultValues: GuestInfo = {
	email: '',
	firstName: '',
	lastName: '',
	phone: '',
	dietaryRestrictions: [],
	dietaryNote: '',
	specialRequests: ''
};

export const Details = () => {
	const { invoice, restaurant, checkoutSession, guestDetails, featureFlags } = useOutletContext<CheckoutGuardOutletContext>();
	const { navConfig, isCtaEnabled, setCtaText, setIsCtaEnabled, checkoutState, updateCheckoutState } =
    useCheckoutContext();

	const navigate = useNavigate();

	// TODO:
	// const initialStep = featureFlags.enableCheckoutAddOns ? fullCheckoutNavConfig.addons.path : baseCheckoutNavConfig.policies.path;

	const [submissionError, setSubmissionError] = useState<SubmissionError>();

	const { mutateAsync: confirmFreeCheckoutSession, error: confirmFreeCheckoutSessionError } =
    useConfirmFreeCheckoutSession();
	const isSubmitting = useIsMutating({
		mutationKey: [confirmFreeKey],
		exact: true
	});

	const { mutateAsync: updateCheckoutSession, error: updateCheckoutError } = useUpdateCheckoutSession();
	const isUpdating = useIsMutating({
		mutationKey: [updateSessionKey],
		exact: true
	});

	const isReleasing = useIsMutating({
		mutationKey: [releaseSessionKey],
		exact: true
	});

	const requiresCreditCard = restaurant.allowNoShowFees || invoice?.total > 0;
	const savedDietaryOptions = checkoutSession.dietaryRestrictions?.restrictions;
	// Build the array of field values ex. ['vegan', false, false, 'nut-free']
	const dietaryOptionFieldValues = restaurant.dietaryRestrictions?.restrictions?.map((option) =>
		savedDietaryOptions?.includes(option) ? option : false
	);

	const scrollRef = useRef<HTMLDivElement>();

	const methods = useForm<GuestInfo>({
		mode: 'all',
		resolver: zodResolver(GuestInfoSchema),
		defaultValues: {
			...defaultValues,
			firstName: checkoutState?.guest?.firstName || guestDetails?.firstName || '',
			lastName: checkoutState?.guest?.lastName || guestDetails?.lastName || '',
			email: checkoutState?.guest?.email || guestDetails?.email,
			phone: checkoutState?.guest?.phone || guestDetails?.phone || '',
			dietaryNote: checkoutSession.dietaryRestrictions?.notes || '',
			dietaryRestrictions: dietaryOptionFieldValues || [],
			specialRequests: checkoutSession.specialRequests || '',
			occasion: checkoutSession.occasion || undefined,
			fastCheckoutEnabled:
        checkoutState?.guest?.hasFastCheckoutEnabled || guestDetails?.hasFastCheckoutEnabled || false
		}
	});

	const {
		handleSubmit,
		formState: { isValid }
	} = methods;

	const isLoading = !!isSubmitting || !!isUpdating || !!isReleasing;

	const disableCTA =
    !invoice || isLoading
    	? true
    	: requiresCreditCard
    		? !isValid || !!isSubmitting || !!isReleasing
    		: !isValid || !!isSubmitting || !!isReleasing;

	const onSubmit = async (data: GuestInfo) => {
		const {
			firstName,
			lastName,
			email,
			phone,
			dietaryRestrictions,
			dietaryNote,
			specialRequests,
			occasion,
			fastCheckoutEnabled
		} = data;

		const payload: GuestDetailsDTO = {
			checkoutId: checkoutSession.id,
			guest: { firstName, lastName, email, phone },
			dietaryRestrictions: {
				restrictions: dietaryRestrictions.filter((item): item is TDR.DietaryRestriction => !!item),
				notes: dietaryNote
			},
			specialRequests,
			occasion,
			fastCheckoutEnabled
		};

		try {
			analytics.identify(checkoutSession.guestId, {
				email,
				firstName,
				lastName,
				phone
			});
		}
		catch (e) {}

		if (requiresCreditCard || featureFlags?.enableAddOns) {
			handleProceedToPayment(payload);
		}
		else {
			handleConfirmBooking(payload);
		}
	};

	const handleConfirmBooking = async (payload: GuestDetailsDTO) => {
		try {
			const result = await confirmFreeCheckoutSession(payload);

			if (result.success) {
				trackReservationCreation(checkoutState, checkoutSession, invoice, null, null);
				navigate({ pathname: `/${restaurant.slug}/checkout/${checkoutSession.id}/confirmation` }, { replace: true });
			}
			else {
				setSubmissionError(SubmissionError.Confirmation);
			}
		}
		catch (error) {
			setSubmissionError(SubmissionError.Confirmation);
			Sentry.captureException(error);
		}
	};

	const handleProceedToPayment = async (payload: GuestDetailsDTO) => {
		try {
			const result = await updateCheckoutSession({
				input: payload,
				userToken: checkoutState.firebaseToken
			});

			if (result.success) {
				updateCheckoutState({
					guest: result.guestDetails,
					stripeClientSecret: result.clientSecret,
					stripePaymentMethod: result.paymentMethod,
					guestDetailsDTO: payload
				});

				try {
					analytics.identify(checkoutSession.guestId, {
						email: result.guestDetails.email,
						firstName: result.guestDetails.firstName,
						lastName: result.guestDetails.lastName,
						phone: result.guestDetails.phone
					});
				}
				catch (e) {}

				navigate(`../${navConfig.details.next}`);
			}
			else {
				setSubmissionError(SubmissionError.Proceed);
			}
		}
		catch (error) {
			setSubmissionError(SubmissionError.Proceed);
			if (error instanceof Error) {
				Sentry.captureException(error);
			}
		}
	};

	useEffect(() => {
		setIsCtaEnabled(!disableCTA);
		setCtaText(featureFlags?.enableAddOns ? CheckoutCTALabel.PROCEED : requiresCreditCard ? CheckoutCTALabel.PAYMENT_PROCEED : CheckoutCTALabel.CONFIRM);
	}, [disableCTA, requiresCreditCard]);

	if (
		submissionError &&
    !isUpdating &&
    !isSubmitting &&
    !isBadRequestErrorResponse(confirmFreeCheckoutSessionError) &&
    !isBadRequestErrorResponse(updateCheckoutError)
	) {
		switch (submissionError) {
			case SubmissionError.Confirmation:
				return <ConfirmationFailed restaurantSlug={restaurant.slug} sessionId={checkoutSession.id} />;
			case SubmissionError.Proceed:
				return <ProceedFailed restaurantSlug={restaurant.slug} sessionId={checkoutSession.id} />;
		}
	}

	let ctaLabel = CheckoutCTALabel.CONFIRM;
	if (featureFlags?.privateEvents) {
		ctaLabel = requiresCreditCard ? CheckoutCTALabel.PAYMENT_PROCEED: CheckoutCTALabel.SUBMIT;
	}
	else if (featureFlags?.enableAddOns) {
		ctaLabel = CheckoutCTALabel.PROCEED;
	}
	else if (requiresCreditCard) {
		ctaLabel = CheckoutCTALabel.PAYMENT_PROCEED;
	}

	return (
		<>
			<div className={`${layoutStyles.Body} ${layoutStyles.BodyNoGap}`} ref={scrollRef}>
				<FormProvider {...methods}>
					<ContactInput />
					<DietaryInput
						restaurant={restaurant}
						currentNote={methods.watch('dietaryNote')}
						currentRestrictions={methods.watch('dietaryRestrictions')}
						form={methods}
						isSaveEnabled={
							methods.formState.dirtyFields?.dietaryRestrictions?.length > 0 || methods.formState.dirtyFields?.dietaryNote
						}
					/>
					{(checkoutState.isLargeGroup || featureFlags?.privateEvents) && (
						 <OccasionInput
						 	currentSelected={methods.watch('occasion')}
							formControl={methods.control}
							isSaveEnabled={methods.formState.dirtyFields.occasion}
						 />
					)}
					<RequestsInput
						formRegister={methods.register('specialRequests')}
						currentNote={methods.watch('specialRequests')}
						isSaveEnabled={methods.formState.dirtyFields?.specialRequests}
					/>
				</FormProvider>
			</div>

			<div className={layoutStyles.Footer}>
				<CTAButton
					onClick={handleSubmit(onSubmit)}
					disabled={!isCtaEnabled}
					buttonText={ctaLabel}
					loading={isLoading}
				/>
			</div>
		</>
	);
};
