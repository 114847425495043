import React from 'react';
import { formatDisplayPhoneNumber } from 'tdr-common';
import { analytics } from '../../helpers/analytics';
import ArrowRight2 from 'iconsax-react/dist/esm/ArrowRight2';
import Call from 'iconsax-react/dist/esm/Call';
import styles from './buttons.module.scss';

export interface RestaurantPhoneLinkProps {
	phoneNumber: string;
	iconVariant?: boolean;
}

const RestaurantPhoneLink = ({ phoneNumber, iconVariant = false }: RestaurantPhoneLinkProps) => {

	return (
		<a
			onClick={() => analytics.track('Redirected to Phone Number', {
				phoneNumber: phoneNumber
			})}
			href={`tel:${phoneNumber}`}
			aria-label={`Call ${phoneNumber}`}
			className={`${styles.link} ${styles.anchorLink} ${iconVariant ? styles.iconVariantLink : ''}`}
		>
			{iconVariant && <Call />}
			<span>{formatDisplayPhoneNumber(phoneNumber)}</span>
			{iconVariant && <ArrowRight2 />}
		</a>
	);
};

export default RestaurantPhoneLink;
