import { TDR } from 'tdr-common';
import config from '../common/config';

export default async function getPromoCode(invoice: Partial<TDR.Invoice>, promoCode: string) {
	const response = await fetch(`${config.apiHost}/getPromoCode`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			invoice: invoice,
			promoCode: promoCode
		})
	});

	const result = await response.json();

	return result;
}