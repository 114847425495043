import { TDR } from 'tdr-common';
import config from '../common/config';
import Checkout = TDR.CheckoutSession;
import { useQuery } from '@tanstack/react-query';
import { ErrorResponse } from '../common/ErrorResponse';

export function useCheckoutSession(id: string) {
	const query = useQuery({
		enabled: !!id,
		queryKey: ['checkoutSession', id],
		queryFn: () => getCheckoutSession({ checkoutId: id }),
		refetchOnWindowFocus: false
	});

	return query;
}

async function getCheckoutSession(
	input: Checkout.ValidateCheckoutSessionInput
): Promise<Checkout.CreateCheckoutSessionOutput> {
	const response = await fetch(`${config.apiHost}/getCheckoutSession`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as Checkout.CreateCheckoutSessionOutput;
	if (!response.ok) {
		throw new ErrorResponse(response, responsePayload);
	}

	return responsePayload;
}
