import './styles/app.scss';

import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TDRApp from './TDRApp';
import config from './common/config';

import 'normalize.css';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { extraErrorDataIntegration, captureConsoleIntegration } from '@sentry/integrations';
import { ErrorBoundary } from './pages/errors/UnexpectedErrorPage';

if (config.sentry.enable) {
	Sentry.init({
		...config.sentry,
		release: process.env.RELEASE_VERSION,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			  }),
			  Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
				mask: ['#mpframe'],
				ignore: ['#mpframe']
			}),
			Sentry.dedupeIntegration(),
			Sentry.browserProfilingIntegration(),
			extraErrorDataIntegration(),
			captureConsoleIntegration({
				levels: ['error', 'assert']
			}),
			Sentry.feedbackIntegration({
				colorScheme: 'dark',
				autoInject: false,
				showBranding: false,
				themeDark: {
					background: '#1B1B1B',
					backgroundHover: '#1D1D1D',
					submitBackground: '#8D8058',
					submitBackgroundHover: '#7c6f47',
					submitBorder: '#7c6f47',
					borderRadius: '16px'
				}
			})
		  ],
		  beforeSend(event) {
			// if (!event || event.message?.includes('test')) {
			// 	return null;
			// }
			// Modify or drop the event here
			if (event.user) {
			  // Don't send user's email address
			  delete event.user.email;
			}
			return event;
		  }
	});
}

const appContainer = document.createElement('div');
appContainer.id = 'app';

document.body.appendChild(appContainer);
document.documentElement.lang = 'en';

const root = createRoot(appContainer);
root.render(
	<Sentry.ErrorBoundary
		beforeCapture={(scope) => {
			scope.setTag('location', 'Global');
		}}
	  	fallback={(props) => <ErrorBoundary {...props}/>}
	>
		<TDRApp />
	</Sentry.ErrorBoundary>
);