import React from 'react';
import { ControlledDialog } from './ControlledDialog';
import { CTAButton, CustomerSupportLink } from '../../buttons';

type NoMatchingTablesProps = {
	open: boolean;
	onDismiss: () => void;
};

export const NoMatchingTables = ({ open, onDismiss }: NoMatchingTablesProps) => {
	const body = (
		<>
			<p>
        We apologize for any inconvenience. Currently, there are no tables that match your criteria. Please consider
        adjusting your parameters or try again later.
			</p>
			<p>
        For immediate assistance, feel free to reach out to us at <CustomerSupportLink />.
			</p>
		</>
	);

	return (
		<ControlledDialog
			isOpen={open}
			hasCloseButton={false}
			onClose={() => {}}
			icon='error'
			title='Oops! No available tables'
			body={body}
			footer={
				<>
					<CTAButton buttonText='Dismiss' onClick={onDismiss} variant='dark' />
				</>
			}
		/>
	);
};
