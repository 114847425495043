import { TDR } from 'tdr-common';
import config from '../common/config';
import Availability = TDR.Availability;
import { useIsMutating, useQuery } from '@tanstack/react-query';
import { MUTATION_KEY } from './releaseCheckoutSession';

export function useServiceTimeslots(input: TDR.Availability.getServicesInput) {
	const isReleasing = useIsMutating({
		mutationKey: [MUTATION_KEY],
		exact: true
	});

	const query = useQuery({
		enabled: !isReleasing && !!input.dateStr,
		queryKey: ['serviceTimeslots', input],
		queryFn: () => getServiceTimeslots(input)
	});

	return query;
}

async function getServiceTimeslots(input: TDR.Availability.getServicesInput): Promise<Availability.getServicesOutput> {
	const response = await fetch(`${config.apiHost}/getServicesTimeslots`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as Availability.getServicesOutput;

	if (!response.ok) {
		throw response;
	}
	else if (!responsePayload?.success) {
		throw Error('Could Not Get Available Time slots');
	}

	return responsePayload;
}