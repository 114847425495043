import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { TableGuardOutletContext } from '../routes/TableGuard';
import { AvailabilityGuardContext } from '../routes/AvailabilityGuard';
import TableDrawer from '../components/table-drawer/TableDrawer';

export const TableDetailsPage = () => {
	const { table, restaurant } = useOutletContext<TableGuardOutletContext>();
	const { searchFilters } = useOutletContext<AvailabilityGuardContext>();
	return <TableDrawer selectedTable={table} restaurant={restaurant} searchFilters={searchFilters} />;
};
