import React from 'react';
import {
	TDR
} from 'tdr-common';
import Deposit from '../../icons/deposit.svg';
import Stop from '../../icons/stop.svg';
import Timer from '../../icons/timer.svg';
import { PolicyItem } from '../policies/PolicyItem';

type PolicyItemsDemoProps = {
	settings: TDR.LargeGroupSettings;
};
export const PolicyItemsDemo = ({ settings }: PolicyItemsDemoProps) => {
	const {
		cancellationWindow,
		bookingDeposit,
		gracePeriod
	} = settings;

	const depositText = bookingDeposit?.amount ? `$${bookingDeposit?.amount}${bookingDeposit?.perPerson ? ' per guest':''}` :'';

	return (
		<>
			{bookingDeposit?.amount > 0 && (
				<PolicyItem icon={Deposit} label='Deposit fee'>
					<p>
						A minimum spend of {' '}
						<strong>{depositText}</strong>{' '}
						is required for dining.
					</p>
				</PolicyItem>
			)}

			{cancellationWindow > 0 && (
				<PolicyItem icon={Stop} label='Cancellation'>
					<p>
						Cancellations are non-refundable within {' '}
						<strong>{cancellationWindow} hours</strong> of your scheduled booking.
					</p>
				</PolicyItem>
			)}


			{gracePeriod > 0 && (
				<PolicyItem icon={Timer} label='Grace period'>
					<p>
						Your seating may not be accommodated if you are more than <strong>{gracePeriod} minutes</strong> late.
					</p>
				</PolicyItem>
			)}
		</>
	);
};
