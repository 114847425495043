import * as Sentry from '@sentry/react';
import { useIsMutating } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MUTATION_KEY, useCreateCheckoutSession } from '../../api/createCheckoutSession';
import { InternalErrorToast } from '../../pages/errors/InternalErrorToast';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';
import { TableGuardOutletContext } from '../../routes/TableGuard';
import CTAButton from './CTAButton';

type BookNowButtonProps = {
	disabled?: boolean;
	text?: string;
}

export const BookNowButton = ({ disabled = false, text }: BookNowButtonProps) => {
	const navigate = useNavigate();

	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();
	const { searchFilters, table: selectedTable } = useOutletContext<TableGuardOutletContext>();

	const { mutateAsync: createCheckoutSession } = useCreateCheckoutSession();

	const isMutating = useIsMutating({
		mutationKey: [MUTATION_KEY],
		exact: true
	});

	const filtersComplete = selectedTable && searchFilters?.groupSize && searchFilters.date && !!searchFilters.time;

	const handleCTAClick = async () => {
		try {
			if (filtersComplete) {
				const response = await createCheckoutSession({
					searchParams: searchFilters,
					tableId: selectedTable.id
				});

				if (response?.success && response.checkoutSession.id) {
					navigate(`/${restaurant.slug}/checkout/${response.checkoutSession.id}/policies`);
				}
				else {
					Sentry.captureMessage(response.message || 'Failed attempt to create checkout session');
					toast(<InternalErrorToast />, { toastId: 'internal-error', type: 'error' });
				}
			}
			else {
				const message = 'Group size, date, and time are required to continue.';
				Sentry.captureMessage(message);
				toast(message, { toastId: 'missing-filters', type: 'error' });
			}
		}
		catch (e) {
			e?.message && Sentry.captureMessage(e?.message);
		}
	};

	return (
		<CTAButton
			loading={!!isMutating}
			buttonText={text ?? 'Book Now'}
			onClick={handleCTAClick}
			disabled={!filtersComplete || disabled}
		/>
	);
};
