import { TDR } from 'tdr-common';
import config from '../common/config';
import Checkout = TDR.CheckoutSession;
import { useMutation } from '@tanstack/react-query';

export const MUTATION_KEY = 'createCheckoutSession';

export function useCreateCheckoutSession() {
	const mutation = useMutation({
		retry: 3,
		mutationKey: [MUTATION_KEY],
		mutationFn: (input: Checkout.CreateCheckoutSessionInput) => createCheckoutSession(input),
		scope: {
			id: 'createCheckoutSession'
		}
	});

	return mutation;
}

async function createCheckoutSession(input: Checkout.CreateCheckoutSessionInput) {

	const response = await fetch(`${config.apiHost}/createCheckoutSession`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(input)
	});

	const responsePayload = await response.json() as Checkout.CreateCheckoutSessionOutput;

	if (!response.ok) {
		throw response;
	}

	return responsePayload;
}