import { Timestamp } from 'firebase/firestore';
import { generateInvoice, legacyTimeslotsToPricingPolicies, TDR } from 'tdr-common';
import getReservationInvoice from '../api/getReservationInvoice';

export const getNewInvoiceForModifiedTable = async ({
	restaurant,
	newTable,
	newGuestCount,
	newBookingTime,
	existingReservation,
	basePolicies
}: {
	restaurant: TDR.Restaurant;
	newTable: TDR.Table;
	newGuestCount: number;
	newBookingTime: Timestamp;
	existingReservation: TDR.Reservation;
	basePolicies: TDR.PricingPolicy[];
}): Promise<Partial<TDR.Invoice>> => {
	const tableOverridePolicies = legacyTimeslotsToPricingPolicies(restaurant, [newTable]);
	const policies = [...tableOverridePolicies, ...basePolicies];
	const existingInvoice = await getReservationInvoice(existingReservation.id);

	const newReservation: TDR.Reservation = {
		...existingReservation,
		guests: newGuestCount,
		time: newBookingTime,
		tableId: newTable.id,
		items: [
			...existingReservation.items.filter((item) => item.type !== TDR.Reservation.ItemType.Table),
			{
				id: newTable.id,
				type: TDR.Reservation.ItemType.Table,
				label: newTable.label,
				tags: newTable.tags
			}
		]
	};

	const newInvoice = generateInvoice(
		restaurant.currency,
		policies,
		newReservation,
		existingInvoice,
		restaurant,
		existingReservation,
		newTable
	);

	return newInvoice;
};
