import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { computeCutoffs, TDR } from 'tdr-common';
import { z } from 'zod';
import { useReservation } from '../api/getReservation';
import { useRestaurant } from '../api/getRestaurant';
import { useTables } from '../api/getRestaurantTables';
import { URLParams } from '../common/types';
import { ModificationUnavailable } from '../components/dialog/controlled-dialog/ModificationUnavailable';
import { RefundPeriodPassed } from '../components/dialog/controlled-dialog/RefundPeriodPassed';
import WholeScreenLoading from '../components/whole-screen-loading/WholeScreenLoading';
import { useModificationFlowState } from '../context/ModificationFlowProvider';
import { analytics, reservationMiddlewareBuilder } from '../helpers/analytics';
import { ModificationLayout } from '../layouts/ModificationLayout';
import layoutStyles from '../components/search/SearchContainer.module.scss';
import { NotFoundErrorPage } from '../pages/errors/NotFoundErrorPage';

export const modifyBookingSchema = z.object({
	tableId: z.string(),
	guests: z.number(),
	epochInSeconds: z.number(),
	specialRequests: z.string(),
	dietaryRestrictions: z.array(z.union([z.nativeEnum(TDR.DietaryRestriction), z.boolean()])),
	dietaryNote: z.string().max(300, 'Must be 300 characters or less'),
	occasion: z.nativeEnum(TDR.Occasion).optional(),
	stripeClientSecret: z.string().optional(),
	stripePaymentMethod: z.any().optional()
});

export type ModifyBookingFormData = z.infer<typeof modifyBookingSchema>;

export const BookingGuard = () => {
	const { reservationId } = useParams<URLParams>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const {
		setModificationStatus,
		nonRefundableWarning,
		setNonRefundableWarning,
		cannotModifyWarning,
		setCannotModifyWarning
	} = useModificationFlowState();

	const { data: reservation, isLoading: isReservationLoading } = useReservation(reservationId);
	const { data: restaurant, isLoading: isRestaurantLoading } = useRestaurant(reservation?.restaurantId);

	const { data: tables, isLoading: areTablesLoading } = useTables(restaurant?.id);

	// Build the array of field values ex. ['vegan', false, false, 'nut-free']
	const dietaryOptionFieldValues = restaurant?.dietaryRestrictions?.restrictions?.map((option) =>
		reservation?.dietaryRestrictions?.restrictions?.includes(option) ? option : false
	);

	const methods = useForm<ModifyBookingFormData>({
		mode: 'all',
		resolver: zodResolver(modifyBookingSchema),
		defaultValues: {
			tableId: reservation?.tableId,
			guests: reservation?.guests,
			epochInSeconds: reservation?.time.seconds,
			specialRequests: reservation?.guestNotes,
			occasion: reservation?.occasion,
			dietaryRestrictions: dietaryOptionFieldValues,
			dietaryNote: reservation?.dietaryRestrictions?.notes
		},
		values: {
			tableId: reservation?.tableId,
			guests: reservation?.guests,
			epochInSeconds: reservation?.time.seconds,
			specialRequests: reservation?.guestNotes,
			occasion: reservation?.occasion,
			dietaryRestrictions: dietaryOptionFieldValues,
			dietaryNote: reservation?.dietaryRestrictions?.notes
		}
	});

	useEffect(() => {
		if (reservation) {
			const trackingMiddleware = reservationMiddlewareBuilder(reservation.id);
			analytics.addSourceMiddleware(trackingMiddleware);

			return () => analytics.removeSourceMiddleware(trackingMiddleware);
		}
	}, [reservation]);

	const cutoffs = useMemo(() => {
		if (reservation && restaurant) {
			return computeCutoffs(reservation, restaurant);
		}
	}, [reservation, restaurant]);

	useEffect(() => {
		if (cutoffs) {
			const now = new Date();
			const { update, cancel } = cutoffs;
			const cancelCutOff = cancel.toJSDate();
			const updateCutOff = update.toJSDate();
			//after this period is passed, the user is forced into the cancellation flow
			if (now > updateCutOff) {
				setModificationStatus(TDR.Reservation.ModificationDoneStatus.ModificationPassedCutOffPeriod);
			}
			//user can cancel and modify, but will not get any refunds
			else if (now > cancelCutOff) {
				setModificationStatus(TDR.Reservation.ModificationDoneStatus.CancellationPassedCutOffPeriod);
			}
		}
	}, [cutoffs]);

	if (isReservationLoading || isRestaurantLoading || areTablesLoading) {
		return <WholeScreenLoading />;
	}

	if ((!isReservationLoading && !reservation) || (!isRestaurantLoading && !restaurant)) {
		return <NotFoundErrorPage />;
	}

	return (
		<FormProvider {...methods}>
			<ModificationLayout restaurant={restaurant} tables={tables}>
				<div className={layoutStyles.Container}>
					<Outlet />

					<RefundPeriodPassed
						isOpen={nonRefundableWarning.isOpen}
						onClose={() => setNonRefundableWarning({ isOpen: false })}
						onProceed={() => {
							navigate(nonRefundableWarning.proceedTo);
							setNonRefundableWarning({ isOpen: false });
						}}
						restaurant={restaurant}
					/>

					<ModificationUnavailable
						isOpen={cannotModifyWarning.isOpen}
						onClose={() => setCannotModifyWarning({ isOpen: false })}
						onDismiss={() => {
							setCannotModifyWarning({ isOpen: false });
						}}
						restaurant={restaurant}
					/>
				</div>
			</ModificationLayout>
		</FormProvider>
	);
};
