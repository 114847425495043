import React from 'react';
import styles from './TableTag.module.scss';
import { TDR } from 'tdr-common';
import { customIcons, DynamicIcon } from '../icons';

type TableTagProps = {
	label: TDR.Attributes.OptionValue | string;
	iconName?: string;
};

export const TableTag: React.FC<TableTagProps> = ({ label, iconName }) => {
	return label ? (
		<div className={styles.Tag}>
			{iconName ? customIcons()[iconName] ?? <DynamicIcon iconName={iconName} size={20} color={'#94969C'} /> : null}
			<span translate='no'>{label}</span>
		</div>
	) : null;
};
