import { DateTime } from 'luxon';

export const fromLocalJsDate = (date: Date, zone?: string) => {
	// +1 because DateTime months are NOT zero-indexed
	return DateTime.fromObject(
		{
			year: date.getFullYear(),
			month: date.getMonth() + 1,
			day: date.getDate()
		},
		{ zone }
	);
};

export const toLocalJsDate = (date: DateTime) => {
	// -1 because jsDate months are zero-indexed
	return new Date(date.year, date.month - 1, date.day);
};

export const isToday = (date: Date) => {
	const today = new Date();
	return date.toDateString() === today.toDateString();
};
