import inRange from 'lodash/inRange';
import React, { useEffect, useState } from 'react';
import { TDR } from 'tdr-common';
import { HASH_ELEMENT_IDS } from '../../common/hashElementIds';
import { FILTER_CHANGED, analytics } from '../../helpers/analytics';
import { SearchFilters } from '../../routes/AvailabilityGuard';
import { Dropdown } from '../dropdown/Dropdown';
import { Subheading } from '../typography/Subheading';
import styles from './PartySizeSelector.module.scss';

export interface PartySizeOption {
	value: string;
	label: string;
	disabled: boolean;
	inboundRequest: boolean;
}
export interface PartySizeSelectorProps {
	searchFilters: SearchFilters;
	onChange?: (newOption: PartySizeOption) => void;
	defaultValue?: number;
	options: PartySizeOption[];
	analyticsOption?: any;
}

export function PartySizeSelector({
	searchFilters,
	options = [],
	defaultValue,
	onChange,
	analyticsOption = {}
}: PartySizeSelectorProps) {

	const [selectedOption, setSelectedOption] = useState<PartySizeOption>();

	useEffect(() => {
		if (searchFilters?.groupSize) {
			setSelectedOption(options.find((option) => Number(option.value) === searchFilters.groupSize));
		}
		else {
			setSelectedOption(undefined);
		}
	}, [searchFilters.groupSize, options]);

	return (
		<>
			<div className={styles.GroupSelector} id={HASH_ELEMENT_IDS.groupSize}>
				<Subheading>Group Size</Subheading>

				<Dropdown
					placeholder={<span>Select...</span>}
					label='Group size'
					value={selectedOption?.value || defaultValue?.toString()}
					onValueChange={(value: string) => {
						const option = options.find((option) => option.value === value);
						setSelectedOption(option);

						onChange?.(option);
						analytics.track(FILTER_CHANGED, {
							filterName: 'groupSize',
							filterValue: option.value,
							...analyticsOption
						});
					}}
					options={options}
				/>
			</div>
		</>
	);
}

export const buildGroupSizeOptions = (guestNumbers: number[], selectedTable: TDR.Table, showInboundOption?: boolean): [PartySizeOption[], PartySizeOption | null] => {
	const guestOptions = guestNumbers?.map((num, idx) => {
		if (!!num) {
			return {
				value: idx.toString(),
				label: `${idx} Guest${idx > 1 ? 's' : ''}`,
				disabled: !!(selectedTable && !inRange(idx, selectedTable.minGuests, selectedTable.maxGuests + 1)),
				inboundRequest: false
			};
		}
		else {
			return null;
		}
	})
		.filter((val) => !!val);

	let leadGenOption: PartySizeOption;

	if (showInboundOption && guestOptions?.length > 0) {
		const num = guestOptions[guestOptions.length - 1].value;
		const numInt = parseInt(num) + 1;
		leadGenOption = {
			value: numInt.toString(),
			label: `${numInt}+ Guest${numInt > 1 ? 's' : ''}`,
			disabled: false,
			inboundRequest: true
		};
		guestOptions.push(leadGenOption);
	}
	return [guestOptions, leadGenOption];
};

