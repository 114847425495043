import React from 'react';
import styles from './SidebarHeader.module.scss';

type SidebarHeaderProps = {
	title: string;
	subtitle: string;
	buttonLeft?: React.ReactNode;
	buttonRight?: React.ReactNode;
};

export const SidebarHeader = ({
	title,
	subtitle,
	buttonLeft,
	buttonRight
}: SidebarHeaderProps) => {

	return (
		<div className={styles.Wrapper}>
			<div className={styles.TitleWrapper}>
				{buttonLeft}

				<div className={`${styles.Text} ${buttonLeft && buttonRight ? styles['Text--center'] : ''}`}>
					<h3 className={styles.Subtitle} translate='no'>{subtitle}</h3>
					<h2 className={styles.Title}>{title}</h2>
				</div>

				{buttonRight}
			</div>
		</div>
	);
};
