import React from 'react';
import styles from './PolicyItem.module.scss';
import { customIcons, DynamicIcon } from '../icons';

type PolicyItemProps = {
	icon: string;
	title?: string;
	label?: string;
	children: React.ReactNode;
}


export const PolicyItem = ({ icon, title = 'booking request', label, children }: PolicyItemProps) => {
	return (
		<li className={styles.PolicyItem}>
			{label ?
				<img src={icon} alt={label} />
				:
				(icon ? customIcons({ filter: 'none' })[icon] ?? <DynamicIcon iconName={icon} /> : null)
			}
			<div className={styles.PolicyBody}>
				<h3>{title}</h3>
				{children}
			</div>
		</li>
	);
};
