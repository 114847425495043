import React from 'react';
import styles from './BookingOptionsHeader.module.scss';
import { useOutletContext } from 'react-router-dom';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';

export const BookingOptionsHeader = () => {
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	return (
		<div className={styles.TileTitle}>
			<h1 className={styles.Name}>{restaurant.displayName}</h1>
			<h3 className={styles.Address}>{restaurant.displayAddress}</h3>
		</div>
	);
};