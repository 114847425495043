import mixpanel from 'mixpanel-browser';
import { AbstractBehavioralTool, Preferences } from '../../../components/cookie-consent/types';
import config from '../../../common/config';


export class MixpanelTool extends AbstractBehavioralTool {
	private isAnonymized = false;
	private isLoaded = false;

	public load(userPreferances: Preferences) {
		this.isAnonymized = userPreferances.analytics === false;// || userPreferances.advertising === false;

		if (this.isLoaded) {
			mixpanel.set_config({
				...config.analytics.mixpanel.config,
				ip: !this.isAnonymized
			});
		}
		else {
			mixpanel.init(config.analytics.mixpanel.token, {
				...config.analytics.mixpanel.config,
				ip: !this.isAnonymized
			});
		}

		this.isLoaded = true;
	}

	public track(event: string, properties: Record<string, unknown>): void {
		return mixpanel.track(event, properties);
	}

	public page(name = 'Loaded a Page', properties: Record<string, unknown>): void {
		mixpanel.track(
			name,
			properties
		);
	}

	public identify(userId: string, traits: Record<string, string>): void {
		if (userId) {
			mixpanel.identify(userId);
		}

		if (!this.isAnonymized) {
			if (traits?.email) {
				mixpanel.alias(traits?.email);
			}

			mixpanel.people.set(traits);
		}
	}

}