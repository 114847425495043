import React from 'react';
import { Skeleton } from '../skeleton/Skeleton';
import { TimeslotSkeleton } from '../timeslot-list/TimeslotSkeleton';

export const TimeSelectorSkeleton = ({ halfHeight }: { halfHeight: boolean}) => {
	return (
		<div>
			<Skeleton width='100%' height='33px' />
			<TimeslotSkeleton halfHeight={halfHeight} />
		</div>
	);
};
