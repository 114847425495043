import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { Calendar as ReactCalendar } from 'react-calendar';
import { DATESTR_FMT, MONTHSTR_FMT, TDR, classifyAvailability, isRestaurantClosed } from 'tdr-common';
import { useCalendarAvailability } from '../../api/availability/availability';
import getFirstBookableMonthByTable, { getEarliestMonth } from '../../api/getFirstBookableMonth';
import { HASH_ELEMENT_IDS } from '../../common/hashElementIds';
import { applyGroupSizeFilter } from '../../helpers/applyGroupSizeFilter';
import DownArrow from '../../icons/down-chevron.svg';
import { SearchFilters } from '../../routes/AvailabilityGuard';
import Spinner from '../Spinner';
import { LowAvailabilityNotice } from '../notice/LowAvailabilityNotice';
import { AvailabilityIndicator } from './AvailabilityIndicator';
import { AvailabilityLegend } from './AvailabilityLegend';
import styles from './Calendar.module.scss';
import { fromLocalJsDate } from './Calendar.utils';

export type AvailabilityCalendarForModsProps = {
	tables: TDR.Table[];
	restaurant: TDR.Restaurant;
	selectedTable?: TDR.Table;
	onChange?: (date: DateTime, availabilityPercent: number) => void;
	disabled?: boolean;
	filters: SearchFilters;
};

const AvailabilityCalendarForMods = ({
	tables,
	selectedTable,
	restaurant,
	onChange,
	disabled,
	filters
}: AvailabilityCalendarForModsProps) => {
	const TODAY = DateTime.now().setZone(restaurant?.timezone);
	const THIS_MONTH_STR = TODAY.toFormat(MONTHSTR_FMT);
	const STARTING_MONTH_STR = filters.date
		? DateTime.fromFormat(filters.date, DATESTR_FMT).toFormat(MONTHSTR_FMT)
		: THIS_MONTH_STR;

	const [selectedMonth, setSelectedMonth] = useState<string>(STARTING_MONTH_STR);

	const activeStartDate = selectedMonth && DateTime.fromFormat(selectedMonth, MONTHSTR_FMT).toJSDate();

	const filteredTables = selectedTable ? [selectedTable] : applyGroupSizeFilter(tables, filters.groupSize);

	const { data: selectedMonthAvailability, isLoading } = useCalendarAvailability({
		tables: filteredTables,
		month: selectedMonth,
		restaurantId: restaurant.id
	});

	const getFirstBookableMonth = useMemo(
		() => () => {
			getFirstBookableMonthByTable(restaurant.id).then((firstMonthsForTables) => {
				if (firstMonthsForTables) {
					let firstMonth = THIS_MONTH_STR;
					if (selectedTable && selectedTable.id in firstMonthsForTables.byTable) {
						firstMonth = firstMonthsForTables.byTable[selectedTable.id] ?? firstMonth;
					}
					else {
						const earliestMonth = getEarliestMonth(firstMonthsForTables);
						firstMonth = earliestMonth && earliestMonth > firstMonth ? earliestMonth : firstMonth;
					}
					if (selectedMonth && firstMonth > selectedMonth) {
						setSelectedMonth(firstMonth);
					}
				}
			});
		},
		[restaurant.id]
	);

	useEffect(() => {
		getFirstBookableMonth?.();
	}, [getFirstBookableMonth]);

	useEffect(() => {
		if (filters.date && selectedMonthAvailability) {
			const initialDate = DateTime.fromFormat(filters.date, DATESTR_FMT).toJSDate();
			handleDateChange(initialDate);
		}
	}, [selectedMonthAvailability]);

	const handleDateChange = (date: Date) => {
		const luxonDate = fromLocalJsDate(date, restaurant?.timezone);
		const dateStr = luxonDate.toFormat(DATESTR_FMT);
		const dayAvailability = selectedMonthAvailability?.calendarAvailability[dateStr]?.available;
		if (dayAvailability === 0) {
			return onChange(luxonDate, 0);
		}
		return onChange(luxonDate, 100);
	};

	const handleActiveStartDateChange = ({ activeStartDate }) => {
		setSelectedMonth(DateTime.fromJSDate(activeStartDate).toFormat(MONTHSTR_FMT));
	};

	const getAvailabilityForDate = (date: Date) => {
		return selectedMonthAvailability?.calendarAvailability
			? classifyAvailability(
				selectedMonthAvailability.calendarAvailability[DateTime.fromJSDate(date).toFormat(DATESTR_FMT)]
			)
			: 'closed';
	};

	const isBeforeDate = (date1: DateTime, date2: DateTime) => {
		// We need to do that because now that the timezone is set (to handle days rolling over)
		// using simple diffs and <> operators don't work anymore

		if (date1.year < date2.year) {
			return true;
		}
		else if (date1.year > date2.year) {
			return false;
		}

		if (date1.month < date2.month) {
			return true;
		}
		else if (date1.month > date2.month) {
			return false;
		}

		if (date1.day < date2.day) {
			return true;
		}
		return false;
	};

	return (
		<div className={styles.Container} translate='no' id={HASH_ELEMENT_IDS.calendar}>
			{filters?.groupSize && isLoading && (
				<div className={styles.Spinner} data-cy='calendarLoading'>
					<Spinner size='xs' />
				</div>
			)}

			<ReactCalendar
				locale={new Intl.NumberFormat()?.resolvedOptions()?.locale || 'en-US'}
				view={'month'}
				value={filters.date ? DateTime.fromFormat(filters.date, DATESTR_FMT).toJSDate() : null}
				onChange={handleDateChange}
				activeStartDate={activeStartDate}
				onActiveStartDateChange={handleActiveStartDateChange}
				nextLabel={<NextMonthIcon />}
				prevLabel={<PrevMonthIcon />}
				calendarType='gregory' // displays Sunday as first day of week
				tileContent={({ date }) => (
					<AvailabilityIndicator
						{...{ date }}
						availability={getAvailabilityForDate(date)}
						disabled={isBeforeDate(DateTime.fromJSDate(date), TODAY) || disabled || isLoading}
					/>
				)}
				tileDisabled={({ date }) =>
					isBeforeDate(DateTime.fromJSDate(date), TODAY) ||
          disabled ||
          isRestaurantClosed(fromLocalJsDate(date), restaurant) ||
          isLoading
          // !!isReleasing
				}
				minDate={TODAY.toJSDate()}
				maxDate={
					disabled
						? TODAY.toJSDate()
						: restaurant.bookableDaysInAdvance
							? new Date(TODAY.toJSDate().getTime() + restaurant.bookableDaysInAdvance * 24 * 60 * 60 * 1000)
							: null
				} // for disabling month navigation when calendar is disabled
				showNeighboringMonth={false}
				aria-busy={isLoading}
				next2Label={null}
				prev2Label={null}
				className={disabled ? 'react-calendar--disabled' : ''}
			/>
			<AvailabilityLegend disabled={disabled} />
			{selectedMonthAvailability?.aggregatedStatus === 'limited' ? (
				<LowAvailabilityNotice selectedMonth={selectedMonth} />
			) : null}
		</div>
	);
};

export default AvailabilityCalendarForMods;

/*********************
 * Icons
 *********************/
const NextMonthIcon = () => <img src={DownArrow} alt={'Next month button'} />;
const PrevMonthIcon = () => <img src={DownArrow} alt={'Previous month button'} />;
