import Trash from 'iconsax-react/dist/esm/Trash';
import React from 'react';
import Check from '../../icons/checkmark.svg';
import Back from '../../icons/left-chevron-enabled.svg';
import Add from 'iconsax-react/dist/esm/Add';
import Minus from 'iconsax-react/dist/esm/Minus';
import X from '../../icons/x.svg';
import styles from './SquareIconButton.module.scss';

type SquareIconButtonProps = {
	variant: 'exit' | 'back' | 'save' | 'minus' | 'add'| 'delete';
	onClick?: () => void;
	ariaLabel?: string;
	disabled?: boolean;
}

export const SquareIconButton = ({ variant, onClick, ariaLabel, disabled }: SquareIconButtonProps) => {
	const icons = {
		exit: <img src={X} alt='Exit' />,
		back: <img src={Back} alt='Back' />,
		save: <img src={Check} alt='Save' />,
		minus: <Minus size="22" />,
		add: <Add size="22" />,
		delete: <Trash color='#F04438' />
	};

	return (
		<button
			onClick={onClick}
			className={`${styles.Button} ${
				styles[`Button--${variant}`]
			}`}
			disabled={disabled}
			aria-label={ariaLabel}
		>
			{icons[variant]}
		</button>
	);
};