import { TDR } from 'tdr-common';
import config from '../common/config';
import { useMutation } from '@tanstack/react-query';

export function useModifyReservation(reservationId: string) {
	const mutation = useMutation({
		mutationKey: ['modifyReservation', reservationId],
		mutationFn: (updateDto: Partial<TDR.Reservation>) => modifyReservation(updateDto)
	});

	return mutation;
}

export default async function modifyReservation(reservation: Partial<TDR.Reservation>) {
	const response = await fetch(`${config.apiHost}/modifyReservation`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},

		body: JSON.stringify(reservation)
	});

	return response.json();
}
