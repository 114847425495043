import Medusa from '@medusajs/medusa-js';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useReservation } from '../../api/getReservation';
import { useRestaurant } from '../../api/getRestaurant';
import { useUpdateReservationItems } from '../../api/updateReservationItems';
import { URLParams } from '../../common/types';
import { CTAButton } from '../../components/buttons';
import { SquareIconButton } from '../../components/buttons/SquareIconButton';
import { AddOnItem } from '../../components/checkout/AddOnItem';
import { Header } from '../../components/checkout/Header';
import { AddOnItemDTO } from '../../components/checkout/steps/AddOns';
import { DiscardChanges } from '../../components/dialog/controlled-dialog/DiscardChanges';
import { Notice } from '../../components/notice-new/Notice';
import layoutStyles from '../../layouts/ModificationLayout.module.scss';

const medusa = new Medusa({
	maxRetries: 3,
	baseUrl: 'https://medusa.tablz.com'
});

export const EditAddOnsPage = () => {
	const navigate = useNavigate();
	const { reservationId } = useParams<URLParams>();

	const { data: reservation } = useReservation(reservationId);
	const { data: restaurant } = useRestaurant(reservation.restaurantId);
	const updateItemsMutation = useUpdateReservationItems();

	const [addOns, setAddOns] = useState<AddOnItemDTO[]>(reservation.items.filter((item) => item.type === 'addon') as AddOnItemDTO[]);
	const [products, setProducts] = useState<any[]>([]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isFormDirty, setIsFormDirty] = useState(false);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const handleConfirmClick = async () => {
		const response = await updateItemsMutation.mutateAsync({
			input: {
				reservationId: reservationId,
				items: addOns as any
			}
		});

		if (response.success) {
			navigate(`/booking/${reservationId}`);
			toast.success('Add-ons have been updated successfully.');
		}
		else {
			const errorMessage = `Failed to update add-ons: ${response.message}`;
			Sentry.captureException(new Error(errorMessage));
			toast.error('Oops, something went wrong');
		}
	};

	const returnToBookingDetails = () => {
		navigate(`/booking/${reservationId}`);
	};

	const handleExitClick = () => {
		if (isFormDirty) {
			setIsConfirmOpen(true);
		}
		else {
			returnToBookingDetails();
		}
	};

	const handleConfirmExit = () => {
		returnToBookingDetails();
		setIsConfirmOpen(false);
	};

	const handleCancelExit = () => {
		setIsConfirmOpen(false);
	};

	const updateAddOns = (id: string, quantity: number, variantId: string) => {
		const existingItem = addOns?.findIndex((item) => item.id === id && item.variantId === variantId);
		if (existingItem !== -1) {
			const updatedAddOns = [...addOns];
			updatedAddOns[existingItem].quantity = quantity;
			setAddOns(updatedAddOns);
		}
		else {
			setAddOns([...addOns, { id, quantity, variantId }]);
		}
	};

	//TODO - use react query to fetch products
	useEffect(() => {
		if (!!restaurant['salesChannelId'] && products.length === 0) {
			medusa.products
				.list({
					sales_channel_id: [restaurant['salesChannelId']]
				})
				.then((res) => {
					const flattenedProducts = res.products.flatMap((product) =>
						product.variants.map((variant) => ({
							...product,
							title: variant.title,
							variantId: variant.id,
							price: {
								amount: variant.prices?.[0]?.amount / 100 || 0, // all currencies are in cents, even AED
								unit: variant.prices?.[0]?.currency ?? 'USD'
							},
							isSignatureItem: product.metadata?.['SIGNATURE'] === 'true',
							tag: variant.material,
							unit: product.metadata?.['UNIT'] ?? 'unit',
							imageUrl: product.images?.[0]?.url
						}))
					);
					setProducts(flattenedProducts);
				})
				.catch((err) => {
					Sentry.captureException(err);
					toast.error('An error occurred while fetching add-on items. Please try again later.');
					navigate(`/booking/${reservationId}`);
				});
		}
	}, [restaurant['salesChannelId']]);

	return (
		<>
			<div className={layoutStyles.Header}>
				<Header
					title='Change Add-Ons'
					subtitle='Modify Booking'
					iconLeft={<></>}
					iconRight={<SquareIconButton variant='exit' />}
					iconRightOnClick={handleExitClick}
				/>
			</div>

			<div className={layoutStyles.Body}>
				<div className={layoutStyles.Section}>
					<Notice text={'No payment upfront, ready upon arrival!'} />
					{products.length > 0
						? products.map((product) => (
							<AddOnItem
								key={product.variantId}
								product={product}
								restaurant={restaurant}
								updateAddOns={updateAddOns}
								initialQuantity={addOns.find(addon => addon.variantId === product.variantId)?.quantity || 0}
							/>
						))
						: null}
				</div>
			</div>

			<footer className={layoutStyles.Footer}>
				<CTAButton
					variant='transparent'
					buttonText={'Confirm'}
					// disabled={!isFormDirty || updateItemsMutation.isPending}
					disabled={updateItemsMutation.isPending}
					loading={updateItemsMutation.isPending}
					onClick={handleConfirmClick}
				/>
			</footer>

			<DiscardChanges
				open={isConfirmOpen}
				onClose={() => setIsConfirmOpen(false)}
				onDiscard={handleConfirmExit}
				onCancel={handleCancelExit}
			/>
		</>
	);
};
