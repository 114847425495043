import { TDR, querySnapshotToArray } from 'tdr-common';
import { collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';
import { useQuery } from '@tanstack/react-query';

export function useFeatureFlags(restaurantId: string) {

	const query = useQuery({
		enabled: !!restaurantId,
		queryKey: ['restaurantFeatureFlags', restaurantId],
		queryFn: () => getRestaurantFeatureFlags(restaurantId)
	});

	return query;
}

async function getRestaurantFeatureFlags (restaurantId: string): Promise<TDR.FeatureFlags> {
	const db = getFirestore();
	const q = query(collection(db, 'FeatureFlags'), where('restaurantId', '==', restaurantId), limit(1));
	const querySnapshot = await getDocs(q);
	return querySnapshotToArray(querySnapshot)?.[0] || null;
}