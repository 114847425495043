
export enum AnalyticsTools {
	ALL='all',
	MIXPANEL='mixpanel',
}

export abstract class AbstractAnalyticalTool {
	public abstract load(userPreferances: Preferences);
	public abstract identify(userId: string, traits: Record<string, unknown>): void;
}

export abstract class AbstractBehavioralTool extends AbstractAnalyticalTool {
	public abstract track(event: string, properties: Record<string, unknown>): void;
	public abstract page(name: string, properties: Record<string, unknown>): void;
}

export enum PreferencesCategory {
	FUNCTIONAL='functional',
	ANALYTICS='analytics',
	//For future use.
	// ADVERTISING='advertising',
}

export interface MiddlewareParams {
	payload: any;
	next: (payload: MiddlewareParams['payload'] | null) => void
}

export type MiddlewareFunction = (
	middleware: MiddlewareParams
) => void | Promise<void>

export type Preferences = Partial<Record<PreferencesCategory, boolean>>;
export type PreferenceConfiguration = Partial<Record<PreferencesCategory, Preference>>

export type IntegrationMap = Partial<Record<AnalyticsTools, boolean>>
export interface Preference {
	title: string;
	description: string;
}
export interface PreferencesManager {
	loadPreferences(): Preferences;
	savePreferences(prefs: Preferences): void;
}
export interface AnalyticsParams {
	preferences?: Preferences;

}