import React from 'react';
import styles from './HeaderLarge.module.scss';
import { TDR } from 'tdr-common';

type HeaderLargeProps = {
	table: TDR.Table;
};

export const HeaderLarge = ({ table }: HeaderLargeProps) => {
	return (
		<header className={styles.Wrapper}>
			<h3 className={styles.Subtitle}>{table.label || 'Table'}</h3>
			<h2 className={styles.Title}>{table.name}</h2>
		</header>
	);
};
