import React from 'react';
import { SubHeader } from '../../headers/SubHeader';
import { getDisplayTime } from '../../../helpers/getDisplayTime';
import { DateTime } from 'luxon';
import { DATESTR_FMT, DAYSTR_FMT } from 'tdr-common';
import { CalendarIcon, ClockIcon, GuestIcon } from '../../icons';

import styles from '../../../layouts/InboundRequestLayout.module.scss';

type DetailsProps = {
	guests: number;
	date: string;
	time: string;
};
export const Details: React.FC<DetailsProps> = ({ guests, date, time }) => {
	return (
		<div className={styles.Section}>
			<SubHeader title='Booking Details' />

			<div className='OverviewList'>
				<div className='OverviewList__Item'>
					<GuestIcon />
					{`${guests}+ Guests`}
				</div>

				<div className='OverviewList__Item'>
					<CalendarIcon />
					{date && DateTime.fromFormat(date, DATESTR_FMT).toFormat(DAYSTR_FMT)}
				</div>

				<div className='OverviewList__Item'>
					<ClockIcon />
					{time && getDisplayTime(time)}
				</div>
			</div>
		</div>
	);
};
