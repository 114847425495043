import React, { CSSProperties } from 'react';
import styles from './CTAButton.module.scss';
import Spinner from '../Spinner';

export type CTAButtonVariant = 'primary' | 'secondary' | 'text' | 'dark' | 'transparent';

type CTAButtonProps = {
	variant?: CTAButtonVariant;
	buttonText: string;
	textColor?: string;
	backgroundColor?: CSSProperties['backgroundColor'];
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	ariaLabel?: string;
	iconBefore?: React.ReactNode;
	iconAfter?: React.ReactNode;
	disabled?: boolean;
	loading?: boolean;
	isSelected?: boolean;
}

const CTAButton = ({
	variant = 'primary',
	buttonText,
	textColor,
	backgroundColor,
	onClick,
	ariaLabel,
	iconBefore,
	iconAfter,
	disabled = false,
	loading = false,
	isSelected
}: CTAButtonProps) => {

	const classes = `${styles.Button} ${styles[`Button--${variant}`]} ${isSelected ? styles.Selected : ''}`;

	return (
		<button
			type='button'
			className={classes}
			onClick={onClick}
			title={buttonText}
			aria-label={ariaLabel || buttonText}
			aria-busy={loading}
			disabled={disabled}
			aria-disabled={disabled}
			style={{
				color: textColor,
				backgroundColor
			}}
		>
			{loading ? <Spinner size='xs' /> : (
				<span>
					<span>{iconBefore && iconBefore}</span>
					<span>{buttonText}</span>
					<span>{iconAfter && iconAfter}</span>
				</span>
			)}
		</button>
	);
};

export default CTAButton;