import React from 'react';
import { CustomerSupportLink } from '../buttons';
import styles from './ContactUsFooter.module.scss';

export const ContactUsFooter = () => {
	return (
		<p className={styles.Text}>
      Got questions? Your concerns are important to us. Please reach out to us at <CustomerSupportLink /> for
      assistance.
		</p>
	);
};
