import React, { RefObject } from 'react';
import ChevronDown from '../../icons/down-chevron.svg';
import styles from './ScrollDownButton.module.scss';

type ScrollDownButtonProps = {
	scrollRef: RefObject<HTMLElement>;
}

export const ScrollDownButton = ({ scrollRef }: ScrollDownButtonProps) => {
	const handleScrollDown = () => {
		if (scrollRef && scrollRef.current) {
			scrollRef.current.scrollBy({ top: 350, behavior: 'smooth' });
		}
	};

	return (
		<button className={styles.Button} onClick={handleScrollDown}>
			<span>Scroll down</span>
			<img src={ChevronDown} alt='scroll down' />
		</button>
	);
};
