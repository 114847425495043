

import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { analytics } from '../../helpers/analytics';
import { AvailabilityGuardContext, serializeSearchFiltersForURL } from '../../routes/AvailabilityGuard';
import { InventoryCarousel } from '../inventory-carousel/InventoryCarousel';

export type TableCarouselProps = {
	onClick: (table: TDR.Table) => void;
}

const TableCarousel = () => {
	const { searchFilters } = useOutletContext<AvailabilityGuardContext>();

	const navigate = useNavigate();

	const onTableClick = (table: TDR.Table) => {
		analytics.track('Table Clicked From Carousel', {
			name: table.name,
			id: table.id,
			maxGuests: table.maxGuests,
			minGuests: table.minGuests,
			supportLargeGroup: table.supportLargeGroup ?? false,
			internalName: table.internalName,
			isPatio: table.isPatio
		});

		navigate({ pathname: `${table.slug}`, search: serializeSearchFiltersForURL(searchFilters).toString() });
	};

	return <InventoryCarousel onClick={onTableClick} />;

};

export default TableCarousel;
