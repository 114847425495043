import React from 'react';
import { TDR } from 'tdr-common';
import ArrowRight from '../../icons/right-icon.svg';
import { ProximityInfoContainer } from './ProximityInfoContainer';
import useNavigateWithQuery from '../../hooks/useNavigateWIthQuery';


type LostYourWayProps = {
	table: Pick<TDR.Table, 'slug'>,
	navigateToPin: (table) => void
}
export const LostYourWay: React.FC<LostYourWayProps> = ({ table, navigateToPin }) => {
	const navigateWithQuery = useNavigateWithQuery();

	const tableLink = `explore/${table.slug}`;

	let onClick = () => navigateWithQuery(tableLink);

	if (location.pathname.endsWith(tableLink)) {
		onClick = () => navigateToPin(table);
	}

	return (
		<ProximityInfoContainer
			title='Lost your way?'
			body={<>Let&apos;s find the closest table</>}
			icon={ArrowRight}
			onClick={onClick}
		/>
	);
};
