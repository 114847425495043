import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import { TDR } from 'tdr-common';
import layoutStyles from '../../layouts/CheckoutLayout.module.scss';
import { SubHeader } from '../headers/SubHeader';
import { EmailIcon, GuestIcon, PhoneIcon } from '../icons';
import styles from './GuestDetails.module.scss';

export interface GuestDetailsProps {
	reservation: TDR.Reservation;
}

export function GuestDetails({ reservation }: GuestDetailsProps) {
	return (
		<div className={layoutStyles.Section}>
			<div className={styles.Header}>
				<SubHeader title='Contact Details' />
				<Link to='modify/guest' aria-label='Edit Contact Details' className={styles.Button}>
					<span>Edit</span>
				</Link>
			</div>

			<div className={layoutStyles.SubSection}>
				<div className={styles.Item}>
					<GuestIcon />
					{reservation.fullName}
				</div>

				<div className={styles.Item}>
					<EmailIcon />
					{reservation.email}
				</div>

				<div className={styles.Item}>
					<PhoneIcon />
					{formatPhoneNumberIntl(reservation.phone)}
				</div>
			</div>
		</div>
	);
}
