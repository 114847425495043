import React, { useState } from 'react';
import { analytics } from '../../../helpers/analytics';
import { useDisclosure } from '../../../hooks/useDisclosure';
import Dislike from '../../../icons/dislike.svg';
import Like from '../../../icons/like.svg';
import { CTAButton } from '../../buttons';
import { Dialog, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../Dialog';
import styles from './InfoDialog.module.scss';

type InfoDialogProps = {
	triggerButton: React.ReactElement;
	title: string;
	body: React.ReactNode;
	variant?: 'dismiss' | 'feedback';
};

enum UserFeedback {
	NONE = 'none',
	LIKE = 'like',
	DISLIKE = 'dislike',
}

const getFeedbackLabel = (userFeedback: UserFeedback) => {
	return userFeedback === UserFeedback.LIKE ? 'Helpful' : 'Unhelpful';
};

export const InfoDialog = ({ triggerButton, title, body, variant = 'dismiss' }: InfoDialogProps) => {
	const { close, open, isOpen } = useDisclosure();

	const [userFeedback, setUserFeedback] = useState<UserFeedback>(UserFeedback.NONE);

	return (
		<Dialog
			open={isOpen}
			onOpenChange={(isOpen) => {
				if (!isOpen) {
					close();
				}
				else {
					open();
				}
			}}
		>
			<DialogTrigger className={styles.Trigger}>{triggerButton}</DialogTrigger>

			<DialogContent hasCloseButton fillContainerWidth>
				<DialogHeader>
					<DialogTitle>{title}</DialogTitle>
				</DialogHeader>

				<DialogBody>
					{body}

					{variant === 'feedback' && (
						<>
							<p>Was this helpful?</p>

							<div className={styles.FeedbackOptions}>
								<button
									className={`${styles.FeedbackOption} ${
										userFeedback === UserFeedback.LIKE ? styles['FeedbackOption--selected'] : ''
									}`}
									onClick={() =>
										setUserFeedback(userFeedback === UserFeedback.LIKE ? UserFeedback.NONE : UserFeedback.LIKE)
									}
									aria-label='This information was helpful toggle'
								>
									<img src={Like} alt='Like' />
								</button>

								<button
									className={`${styles.FeedbackOption} ${
										userFeedback === UserFeedback.DISLIKE ? styles['FeedbackOption--selected'] : ''
									}`}
									onClick={() =>
										setUserFeedback(userFeedback === UserFeedback.DISLIKE ? UserFeedback.NONE : UserFeedback.DISLIKE)
									}
									aria-label='The Information was not helpful toggle button'
								>
									<img src={Dislike} alt='Dislike' />
								</button>
							</div>
						</>
					)}
				</DialogBody>

				<DialogFooter>
					{variant === 'feedback' && (
						<CTAButton
							variant='secondary'
							buttonText='Submit'
							ariaLabel='Submit Feedback Button'
							disabled={userFeedback === UserFeedback.NONE}
							onClick={() => {
								analytics.track(`${getFeedbackLabel(userFeedback)} ${title}`);
								close();
							}}
						/>
					)}

					{variant === 'dismiss' && (
						<CTAButton variant='secondary' buttonText='Dismiss' ariaLabel='Close information dialog' onClick={close} />
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
