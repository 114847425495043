import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { TDR } from 'tdr-common';
import { verifyCode } from '../../api/guest';
import { useCheckoutContext } from '../../context/new/CheckoutContext';
import { analytics } from '../../helpers/analytics';
import { countryCodeConverter } from '../../helpers/countryCodeConverter';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';
import AgreementCheckbox from '../agreement-checkbox/AgreementCheckbox';
import { EmailCheckInput } from '../form-inputs/EmailCheckInput';
import { RHFPhoneInput } from '../form-inputs/RHFPhoneInput';
import { RHFTextInput } from '../form-inputs/RHFTextInput';
import { SubHeader } from '../headers/SubHeader';
import { GuestInfo } from './types';

import layoutStyles from '../../layouts/CheckoutLayout.module.scss';
import styles from './ContactInput.module.scss';

export const ContactInput = () => {
	const { restaurant, featureFlags } = useOutletContext<RestaurantGuardOutletContext>();
	const { isSaveContactChecked, setIsSaveContactChecked, updateCheckoutState } = useCheckoutContext();

	const [verifyingEmail, setVerifyingEmail] = useState<boolean>(false);
	const [guest, setGuest] = useState<TDR.Guest.Guest>(null);

	const {
		register,
		control,
		formState: { errors },
		getValues,
		setValue,
		trigger
	} = useFormContext<GuestInfo>();

	const hasFilledOutContactInfo =
    getValues().email &&
    getValues().firstName &&
    getValues().lastName &&
    getValues().phone &&
    !errors.email &&
    !errors.firstName &&
    !errors.lastName &&
    !errors.phone;

	const confirmationHandler = async (
		code: string,
		sessionId: string,
		setInvalidCode: React.Dispatch<React.SetStateAction<boolean>>
	) => {
		setInvalidCode(false);
		setVerifyingEmail(true);
		const res = await verifyCode({ code, sessionId });
		if (res.success) {
			if (res.token) {
				updateCheckoutState({
					firebaseToken: res.token
				});
			}
			if (!!res.guest) {
				analytics.track('Checkout Auto-Filled', { email: res.guest?.email, userId: res.guest?.id });
				setValue('firstName', res.guest.firstName);
				setValue('lastName', res.guest.lastName);
				setValue('phone', res.guest.phone);
				setValue('fastCheckoutEnabled', res.guest.hasFastCheckoutEnabled);
				await trigger(); //need to re-trigger rhf validation to get all the form states updated.
			}
			setGuest(res.guest);
		}
		else {
			setInvalidCode(true);
		}
		setVerifyingEmail(false);
	};

	return (
		<section className={layoutStyles.Section}>
			<SubHeader title='Contact Information' isRequired={true} />
			<span id='sign-in-button' style={{ display: 'none' }} />

			<div className={styles.ContactInfo}>
				<EmailCheckInput
					name='email'
					register={register}
					errors={errors}
					enableFastCheckout={featureFlags?.enableFastCheckout}
					disabled={verifyingEmail}
					onCodeSubmit={confirmationHandler}
					guest={guest}
				/>

				<div className={styles.Row}>
					<RHFTextInput label='First Name' name='firstName' register={register} error={errors.firstName?.message} />
					<RHFTextInput label='Last Name' name='lastName' register={register} error={errors.lastName?.message} />
				</div>

				<RHFPhoneInput
					name='phone'
					control={control}
					label='Phone Number'
					defaultCountry={countryCodeConverter(restaurant.country ?? restaurant.stripeRegion)}
					error={errors.phone?.message}
				/>
			</div>

			<AnimatePresence>
				{hasFilledOutContactInfo && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<AgreementCheckbox
							id='fastCheckoutAgreement'
							checked={isSaveContactChecked}
							onChange={(evt) => {
								setIsSaveContactChecked(evt.target.checked);
							}}
							label={'Save my information for future checkouts.'}
							required={false}
							labelVariant='small'
						/>
					</motion.div>
				)}
			</AnimatePresence>
		</section>
	);
};
