import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { DATESTR_FMT, DAYSTR_FMT } from 'tdr-common';
import { getDisplayTime } from '../../helpers/getDisplayTime';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';
import { RequestSubmitted } from '../confirmation';

export const LargeGroupRequestResult = () => {
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	const location = useLocation();
	const navigate = useNavigate();

	const day = location.state?.day;
	const time = location.state?.time;
	const guests = location.state?.groupSize;
	const restaurantName = restaurant?.name;

	const paramsFilled = !!day && !!time && !!guests && !!restaurantName;
	const formattedDay = day && DateTime.fromFormat(day, DATESTR_FMT).toFormat(DAYSTR_FMT);
	const formattedTime = time && getDisplayTime(time);

	const returnToBookingPortalRoute = `/${restaurant.slug}/explore`;

	useEffect(() => {
		// handlePopstateEvent refreshes the page so don't need a cleanup function to remove the listener
		if (!paramsFilled) {
			navigate(returnToBookingPortalRoute, { replace: true });
		}
		window.addEventListener('popstate', handlePopstateEvent);
	}, []);

	const handlePopstateEvent = () => {
		navigate(returnToBookingPortalRoute, { replace: true });
	};

	return paramsFilled ? (
		<RequestSubmitted
			restaurantName={restaurantName}
			guests={guests}
			date={formattedDay}
			time={formattedTime}
			linkTo={returnToBookingPortalRoute}
		/>
	) : null;
};
