import React from 'react';
import { TDR } from 'tdr-common';
import styles from './EventCard.module.scss';
import { DynamicIcon } from '../icons';
import { getCdnImageUri } from '../../helpers/getCDNURI';
import exportedConfig from '../../common/config';
import { formatBetweenTimes, formatDate, formatDaysOfWeek } from './EventCard';

export const EventCardLinkType = ({ event }: { event: TDR.Event }) => {
	const handleClick = () => {
		if (event.href) {
			window.open(event.href, '_blank');
		}
	};

	let filePath = `/images/events/${event.id}/coverImage.jpg`;
	if (exportedConfig.deployment !== 'production') {
		filePath = `/staging/images/events/${event.id}/coverImage.jpg`;
	}

	// Prioritize 'dates' over 'daysOfWeek'
	const formattedDates = formatDate(event.condition?.value?.dates || []);
	const formattedDays = event.condition?.value?.daysOfWeek
		? formatDaysOfWeek(event.condition.value.daysOfWeek)
		: null;
	const timeInfo = event.condition?.value?.betweenTimes
		? formatBetweenTimes(event.condition.value.betweenTimes)
		: null;

	return (
		<div onClick={handleClick} className={styles.wrapper}>
			<img
				src={getCdnImageUri(filePath)}
				alt="Event Cover Image"
				className={styles.mediaContent}
				style={{ height: '270px' }}
			/>
			<div className={styles.titleSection}>
				<div className={styles.title}>{event.displays[0].title}</div>
				<div className={styles.timeInfo}>
					{/* Display formatted date if available, otherwise days of the week */}
					{formattedDates && (
						<div className={styles.tag}>
							<DynamicIcon iconName={'Calendar'} size={20} color={'#94969C'} />
							{formattedDates}
						</div>
					)}
					{!formattedDates && formattedDays && (
						<div className={styles.tag}>
							<DynamicIcon iconName={'Calendar'} size={20} color={'#94969C'} />
							{formattedDays}
						</div>
					)}
					{/* Add time information if available */}
					{timeInfo && (
						<div className={styles.tag}>
							<DynamicIcon iconName={'Clock'} size={20} color={'#94969C'} />
							{timeInfo}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
