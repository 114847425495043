import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { TDR, getFormattedCutOffMargin } from 'tdr-common';
import AdditionalFee from '../../icons/additionalfee.svg';
import Stop from '../../icons/stop.svg';
import Timer from '../../icons/timer.svg';
import { CheckoutGuardOutletContext } from '../../routes/CheckoutGuard';
import { PolicyItem } from '../policies/PolicyItem';

import styles from './PolicyItems.module.scss';

export default function PolicyItems() {
	const { restaurant, invoice, checkoutSession: session } = useOutletContext<CheckoutGuardOutletContext>();

	const isPaidBooking = invoice?.total > 0;

	let cancelCutoff = '24 hours';
	if (session?.time && restaurant) {
		const formattedCutoff = getFormattedCutOffMargin(session as Partial<TDR.Reservation>, restaurant, 'cancel');
		if (formattedCutoff) {
			cancelCutoff = formattedCutoff;
		}
	}

	return (
		<ul className={styles.PolicyList}>
			{isPaidBooking && (
				<>
					<PolicyItem icon={'ImportCircle'} title={'Seating Selection'}>
						<p>The seating <strong>selection fee is separate</strong> from the food and beverage deposit</p>
					</PolicyItem>

					<PolicyItem icon={Stop} label='Cancellation' title={'Cancellation Window'}>
						<p>
							Cancellations can be made with no penalty up to <strong>{cancelCutoff}</strong> in advance of your
							booking time.
						</p>
					</PolicyItem>
				</>
			)}

			{restaurant.allowNoShowFees && restaurant.feeDisclaimer && (
				<PolicyItem icon={AdditionalFee} label='No-show fee' title={'No-Show Fee'}>
					<p>{restaurant.feeDisclaimer}</p>
				</PolicyItem>
			)}

			<PolicyItem icon={Timer} label='Grace period' title={'Grace Period'}>
				<p>
					All bookings have a <strong>{restaurant.gracePeriod || 15} minute</strong> grace period from your reserved seating time. Your
					booking may not be honored if you arrive past the {restaurant.gracePeriod || 15} minute grace period on the day of your
					reservation.
				</p>
			</PolicyItem>
		</ul>
	);
}
