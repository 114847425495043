import React from 'react';

const VideoUnsupportedMessage = ({ href }: { href: string }) => {
	return (
		<p>
      Your browser doesn&apos;t support HTML video. Here is a
			<a href={href}>link to the video</a> instead.
		</p>
	);
};

export default VideoUnsupportedMessage;