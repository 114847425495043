import React from 'react';
import { TDR } from 'tdr-common';
import { SubHeader } from '../../headers/SubHeader';
import OccasionOption from '../../checkout/OccasionOption';
const occasionSelection = Object.values(TDR.Occasion);

type OccasionProps = {
	setOccasion: React.Dispatch<React.SetStateAction<TDR.Occasion>>;
	occasion: TDR.Occasion
}
export const Occasions: React.FC<OccasionProps>= ({ setOccasion, occasion }) => {

	return (
		<div className='CheckoutContent__Section'>
			<SubHeader title='Occasion' isOptional />

			<div className='OverviewSelectionContent'>
				<ul className='OverviewSelections'>
					{occasionSelection.map((occ) => (
						<OccasionOption
							key={occ}
							label={occ}
							onClick={() => {
								setOccasion(occ);
							}
							}
							selected={occasion?.includes?.(
								occ
							)}
						/>
					))}
				</ul>

			</div>
		</div>
	);
};
