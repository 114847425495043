import React from 'react';
import { TDR } from 'tdr-common';
import { useViewToggle } from '../../context/ViewToggleProvider';
import { useScreenSize } from '../../hooks/useScreenSize';
import ArrowRight from '../../icons/right-icon.svg';
import TableInfo from '../table-drawer/TableInfo';
import { ProximityInfoContainer } from './ProximityInfoContainer';
import useNavigateWIthQuery from '../../hooks/useNavigateWIthQuery';

type ClosestTableInfoProps = {
	table: TDR.Table;
	onClick: (table: TDR.Table) => void;
};
export const ClosestTableInfo: React.FC<ClosestTableInfoProps> = ({ table, onClick }) => {
	const navigateWIthQuery = useNavigateWIthQuery();
	const { isVirtualView, turnOffVirtualView } = useViewToggle();
	const { isSmallScreen } = useScreenSize();

	return (
		<ProximityInfoContainer
			title={table.name}
			body={<TableInfo minGuests={table.minGuests} maxGuests={table.maxGuests} />}
			icon={ArrowRight}
			replaceIconWithLabel={isVirtualView || isSmallScreen}
			onClick={() => {
				onClick(table);
				turnOffVirtualView();
				navigateWIthQuery(`explore/${table.slug}`);
			}}
		/>
	);
};
