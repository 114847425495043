import React from 'react';
import styles from './AddOptionSelections.module.scss';

type AddOptionSelectionsProps = {
	items?: string[];
	note?: string;
}

export const AddOptionSelections = ({ items, note } : AddOptionSelectionsProps) => {
	if (!note && (items?.length === 0 || !items?.[0])) {
		return null;
	}

	return (
		<div className={styles.Container}>
			{items?.map((item) => (
				<p key={item} className={styles.Item}>{item}</p>
			))}
			{note && <p className={`${styles.Item} ${styles.Note}`}>Note: {note}</p>}
		</div>
	);
};
