import React from 'react';
import { ControlledDialog } from './ControlledDialog';
import { CTAButton } from '../../buttons';

type DiscardChangesProps = {
	open: boolean;
	onClose: () => void;
	onDiscard: () => void;
	onCancel: () => void;
};

export const DiscardChanges = ({ open, onClose, onDiscard, onCancel }: DiscardChangesProps) => {
	return (
		<ControlledDialog
			isOpen={open}
			onClose={onClose}
			icon='danger'
			title='Discard Changes?'
			body={<p>Any unsaved edits will be discarded.</p>}
			footer={
				<>
					<CTAButton buttonText='Discard changes' onClick={onDiscard} variant='dark' textColor='#D92D20' />
					<CTAButton buttonText='Cancel' onClick={onCancel} variant='dark' />
				</>
			}
		/>
	);
};
