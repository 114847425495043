import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { AvailabilityGuardContext, serializeSearchFiltersForURL } from '../../routes/AvailabilityGuard';
import CTALink from './CTALink';
import { TableGuardOutletContext } from '../../routes/TableGuard';
import { RestaurantGuardOutletContext } from '../../routes/RestaurantGuard';

export const FindAvailabilityButton = ({ label = 'Find Availability' }: { label?: string }) => {
	const { searchFilters: preSetFilters } = useOutletContext<AvailabilityGuardContext>();
	const { table: selectedTable } = useOutletContext<TableGuardOutletContext>();
	const { featureFlags } = useOutletContext<RestaurantGuardOutletContext>();

	const searchFilters = preSetFilters?.groupSize ? preSetFilters : selectedTable?.minGuests && featureFlags?.autoSelectGroupSize ? { groupSize : selectedTable.minGuests } : {};
	return (
		<CTALink
			variant='primary'
			to={{ pathname: 'availability', search: serializeSearchFiltersForURL(searchFilters).toString() }}
			ariaLabel='Find availability for selected table'
			text={label} />
	);
};
