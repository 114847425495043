import React, { useState, createContext } from 'react';
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore, Firestore } from 'firebase/firestore';
import { ProviderProps } from '../common/types';
import config from '../common/config';
export namespace FirebaseContext {
	export type Value = {
		initialized: boolean,
		firestore: Firestore,
		email: string,
		token: string,
		analytics: any // TODO: figure it proper typing!
	};
}



export const FirebaseContext = createContext<FirebaseContext.Value>({
	initialized: true,
	firestore: null,
	email: null,
	token: null,
	analytics: null
});
FirebaseContext.displayName = 'Firebase';

export const FirebaseProvider = ({ children }: ProviderProps) => {
	const [firebaseState, setFirebaseState] = useState<FirebaseContext.Value>({
		initialized: true,
		firestore: null,
		email: null,
		token: null,
		analytics: null
	});

	if (getApps().length === 0) {
		const app = initializeApp(config.firebase);
		const firestore = getFirestore(app);
		setFirebaseState({
			...firebaseState,
			firestore
		});
	}

	return (
		<FirebaseContext.Provider value={firebaseState}>
			{children}
		</FirebaseContext.Provider>
	);
};