import React from 'react';

export type IconButtonProps = {
	icon: string,
	ariaLabel: string,
	onClick: () => void,
}

export const IconButton = ({ icon, ariaLabel, onClick }: IconButtonProps) => {
	return (
		<button aria-label={ariaLabel} className='IconButton' onClick={onClick} tabIndex={0}>
			<img src={icon} alt={ariaLabel}/>
		</button>
	);
};

export const IconButtonSquare = ({ icon, ariaLabel, onClick }: IconButtonProps) => {
	return (
		<button aria-label={ariaLabel} className='IconButtonSquare' onClick={onClick} tabIndex={0}>
			<img src={icon} alt={ariaLabel}/>
		</button>
	);
};
