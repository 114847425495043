import topDomain from '@segment/top-domain';
import cookies from 'js-cookie';
import { analytics } from '../../helpers/analytics';
import { AnalyticsParams, PreferenceConfiguration, Preferences, PreferencesCategory } from './types';
import config from '../../common/config';

const DEFAULT_COOKIE_NAME = 'tracking-preferences';
const COOKIE_DEFAULT_EXPIRES = 365;

export const ACCEPT_ALL_PREFERENCES: Preferences = Object.values(PreferencesCategory).reduce((acc, category) => {
	acc[category] = true;
	return acc;
}, {} as Preferences);

export const DEFAULT_PREFERANCES_CONFIG: PreferenceConfiguration = {
	[PreferencesCategory.FUNCTIONAL]: {
		title: 'Essential Cookies',
		description: 'Essential cookies are necessary for the basic functions of our site. They remember your preferences and consent settings to ensure a smooth browsing experience.'
	},
	[PreferencesCategory.ANALYTICS]: {
		title: 'Analytical Cookies',
		description: 'Analytical cookies help us understand how visitors interact with our website by collecting information about your usage and site traffic. This data aids us in improving the website\'s functionality and user experience.'
	}
};

// TODO: harden against invalid cookies
// TODO: harden against different versions of cookies
export function loadPreferences(cookieName?: string): Preferences | undefined {
	const cookieContent = cookies.get(cookieName || DEFAULT_COOKIE_NAME);
	if (!cookieContent) {
		return undefined;
	}
	return JSON.parse(cookieContent);
}
export interface SavePreferences {
	preferences: Preferences;
	cookieDomain?: string;
	cookieName?: string;
	cookieExpires?: number;
}

export function savePreferences({
	preferences,
	cookieDomain,
	cookieName,
	cookieExpires
}: SavePreferences) {
	const domain = cookieDomain || topDomain(window.location.href);
	const expires = cookieExpires || COOKIE_DEFAULT_EXPIRES;

	cookies.set(cookieName || DEFAULT_COOKIE_NAME, JSON.stringify(preferences), {
		expires,
		domain
	});
}

function isDemoEnv() {
	return ['demo.tablz.com', 'demo-staging.tablz.com'].includes(location.host);
}

export function conditionallyLoadAnalytics({
	preferences
}: AnalyticsParams) {

	if (config.analytics.enable === false || isDemoEnv()) {
		return;
	}

	// Load a.js normally when consent isn't required and there's no preferences
	analytics.load(preferences);
	return;
}