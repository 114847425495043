import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TDR } from 'tdr-common';
import { useReservation } from '../../api/getReservation';
import { useRestaurantBasePolicies } from '../../api/getRestaurantBasePolicies';
import { useFeatureFlags } from '../../api/getRestaurantFeatureFlags';
import { useRestaurantTaxPolicies } from '../../api/getRestaurantTaxPolicies';
import { useModifyReservation } from '../../api/modifyReservation';
import { URLParams } from '../../common/types';
import { useModificationFlowState } from '../../context/ModificationFlowProvider';
import { getNewInvoiceForModifiedTable } from '../../helpers/getNewInvoiceForModifiedTable';
import luxonToFirebaseTimestamp from '../../helpers/luxonToFirebaseTimestamp';
import { trackReservationModifications } from '../../helpers/reservationEventTrackers';
import { ModifyBookingFormData } from '../../routes/BookingGuard';
import { CTAButton } from '../buttons';
import { PatioInfoSection } from '../callouts/PatioInfoSection';
import SearchResultPricing from '../price-label/PriceLabel';

import styles from './BookNowFooter.module.scss';

export interface ReviewFooterProps {
	restaurant: TDR.Restaurant;
	selectedTable: TDR.Table;
	priceAmount: number;
	displayUnit: string;
	conflictingFilter?: string;
}

export const ReviewFooter = ({
	restaurant,
	selectedTable,
	priceAmount,
	displayUnit,
	conflictingFilter
}: ReviewFooterProps) => {
	const navigate = useNavigate();
	const { reservationId } = useParams<URLParams>();

	const { newInvoice, setNewInvoice } = useModificationFlowState();

	const { data: reservation } = useReservation(reservationId);
	const { data: featureFlags } = useFeatureFlags(restaurant.id);
	const { data: restaurantPolicies, isLoading: loadingRestaurantPolicies } = useRestaurantBasePolicies(restaurant.id);
	const { data: taxPolicies, isLoading: loadingTaxPolicies } = useRestaurantTaxPolicies(restaurant);

	const hidePricing = !!featureFlags?.hideFreePricing;

	const modifyReservationMutation = useModifyReservation(reservationId);

	const methods = useFormContext<ModifyBookingFormData>();

	const conflictingFilterDisplayMap = {
		date: 'date',
		time: 'time',
		groupSize: 'group size'
	};

	const handleReviewClick = async () => {
		const updatedTimestamp = luxonToFirebaseTimestamp(DateTime.fromSeconds(methods.getValues('epochInSeconds')));
		if (newInvoice.payable > 0) {
			const response = await modifyReservationMutation.mutateAsync({
				id: reservationId,
				tableId: selectedTable.id,
				price: newInvoice.total,
				guests: methods.getValues('guests'),
				time: updatedTimestamp,
				items: [
					...reservation.items.filter((item) => item.type !== TDR.Reservation.ItemType.Table),
					{
						id: selectedTable.id,
						type: TDR.Reservation.ItemType.Table,
						label: selectedTable.label,
						tags: selectedTable.tags
					}
				]
			});
			if (response.success) {
				methods.setValue('stripeClientSecret', response.clientSecret);
				methods.setValue('stripePaymentMethod', response.paymentMethod);
				navigate(`/booking/${reservationId}/payment`);
			}
			else {
				toast.error(response.message || 'Oops, something went wrong');
				Sentry.captureMessage(`modifyReservation Error: ${response.message}`);
			}
		}
		// Refund required
		else {
			navigate(`/booking/${reservationId}/refund`);
		}
	};

	const handleConfirmClick = async () => {
		const updatedTimestamp = luxonToFirebaseTimestamp(DateTime.fromSeconds(methods.getValues('epochInSeconds')));
		const response = await modifyReservationMutation.mutateAsync({
			id: reservationId,
			tableId: selectedTable.id,
			price: newInvoice.total,
			guests: methods.getValues('guests'),
			time: updatedTimestamp,
			items: [
				...reservation.items.filter((item) => item.type !== TDR.Reservation.ItemType.Table),
				{
					id: selectedTable.id,
					type: TDR.Reservation.ItemType.Table,
					label: selectedTable.label,
					tags: selectedTable.tags
				}
			]
		});
		if (response.success) {
			navigate(`/booking/${reservationId}`);
			trackReservationModifications({
				reservation,
				itemsChanged: { tableName: selectedTable.name, price: newInvoice.total }
			});
			toast.success('Changes saved successfully!');
		}
		else {
			const errorMessage = `modifyReservation Error: ${response.message}`;
			const sentryErrorID = Sentry.captureException(new Error(errorMessage));
			toast.error(response.message || 'Oops, something went wrong');
			trackReservationModifications({ reservation, errorMessage, sentryErrorID });
		}
	};

	const handleChangeFilterClick = () => {
		navigate(`/booking/${reservationId}/modify`);
	};

	useEffect(() => {
		if (loadingRestaurantPolicies || loadingTaxPolicies) {
			return;
		}

		const calculateNewInvoice = async () => {
			const invoice = await getNewInvoiceForModifiedTable({
				restaurant,
				newTable: selectedTable,
				newGuestCount: methods.getValues('guests'),
				newBookingTime: luxonToFirebaseTimestamp(DateTime.fromSeconds(methods.getValues('epochInSeconds'))),
				existingReservation: reservation,
				basePolicies: [...(restaurantPolicies ?? []), ...(taxPolicies ?? [])]
			});

			setNewInvoice(invoice);
		};

		calculateNewInvoice();
	}, [
		restaurant,
		selectedTable,
		restaurantPolicies,
		taxPolicies,
		loadingRestaurantPolicies,
		loadingTaxPolicies,
		reservationId
	]);

	const isPaymentStepRequired = newInvoice?.payable !== 0;

	return loadingRestaurantPolicies || loadingTaxPolicies || !newInvoice ? null : (
		<>
			{selectedTable?.isPatio && <PatioInfoSection />}

			<div className={`${styles.Container} ${hidePricing || !isPaymentStepRequired ? '' : styles['Container--grid']}`}>
				{!hidePricing && isPaymentStepRequired && (
					<SearchResultPricing restaurant={restaurant} priceAmount={priceAmount} displayUnit={displayUnit} />
				)}

				{conflictingFilter ? (
					<CTAButton
						onClick={() => handleChangeFilterClick()}
						buttonText={`Change ${conflictingFilterDisplayMap[conflictingFilter]}`}
					/>
				) : (
					<CTAButton
						buttonText={
							isPaymentStepRequired ? 'Review' : reservation.isLargeGroup ? 'Submit Change Request' : 'Confirm Change'
						}
						onClick={isPaymentStepRequired ? handleReviewClick : handleConfirmClick}
						disabled={modifyReservationMutation.isPending}
						loading={modifyReservationMutation.isPending}
					/>
				)}
			</div>
		</>
	);
};
