import React from 'react';
import X from '../../icons/x.svg';

type InputClearButtonProps = {
	onClick: () => void;
	isVisible: boolean;
}

export const InputClearButton = ({ onClick, isVisible }: InputClearButtonProps) => {
	return (
		<button
			className={`InputClearButton ${!isVisible ? 'InputClearButton--hidden' : ''}`}
			onClick={onClick}
			onKeyDown={(event) => ['Enter', ' '].includes(event.key) && onClick()}
			tabIndex={0}
			aria-label='Clear text field button'
		>
			<img
				src={X}
				alt={'Clear input field icon'}
			/>
		</button>
	);
};