import { getApp } from 'firebase/app';
import { RecaptchaVerifier, getAuth } from 'firebase/auth';
import noop from 'lodash/noop';
import React, { useEffect, useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { TDR } from 'tdr-common';
import { useDebouncedCallback } from 'use-debounce';
import { ZodError, z } from 'zod';
import { fastCheckoutCheck, sendVerificationSMS } from '../../api/guest';
import { analytics } from '../../helpers/analytics';
import CheckIcon from '../../icons/green-check-circle.svg';
import { RHFTextInput } from './RHFTextInput';
import { VerificationCode } from '../sms-code-verification/VerificationCode';
import Spinner from '../Spinner';

export interface EmailCheckInputProps {
	name: string;
	email?: string;
	register: UseFormRegister<{
		firstName?: string;
		lastName?: string;
		email?: string;
		phone?: string;
	}>;
	enableFastCheckout: boolean;
	errors: FieldErrors<{
		firstName?: string;
		lastName?: string;
		email?: string;
		phone?: string;
	}>;
	guest?: TDR.Guest.Guest;
	onCodeSubmit: (
		code: string,
		sessionId: string,
		setInvalidCode: React.Dispatch<React.SetStateAction<boolean>>
	) => Promise<void>;
	disabled?: boolean;
}

export function EmailCheckInput({
	name,
	email: defaultEmail,
	guest,
	onCodeSubmit,
	register,
	enableFastCheckout,
	errors,
	disabled
}: EmailCheckInputProps) {
	const [obfuscatedPhone, setObfuscatedPhone] = useState<string>(undefined);
	const [emailLoading, setEmailLoading] = useState<boolean>(false);
	const [sessionId, setSessionId] = useState<string>(null);
	const [email, setEmail] = useState<string>(defaultEmail || null);
	const [emailCheckCache, setEmailCheckCache] = useState({});
	const [verifier, setVerifier] = useState<RecaptchaVerifier>(null);

	const app = getApp();
	const auth = getAuth(app);

	const checkEmail = (email: string) => {
		try {
			z.string().email().parse(email);
			setEmail(email);
			if (!emailLoading && emailCheckCache[email] === undefined) {
				setEmailLoading(true);
				fastCheckoutCheck({ email })
					.then((r) => {
						if (r.success) {
							setObfuscatedPhone(r.obfuscatedPhone);
							setEmailCheckCache({ ...emailCheckCache, [email]: true });
							setEmailLoading(false);
						}
						else {
							setEmailCheckCache({ ...emailCheckCache, [email]: false });
							analytics.track('Guest Not Found', { email: email });
							setEmailLoading(false);
						}
					})
					.catch(() => {
						setEmailCheckCache({ ...emailCheckCache, [email]: false });
						setEmailLoading(false);
					});
			}
		}
		catch (e) {
			if (e instanceof ZodError) {
				return;
			}
			else {
				throw e;
			}
		}
	};

	const debounced = enableFastCheckout ? useDebouncedCallback(checkEmail, 1000) : noop;

	useEffect(() => {
		if (emailCheckCache[email]) {
			signIn();
		}
	}, [emailCheckCache[email]]);

	const signIn = async () => {
		const verify =
      verifier ??
      new RecaptchaVerifier(auth, 'sign-in-button', {
      	size: 'invisible',
      	callback: (res) => res
      });
		if (!verifier) {
			setVerifier(verify);
		}
		const recaptchaToken = await verify.verify();
		recaptchaToken && analytics.track('Recaptcha Completed', { email: email });
		const res = await sendVerificationSMS({ email, recaptchaToken });
		setSessionId(res.sessionId);
	};

	return (
		<>
			<RHFTextInput
				label='Email'
				name={name}
				register={register}
				onChange={(e) => debounced(e.target.value)}
				error={errors.email?.message}
				icon={
					emailCheckCache[email] ? (
						<img src={CheckIcon} />
					) : emailLoading ? (
						<Spinner size={'xxs'} />
					) : null
				}
			/>

			{sessionId && !guest ?
				<VerificationCode
					obfuscatedPhone={obfuscatedPhone}
					confirmationHandler={(code, setInvalidCode) => onCodeSubmit(code, sessionId, setInvalidCode)}
					onClickResend={signIn}
					disableResend={disabled}
				/>: null }
		</>
	);
}
