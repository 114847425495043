import React from 'react';
import * as Progress from '@radix-ui/react-progress';
import styles from './ProgressBar.module.scss';

type ProgressBarProps = {
	value: number;
}

export const ProgressBar = ({ value }: ProgressBarProps) => {
	if (!value) {
		return null;
	}

	return (
		<Progress.Root className={styles.Root} value={value}>
			<Progress.Indicator className={styles.Indicator} style={{ transform: `translateX(-${100 - value}%)` }}/>
		</Progress.Root>
	);
};
