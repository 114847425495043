import Medusa from '@medusajs/medusa-js';
import { useIsMutating } from '@tanstack/react-query';
import every from 'lodash/every';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { MILLISECONDS_IN_AN_HOUR, TDR, toLuxon } from 'tdr-common';
import { MUTATION_KEY as releaseSessionKey } from '../../../api/releaseCheckoutSession';
import { MUTATION_KEY as updateCheckoutItemsKey, useUpdateCheckoutItems } from '../../../api/updateCheckoutItems';
import { CheckoutCTALabel, useCheckoutContext } from '../../../context/new/CheckoutContext';
import layoutStyles from '../../../layouts/CheckoutLayout.module.scss';
import { CheckoutGuardOutletContext } from '../../../routes/CheckoutGuard';
import { RestaurantGuardOutletContext } from '../../../routes/RestaurantGuard';
import { CTAButton } from '../../buttons';
import { Notice } from '../../notice-new/Notice';
import { AddOnItem } from '../AddOnItem';
import UpdateCheckoutItemsInput = TDR.CheckoutSession.UpdateCheckoutItemsInput;
import { analytics } from '../../../helpers/analytics';

const medusa = new Medusa({
	maxRetries: 3,
	baseUrl: 'https://medusa.tablz.com'
});

export type AddOnProduct = {
	id: string;
	title: string;
	tag: string;
	description?: string;
	leadTimeHours?: number;
	variantId: string;
	isSignatureItem: boolean;
	unit: string;
	price: {
		amount: number;
		unit: string;
	};
	imageUrl: string;
};

export type AddOnItemDTO = {
	id: string;
	quantity: number;
	variantId: string;
};

type AddOnState = 'selection_required' | 'skip_selection' | 'proceed';

export const AddOns = () => {
	// TODO:
	// const { featureFlags } = useOutletContext<RestaurantGuardOutletContext>();
	const { navConfig, updateCheckoutState } = useCheckoutContext();
	const { checkoutSession } = useOutletContext<CheckoutGuardOutletContext>();
	const { restaurant } = useOutletContext<RestaurantGuardOutletContext>();

	const [selectionState, setSelectionState] = useState<AddOnState>('skip_selection');
	const [addOns, setAddOns] = useState<AddOnItemDTO[]>(checkoutSession.items.filter((item) => item.type === 'addon') as AddOnItemDTO[]);
	const [products, setProducts] = useState<any[]>([]);
	//we use the expiration time because that is the absolute latest time the user can book before verifying again.
	const millisToBooking = checkoutSession.time && checkoutSession.expiration && toLuxon(checkoutSession.time, restaurant.timezone).toMillis() - toLuxon(checkoutSession.expiration, restaurant.timezone).toMillis();
	//TODO - use react query to fetch products
	useEffect(() => {
		if (!!restaurant['salesChannelId'] && products.length === 0) {
			medusa.products
				.list({
					sales_channel_id: [restaurant['salesChannelId']]
				})
				.then((res) => {
					if (res.products.length === 0) {
						navigate(`../${navConfig.policies.path}`);
					}
					const flattenedProducts = res.products
						.filter((product) => !product.metadata?.['LEAD_TIME_HOURS'] || millisToBooking > (Number(product.metadata?.['LEAD_TIME_HOURS']) * MILLISECONDS_IN_AN_HOUR))
						.flatMap((product) =>
							product.variants.map((variant) => ({
								...product,
								title: variant.title,
								variantId: variant.id,
								description: product.description,
								price: {
									amount: variant.prices?.[0]?.amount / 100 || 0, // all currencies are in cents, even AED
									unit: variant.prices?.[0]?.currency ?? 'USD'
								},
								isSignatureItem: product.metadata?.['SIGNATURE'] === 'true',
								displayOrder: Number(product.metadata?.['DISPLAY_ORDER'] ?? 0),
								tag: variant.material,
								unit: product.metadata?.['UNIT'] ?? 'unit',
								imageUrl: product.images?.[0]?.url
							}))
						);
					if(flattenedProducts.length === 0) {
						navigate(`../${navConfig.payment.path}`);
					}
					setProducts(flattenedProducts.sort((a, b) => a.displayOrder - b.displayOrder));
				})
				.catch((err) => {
					console.error(err);
					navigate(`../${navConfig.payment.path}`);
				});
		}
	}, [restaurant['salesChannelId']]);

	const navigate = useNavigate();

	const ctaLabel: Record<AddOnState, CheckoutCTALabel> = {
		selection_required: CheckoutCTALabel.ADDON_PROCEED,
		skip_selection: CheckoutCTALabel.SKIP,
		proceed: CheckoutCTALabel.ADDON_PROCEED
	};

	const areAddOnsEmpty = useCallback((addOns: AddOnItemDTO[]) => {
		return addOns?.length === 0 || every(addOns, (item: AddOnItemDTO) => item.quantity === 0);
	}, []);

	const { mutateAsync: updateCheckoutItems } = useUpdateCheckoutItems();

	const isUpdating = useIsMutating({
		mutationKey: [updateCheckoutItemsKey],
		exact: true
	});
	const isReleasing = useIsMutating({
		mutationKey: [releaseSessionKey],
		exact: true
	});

	const updateAddOns = (id: string, quantity: number, variantId: string) => {
		const existingItem = addOns?.findIndex((item) => item.id === id && item.variantId === variantId);
		analytics?.track('AddOns Updated', { id, quantity, variantId, reservationId: checkoutSession.id });
		if (existingItem !== -1) {
			const updatedAddOns = [...addOns];
			updatedAddOns[existingItem].quantity = quantity;
			setAddOns(updatedAddOns.filter((addOn) => addOn.quantity > 0));
		}
		else {
			setAddOns([...addOns, { id, quantity, variantId }].filter((addon) => addon.quantity > 0));
		}
	};

	//TODO - this needs to be better as well
	const handleProceedToNextStep = () => {
		const payload: UpdateCheckoutItemsInput = {
			checkoutId: checkoutSession.id,
			items: addOns as any
		};
		updateCheckoutState({ addOnItemsDTO: addOns });
		updateCheckoutItems({ input: payload })
			.then(() => {
				navigate(`../${navConfig.payment.path}`);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	useEffect(() => {
		if (areAddOnsEmpty(addOns)) {
			// TODO: handle mandatory addons
			setSelectionState('skip_selection');
		}
		else {
			setSelectionState('proceed');
		}
	}, [addOns]);

	return (
		<>
			<div className={`${layoutStyles.Body} ${layoutStyles.BodyNoGap}`}>
				<div className={layoutStyles.Section}>
					<Notice text={'Want to add something special to your reservation?\nPre-order now, pay later'} />
					{products.length > 0
						? products.map((product) => (
							<AddOnItem
								key={product.variantId}
								product={product}
								restaurant={restaurant}
								updateAddOns={updateAddOns}
								initialQuantity={addOns.find(addon => addon.variantId === product.variantId)?.quantity || 0}
							/>
						))
						: null}
				</div>
			</div>

			<div className={layoutStyles.Footer}>
				<CTAButton
					onClick={handleProceedToNextStep}
					buttonText={ctaLabel[selectionState]}
					loading={!!isUpdating}
					disabled={!!isUpdating || !!isReleasing}
				/>
			</div>
		</>
	);
};
