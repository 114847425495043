import React from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';
import { getPatioSection } from '../helpers/getPatioSection';
import { RestaurantGuardOutletContext } from './RestaurantGuard';

type PatioGuardProps = {
	children: React.ReactNode;
}

export const PatioGuard = ({ children }: PatioGuardProps) => {
	const { restaurant, tables, featureFlags } = useOutletContext<RestaurantGuardOutletContext>();

	const patioSection = getPatioSection(tables) || restaurant.settings?.alternativeBookingOption?.label === 'Outdoor';


	if (!patioSection) {
		return <Navigate to={`/${restaurant.slug}/explore`} />;
	}

	// If flag not enabled, or error fetching flag, navigate back to main landing page (/:restaurantSlug)
	if (!featureFlags?.patio && !(restaurant.settings?.alternativeBookingOption?.label === 'Outdoor')) {
		return <Navigate to="../" />;
	}

	return <>{children}</>;

};