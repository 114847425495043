import React from 'react';
import { Link, To } from 'react-router-dom';
import X from '../../icons/x.svg';

import styles from './buttons.module.scss';
export interface CloseButtonProps {
	to: To;
	className?: string;
}

export function CloseButton({ to, className }: CloseButtonProps) {
	return (
		<Link to={to} className={`${styles.IconWrapper} ${styles['IconWrapper--default']} ${className}`}>
			<img src={X} alt='Close button' />
		</Link>
	);
}
