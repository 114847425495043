import React from 'react';
import { Link, To } from 'react-router-dom';
import SearchNormal1 from 'iconsax-react/dist/esm/SearchNormal1';

import styles from './buttons.module.scss';

export interface SearchButtonProps {
	to: To;
}

export function SearchButton({ to }: SearchButtonProps) {
	return (
		<Link to={to} className={`${styles.IconWrapper} ${styles['IconWrapper--search']}`}>
			<SearchNormal1 />
		</Link>
	);
}
