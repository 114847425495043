import { useEffect, useRef, useState } from 'react';

export const useScrollToElement = (options?: IntersectionObserverInit): [boolean, React.RefObject<HTMLDivElement>, () => void] => {
	const [isVisible, setIsVisible] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	const scrollToElement = () => {
		containerRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting), options);

		if (containerRef?.current) {
			observer.observe(containerRef.current);
		}

		return () => {
			observer.disconnect();
		};
	});

	return [isVisible, containerRef, scrollToElement];
};