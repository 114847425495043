import { TDR } from 'tdr-common';
import flatMap from 'lodash/flatMap';

type LineItem = TDR.Invoice.LineItem
type LineItems = LineItem[]

/** Returns true if lineItem contains any per-guest charges, and false otherwise. */
export const doesItemHavePerGuestCharges = (lineItem: LineItem) => {
	const perGuestCharges = lineItem.charges.filter(
		(charge) => charge.type === 'per-guest' && charge.target === 'total'
	);
	return perGuestCharges.length > 0;
};

/** Retrieves the table selection fee item from an invoice.
 * 	Throws an error if more than one table fee is found.
 */
export const getTableSelectionFeeItem = (lineItems: LineItems) => {
	const tableFeeItems = lineItems.filter(lineItem => lineItem.item?.type === 'table');

	if (tableFeeItems.length > 1) {
		throw new Error('Cannot have more than 1 table fee line item');
	}

	return tableFeeItems[0];
};

/** Retrieves the promo code line item, if any, from an invoice.
 *  Throws an error if more than one coupon item is found.
 */
export const getPromoCodeDiscountItem = (lineItems: LineItems) => {
	const couponLineItems = lineItems.filter(lineItem => lineItem.item?.type === 'coupon');

	if (couponLineItems.length > 1) {
		throw new Error('Cannot apply more than 1 promo code discount');
	}

	return couponLineItems[0];
};

/** Returns the sum of all deposit values in lineItems */
export const sumDepositLineItems = (lineItems: LineItems) => {
	return lineItems.reduce(
		(totalDeposits, lineItem) => totalDeposits + (lineItem.deposit || 0),
		0
	);
};

/** Returns the sum of all tax values in lineItems */
export const sumTaxLineItems = (lineItems: LineItems) => {
	return flatMap(lineItems)
		.filter((adj) => adj.tax)
		.reduce((total, adj) => total + adj.total, 0);
};

/** Returns the sum of all deferred values in lineItems */
export const sumDeferredLineItems = (lineItems: LineItems) => {
	return lineItems.reduce(
		(totalDeferred, lineItem) => totalDeferred + (lineItem.deferred || 0),
		0
	);
};

/** Returns the sum of all values that are not tax values in lineItems */
export const sumOtherLineItems = (lineItems: LineItems) => {
	return flatMap(lineItems)
		.filter((adj) => !adj.tax)
		.reduce((total, adj) => total + adj.total, 0);
};