import React from 'react';
import { Divider } from '../components/divider/Divider';
import styles from './ResultPageLayout.module.scss';

type ResultPageChild = {
	children: React.ReactNode;
};

type ResultPageLayoutProps = ResultPageChild & {
	headerIcon?: string;
	iconAltText?: string;
};
export function ResultPageLayout({ headerIcon, iconAltText, children }: ResultPageLayoutProps) {
	return (
		<div className={styles.Container}>
			{headerIcon && <img className={styles.Icon} src={headerIcon} alt={iconAltText} />}
			{children}
		</div>
	);
}

const ResultTitle = ({ children }: ResultPageChild) => (
	<>
		<h2 className={styles.Title}>{children}</h2>
		<Divider width={129} />
	</>
);

const ResultBody = ({ children }: ResultPageChild) => <div className={styles.Body}>{children}</div>;

const ResultFooter = ({ children }: ResultPageChild) => <div className={styles.Footer}>{children}</div>;

ResultPageLayout.Title = ResultTitle;
ResultPageLayout.Body = ResultBody;
ResultPageLayout.Footer = ResultFooter;
