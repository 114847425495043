import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { RestaurantGuardContext } from './RestaurantGuard';
import { mostCompatibleGroupSize } from '../helpers/groupSIze';


export const SkipTrafficGuard = ( ) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const parentContext = useContext(RestaurantGuardContext);

	useEffect(() => {
		if (searchParams.get('skip')?.toLowerCase() === 'true') {
			// Skip landing page redirection
			if(parentContext.featureFlags?.autoSelectGroupSize) {
				const groupSize = mostCompatibleGroupSize(parentContext.tables);
				navigate(`explore/filters?groupSize=${groupSize}`);
			}
			else {

				navigate('explore/filters');
			}

		}
	},[searchParams]);

	return <Outlet context={{ ...parentContext }} />;
};