import React, { createContext, useContext, useState } from 'react';
import { TDR } from 'tdr-common';
import { ProviderProps } from '../common/types';

interface ModificationFlowContextType {
	hasStartedFlow: boolean;
	startFlow: () => void;
	newInvoice: Partial<TDR.Invoice>;
	setNewInvoice: React.Dispatch<React.SetStateAction<Partial<TDR.Invoice>>>;
	isPastModificationPeriod: boolean;
	isPastRefundablePeriod: boolean;
	setModificationStatus: React.Dispatch<React.SetStateAction<TDR.Reservation.ModificationDoneStatus>>;
	nonRefundableWarning: CutoffWarning;
	setNonRefundableWarning: React.Dispatch<React.SetStateAction<CutoffWarning>>;
	cannotModifyWarning: CutoffWarning;
	setCannotModifyWarning: React.Dispatch<React.SetStateAction<CutoffWarning>>;
}

interface CutoffWarning {
	isOpen: boolean;
	proceedTo?: string;
}

const initialCutoffWarningState: CutoffWarning = {
	isOpen: false
};

const ModificationFlowContext = createContext<ModificationFlowContextType | undefined>(undefined);

export const ModificationFlowProvider: React.FC<ProviderProps> = ({ children }) => {
	const [hasStartedFlow, setHasStartedFlow] = useState(false);
	const [modificationStatus, setModificationStatus] = useState<TDR.Reservation.ModificationDoneStatus>();
	const [newInvoice, setNewInvoice] = useState<Partial<TDR.Invoice>>();

	const [nonRefundableWarning, setNonRefundableWarning] = useState<CutoffWarning>(initialCutoffWarningState);
	const [cannotModifyWarning, setCannotModifyWarning] = useState<CutoffWarning>(initialCutoffWarningState);

	const isPastModificationPeriod =
    modificationStatus === TDR.Reservation.ModificationDoneStatus.ModificationPassedCutOffPeriod;
	const isPastRefundablePeriod =
    modificationStatus === TDR.Reservation.ModificationDoneStatus.CancellationPassedCutOffPeriod;

	const startFlow = () => {
		setHasStartedFlow(true);
	};

	return (
		<ModificationFlowContext.Provider
			value={{
				hasStartedFlow,
				startFlow,
				newInvoice,
				setNewInvoice,
				isPastModificationPeriod,
				isPastRefundablePeriod,
				setModificationStatus,
				nonRefundableWarning,
				setNonRefundableWarning,
				cannotModifyWarning,
				setCannotModifyWarning
			}}
		>
			{children}
		</ModificationFlowContext.Provider>
	);
};

export const useModificationFlowState = () => {
	const context = useContext(ModificationFlowContext);
	if (!context) {
		throw new Error('useModificationFlowState must be used within a ModificationFlowProvider');
	}
	return context;
};
