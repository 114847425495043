import React from 'react';
import styles from './MediaCarouselIndicator.module.scss';

export default function MediaCarouselIndicator(
	onClick: (e: React.MouseEvent | React.KeyboardEvent) => void,
	isSelected: boolean,
	index: number
) {
	return (
		<li className={styles.ListItem}>
			<button
				className={`${styles.Button} ${isSelected ? `${styles['Button--selected']}` : ''}`}
				tabIndex={0}
				type="button"
				aria-label={`Button for slide number ${index+1}`}
				onClick={onClick}
			/>
		</li>
	);
}
