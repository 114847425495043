import React from 'react';
import { TDR } from 'tdr-common';
import styles from './PriceLabel.module.scss';

type SearchResultPricingProps = {
	restaurant: TDR.Restaurant;
	priceAmount: number;
	displayUnit: string;
};

const SearchResultPricing = ({ restaurant, priceAmount, displayUnit }: SearchResultPricingProps) => {
	const { placement, symbol } = TDR.CurrencyConfig[restaurant?.currency || TDR.Currency.USD];

	const currencySymbol = <span className={styles.Price__Symbol}>{symbol}</span>;

	return (
		<div className={styles.Container}>
			{!!priceAmount && (
				<>
					<span className={styles.selectionFeeLabel}>SELECTION FEE</span>
					<span className={styles.Price}>
						<span translate='no'>{placement === TDR.CurrencyPlacement.BEFORE && currencySymbol}</span>
						<span translate='no'>{priceAmount}</span>
						<span translate='no'>{placement === TDR.CurrencyPlacement.AFTER && currencySymbol}</span>

						<span className={styles.Price__Unit}>/ {displayUnit}</span>
					</span>
				</>
			)}
		</div>
	);
};

export default SearchResultPricing;
