import Add from 'iconsax-react/dist/esm/Add';
import React from 'react';
import RightArrow from '../../icons/right-chevron-enabled.svg';
import styles from './AddOptionButton.module.scss';

type AddOptionButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	label: string;
	onClick: () => void;
};

export const AddOptionButton = ({ label, onClick, ...props }: AddOptionButtonProps) => {
	return (
		<button className={styles.Container} onClick={onClick} {...props}>
			<div className={styles.Copy}>
				<Add size={24} />
				{label}
			</div>

			<img className={styles.Arrow} src={RightArrow} alt='go to options' />
		</button>
	);
};
