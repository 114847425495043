import { useMutationState } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getDateFromNow, TDR, toLuxon } from 'tdr-common';
import { useEntityWithAvailabilityRestrictingPolicies } from '../../api/getRestaurantBySlug';
import { MUTATION_KEY } from '../../api/releaseCheckoutSession';
import { DAY_IN_MILISECONDS } from '../../common/constants';
import { HASH_ELEMENT_IDS } from '../../common/hashElementIds';
import { GlobalParameterContext } from '../../context/new/GlobalParameterContext';
import { analytics, FILTER_CHANGED } from '../../helpers/analytics';
import { getMaxSupportedGroupSize } from '../../helpers/getMaxSupportedGroupSize';
import { AvailabilityGuardContext, serializeSearchFiltersForURL } from '../../routes/AvailabilityGuard';
import { TableGuardOutletContext } from '../../routes/TableGuard';
import CTAButton from '../buttons/CTAButton';
import { CloseButton } from '../buttons/CloseButton';
import AvailabilityCalendar from '../calendar/AvailabilityCalendar';
import { isToday, toLocalJsDate } from '../calendar/Calendar.utils';
import { SameDayBookingInfo } from '../dialog/controlled-dialog/SameDayBookingInfo';
import { UnavailableDateInfo } from '../dialog/controlled-dialog/UnavailableDateInfo';
import { PastHorizonInfo } from '../dialog/info-dialog/PastHorizonInfo';
import { DirectLGBInboundNotice } from '../large-group-inbound-notice/variants/DirectLGBInboundNotice';
import { AvailabilityLevel } from '../notice/TableInventoryNotice';
import { PartySizeOption, PartySizeSelector } from '../party-size-selector/PartySizeSelector';
import { SidebarHeader } from '../sidebar-header/SidebarHeader';
import { TimeSelector } from '../time-selector/TimeSelector';
import { TimeSelectorSkeleton } from '../time-selector/TimeSelectorSkeleton';
import styles from './SearchContainer.module.scss';
import AvailabilityPrivateEventsCalendar from '../calendar/AvailabilityPrivateEventsCalendar';

type SearchContainerProps = {
	notice?: (noticeType: AvailabilityLevel) => React.ReactNode;
	results: TDR.Availability.searchOutput;
	tableIds: string[];
	footer?: ReactNode;
	isLoading?: boolean;
	groupSizeOptions: PartySizeOption[];
};
const SearchContainer = ({ notice, results, tableIds, footer, isLoading, groupSizeOptions }: SearchContainerProps) => {
	const { restaurant, updateSearchParams, searchFilters, featureFlags } = useOutletContext<AvailabilityGuardContext>();
	const { table: selectedTable } = useOutletContext<TableGuardOutletContext>();

	const [sameDayBookingOpen, setSameDayBookingOpen] = useState(false);
	const { searchString } = useContext(GlobalParameterContext);
	const navigate = useNavigate();

	const [noticeType, setNoticeType] = useState<AvailabilityLevel>();
	const [unavailableDateOpen, setUnavailableDateOpen] = useState(false);
	const [pastHorizonOpen, setPastHorizonOpen] = useState(false);
	const [lastUnavailableDateClicked, setLastUnavailableDateClicked] = useState<DateTime>(null);

	const { data: entityWithPolicies } = useEntityWithAvailabilityRestrictingPolicies({
		slug: restaurant.slug,
		id: selectedTable?.id,
		searchParams: searchFilters
	});

	const [showInboundLeadNotice, setShowInboundLeadNotice] = useState(false);
	// const [selectedServiceName, setSelectedServiceName] = useState<string>();
	const [selectedDate, setSelectedDate] = useState<string>();
	const [isSwitchingDate, setIsSwitchingDate] = useState(false);

	const searchContainerRef = useRef<HTMLDivElement>();

	const releaseSessionMutatationState = useMutationState({
		filters: { mutationKey: [MUTATION_KEY] },
		select: (mutation) => mutation.state.status
	})[0]; // useMutationState always returns an array

	// parameter resetting
	useEffect(() => {
		if (results && searchFilters && !isLoading) {
			const newFilters = { ...searchFilters };
			if (results.metadata?.time?.length === 0) {
				delete newFilters.time;
			}
			if (results.metadata?.date?.length === 0) {
				delete newFilters.date;
			}
			if (results.metadata?.groupSize?.length === 0) {
				delete newFilters.groupSize;
			}
			if (JSON.stringify(newFilters) !== JSON.stringify(searchFilters)) {
				navigate(
					{
						search: serializeSearchFiltersForURL(newFilters).toString()
					},
					{
						replace: true
					}
				);
			}
		}
	}, [results, searchFilters, isLoading]);

	useEffect(() => {
		if (featureFlags?.privateEvents) {
			setNoticeType(undefined);
		}
		else if (!searchFilters?.groupSize || !results || showInboundLeadNotice) {
			setNoticeType(undefined);
		}
		else if (!results?.metadata?.totalAvailable) {
			setNoticeType(AvailabilityLevel.NONE);
		}
		else if (results?.metadata?.groupSize?.length < TDR.LOW_INVENTORY_THRESHOLD) {
			setNoticeType(AvailabilityLevel.LOW);
		}
		else {
			setNoticeType(undefined);
		}
	}, [
		searchFilters?.groupSize,
		results?.metadata?.groupSize?.length,
		results?.metadata?.totalAvailable,
		showInboundLeadNotice
	]);

	const handleGroupSizeChange = (option: PartySizeOption) => {
		if (option.inboundRequest) {
			if (featureFlags?.largeGroupInbounds?.options?.version === 'form') {
				navigate(`/${restaurant.slug}/explore/request`);
			}
			else {
				setShowInboundLeadNotice(true);
			}
		}
		else {
			setShowInboundLeadNotice(option?.inboundRequest === true);

			updateSearchParams(
				{
					groupSize: option?.value ? Number(option.value) : undefined
				},
				{
					hash: HASH_ELEMENT_IDS.calendar
				}
			);
		}
	};

	const handleDateChange = (date: DateTime, availabilityPercent: number) => {
		const isSameDay = isToday(toLocalJsDate(date)) && availabilityPercent === 0;
		const TODAY = DateTime.now().setZone(restaurant?.timezone);
		const restaurantFurthestBookableDate = restaurant.bookableDaysInAdvance
			? new Date(TODAY.toJSDate().getTime() + restaurant.bookableDaysInAdvance * DAY_IN_MILISECONDS)
			: null;
		const entityHorizonCutoff = entityWithPolicies?.['availabilityRestrictingPolicies']?.find(
			(policy) => policy.type === 'BookingHorizon'
		)?.cutoff;
		const furthestBookableDate = entityHorizonCutoff
			? getDateFromNow(entityHorizonCutoff['value'], entityHorizonCutoff['unit'])
			: restaurantFurthestBookableDate;
		const isPastHorizon = date.toSeconds() > toLuxon(furthestBookableDate, restaurant.timezone)?.toSeconds();

		if (isSameDay) {
			setSameDayBookingOpen(true);
		}
		else if (availabilityPercent > 0) {
			setSameDayBookingOpen(false);
			setUnavailableDateOpen(false);
			if (date.toISODate() !== selectedDate) {
				analytics.track(FILTER_CHANGED, {
					filterName: 'date',
					filterValue: date.toISO(),
					sameDay: isSameDay,
					...(selectedTable
						? { searchFlow: 'find-a-table' }
						: {
							tablesShown: results?.metadata?.totalAvailable
						})
				});
			}
		}
		else if (isPastHorizon) {
			setPastHorizonOpen(true);
		}
		else {
			setUnavailableDateOpen(true);
			setLastUnavailableDateClicked(date);
		}
	};

	const handleTimeslotChange = (newTime: string, price?: number) => {
		if (newTime !== searchFilters.time) {
			analytics.track(FILTER_CHANGED, {
				filterName: 'timeslot',
				filterValue: newTime,
				price,
				...(selectedTable
					? { searchFlow: 'find-a-table' }
					: {
						tablesShown: results?.metadata?.totalAvailable
					})
			});
		}

		updateSearchParams({
			time: newTime ?? undefined
		});
	};

	const handleClearSelections = () => {
		navigate(
			{
				search: ''
			},
			{ replace: true }
		);
	};

	useEffect(() => {
		if (selectedDate !== searchFilters.date) {
			setIsSwitchingDate(isLoading);
		}
		if (searchFilters.date && !isLoading) {
			setSelectedDate(searchFilters.date);
		}
	}, [searchFilters.date, isLoading]);

	const anyFiltersSet = !!searchFilters?.groupSize || !!searchFilters?.date || !!searchFilters?.time;

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.4 }}
			className={styles.Container}
			ref={searchContainerRef}
			id='SearchContainer'
		>
			<header className={styles.Header}>
				<SidebarHeader
					title='Find Availability'
					subtitle={selectedTable ? selectedTable.name : restaurant.name}
					buttonRight={
						<CloseButton
							to={{
								pathname: '../',
								search: searchString
							}}
						/>
					}
				/>
			</header>

			<section className={styles.Body} id='scrollableArea'>
				<PartySizeSelector
					searchFilters={searchFilters}
					onChange={handleGroupSizeChange}
					options={groupSizeOptions}
					defaultValue={searchFilters?.groupSize || undefined}
					analyticsOption={
						selectedTable
							? { searchFlow: 'find-a-table' }
							: {
								tablesShown: results?.metadata?.totalAvailable
							}
					}
				/>

				{showInboundLeadNotice && !!featureFlags?.largeGroupInbounds?.enabled && !!restaurant?.phoneNumber ? (
					<DirectLGBInboundNotice
						maxGroupSize={getMaxSupportedGroupSize(groupSizeOptions)}
						restaurant={restaurant}
					/>
				): null}

				{notice?.(noticeType)}

				{featureFlags?.privateEvents ? (
					<AvailabilityPrivateEventsCalendar
						disabled={showInboundLeadNotice || searchFilters.groupSize === undefined}
						onChange={handleDateChange}
						filters={searchFilters}
						entityWithPolicies={entityWithPolicies}
					/>):(
					<AvailabilityCalendar
						disabled={showInboundLeadNotice || searchFilters.groupSize === undefined}
						onChange={handleDateChange}
						filters={searchFilters}
						entityWithPolicies={entityWithPolicies}
					/>)}

				<div id={HASH_ELEMENT_IDS.timeslots}>
					{!showInboundLeadNotice &&
            !!searchFilters?.date &&
            (isSwitchingDate || !selectedDate || !tableIds ? (
            	<TimeSelectorSkeleton halfHeight={!selectedTable} />
            ) : (
            	<TimeSelector
            		restaurant={restaurant}
            		table={selectedTable}
            		searchFilters={searchFilters}
            		tableIds={tableIds}
            		isReleasingSession={releaseSessionMutatationState === 'pending'}
            		hideFreePricing={featureFlags?.hideFreePricing}
            		onTimeslotChange={(value, _timeslot, price) => {
            			handleTimeslotChange(value, price);
            		}}
            	/>
            ))}
				</div>

				{anyFiltersSet && (
					<CTAButton variant='text' buttonText='Clear Selections' textColor='#F00' onClick={handleClearSelections} />
				)}
			</section>

			<footer className={styles.Footer}>{footer}</footer>

			<SameDayBookingInfo
				isOpen={sameDayBookingOpen}
				onClose={() => setSameDayBookingOpen(false)}
				restaurant={restaurant}
			/>

			<PastHorizonInfo
				entityWithPolicies={entityWithPolicies}
				isOpen={pastHorizonOpen}
				onClose={() => setPastHorizonOpen(false)}
			/>

			<UnavailableDateInfo
				isOpen={unavailableDateOpen}
				onClose={() => setUnavailableDateOpen(false)}
				date={lastUnavailableDateClicked}
				groupSize={searchFilters.groupSize}
				restaurant={restaurant}
			/>
		</motion.div>
	);
};

export default SearchContainer;
