
import React from 'react';
import { ProviderProps } from '../common/types';
import { FirebaseProvider } from './FirebaseContext';

export const AppContextProvider = ({ children }: ProviderProps) => {
	return (
		<FirebaseProvider>
			{children}
		</FirebaseProvider>
	);
};



