import React from 'react';
import styles from './buttons.module.scss';
import back from '../../icons/left-chevron-enabled.svg';

type BackButtonProps = {
	onClick: () => void;
}

const BackButton = ({ onClick }: BackButtonProps) => {
	return (
		<button className={styles.BackButton} onClick={onClick} aria-label='Back button'>
			<img src={back} alt='Back Button Icon' />
		</button>
	);
};

export default BackButton;