import React from 'react';
import X from '../../icons/x.svg';

import styles from './buttons.module.scss';
export interface CloseButtonNonLinkProps {
	onClick: () => void;
	className?: string;
}

export function CloseButtonNonLink({ onClick, className }: CloseButtonNonLinkProps) {
	return (
		<div onClick={onClick} className={`${styles.IconWrapper} ${styles['IconWrapper--default']} ${className}`}>
			<img src={X} alt='Close button' />
		</div>
	);
}
